import { AnalyticsActionTypes, type TrackErrorModel } from '@core/analytics';

import type {
  RfaErrorTrackerOptions,
  RfaFocusChangeTrackerOptions,
  RfaValueChangeTrackerOptions,
} from '../models/rfa-tracker-options.interface';

export function focusChangeOptionsToConfig(
  options: RfaFocusChangeTrackerOptions,
  defaults: Partial<RfaFocusChangeTrackerOptions> = {}
): any {
  const completeOptions = {
    ...defaults,
    ...options,
  };

  return {
    field: completeOptions.fieldName ?? '',
    rendering: completeOptions.componentName
      ? { componentName: completeOptions.componentName }
      : undefined,
    hashValue: completeOptions.hashValue ?? false,
  };
}

export function valueChangeOptionsToConfig(
  options: RfaValueChangeTrackerOptions & { value: string },
  defaults: Partial<RfaValueChangeTrackerOptions> = {}
): any {
  const completeOptions = {
    ...defaults,
    ...options,
  };

  return {
    rendering: completeOptions.componentName
      ? { componentName: completeOptions.componentName }
      : undefined,
    label: completeOptions.fieldName ?? '',
    inputLabel: completeOptions.value,
    hashValue: completeOptions.hashValue ?? false,
    trackFocus: false,
    sendValue: true,
  };
}

export function errorOptionsToConfig(
  options: RfaErrorTrackerOptions,
  defaults: Partial<RfaErrorTrackerOptions> = {}
): TrackErrorModel {
  const completeOptions = {
    ...defaults,
    ...options,
  };

  return {
    rendering: completeOptions.componentName
      ? { componentName: completeOptions.componentName }
      : undefined,
    field: completeOptions.description ?? '',
    description: completeOptions.fieldName ?? '',
    type: AnalyticsActionTypes.SYSTEM_ERROR,
    step: 0,
  };
}
