<a
  [routerLink]="['/' + searchResultsPage]"
  [queryParams]="params(query)"
  [queryParamsHandling]="'replace'"
  class="suggestion__content"
  wlKeyboardNavigationTarget
  [attr.aria-label]="'Doe zoekopdracht naar ' + query"
>
  <fa-icon icon="magnifying-glass"></fa-icon>
  @if (value) {
    <div class="suggestion__text" [innerHTML]="value"></div>
  } @else {
    <ng-content></ng-content>
  }
</a>
