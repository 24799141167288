<div class="position-relative h-100">
  @if (variant === 'card') {
    <wl-commodity-offer-card
      [offer]="offer"
      [showLoadingState]="showLoadingState"
    >
      <ed-commodity-offer-header
        [duration]="offer?.durationTitle"
        [productTitle]="offer?.productTitle"
        [productSubTitle]="offer?.productSubtitle"
        [incentive]="offer?.incentiveTitle"
        [highlight]="offer?.isHighlightedLabel"
        [showPriceTag]="false"
        [isDynamicProduct]="offer?.isDynamicProduct"
        [dynamicPricingTitle]="offer?.dynamicPricingTitle"
      ></ed-commodity-offer-header>

      <div class="d-flex flex-column flex-grow-1 mt-2">
        <wl-commodity-offer-price-tag
          [offer]="offer"
          [content]="content"
          [showBudgetbillProposal]="false"
          class="mb-3"
        ></wl-commodity-offer-price-tag>

        <ng-container *ngTemplateOutlet="offerSharedContent"></ng-container>
      </div>
    </wl-commodity-offer-card>
  }
  @if (variant === 'accordion') {
    <wl-commodity-offer-accordion
      [showLoadingState]="showLoadingState"
      [opened]="active"
      [offer]="offer"
    >
      <ng-template #accordionHeader>
        <ed-commodity-offer-header
          [duration]="offer?.durationTitle"
          [highlight]="offer?.isHighlightedLabel"
          [incentive]="offer?.incentiveTitle"
          [price]="offer?.expectedMonthlyAmount"
          [priceDurationLabel]="content?.priceDurationLabel"
          [productSubTitle]="offer?.productSubtitle"
          [productTitle]="offer?.productTitle"
          [isDynamicProduct]="offer?.isDynamicProduct"
          [dynamicPricingTitle]="offer?.dynamicPricingTitle"
          [showPriceTag]="true"
        ></ed-commodity-offer-header>
      </ng-template>
      <ng-template #accordionBody>
        <div class="d-flex flex-column mt-n3 mx-n2">
          <ng-container *ngTemplateOutlet="offerSharedContent"></ng-container>
        </div>
      </ng-template>
    </wl-commodity-offer-accordion>
  }
</div>

<ng-template #offerSharedContent>
  <wl-commodity-price-breakdown
    [offer]="offer"
    [content]="content"
    class="mb-2"
  ></wl-commodity-price-breakdown>
  <div class="flex-grow-1"></div>

  <div class="d-flex flex-column">
    @if (selectableOffer) {
      <button
        class="btn btn-sales-primary mb-2 w-100"
        (click)="selectOffer.emit()"
        wlAutomationId="offer-submit-button"
        wlCSCapture
      >
        {{ content?.offerSubmitButtonLabel }}
      </button>
    }

    <ed-commodity-vertical-offer-dropdown
      [offer]="offer"
      [content]="content"
      (viewTariffs)="viewTariffModal.emit()"
      [expanded]="showTariffs"
      (expand)="openTariffDropdown.emit()"
      (collapse)="closeTariffDropdown.emit()"
    ></ed-commodity-vertical-offer-dropdown>

    @if (content?.moreInfoPriceAndConditionLinkLabel) {
      <div class="d-flex justify-content-center">
        <button
          class="btn p-0 border-0 btn-link mt-2 mb-1"
          (click)="openMoreInfo.emit()"
          wlAutomationId="info-card-button"
          wlCSCapture
        >
          {{ content?.moreInfoPriceAndConditionLinkLabel }}
        </button>
      </div>
    }
  </div>
</ng-template>
