import { Component, Input, type OnInit } from '@angular/core';

import { focusChangeOptionsToConfig } from '../../helpers/tracker-options-to-config';
import { RfaFormControl } from '../../../level1';
import type { RfaComponents } from '../RfaComponents';

@Component({
  selector: 'rfa-text-area',
  templateUrl: './rfa-text-area.component.html',
  styleUrls: ['../styles.scss'],
})
export class RfaTextAreaComponent implements OnInit {
  @Input({ required: true }) control!: RfaFormControl<
    (typeof RfaComponents.TextArea)['valueType'],
    typeof RfaComponents.TextArea
  >;
  @Input() newDesign = false;

  autocompleteId!: string;

  ngOnInit(): void {
    this.autocompleteId =
      this.control.options.autocompleteId ??
      this.control.generateRandomAutocompleteId();
  }

  get label(): string {
    return this.control.options.label ?? '';
  }

  get placeholder(): string {
    return this.control.options.placeholder ?? '';
  }

  focusChangeTrackConfig() {
    return focusChangeOptionsToConfig(
      this.control.options.analytics?.focusChangeTracker ?? {
        fieldName: this.control.options.label,
      },
      {
        fieldName: this.control.options.label,
        hashValue: false,
      }
    );
  }
}
