import { EnvConfigBrand } from '@core/config-models';

import type { DefaultLoadTranslationFilesConfig } from './interfaces';

/**
 * Dutch ISO language code
 */
export const dutchIsoLanguage = 'nl-NL';

/**
 * Default path to the non-brand-specific json translation file
 */
export const defaultJsonTranslationPath = './i18n/nl-NL.json';

/**
 * Default paths to the brand-specific json translation files
 */
export const defaultBrandJsonTranslationPaths = {
  [EnvConfigBrand.ED]: './i18n/ed.nl-NL.json',
  [EnvConfigBrand.Essent]: './i18n/essent.nl-NL.json',
};

/**
 * Default namespace for the translations
 */
export const appNamespace = 'app';
export const appButtonsNamespace = `${appNamespace}.buttons`;
export const appLabelsNamespace = `${appNamespace}.labels`;
export const appPrepositionsNamespace = `${appNamespace}.prepositions`;

/**
 * Create a default load translation files config for Dutch translations
 *
 * @param config the config to use to create the default config
 */
export const defaultLoadTranslationFilesConfig = (
  config: DefaultLoadTranslationFilesConfig
) => ({
  namespace: config.namespace,
  jsonTranslationPath: defaultJsonTranslationPath,
  brandJsonTranslationPaths: config.addBrandJsonTranslationFiles
    ? defaultBrandJsonTranslationPaths
    : undefined,
});
