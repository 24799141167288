import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Status } from '@essent/common';
import { AuthenticationStatus } from '@account/models';

import * as fromAccount from './account.reducer';
import { getAccountState } from './feature.selectors';

const getAccount = createSelector(
  getAccountState,
  (feature) => feature.account
);

const getUserInfo = createSelector(getAccount, fromAccount.getData);

export const getAccountStatus = createSelector(
  getAccount,
  fromAccount.getStatus
);

const getAuthenticated = createSelector(
  getAccount,
  fromAccount.getAuthenticated
);

export const getAccountHasError = createSelector(
  getAccountStatus,
  (status) => status === Status.ERROR
);

export const getIsLoggingIn = createSelector(
  getAuthenticated,
  (status) => status === AuthenticationStatus.Pending
);

export const getIsLoggedIn = createSelector(
  getAuthenticated,
  (status) => status === AuthenticationStatus.Success
);

export const getIsLoggedOut = createSelector(
  getAuthenticated,
  (status) => status === AuthenticationStatus.Failure
);

export const getAccountId = createSelector(
  getUserInfo,
  (data) => data && data.accountId
);

export const getAccountIdIfExists = createSelector(
  getAccountState,
  (state) => state?.account?.data?.accountId
);

export const getOnlineAccountId = createSelector(
  getUserInfo,
  (data) => data && data.onlineAccountId
);

export const getHasMultipleAccounts = createSelector(
  getUserInfo,
  (data) => data && data.hasMultipleAccounts
);

export const getHasAuthenticator = createSelector(
  getUserInfo,
  (data) => data && data.hasAuthenticator
);

export const selectAccountId = pipe(select(getAccountId), filter(isNotNullish));

export const selectOnlineAccountId = pipe(
  select(getOnlineAccountId),
  filter(isNotNullish)
);

export const selectHasAuthenticator = pipe(
  select(getHasAuthenticator),
  filter(isNotNullish)
);
