<div class="d-flex flex-column">
  <div class="d-flex">
    <div class="me-2 w-100">
      <ng-container [ngTemplateOutlet]="postalCode"></ng-container>
    </div>
    <div class="me-1">
      <ng-container [ngTemplateOutlet]="houseNumber"></ng-container>
    </div>
    <div>
      <ng-container [ngTemplateOutlet]="addition"></ng-container>
    </div>
  </div>
  <div class="d-flex flex-column">
    <ng-container [ngTemplateOutlet]="errorMessages"></ng-container>
  </div>
</div>

<ng-template #postalCode>
  <wl-text
    wlAutocomplete="postal-code"
    [rendering]="rendering"
    [step]="trackStep.step"
    [stepName]="trackStep.stepName"
    fieldName="postcode"
    [sendValue]="false"
    [valueConverter]="_uppercaseConverter"
    [formsControl]="formState.controls.postalCode"
    [placeholder]="content.PostalCodePlaceholder"
    [label]="floatingLabels ? content.PostalCodeLabel : ''"
    [floatingLabel]="floatingLabels"
  ></wl-text>
</ng-template>
<ng-template #houseNumber>
  <wl-number
    wlAutocomplete="housenumber"
    [rendering]="rendering"
    [step]="trackStep.step"
    [stepName]="trackStep.stepName"
    fieldName="huisnummer"
    [sendValue]="false"
    [formsControl]="formState.controls.communicationNumber"
    [placeholder]="content.HouseNumberPlaceholder"
    [label]="floatingLabels ? content.HouseNumberLabel : ''"
    [floatingLabel]="floatingLabels"
  ></wl-number>
</ng-template>
<ng-template #addition>
  <wl-text
    [rendering]="rendering"
    [stepName]="trackStep.stepName"
    [step]="trackStep.step"
    fieldName="toevoeging"
    [sendValue]="false"
    [formsControl]="formState.controls.houseNumberAddition"
    [label]="floatingLabels ? content.HouseNumberAdditionLabel : ''"
    [placeholder]="content.HouseNumberAdditionPlaceholder"
    [floatingLabel]="floatingLabels"
    wlAutocompleteDisabled
  ></wl-text>
</ng-template>
<ng-template #errorMessages>
  @if (formState.controls.postalCode | isInvalid) {
    @if (formState.controls.postalCode?.errors?.required) {
      <wl-input-error
        [automationId]="automationId + '-postalcode-required-error'"
        [label]="content.PostalCodeRequiredError"
        messageDescription="postcode"
        [rendering]="rendering"
        [stepName]="trackStep.stepName"
      ></wl-input-error>
    }
    @if (formState.controls.postalCode?.errors?.isPostalCode) {
      <wl-input-error
        [automationId]="automationId + '-postalcode-invalid-error'"
        [label]="content.PostalCodeNotValidError"
        messageDescription="postcode"
        [rendering]="rendering"
        [stepName]="trackStep.stepName"
      ></wl-input-error>
    }
    @if (formState.controls.postalCode?.errors?.postalCodeIsExcluded) {
      <wl-input-error
        [automationId]="automationId + '-postalcode-is-excluded'"
        [label]="content.PostalCodeExcludedError"
        messageDescription="postcode"
        [rendering]="rendering"
        [stepName]="trackStep.stepName"
      ></wl-input-error>
    }
  }
  @if (formState.controls.communicationNumber | isInvalid) {
    @if (formState.controls.communicationNumber) {
      <wl-input-error
        [automationId]="automationId + '-housenumber-required-error'"
        [label]="content.HouseNumberRequiredError"
        messageDescription="huisnummer"
        [rendering]="rendering"
        [stepName]="trackStep.stepName"
      ></wl-input-error>
    }
    @if (formState.controls.communicationNumber?.errors?.isNumeric) {
      <wl-input-error
        [automationId]="automationId + '-housenumber-invalid-error'"
        [label]="content.HouseNumberNotValidError"
        messageDescription="huisnummer"
        [rendering]="rendering"
        [stepName]="trackStep.stepName"
      ></wl-input-error>
    }
  }
  @if (formState.controls.houseNumberAddition | isInvalid) {
    @if (formState.controls.houseNumberAddition?.errors?.maxLength) {
      <wl-input-error
        [automationId]="automationId + '-housenumber-addition-maxlength-error'"
        [rendering]="rendering"
        [label]="content.HouseNumberAdditionMaxLengthError"
      ></wl-input-error>
    }
  }
</ng-template>
