import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PUBLIC_PREFIX } from '@core/config-angular';
import type {
  PostHeatpumpV1Data,
  PostHeatpumpV1Response,
} from '@integration/api-client/feh';

@Injectable()
export class PostHeatpumpsLeadService
  implements BaseService<PostHeatpumpV1Data, PostHeatpumpV1Response>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/feh/leads/heat-pump/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: PostHeatpumpV1Data
  ): Observable<PostHeatpumpV1Response> {
    const headers = { Accept: 'application/json', Brand: 'essent' };
    return this.httpClient.post<PostHeatpumpV1Response>(
      this.endpoint,
      payload.requestBody,
      { headers }
    );
  }
}
