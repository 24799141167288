<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'controleren',
  }"
>
  <ng-template #content>
    <div class="mb-4">
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryBefore"
      ></span>
      @if (rendering?.fields?.confirmationSummaryTooltip?.value) {
        <wl-tooltip
          class="pe-1"
          [tooltip]="{
            description: rendering?.fields?.confirmationSummaryTooltip?.value,
          }"
        ></wl-tooltip>
      }
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryAfter"
      ></span>
    </div>
    @if (
      !fields?.TermsAndConditionsV2?.value ||
      fields?.EnableActionMailCheckbox?.value ||
      fields?.EnableInformationMailCheckbox?.value
    ) {
      <div class="row g-1">
        <div class="mb-2 col-12">
          @if (!fields?.TermsAndConditionsV2?.value) {
            <div class="mb-2">
              <ng-template
                [ngTemplateOutlet]="termsAndConditionsCheckbox"
              ></ng-template>
            </div>
          }
          <!-- ED/Essent ONLY -->
          @if (fields?.TermsAndConditionsV2?.value) {
            <div>
              <p
                *scText="fields?.ActionInformationMailFormGroupLabel"
                class="fw-bold"
              ></p>
            </div>
          }
          <!-- via e-mail -->
          @if (
            rendering?.fields?.EnableActionMailCheckbox?.value ||
            rendering?.fields?.EnableInformationMailCheckbox?.value
          ) {
            <div>
              <p *scText="fields?.EmailConsentLabel" class="fw-bold"></p>
            </div>
          }
          @if (rendering?.fields?.EnableActionMailCheckbox?.value) {
            <div class="mb-2">
              <wl-checkbox
                [rendering]="rendering"
                stepName="controleren"
                [step]="stepNumber"
                fieldName="email aanbiedingen"
                [formsControl]="formState?.controls?.actionMailAccepted"
              >
                {{ fields?.ActionMail?.value }}
              </wl-checkbox>
            </div>
          }
          @if (rendering?.fields?.EnableInformationMailCheckbox?.value) {
            <wl-checkbox
              [rendering]="rendering"
              stepName="controleren"
              [step]="stepNumber"
              fieldName="email nieuwsbrief"
              [formsControl]="formState?.controls?.informationMailAccepted"
            >
              {{ fields?.InformationMail?.value }}
            </wl-checkbox>
          }
          <!-- via telephone -->
          @if (
            rendering?.fields?.EnableEnergyAdvicePhoneCheckbox?.value ||
            rendering?.fields?.EnableSustainabilityAdvicePhoneCheckbox?.value
          ) {
            <div>
              <p *scText="fields?.PhoneConsentLabel" class="fw-bold mt-2"></p>
              @if (
                rendering?.fields?.EnableEnergyAdvicePhoneCheckbox?.value &&
                rendering?.fields?.EnergyAdvicePhone?.value
              ) {
                <div class="mb-2">
                  <wl-checkbox
                    [formsControl]="
                      formState?.controls?.energyAdvicePhoneAccepted
                    "
                    [label]="fields?.EnergyAdvicePhone?.value"
                    [rendering]="rendering"
                    [showValidation]="false"
                    [tooltip]="
                      fields?.EnergyAdvicePhoneTooltip?.value ?? undefined
                    "
                    fieldName="phone energy advice"
                    stepName="controleren"
                    wlAutomationId="energy-advice-phone-checkbox"
                  />
                </div>
              }
              @if (
                rendering?.fields?.EnableSustainabilityAdvicePhoneCheckbox
                  ?.value && fields?.SustainabilityAdvicePhone?.value
              ) {
                <div>
                  <wl-checkbox
                    [formsControl]="
                      formState?.controls?.sustainabilityAdvicePhoneAccepted
                    "
                    [label]="fields?.SustainabilityAdvicePhone?.value"
                    [rendering]="rendering"
                    [showValidation]="false"
                    [tooltip]="
                      rendering?.fields?.SustainabilityAdvicePhoneTooltip
                        ?.value ?? undefined
                    "
                    fieldName="phone sustainabiltiy advice"
                    stepName="controleren"
                    wlAutomationId="sustainabiltiy-advice-phone-checkbox"
                  />
                </div>
              }
            </div>
          }
          <!-- /ED/Essent ONLY -->
        </div>
      </div>
    }
    <div class="mb-4">
      <div
        class="d-inline me-1"
        *wlGenericRichText="rendering?.fields?.commercialSummary"
      ></div>
      <wl-tooltip
        [tooltip]="{
          description: rendering?.fields?.commercialTooltip?.value,
        }"
      ></wl-tooltip>
    </div>
    @if (fields?.TermsAndConditionsV2?.value) {
      <div class="terms-and-conditions-v2-container p-2 rounded">
        <p
          *scText="fields?.TermsAndConditionsFormGroupLabel"
          class="fw-bold"
        ></p>
        <ng-template
          [ngTemplateOutlet]="termsAndConditionsCheckbox"
        ></ng-template>
      </div>
    }
  </ng-template>
</wl-simple-progressive-form-card>

<ng-template #termsAndConditionsCheckbox>
  <wl-checkbox
    [rendering]="rendering"
    stepName="controleren"
    [step]="stepNumber"
    fieldName="voorwaarden geaccepteerd"
    class="d-block"
    [class.mb-2]="formState?.controls?.conditionsAccepted | isValid"
    [formsControl]="formState?.controls?.conditionsAccepted"
  >
    <span
      wlAutomationId="orderFulfillment-confirm-checkbox-label"
      *scText="fields?.AcceptConditionsTextBefore"
    ></span>
    @for (disclaimer of disclaimers; track $index; let i = $index) {
      <span>
        @if (disclaimer.href && disclaimer.key?.value) {
          <a
            [href]="disclaimer.href"
            [wlAutomationId]="disclaimer.automationId"
            target="_blank"
            *scText="disclaimer.key"
          ></a>
          @if (i + 1 <= disclaimers.length - 1) {
            <span>, </span>
          }
        }
      </span>
    }
    {{ 'And' | translate }}
    @if (offer) {
      <button
        (click)="openAOMModalClick($event)"
        class="tariffs-button btn btn-link p-0"
      >
        {{ 'Tarifs' | translate }}
      </button>
    }
    <span *scText="fields?.AcceptConditionsTextAfter"></span>
  </wl-checkbox>
  @if (formState?.controls?.conditionsAccepted | isInvalid) {
    <div class="ms-6 mb-2">
      <wl-input-error
        wlAutomationId="conditions-accepted-required-error"
        [messageField]="fields?.AcceptConditionsRequiredError"
        messageDescription="voorwaarden geaccepteerd"
        [rendering]="rendering"
        stepName="controleren"
        [step]="stepNumber"
      ></wl-input-error>
    </div>
  }
</ng-template>
