<header class="search-header">
  <div class="navbar p-2">
    <div class="nav-item">
      <a
        class="navbar-logo"
        [routerLink]="['/']"
        [queryParamsHandling]="'preserve'"
      >
        <img [src]="'assets/logo.svg?v=2' | deployUrl" alt="logo" />
      </a>
    </div>
    <div class="nav-item">
      <button class="search-header__close-button" (click)="close()">
        <span class="search-header__close-button-text">Sluiten</span>
        <fa-icon icon="xmark" size="xl"></fa-icon>
      </button>
    </div>
  </div>
</header>
<div class="search-header__input border-bottom">
  <wl-search-bar></wl-search-bar>
</div>
