import type { StreetAddress } from '@essent/new-customer';
import type { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import type { IconLabel } from '@legacy/common-ui-partials';

export const getAddressString = ({
  street,
  houseNumber,
  houseNumberExtension,
  postcode,
  city,
}: Partial<StreetAddress>) =>
  `${street ? street : `${postcode},`} ${houseNumber}${
    houseNumberExtension ? ` ${houseNumberExtension}` : ''
  }${city ? `, ${city}` : ''}`;

export const getConsumptionIconLabels = (
  {
    electricity: { supplyLow, supplyNormal, returnSupplyTotal },
    gas,
  }: ConsumptionValuesVM,
  electricityLabel: string,
  gasLabel: string
): IconLabel[] => {
  const consumptionIconLabels: IconLabel[] = [];

  if (supplyNormal > 0) {
    consumptionIconLabels.push({
      icon: supplyLow > 0 ? 'sun' : 'bolt-lightning',
      label: `${supplyNormal} ${electricityLabel}`,
    });
  }

  if (supplyLow > 0) {
    consumptionIconLabels.push({
      icon: 'moon',
      label: `${supplyLow} ${electricityLabel}`,
    });
  }

  if (gas > 0) {
    consumptionIconLabels.push({
      icon: 'fire-flame',
      label: `${gas} ${gasLabel}`,
    });
  }

  if (returnSupplyTotal) {
    consumptionIconLabels.push({
      icon: 'solar-panel',
      label: `${returnSupplyTotal} ${electricityLabel}`,
    });
  }

  return consumptionIconLabels;
};
