<div class="offer-card-header">
  @if (highlight && floatingHighlight) {
    <div class="offer-card-header__highlight-label floating">
      {{ highlight }}
    </div>
  }

  @if (incentive && showPriceTag && floatingIncentive) {
    <wl-commodity-offer-label
      [style]="'incentive'"
      [label]="incentive"
      class="offer-card-header__incentive-label"
    ></wl-commodity-offer-label>
  }

  <div class="d-flex flex-row align-items-center">
    <div class="flex-grow-1 d-flex flex-column">
      @if (highlight && !floatingHighlight) {
        <div class="d-flex">
          <div class="offer-card-header__highlight-label">
            {{ highlight }}
          </div>
          @if (incentive && showPriceTag && !floatingIncentive) {
            <div class="offer-card-header__incentive-sticker">
              {{ incentive }}
            </div>
          }
        </div>
      }
      <h4 class="offer-card-header__title">
        {{ isDynamicProduct ? dynamicPricingTitle : duration }}
      </h4>
      <span class="offer-card-header__subtitle">{{ productTitle }}</span>
    </div>

    @if (showPriceTag) {
      <ng-container *ngTemplateOutlet="priceTag"></ng-container>
    }
    @if (!showPriceTag) {
      <ng-container *ngTemplateOutlet="incentiveSticker"></ng-container>
    }
  </div>
</div>

<ng-template #priceTag>
  <div class="d-flex flex-column align-items-end me-1">
    <h2 class="offer-card-header__budget-bill-amount mb-0">
      {{ price ?? '-' | roundedCurrency }}
    </h2>
    <span class="offer-card-header__budget-bill-label">{{
      priceDurationLabel
    }}</span>
  </div>
</ng-template>

<ng-template #incentiveSticker>
  @if (incentive) {
    <div class="offer-card-header__incentive-sticker floating">
      {{ incentive }}
    </div>
  }
</ng-template>
