import { Inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type {
  HeatpumpsFunnelSettingsInterface,
  HeatpumpsFunnelStep,
} from '@innogy/eplus/models';
import type { GenericProductData } from '@legacy/common-ui-models';
import { JssPlatformService } from '@core/jss-platform';
import { of } from 'rxjs';

import { selectHeatpumpsFunnelSettings } from './heatpumps-funnel.selectors';
import { createFunnelEffectCreators } from '../../shared/generators/funnel/funnel.effects.factory';
import {
  handleHeatpumpsFunnelGenericErrorAction,
  handleHeatpumpsFunnelPrivateErrorAction,
  initializeHeatpumpsFunnelStepAction,
  mintHeatpumpsTrackingIdAction,
  onHeatpumpsFunnelNavigationAction,
  setHeatpumpsFunnelInitializedAction,
} from './heatpumps-funnel.actions';

@Injectable()
export class HeatpumpsFunnelEffects {
  funnelSettings$ = this.store$.select(selectHeatpumpsFunnelSettings);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly jss: JssPlatformService,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  private readonly effects = createFunnelEffectCreators<
    HeatpumpsFunnelStep,
    HeatpumpsFunnelSettingsInterface,
    GenericProductData
  >(
    this.actions$,
    this.funnelSettings$,
    of(this.jss.isEditorActive()),
    'startPage',
    'wizard',
    this.config,
    {
      initializeFunnelStepAction: initializeHeatpumpsFunnelStepAction,
      handleFunnelPrivateErrorAction: handleHeatpumpsFunnelPrivateErrorAction,
      onFunnelNavigationAction: onHeatpumpsFunnelNavigationAction,
      setFunnelInitializedAction: setHeatpumpsFunnelInitializedAction,
      handleFunnelGenericErrorAction: handleHeatpumpsFunnelGenericErrorAction,
      mintTrackingIdAction: mintHeatpumpsTrackingIdAction,
    }
  );

  public readonly onInitHeatpumpsFunnel$ =
    this.effects.initializeFunnelStepEffectCreator();

  public readonly mintHeatpumpsFunnelTrackingId$ =
    this.effects.mintTrackingIdEffectCreator();

  public readonly onHeatpumpsFunnelGenericError$ =
    this.effects.handleFunnelGenericErrorEffectCreator();

  public readonly onHeatpumpsFunnelNavigation$ =
    this.effects.onFunnelNavigationEffectCreator();

  public readonly onHeatpumpsFunnelPrivateError$ =
    this.effects.handleFunnelPrivateErrorEffectCreator();
}
