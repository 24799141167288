@if (item | navItemToJssRoute: language; as route) {
  <div class="dropdown__wrapper nav-item nav-link">
    @if (item.path) {
      <a
        role="button"
        class="dropdown__toggle"
        aria-haspopup="true"
        [title]="item.name"
        [attr.aria-expanded]="opened"
        (focus)="open()"
        (blur)="close()"
        (mouseenter)="open()"
        (mouseleave)="close()"
        (click)="clicked.emit(item)"
        *wlGenericLink="{ value: { href: item?.path } }"
        wlCSCapture
      >
        {{ item.name }}
        <fa-icon [icon]="'angle-down'"></fa-icon>
      </a>
    } @else {
      <button
        role="button"
        class="dropdown__toggle dropdown__toggle--button"
        aria-haspopup="true"
        [title]="item.name"
        [attr.aria-expanded]="opened"
        (focus)="open()"
        (blur)="close()"
        (mouseenter)="open()"
        (mouseleave)="close()"
        (click)="clicked.emit(item)"
      >
        {{ item.name }}
        <fa-icon [icon]="'angle-down'"></fa-icon>
      </button>
    }

    @if (item && item.children) {
      <div class="dropdown__menu-wrapper">
        <ul role="menu" class="dropdown__menu list-no-default-spacing">
          @for (child of item.children; track child) {
            <li role="menuitem" class="dropdown-item dropdown__custom-item">
              @if (child.path) {
                <a
                  class="dropdown-item-link"
                  *wlGenericLink="{ value: { href: child.path } }"
                  wlCSCapture
                >
                  {{ child.name }}
                </a>
              }
              @if (!child.path) {
                <a class="dropdown-item-link">
                  {{ child.name }}
                </a>
              }
            </li>
          }
        </ul>
      </div>
    }
  </div>
}
