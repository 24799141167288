<wl-checkbox
  [formsControl]="control"
  [rendering]="{ componentName: trackStep.toolName }"
  [step]="trackStep.step"
  [stepName]="trackStep.stepName"
  [fieldName]="fields.Label?.value"
  [tooltip]="fields.Tooltip?.value"
  [hashValue]="fields.HashValue?.value"
  [sendValue]="fields.SendValue?.value"
  [showValidation]="true"
>
  @if (fields.RichTextLabel?.value) {
    <div *wlGenericRichText="fields.RichTextLabel"></div>
  } @else {
    <div *scText="fields.Label"></div>
  }
</wl-checkbox>
