import { Component, Input } from '@angular/core';

import type { FooterAccordion } from './multiple-links-list.model';

@Component({
  selector: 'wl-multiple-links-list',
  templateUrl: './multiple-links-list.component.html',
  styleUrls: [
    './multiple-links-list.component.essent.scss',
    './multiple-links-list.component.ed.scss',
  ],
})
export class MultipleLinksListComponent {
  @Input()
  accordions?: FooterAccordion[];
  @Input()
  collapsible?: boolean;

  trackByItems(index: number, item: FooterAccordion): string {
    return item.title || `${index}`;
  }
}
