<div class="costs-per-unit-header">
  <p
    class="h5 mb-1 text-black"
    *scText="fields?.AomCostsPerUnitTitle"
    wlCSCapture
  ></p>
  @if (isVATIncluded) {
    <p
      wlAutomationId="including-tax-message"
      *scText="fields?.AomCostsPerUnitSubTitleIncludingTax"
      wlCSCapture
    ></p>
  } @else {
    <p
      wlAutomationId="excluding-tax-message"
      *scText="fields?.AomCostsPerUnitSubTitleExcludingTax"
      wlCSCapture
    ></p>
  }
  @if (isDynamicProduct) {
    <div *wlGenericRichText="fields?.DynamicPricingDescription"></div>
  }
</div>
<div class="costs-per-unit-table border">
  @if (electricityFixedDelivery) {
    @if (isSummerWinter) {
      <wl-aom-costs-per-unit-with-summer-winter
        [tariffPeriods]="costPerUnitVM?.electricity?.tariffPeriods"
        [energyTitle]="fields?.AomCostsPerUnitElectricityTitle"
        [deliveryTitle]="fields?.AomCostsPerUnitDeliveryTitle"
        [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitElectricitySubTitle"
        [fixedDeliveryCosts]="electricityFixedDelivery"
        [fixedRedeliveryCostsLabel]="
          fields?.AomCostsPerUnitRedeliveryCostsLabel
        "
        [fixedRedeliveryCosts]="electricityFixedRedelivery"
        [fixedRedeliveryCostsScale]="
          costPerUnitVM?.electricity?.fixedRedeliveryCostsScale
        "
        [redeliveryTitle]="fields?.AomCostsPerUnitRedeliveryTitle"
        [redeliveryDescription]="fields?.AomCostsPerUnitRedeliveryDescription"
      ></wl-aom-costs-per-unit-with-summer-winter>
    }

    @if (!isSummerWinter) {
      <wl-aom-costs-per-unit-without-summer-winter
        [tariffPeriods]="costPerUnitVM?.electricity?.tariffPeriods"
        [fixedDeliveryCosts]="electricityFixedDelivery"
        [energyTitle]="fields?.AomCostsPerUnitElectricityTitle"
        [deliveryTitle]="
          isDynamicProduct
            ? fields?.AomCostsPerUnitDeliveryTitleForDynamic
            : fields?.AomCostsPerUnitDeliveryTitle
        "
        [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitElectricitySubTitle"
        [fixedRedeliveryCostsLabel]="
          fields?.AomCostsPerUnitRedeliveryCostsLabel
        "
        [fixedRedeliveryCosts]="electricityFixedRedelivery"
        [fixedRedeliveryCostsScale]="
          costPerUnitVM?.electricity?.fixedRedeliveryCostsScale
        "
        [redeliveryTitle]="fields?.AomCostsPerUnitRedeliveryTitle"
        [redeliveryDescription]="fields?.AomCostsPerUnitRedeliveryDescription"
        [isDynamicProduct]="isDynamicProduct"
        [expectedPriceTitle]="fields?.ExpectedElectricityPriceDynamic"
        unit="ElectricityUsageUnit"
      ></wl-aom-costs-per-unit-without-summer-winter>
    }
  }
</div>

<div class="costs-per-unit-table border">
  @if (gasDelivery) {
    <wl-aom-costs-per-unit-without-summer-winter
      [tariffPeriods]="costPerUnitVM?.gas?.tariffPeriods"
      [fixedDeliveryCosts]="gasDelivery"
      [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitGasSubTitle"
      [energyTitle]="fields?.AomCostsPerUnitGasTitle"
      [isDynamicProduct]="isDynamicProduct"
      [expectedPriceTitle]="fields?.ExpectedGasPriceDynamic"
      unit="GasUsageUnit"
      [deliveryTitle]="
        isDynamicProduct
          ? fields?.AomCostsPerUnitDeliveryTitleForDynamic
          : fields?.AomCostsPerUnitDeliveryTitle
      "
    ></wl-aom-costs-per-unit-without-summer-winter>
  }
</div>
