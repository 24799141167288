import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { closeGenericModal } from '@innogy/common-ui/modals';
import {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';

export const redirectToElectricityOnlyPage = createEffect(
  (actions = inject(Actions), router = inject(Router)) =>
    actions.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          action.source === GenericModalSources.INCOMPATIBLE_PROMOTION &&
          action.result === GenericModalResults.PRIMARY
      ),
      tap(({ additionalPayload: { href: url } }) => {
        if (url) {
          router.navigateByUrl(url);
        }
      })
    ),
  { dispatch: false, functional: true }
);
