import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { CheckboxItem } from '@innogy/common-ui/forms';
import { TrackToolStep } from '@core/analytics';
import { ScCheckboxSetFormInput } from '@innogy/sitecore-forms/models';
import type { Boxed } from 'ngrx-forms';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-checkbox-set[control][fields][trackStep]',
  templateUrl: './sc-checkbox-set.component.html',
  styleUrls: [],
})
export class ScCheckboxSetComponent implements OnInit {
  @Input() control!: FormState<Boxed<string[]>>;
  @Input() fields!: ScCheckboxSetFormInput;
  @Input() trackStep!: TrackToolStep;

  checkboxItems: CheckboxItem[] = [];

  ngOnInit() {
    this.checkboxItems = this.fields.Items.map((item) => ({
      label: item.fields.Label.value as string,
      value: item.fields.Value.value,
      tooltip: {
        description: item.fields.Tooltip.value,
      },
      trackingLabel: item.fields.TrackingLabel.value,
    }));
  }
}
