<div class="sticker-header__header">
  <div
    class="sticker-header__header-content"
    [class.sticker-header--sticker-spacing]="!!sticker?.src"
  >
    @if (!!title) {
      <p class="h4 h3-md m-0">{{ title }}</p>
    }
    @if (!!subtitle) {
      <p class="sticker-header-subtitle m-0">
        {{ subtitle }}
      </p>
    }
  </div>
  @if (!!sticker?.src) {
    <div class="sticker-header__sticker">
      <img
        [src]="sticker?.src"
        [alt]="sticker?.alt"
        class="sticker-header__sticker-img"
      />
    </div>
  }
</div>
