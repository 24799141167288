@if (label) {
  <label
    [for]="formsControl?.id"
    class="fw-bold mb-0"
    wlAutomationId="non-floating-label"
    >{{ label }}</label
  >
}
<div
  #trackingWrapper
  class="mt-1"
  [wlTrackValueChange]="{
    rendering: rendering,
    label: fieldName || '',
    inputLabel: radioTrackingValue,
    sendValue: sendValue,
    hashValue: hashValue,
    trackFocus: false,
    stepName: stepName,
    step: step || 0,
  }"
>
  @for (radio of radioItems; track trackRadio(i, radio); let i = $index) {
    <div
      class="form-check"
      [class.form-check-inline]="inline"
      [class.mb-1]="!inline"
      [wlAutomationId]="formsControl?.id"
    >
      <!-- Only enable tracking on the first element to ensure NGRX focuses the first radio button when dispatching focus action -->
      <ng-container
        *ngTemplateOutlet="
          radio.disabled ? disabledInput : formsControlInput;
          context: {
            $implicit: radio,
            index: i,
            formsControl: formsControl,
            firstEnabledRadioIndex: firstEnabledRadioIndex,
          }
        "
      ></ng-container>
      <label
        [wlAutomationId]="getId(radio, i)"
        class="form-check-label"
        [for]="getId(radio, i)"
        wlCSCapture
      >
        <div>
          <span [class.pe-1]="radio.tooltip?.description">{{
            radio.label
          }}</span>
          @if (radio.tooltip) {
            <wl-tooltip [tooltip]="radio.tooltip"></wl-tooltip>
          }
        </div>
        @if (radio.sublabel) {
          <small
            class="form-text text-body-secondary d-block"
            *wlGenericRichText="radio.sublabel"
          >
          </small>
        }
      </label>
    </div>
  }
</div>

<ng-template
  #disabledInput
  let-radio
  let-index="index"
  let-formsControl="formsControl"
>
  @if (formsControl) {
    <input
      disabled
      data-disabled="true"
      class="form-check-input"
      type="radio"
      [name]="formsControl.id"
      [id]="getId(radio, index)"
      [value]="radio.value"
    />
  }
</ng-template>

<ng-template
  #formsControlInput
  let-radio
  let-index="index"
  let-formsControl="formsControl"
  let-firstEnabledRadioIndex="firstEnabledRadioIndex"
>
  @if (formsControl) {
    <input
      [ngrxFormControlState]="formsControl"
      class="form-check-input"
      type="radio"
      [name]="formsControl?.id"
      [id]="getId(radio, index)"
      [value]="radio.value"
      (focus)="onFocus()"
      (change)="onChange($event)"
      [ngrxEnableFocusTracking]="index === firstEnabledRadioIndex"
      [wlValidationClasses]="formsControl"
    />
  }
</ng-template>
