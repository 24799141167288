<fa-icon
  *ngIf="icon"
  [icon]="icon | scIcon"
  [ngClass]="iconColor"
  class="me-1"
></fa-icon>
<wl-rich-text
  *ngIf="rendering?.fields?.content?.value"
  [rendering]="rendering"
  class="align-items-center"
>
</wl-rich-text>
