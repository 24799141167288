import { NgModule } from '@angular/core';
import { ConfigModule } from '@core/config-angular';
import { Angulartics2Module } from 'angulartics2';

import { LoadAccountComponent } from './load-account/load-account.component';

@NgModule({
  declarations: [LoadAccountComponent],
  exports: [LoadAccountComponent],
  imports: [ConfigModule, Angulartics2Module],
})
export class AccountComponentsModule {}
