import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';

import { sendQuotationReactionAction } from './email-quotation-reaction.actions';

@Injectable({
  providedIn: 'root',
})
export class EmailQuotationReactionGuard {
  constructor(protected readonly store$: Store<any>) {}

  canActivate(input: GuardInput) {
    const token = input.activatedRoute.queryParams['quotation_token'] || '';
    this.store$.dispatch(sendQuotationReactionAction({ token }));
    return true;
  }
}
