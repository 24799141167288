<div
  class="modal-header align-items-start pb-1 pb-md-2 pb-lg-3"
  [ngClass]="autoCloseTime ? 'overflow-hidden position-relative' : ''"
>
  @if (autoCloseTime) {
    <div
      class="modal-progress-bar"
      [style.animation-duration]="autoCloseTime + 's'"
    ></div>
  }
  <h4 class="modal-title">
    {{ modalTitle }}
  </h4>
  <button
    type="button"
    class="btn-close flex-shrink-0"
    aria-label="Close"
    (click)="handleClick()"
  ></button>
</div>
<div class="modal-body">
  <ng-template [ngTemplateOutlet]="body?.templateRef ?? null"></ng-template>
</div>
<ng-template [ngTemplateOutlet]="footer?.templateRef ?? null"></ng-template>
