import { Component, Input, type OnInit } from '@angular/core';

import { RfaFormControl } from '../../../level1';
import { focusChangeOptionsToConfig } from '../../helpers/tracker-options-to-config';
import type { RfaComponents } from '../RfaComponents';
import type { RfaFocusChangeTrackerOptions } from '../../models/rfa-tracker-options.interface';

@Component({
  selector: 'rfa-number-input',
  templateUrl: './rfa-number-input.component.html',
  styleUrls: ['../styles.scss'],
})
export class RfaNumberInputComponent implements OnInit {
  @Input({ required: true }) control!: RfaFormControl<
    (typeof RfaComponents.NumberInput)['valueType'],
    typeof RfaComponents.NumberInput
  >;
  @Input() newDesign = false;

  autocompleteId!: string;

  ngOnInit(): void {
    this.autocompleteId =
      this.control.options.autocompleteId ??
      this.control.generateRandomAutocompleteId();
  }

  get label(): string {
    return this.control.options.label ?? '';
  }

  get placeholder(): string {
    return this.control.options.placeholder ?? '';
  }

  focusChangeTrackConfig() {
    const focusChangeOptions: RfaFocusChangeTrackerOptions = this.control
      .options.analytics?.focusChangeTracker ?? {
      fieldName: this.control.options.label,
    };

    return focusChangeOptionsToConfig(focusChangeOptions, {
      fieldName: this.control.options.label,
      hashValue: false,
    });
  }
}
