import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@core/analytics';
import { ScTextFormInput } from '@innogy/sitecore-forms/models';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-text[control][fields][trackStep]',
  templateUrl: './sc-text.component.html',
})
export class ScTextComponent {
  @Input() control!: FormState<string>;
  @Input() fields!: ScTextFormInput;
  @Input() trackStep!: TrackToolStep;
  @Input() floatingLabel = false;
}
