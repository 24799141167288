import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BecomeACustomerSharedModule } from '@innogy/become-a-customer/shared';
import { BecomeACustomerUiModule } from '@innogy/become-a-customer/ui';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { GenericLightboxModule } from '@innogy/common-ui/modals';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { CoreExperienceEditorComponentsModule } from '@core/experience-editor-components';
import { OfferMailer, offerSaverEffects } from '@innogy/offer-saver';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsContentsquareModule } from '@legacy/utils-contentsquare';
import {
  NgbCarouselModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CommodityOffersModule } from '@innogy/commodity-offers';
import { CommonUiPartialsModule } from '@legacy/common-ui-partials';
import { EffectsModule } from '@ngrx/effects';

import { HorizontalOfferCalculationBasedOnComponent } from './horizontal-offer/horizontal-offer-calculation-based-on/horizontal-offer-calculation-based-on.component';
import { HorizontalOfferCardComponent } from './horizontal-offer/horizontal-offer-card/horizontal-offer-card.component';
import { HorizontalOfferContainerComponent } from './horizontal-offer/horizontal-offer-container/horizontal-offer-container.component';
import { HorizontalOfferDurationFilterComponent } from './horizontal-offer/horizontal-offer-duration-filter/horizontal-offer-duration-filter.component';
import { HorizontalOfferLoaderComponent } from './horizontal-offer/horizontal-offer-loader/horizontal-offer-loader.component';
import { HorizontalOfferMultipleCardsCarouselComponent } from './horizontal-offer/horizontal-offer-multiple-cards-container/horizontal-offer-multiple-cards-carousel/horizontal-offer-multiple-cards-carousel.component';
import { HorizontalOfferMultipleCardsContainerComponent } from './horizontal-offer/horizontal-offer-multiple-cards-container/horizontal-offer-multiple-cards-container.component';
import { VerticalOfferBudgetbillContainerComponent } from './vertical-offer/vertical-offer-budgetbill-container/vertical-offer-budgetbill-container.component';
import { VerticalOfferCalculationBasedOnComponent } from './vertical-offer/vertical-offer-calculation-based-on/vertical-offer-calculation-based-on.component';
import { VerticalOfferContainerComponent } from './vertical-offer/vertical-offer-container/vertical-offer-container.component';
import { VerticalOfferDropdownComponent } from './vertical-offer/vertical-offer-dropdown/vertical-offer-dropdown.component';
import { VerticalOfferDurationComponent } from './vertical-offer/vertical-offer-duration/vertical-offer-duration.component';
import { VerticalOfferStickerComponent } from './vertical-offer/vertical-offer-sticker/vertical-offer-sticker.component';
import { VerticalOfferTriggerAomButtonComponent } from './vertical-offer/vertical-offer-trigger-aom-button/vertical-offer-trigger-aom-button.component';
import { VerticalOfferComponent } from './vertical-offer/vertical-offer.component';
import { AcquisitionCommodityOfferContainerComponent } from './acquisition-commodity-offer-container/acquisition-commodity-offer-container.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreExperienceEditorComponentsModule,
    BecomeACustomerSharedModule,
    TranslatePipe,
    TranslateDirective,
    JssModule,
    CommonUiSharedModule,
    CommonUiUtilityComponentsModule,
    CommonUiFormsModule,
    UtilsModule,
    FontAwesomeModule,
    NgbPopoverModule,
    CoreAnalyticsModule,
    NgbCarouselModule,
    BecomeACustomerUiModule,
    GenericLightboxModule,
    UtilsContentsquareModule,
    CommodityOffersModule,
    CommonUiPartialsModule,
    EffectsModule.forFeature(offerSaverEffects),
  ],
  declarations: [
    HorizontalOfferContainerComponent,
    HorizontalOfferCardComponent,
    HorizontalOfferMultipleCardsContainerComponent,
    HorizontalOfferMultipleCardsCarouselComponent,
    HorizontalOfferDurationFilterComponent,
    HorizontalOfferCalculationBasedOnComponent,
    HorizontalOfferLoaderComponent,
    VerticalOfferContainerComponent,
    VerticalOfferDurationComponent,
    VerticalOfferDropdownComponent,
    VerticalOfferCalculationBasedOnComponent,
    VerticalOfferBudgetbillContainerComponent,
    VerticalOfferTriggerAomButtonComponent,
    VerticalOfferStickerComponent,
    VerticalOfferComponent,
    AcquisitionCommodityOfferContainerComponent,
  ],
  exports: [
    HorizontalOfferContainerComponent,
    HorizontalOfferCardComponent,
    HorizontalOfferMultipleCardsContainerComponent,
    HorizontalOfferDurationFilterComponent,
    HorizontalOfferCalculationBasedOnComponent,
    VerticalOfferContainerComponent,
    VerticalOfferDurationComponent,
    VerticalOfferDropdownComponent,
    VerticalOfferCalculationBasedOnComponent,
    VerticalOfferBudgetbillContainerComponent,
    VerticalOfferTriggerAomButtonComponent,
  ],
  providers: [OfferMailer],
})
export class BecomeACustomerOfferModule {}
