import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, Renderer2 } from '@angular/core';

import { AddClassToBodyDirective } from './add-class-to-body.directive';

type AllowedBreakpoints = 'sm' | 'md';

@Directive({
  selector: '[wlDisableBodyScrolling]',
})
export class DisableBodyScrollingClassDirective extends AddClassToBodyDirective {
  public override bodyClassName = 'body-disable-scrolling-sm';

  @Input() set minBreakpoint(value: AllowedBreakpoints) {
    this.bodyClassName = `body-disable-scrolling-${value ?? 'sm'}`;
  }

  constructor(
    protected override readonly renderer: Renderer2,
    @Inject(DOCUMENT) protected override readonly document: Document
  ) {
    super(renderer, document);
  }
}
