<nav
  class="navbar navbar-expand-md navbar-light pb-md-2 pb-lg-3 pt-3 px-0 bg-white"
>
  <div class="container justify-content-start navbar-content">
    <div class="d-flex flex-md-column flex-lg-row">
      <a
        class="navbar-brand me-6 pb-lg-1"
        *wlGenericLink="
          { value: { href: brandLogoLinkUrl || '/' } };
          extras: { queryParamsHandling: 'preserve' }
        "
      >
        <img
          [src]="'assets/logo.svg?v=2' | deployUrl"
          class="navbar--logo"
          alt="logo"
        />
      </a>

      @if (!showSearchHeader()) {
        <div class="navbar-nav mx-n2 align-items-end mt-md-1 mt-lg-0">
          @for (navItem of navItems; track navItem) {
            @if (navItem.children && navItem.children.length > 0) {
              <wl-navbar-dropdown
                [item]="navItem"
                [language]="language"
              ></wl-navbar-dropdown>
            } @else {
              <wl-navbar-item
                [navItem]="navItem"
                [language]="language"
                [class.text-brand]="navItem.active"
                [class.text-secondary]="!navItem.active"
              ></wl-navbar-item>
            }

            <ng-template #item>
              <wl-navbar-item
                [navItem]="navItem"
                [language]="language"
                [class.text-brand]="navItem.active"
                [class.text-secondary]="!navItem.active"
              ></wl-navbar-item>
            </ng-template>
          }
        </div>
      }
    </div>

    @if (searchEnabled()) {
      @if (showSearchHeader()) {
        <div class="ms-auto mw-100 w-100 navbar-searchbar">
          <wl-search-bar
            (removedFocus)="showSearchHeader.set(false)"
            [autofocus]="true"
          ></wl-search-bar>
        </div>
      } @else {
        <div class="navbar-nav navbar-search-trigger">
          <wl-search-trigger
            (triggered)="showSearchbar()"
            size="lg"
          ></wl-search-trigger>
        </div>
      }
    }
  </div>
</nav>
