import { Component, HostBinding, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { SkeletonLoaderMask } from '@legacy/common-ui-shared';

import { cardMask } from '../svg-skeleton-masks';

@Component({
  selector: 'wl-commodity-offer-card',
  templateUrl: './commodity-offer-card.component.html',
  styleUrls: [
    './commodity-offer-card.component.ed.scss',
    './commodity-offer-card.component.essent.scss',
  ],
})
export class CommodityOfferCardComponent {
  @Input() offer?: Offer;
  @Input() showLoadingState = false;

  @HostBinding('attr.data-offerid')
  get offerId() {
    return this.offer?.offerId;
  }

  get mask(): SkeletonLoaderMask {
    return cardMask;
  }
}
