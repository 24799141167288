import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';

import { BrandHeaderComponent } from './components/brand-header/brand-header.component';
import { BrandPatternComponent } from './components/brand-pattern/brand-pattern.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { ImageComponent } from './components/image/image.component';
import { PartialJumbotronComponent } from './components/jumbotron/jumbotron.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { MediaCardComponent } from './components/media-card/media-card.component';
import { MediaSectionComponent } from './components/media-section/media-section.component';
import { StickerHeaderComponent } from './components/sticker-header/sticker-header.component';
import { StructuredContentComponent } from './components/structured-content/structured-content.component';
import { UiLinkDirective } from './directives/link.directive';
import { PartialAccordionTitleComponent } from './partial-accordion-title/partial-accordion-title.component';
import { PartialIconLabelComponent } from './partial-icon-label/partial-icon-label.component';

const components = [
  ListItemComponent,
  PartialAccordionTitleComponent,
  MediaCardComponent,
  MediaSectionComponent,
  StructuredContentComponent,
  ImageComponent,
  ButtonComponent,
  PartialJumbotronComponent,
  CardComponent,
  StickerHeaderComponent,
  PartialIconLabelComponent,
  BrandPatternComponent,
  BrandHeaderComponent,
];

const directives = [UiLinkDirective];

@NgModule({
  imports: [CommonModule, FontAwesomeModule, CommonUiSharedModule],
  declarations: [...components, ...directives, BrandHeaderComponent],
  exports: components,
})
export class CommonUiPartialsModule {}

export { CardComponent } from './components/card/card.component';
export { ListItemComponent } from './components/list-item/list-item.component';
export { StickerHeaderComponent } from './components/sticker-header/sticker-header.component';
