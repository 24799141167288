<wl-experience-editor>
  <ng-template wlLive>
    @if (tooltip?.description) {
      <fa-icon
        class="wl-tooltip"
        icon="circle-info"
        ngbPopover="{{ tooltip?.description }}"
        [ngClass]="tooltipColorCssClass"
        (shown)="isOpen = true"
        (hidden)="isOpen = false"
        container="body"
      ></fa-icon>
    }
  </ng-template>
  <ng-template wlPreview>
    <fa-icon
      class="wl-tooltip"
      icon="circle-info"
      [ngClass]="tooltipColorCssClass"
      (shown)="isOpen = true"
      (hidden)="isOpen = false"
    ></fa-icon>
  </ng-template>
</wl-experience-editor>
