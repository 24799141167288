import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import {
  GenericModalResults,
  GenericModalSources,
  GenericModalTrackingConfig,
} from '@legacy/common-ui-shared-interfaces';
import { InnogyComponentRendering } from '@core/jss-models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { closeGenericModal } from './+state/generic-modal.actions';

@Component({
  selector: 'wl-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: [],
})
export class GenericModalComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  @Input() source: GenericModalSources = GenericModalSources.GENERIC_MODAL;
  @Input() title = '';
  @Input() body = '';
  @Input() primaryButtonText = '';
  @Input() primaryButtonStyle = 'btn btn-primary';
  @Input() chatbotSelectorClass = '';
  @Input() secondaryButtonText = '';
  @Input() secondaryButtonStyle = 'btn btn-link';
  @Input() tracking?: GenericModalTrackingConfig;
  @Input() autoCloseTime?: number;

  additionalPayload: any;
  interpolatablePayload: { [key: string]: any } = {};

  constructor(
    readonly activeModal: NgbActiveModal,
    private readonly store$: Store
  ) {}

  ngOnInit(): void {
    if (this.autoCloseTime) {
      setTimeout(() => {
        this.close();
      }, this.autoCloseTime * 1000);
    }
  }

  primary() {
    this.store$.dispatch(
      closeGenericModal({
        source: this.source,
        result: GenericModalResults.PRIMARY,
        additionalPayload: this.additionalPayload,
      })
    );
    this.activeModal.close();
  }

  close() {
    this.store$.dispatch(
      closeGenericModal({
        source: this.source,
        result: GenericModalResults.CLOSE,
        additionalPayload: this.additionalPayload,
      })
    );
    this.activeModal.close();
  }

  secondary() {
    this.store$.dispatch(
      closeGenericModal({
        source: this.source,
        result: GenericModalResults.SECONDARY,
        additionalPayload: this.additionalPayload,
      })
    );
    this.activeModal.close();
  }

  buttonClickEventType(buttonStyle?: string) {
    if (
      buttonStyle &&
      typeof buttonStyle === 'string' &&
      buttonStyle.includes('btn-link')
    ) {
      return 'link-click';
    }

    return 'button-click';
  }

  /**
   * Combines the primary button class from SC with an optional CSS selector class for the chatbot
   */
  get primaryButtonClasses() {
    return `${this.primaryButtonStyle} ${this.chatbotSelectorClass}`;
  }
}
