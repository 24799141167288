import type { Action, ActionReducerMap } from '@ngrx/store';

import type { ContractsState } from './get-contract-groups.reducer';
import { contractGroupReducer } from './get-contract-groups.reducer';

export const contractsSelectorKey = 'contractEntity';

export interface ContractState {
  readonly [contractsSelectorKey]: ContractsState;
}

export const reducers: ActionReducerMap<ContractState, Action> = {
  [contractsSelectorKey]: contractGroupReducer,
};
