import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  getAddressCheck,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import { filter, map, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import type { CreateLeadBoilerSchema } from '@integration/api-client/feh';
import { Store } from '@ngrx/store';
import { getAppConfig } from '@core/config-store';
import { concatLatestFrom } from '@ngrx/operators';
import type { ClientEnvironmentConfig } from '@core/config-models';
import { LocationService } from '@core/jss-seo';
import {
  postCreateLeadBoiler,
  postCreateLeadBoilerError,
  postCreateLeadBoilerSuccess,
} from '@innogy/eplus/temporary-core-modules';

import * as BoilersActions from './boilers.actions';
import { boilersSelectorKey } from './boilers.reducer';
import { selectBoilersAdviceFormSubmitRedirect } from './boilers.selectors';
import type { BoilersAdviceFormData } from '../models/boilers-advice-form-data.interface';

const adviceFormKey = 'boilers-advice-form';

@Injectable()
export class BoilersEffects {
  readonly currentDate = new Date();

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly location: LocationService
  ) {}

  doAddressCheckApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoilersActions.getAddress),
      map(({ postcode, houseNumber }) =>
        getAddressCheck({
          actionId: boilersSelectorKey,
          payload: { postcode, houseNumber },
        })
      )
    )
  );

  handleAddressCheckSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckSuccess),
      filter((params) => params.actionId === boilersSelectorKey),
      map((params) => BoilersActions.getAddressSuccess(params.payload))
    )
  );

  handleAddressCheckError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckError),
      filter((params) => params.actionId === boilersSelectorKey),
      map((params) =>
        BoilersActions.getAddressError({ response: params.payload })
      )
    )
  );

  handleSubmitAdviceForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoilersActions.submitAdviceForm),
      concatLatestFrom(() => [this.store.select(getAppConfig)]),
      map(([params, config]) =>
        postCreateLeadBoiler({
          actionId: adviceFormKey,
          payload: this.mapFormToApiPayload(params.formData, config),
        })
      )
    )
  );

  handleCreateLeadError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postCreateLeadBoilerError),
      filter((params) => params.actionId === adviceFormKey),
      map(() => BoilersActions.submitAdviceFormError())
    )
  );

  handleCreateLeadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postCreateLeadBoilerSuccess),
      filter((params) => params.actionId === adviceFormKey),
      concatLatestFrom(() => [
        this.store.select(selectBoilersAdviceFormSubmitRedirect),
      ]),
      tap(([_, redirectUrl]) =>
        redirectUrl ? this.location.navigateScLink(redirectUrl) : undefined
      ),
      map(() => BoilersActions.submitAdviceFormSuccess())
    )
  );

  private mapFormToApiPayload(
    formData: BoilersAdviceFormData,
    config: ClientEnvironmentConfig | null
  ): CreateLeadBoilerSchema {
    return {
      type: 'boiler',
      lead: {
        title: formData.salutation,
        initials: formData.initials,
        // firstName?: 'string',
        // prefix?: 'string',
        lastName: formData.lastName,
        // dateOfBirth?: 'string',
        addresses: {
          communication: {
            postcode: formData.postalCode,
            street: formData.street,
            houseNumber: formData.houseNumber,
            houseNumberExtension:
              formData.houseNumberAddition.trim() || undefined,
            city: formData.city,
          },
        },
        phone: formData.phone,
        email: formData.email,
      },
      consent: {
        dataUsage: formData.consentContact,
        promotion: formData.consentPromotion,
      },
      context: {
        // loggedBy?: 'string',
        channel: 'web',
        // subChannel?: 'string',
        // servicePartnerBranch?: 'string',
        requestType: 'Advies',
        // campaignName?: 'string',
        brand: config?.brand === 'ed' ? 'energiedirect' : 'essent',
        // externalId?: 'string',
        // leadContractType?: 'Huur',
        // serviceRequestUri?: 'string',
      },
      metadata: {
        temperature: 4,
        createdOn: this.currentDate.toISOString(),
        // remarks?: 'string',
        source: 'Online',
        environment: 'Consument',
        // referrer?: 'string',
        leadTrackingId: 'DirectOther',
        e2eTrackingId: '',
        // gclid?: 'string',
      },
      products: [{ category: 'boiler' }],
    };
  }
}
