import type { InnogyComponentRendering } from '@core/jss-models';
import { createAction, props } from '@ngrx/store';

const CALCULATE_PREFIX = '[Calculate form]';

export const addCalculateRenderingAction = createAction(
  `${CALCULATE_PREFIX} Add calculate rendering`,
  props<{ rendering: InnogyComponentRendering }>()
);

export const calculateQuestionnaireOpenModalAction = createAction(
  `${CALCULATE_PREFIX} Open calculate questionnaire modal`,
  props<{ gasHidden: boolean }>()
);

export const calculateProceedToOfferAction = createAction(
  `${CALCULATE_PREFIX} Proceed to offer`
);

export const calculationDoneAction = createAction(
  `${CALCULATE_PREFIX} Calculation done`
);

export const changeCalculationAction = createAction(
  `${CALCULATE_PREFIX} Change calculation`
);

export const calculateFormLoadedAction = createAction(
  `${CALCULATE_PREFIX} Loaded`
);

export const calculateFormSubmitAction = createAction(
  `${CALCULATE_PREFIX} submit calculate form`
);

export const resetHousenumberExtensionAction = createAction(
  `${CALCULATE_PREFIX} Reset housenumber extension`
);

export const resetAddressAction = createAction(
  `${CALCULATE_PREFIX} Reset address`
);

export const emitCalculateLaststepAction = createAction(
  `${CALCULATE_PREFIX} Emit calculate laststep event`
);

export const setCalculateFormUpdatedAfterAddressCheckAction = createAction(
  `${CALCULATE_PREFIX} set calculate form updated after address check`
);

export const clearCalculateFormUpdatedAfterAddressCheckAction = createAction(
  `${CALCULATE_PREFIX} clear calculate form updated after address check`
);

export const enableCalculateManualHouseNumberAdditionInputAction = createAction(
  `${CALCULATE_PREFIX} enable manual housenumber addition input`
);

export const disableCalculateManualHouseNumberAdditionInputAction =
  createAction(`${CALCULATE_PREFIX} disable manual housenumber addition input`);

export const bacReadyForOfferAction = createAction(
  `${CALCULATE_PREFIX} BAC ready for offer`
);

export const setResidentialTrueForSMEAction = createAction(
  `${CALCULATE_PREFIX} set residential = true for SME (temp workaround)`
);

export const setApiCallsPending = createAction(
  `${CALCULATE_PREFIX} set API calls pending`
);

export const setApiCallsFinished = createAction(
  `${CALCULATE_PREFIX} set API calls finished`
);

export const submitAlternateAddressAction = createAction(
  `${CALCULATE_PREFIX} submit address for checking`
);
