<div class="inswitchinhouse-container mb-2">
  <div class="w-100">
    <wl-dropdown
      class="w-100"
      [dropdownItems]="items"
      [formsControl]="formState?.controls?.inswitchInhouse"
      [label]="fields?.DropdownLabel?.value"
      [floatingLabel]="false"
      [placeholder]="fields?.DropdownPlaceholder?.value"
      [omitValidationClasses]="['is-valid']"
      [wlTrackValueChange]="{
        step: stepNumber,
        rendering: rendering,
        label: 'situatie',
        inputLabel: selectedSituationLabel,
        sendValue: true,
        trackFocus: true,
        stepName: 'leveringsgegevens',
      }"
    />
    @if (formState?.controls?.inswitchInhouse | isInvalid) {
      <wl-input-error
        wlAutomationId="dropdown-selection-required-error"
        stepName="leveringsgegevens"
        [step]="stepNumber"
        [messageField]="fields?.DropdownSelectionRequiredError"
        messageDescription="selectie situatie"
        [rendering]="rendering"
      />
    }
  </div>
  @if (selectedInswitchInhouseOption | async; as selected) {
    @if (selected.showStartDateInput) {
      <div class="d-flex flex-column gap-1 w-100">
        <fieldset>
          <legend class="h6 fw-bold">{{ fields?.InswitchLabel?.value }}</legend>
          @if (selected.inswitchText?.value; as startDateExplanation) {
            <p>
              {{ startDateExplanation }}
            </p>
          }
          @if (startDateOptionControlEnabled && switchingSupplier) {
            <div>
              <wl-radio
                [radioItems]="radioButtonDescriptions"
                [formsControl]="formState?.controls?.startDateOption"
                wlAutomationId="start-date-option-radio-buttons"
              />
              @if (formState?.controls?.startDateOption | isInvalid) {
                <wl-input-error
                  [messageField]="noStartDateOptionChosenErrorMessage"
                />
              }
            </div>
          }
        </fieldset>
        @if (
          !startDateOptionControlEnabled ||
          !switchingSupplier ||
          optedForCustomStartDate
        ) {
          <div>
            <wl-datepicker
              [rendering]="rendering"
              stepName="leveringsgegevens"
              [step]="stepNumber"
              fieldName="datum overstap"
              [formsControl]="formState?.controls?.selectedStartDate"
              [placeholder]="fields?.TransitionDatePlaceholder?.value"
              [label]="fields?.TransitionDateLabel?.value"
              [startValidDate]="startDateRange.start"
              [endValidDate]="startDateRange.end"
              [tooltip]="selected.startDateInputTooltip ?? ''"
              wlAutomationId="startdatepicker"
              [labelFloating]="!labelsShownAboveFields"
              class="start-date"
            />
            @if (selected.startDateInputHint; as startDateHint) {
              <small>
                {{ startDateHint }}
              </small>
            }
            @if (formState?.controls?.selectedStartDate | isInvalid) {
              <wl-input-error
                wlAutomationId="transition-date-required-error"
                stepName="leveringsgegevens"
                [step]="stepNumber"
                [messageField]="fields?.TransitionDateRequiredError"
                messageDescription="datum overstap"
                [rendering]="rendering"
              />
            }
          </div>
        }
        @if (startDateConfirmationRequired) {
          <wl-checkbox
            [rendering]="rendering"
            stepName="leveringsgegevens"
            [step]="stepNumber"
            fieldName="start levering binnen 14 dagen"
            [formsControl]="formState?.controls?.confirmInhouseTerm"
            [label]="fields?.InhouseTermLabel?.value"
            [tooltip]="fields?.InhouseTermTooltip?.value"
          />
        }
      </div>
    }
    @if (
      !startDateOptionControlEnabled || !switchingSupplier || startDateChosen
    ) {
      @if (selected.infoMessage?.value) {
        <wl-info-card
          [message]="selected.infoMessage"
          [buttonLink]="selected.infoMessageButtonLink"
          [backgroundGrey]="true"
          wlAutomationId="information-box"
        />
      }
    }
    @if (
      startDateOptionControlEnabled && switchingSupplier && startDateChosen
    ) {
      <small [innerHTML]="helpText" wlAutomationId="help-text"> </small>
    }
  }
</div>
