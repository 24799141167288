<!-- Desktop view -->
<header class="border-bottom bg-white">
  <div class="container">
    <nav class="navbar navbar-light pb-md-2 pb-3 pt-3 px-0 bg-white">
      <div class="d-flex justify-content-center align-items-center">
        <ng-template #img>
          <img
            [src]="'assets/logo.svg?v=2' | deployUrl"
            class="navbar--logo"
            alt="logo"
          />
        </ng-template>

        <ng-container
          *ngTemplateOutlet="brandLogoLink?.href ? routeViaLogo : actionViaLogo"
        ></ng-container>

        <ng-template #routeViaLogo>
          <a *wlGenericLink="{ value: brandLogoLink }" class="me-2">
            <ng-template *ngTemplateOutlet="img"></ng-template>
          </a>
        </ng-template>

        <ng-template #actionViaLogo>
          <button
            class="p-0 navbar-brand navbar__logo btn btn-white"
            (click)="onLogoClicked()"
          >
            <ng-template *ngTemplateOutlet="img"></ng-template>
          </button>
        </ng-template>

        @if (ecmpHeader?.logo) {
          <div class="navbar-brand border-start h-100">
            <wl-ecmp-header-image
              [ecmpHeader]="ecmpHeader"
            ></wl-ecmp-header-image>
          </div>
        }
      </div>

      @if (showHelp) {
        <button
          class="btn btn-outline-primary"
          wlAutomationId="helpNeeded"
          id="help_needed"
          title="Hulp nodig?"
          (click)="requestHelp()"
        >
          <fa-icon icon="messages"></fa-icon>
          <span class="d-none d-md-inline ms-1">Hulp nodig?</span>
        </button>
      }
    </nav>
  </div>
</header>
