import { selectAccountId } from '@account/entity';
import { Injectable } from '@angular/core';
import { getSelectedContractGroupId } from '@contracting/entity';
import {
  getAddressCheck,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import type { InvoiceAddress } from '@essent/financial';
import {
  getPaymentDetails,
  getPaymentDetailsSuccess,
  patchPaymentDetailsActions,
} from '@essent/financial';
import { ofFormSubmitAction } from '@innogy/utils-deprecated';
import { isNotNullish, ofFormControlUnfocus } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  DisableAction,
  EnableAction,
  MarkAsPristineAction,
  ResetAction,
  SetErrorsAction,
  SetValueAction,
} from 'ngrx-forms';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import type { ChangeInvoiceAddressFormState } from '../payment-details.model';
import { AddressTypes } from '../payment-details.model';
import {
  addressTypeControlId,
  CHANGE_INVOICE_ADDRESS_FORM_ID,
  cityControlId,
  communicationNumberControlId,
  initialFormState,
  postalCodeControlId,
  streetControlId,
} from './change-invoice-address-form.reducer';
import { disableStreetAndCityAction } from './change-invoice-address.actions';
import {
  getChangeInvoiceAddressForm,
  getChangeInvoiceAddressFormState,
} from './change-invoice-address.selectors';

@Injectable()
export class ChangeInvoiceAddressEffects {
  public fetchAddressOnPostalCodeNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofFormControlUnfocus(
        [postalCodeControlId, communicationNumberControlId],
        this.store$,
        getChangeInvoiceAddressFormState
      ),
      distinctUntilChanged(
        (prev, curr) =>
          prev.value.communicationNumber === curr.value.communicationNumber &&
          prev.value.postalCode === curr.value.postalCode
      ),
      filter((state) => !!state.value.postalCode),
      filter((state) => !!state.value.communicationNumber),
      map((state) =>
        getAddressCheck({
          actionId: CHANGE_INVOICE_ADDRESS_FORM_ID,
          payload: {
            houseNumber: `${state.value.communicationNumber}`,
            postcode: state.value.postalCode,
          },
        })
      )
    )
  );
  public updateFormTypeOnCommunicationAddressSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentDetailsSuccess),
      map((action) => [
        action.payload.invoiceAddress != null &&
        'pobox' in action.payload.invoiceAddress
          ? AddressTypes.POSTALBOX
          : AddressTypes.ADDRESS,
      ]),
      map(
        ([addressType]) => new SetValueAction(addressTypeControlId, addressType)
      )
    )
  );
  public disableStreetAndCityAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableStreetAndCityAction),
      mergeMap(() => [
        new SetValueAction(streetControlId, initialFormState.street),
        new SetValueAction(cityControlId, initialFormState.city),
        new DisableAction(streetControlId),
        new MarkAsPristineAction(streetControlId),
        new DisableAction(cityControlId),
        new MarkAsPristineAction(cityControlId),
      ])
    )
  );
  public resetStreetAndCityOnGet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheck),
      filter((action) => action.actionId === CHANGE_INVOICE_ADDRESS_FORM_ID),
      map((_) => disableStreetAndCityAction())
    )
  );
  public updateFormOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckSuccess),
      filter((action) => action.actionId === CHANGE_INVOICE_ADDRESS_FORM_ID),
      mergeMap((action) => [
        new SetValueAction(streetControlId, action.payload.street),
        new SetValueAction(cityControlId, action.payload.city),
      ])
    )
  );
  public enableCityAndStreetOnError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddressCheckError),
      filter((action) => action.actionId === CHANGE_INVOICE_ADDRESS_FORM_ID),
      mergeMap((_) => [
        new SetValueAction(streetControlId, initialFormState.street),
        new SetValueAction(cityControlId, initialFormState.city),
        new EnableAction(streetControlId),
        new EnableAction(cityControlId),
      ])
    )
  );
  private readonly accountId$ = this.store$.pipe(selectAccountId);
  private readonly getChangeInvoiceAddressForm$ = this.store$.pipe(
    select(getChangeInvoiceAddressForm)
  );
  public resetStreetAndCityOnReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetAction>(ResetAction.TYPE),
      filter((action) => action.controlId === CHANGE_INVOICE_ADDRESS_FORM_ID),
      withLatestFrom(this.getChangeInvoiceAddressForm$),
      mergeMap(([action, form]) => [
        new SetValueAction(action.controlId, initialFormState),
        new SetValueAction(addressTypeControlId, form.initialAddressType),
        new SetErrorsAction(action.controlId, {}),
        disableStreetAndCityAction(),
      ])
    )
  );
  private readonly getChangeInvoiceAddressFormState$ = this.store$.pipe(
    select(getChangeInvoiceAddressFormState)
  );
  private readonly selectedContractGroupId$ = this.store$.pipe(
    select(getSelectedContractGroupId),
    filter(isNotNullish)
  );
  public resetFormStateAfterSuccessfulPatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchPaymentDetailsActions.successAction),
      filter(({ actionId }) => actionId === CHANGE_INVOICE_ADDRESS_FORM_ID),
      withLatestFrom(this.accountId$, this.selectedContractGroupId$),
      map(([_, accountId, contractGroupId]) =>
        getPaymentDetails({
          payload: { accountId, contractGroupId },
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  private readonly getInvoiceAddressByState = (
    state: FormGroupState<ChangeInvoiceAddressFormState>
  ): InvoiceAddress | undefined => {
    const invoiceAddressBase = {
      postcode: state.value.postalCode,
      city: state.value.city,
      country: state.value.country,
    };

    switch (state.value.addressType) {
      case AddressTypes.ADDRESS:
        return {
          ...invoiceAddressBase,
          houseNumber: `${state.value.communicationNumber}`,
          houseNumberExtension:
            state.value.houseNumberAddition.trim() === ''
              ? undefined
              : state.value.houseNumberAddition,
          street: state.value.street,
        };
      case AddressTypes.POSTALBOX:
        return {
          ...invoiceAddressBase,
          pobox: `${state.value.communicationNumber}`,
        };
      default:
        return undefined;
    }
  };

  public submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(CHANGE_INVOICE_ADDRESS_FORM_ID),
      withLatestFrom(
        this.accountId$,
        this.selectedContractGroupId$,
        this.getChangeInvoiceAddressFormState$
      ),
      map(([_, accountId, contractGroupId, formState]) => {
        return patchPaymentDetailsActions.requestAction({
          actionId: CHANGE_INVOICE_ADDRESS_FORM_ID,
          payload: {
            accountId,
            contractGroupId,
            paymentDetails: {
              invoiceAddress: this.getInvoiceAddressByState(formState),
            },
          },
        });
      })
    )
  );
}

// eslint-disable-next-line max-lines
