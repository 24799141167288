import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ColumnContext } from '@legacy/common-ui-shared-interfaces';
import { ImageSettings } from '@legacy/common-ui-utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import type { ButtonTheme, ContentTheme } from '@legacy/common-ui-partials';

import {
  BasicContentValues,
  BasicContentSettings,
} from '../../basic-content-settings.model';

@Component({
  selector: 'wl-basic-content-variant-media-section',
  templateUrl: './basic-content-variant-media-section.component.html',
  styleUrls: [
    './basic-content-variant-media-section.ed.scss',
    './basic-content-variant-media-section.essent.scss',
  ],
})
export class BasicContentVariantMediaSectionComponent implements OnInit {
  @Input() content?: BasicContentValues;
  @Input() settings?: BasicContentSettings;
  @Input() context?: ColumnContext;
  @Input() image?: ImageSettings;
  @Input() rendering?: InnogyComponentRendering;

  primaryButtonTheme: ButtonTheme = 'secondary';

  get contentTheme(): ContentTheme | undefined {
    // This breaks super easily, should match something like 'settings.theme'.
    // The idea is to set an override so cards with a transparent background
    // on a dark row automatically get a brand-alt theme for the content.
    if (
      this.context?.backgroundClass === 'bg-brand-alt' &&
      this.settings?.theme === 'translucent'
    ) {
      return 'brand-alt';
    }
    return this.settings?.theme;
  }

  get reserveVerticalSpacing() {
    return this.settings?.theme !== 'translucent';
  }

  private setPrimaryButtonTheme() {
    const buttonStyleString = this.content?.buttons?.primary?.style;
    const style = buttonStyleString?.includes('primary')
      ? 'primary'
      : 'secondary';
    this.primaryButtonTheme = style;
  }

  ngOnInit() {
    this.setPrimaryButtonTheme();
  }
}
