/* eslint-disable max-lines */

import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  RevertFormState,
  SaveFormState,
  validateElectricity,
  validateGas,
} from '@innogy/become-a-customer/shared';
import { AlternativeHousenumberExtensions } from '@innogy/become-a-customer/shared/interfaces';
import type { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import {
  isInt,
  isPostalCode,
  requiredNumber,
  validateSequential,
  validateSequentialIf,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  focus,
  markAsDirty,
  markAsTouched,
  markAsUnsubmitted,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  setValue,
  SetValueAction,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { greaterThan, required } from 'ngrx-forms/validation';

import {
  addCalculateRenderingAction,
  calculateFormSubmitAction,
  changeCalculationAction,
  clearCalculateFormUpdatedAfterAddressCheckAction,
  disableCalculateManualHouseNumberAdditionInputAction,
  enableCalculateManualHouseNumberAdditionInputAction,
  resetAddressAction,
  resetHousenumberExtensionAction,
  setApiCallsFinished,
  setApiCallsPending,
  setCalculateFormUpdatedAfterAddressCheckAction,
  submitAlternateAddressAction,
} from './calculate.actions';

export const CALCULATE_FORM_ID = 'calculateForm';

export const postalCodeFormControlId = `${CALCULATE_FORM_ID}.postalCode`;
export const houseNumberFormControlId = `${CALCULATE_FORM_ID}.houseNumber`;
export const houseNumberAdditionFormControlId = `${CALCULATE_FORM_ID}.houseNumberAddition`;
export const gasUsageFormControlId = `${CALCULATE_FORM_ID}.gasUsage`;
export const electricityUsageFormControlId = `${CALCULATE_FORM_ID}.electricityUsage`;
export const electricityUsageNormalFormControlId = `${CALCULATE_FORM_ID}.electricityUsageNormal`;
export const electricityUsageOffPeakFormControlId = `${CALCULATE_FORM_ID}.electricityUsageOffPeak`;
export const electricityReturnFormControlId = `${CALCULATE_FORM_ID}.electricityReturn`;
export const doubleMeterFormControlId = `${CALCULATE_FORM_ID}.doubleMeter`;
export const hasSolarPanelFormControlId = `${CALCULATE_FORM_ID}.hasSolarPanel`;
export const returnedElectricityPeakFormControlId = `${CALCULATE_FORM_ID}.returnedElectricityPeak`;
export const returnedElectricityOffPeakFormControlId = `${CALCULATE_FORM_ID}.returnedElectricityOffPeak`;
export const numberOfSolarPanelsFormControlId = `${CALCULATE_FORM_ID}.numberOfSolarPanels`;

export interface CalculateFormState {
  formState: FormGroupState<FullCalculateFormValues>;
  backupFormState?: FullCalculateFormValues;
  rendering?: InnogyComponentRendering | any;
  updatedAfterAddressCheck: boolean;
  manualHouseNumberAddition: boolean;
  apiCallsPending: boolean;
}

const initialFormValues: FullCalculateFormValues = {
  postalCode: '',
  houseNumber: NaN,
  houseNumberAddition: undefined,
  gasUsage: NaN,
  electricityUsage: NaN,
  electricityUsageNormal: NaN,
  electricityUsageOffPeak: NaN,
  electricityReturn: NaN,
  doubleMeter: false,
  hasSolarPanel: false,
  returnedElectricityPeak: NaN,
  returnedElectricityOffPeak: NaN,
  numberOfSolarPanels: NaN,
};

export const initialFormState = createFormGroupState<FullCalculateFormValues>(
  CALCULATE_FORM_ID,
  initialFormValues
);

export const initialCalculateFormState: CalculateFormState = {
  formState: initialFormState,
  backupFormState: undefined,
  rendering: undefined,
  updatedAfterAddressCheck: false,
  manualHouseNumberAddition: false,
  apiCallsPending: false,
};

export const getRenderingValue =
  <T>(key: string, defaultValue: T) =>
  (state: CalculateFormState) => {
    return getFieldValue<T>(state.rendering, key, defaultValue);
  };

const validateForm = (
  state: CalculateFormState
): FormGroupState<FullCalculateFormValues> => {
  const isElectricityRequired = getRenderingValue(
    'ElectricityUsageRequired',
    false
  )(state);
  const isGasRequired = getRenderingValue('GasUsageRequired', false)(state);
  const noInputsRequired = !isElectricityRequired && !isGasRequired;

  // Have to cast the typing to FullCalculateFormValues because ngrx forms can not handle generic types properly
  const validatedConsumptionState = validateGas(
    isGasRequired,
    state.formState,
    noInputsRequired
  )(
    validateElectricity(
      isElectricityRequired,
      state.formState,
      noInputsRequired
    )(state.formState)
  ) as FormGroupState<FullCalculateFormValues>;

  const userHasSolarPanels = state.formState.value.hasSolarPanel;
  const userHasSmartMeter = state.formState.value.doubleMeter;
  const estimationToolShown = getRenderingValue(
    'EstimationToolIsShown',
    false
  )(state);

  let validatedState = updateGroup<FullCalculateFormValues>({
    postalCode: validateSequential(required, isPostalCode),
    houseNumber: validateSequential(requiredNumber, isInt, greaterThan(0)),
    electricityReturn: validateSequentialIf(
      userHasSolarPanels && !userHasSmartMeter
    )(requiredNumber, isInt, greaterThan(0)),
  })(validatedConsumptionState);

  // For variations B, B.1 and C
  if (estimationToolShown) {
    validatedState = updateGroup<FullCalculateFormValues>({
      returnedElectricityPeak: validateSequentialIf(
        userHasSolarPanels && userHasSmartMeter
      )(requiredNumber, isInt, greaterThan(0)),
      returnedElectricityOffPeak: validateSequentialIf(
        userHasSolarPanels && userHasSmartMeter
      )(requiredNumber, isInt, greaterThan(0)),
      numberOfSolarPanels: validateSequentialIf(
        userHasSolarPanels &&
          state.formState.value.numberOfSolarPanels !== undefined &&
          // Explicitly checking for not NaN since this is the default empty value
          !isNaN(state.formState.value.numberOfSolarPanels)
      )(isInt, greaterThan(0)),
    })(validatedState);
  }

  return validatedState;
};

const _reducer = createReducer(
  initialCalculateFormState,
  onNgrxForms(),
  on(calculateFormSubmitAction, (state) => {
    let formState = markAsDirty(state.formState);
    formState = markAsTouched(formState);
    return {
      ...state,
      formState,
    };
  }),
  on(resetHousenumberExtensionAction, (state) => {
    let formState = state.formState;
    formState = setValue(formState, {
      ...formState.value,
      houseNumberAddition: '',
    });

    return {
      ...state,
      formState,
      manualHouseNumberAddition: false,
    };
  }),
  on(submitAlternateAddressAction, (state) => {
    let formState = state.formState;

    formState = updateGroup<FullCalculateFormValues>({
      postalCode: (s) => markAsDirty(markAsTouched(s)),
      houseNumber: (s) => markAsDirty(markAsTouched(s)),
    })(formState);

    return {
      ...state,
      formState,
    };
  }),
  on(resetAddressAction, (state) => {
    let formState = state.formState;

    if (formState.controls.houseNumber) {
      formState = {
        ...formState,
        controls: {
          ...formState.controls,
          postalCode: focus(reset(setValue(formState.controls.postalCode, ''))),
          houseNumber: reset(setValue(formState.controls.houseNumber, NaN)),
        },
      };
    }
    return {
      ...state,
      formState,
    };
  }),
  on(changeCalculationAction, (state) => {
    return {
      ...state,
      formState: markAsUnsubmitted(state.formState),
    };
  }),
  on(addCalculateRenderingAction, (state, action) => {
    return { ...state, rendering: action.rendering };
  }),
  on(SaveFormState, (state) => ({
    ...state,
    backupFormState: state.formState.value,
  })),
  on(RevertFormState, (state) => ({
    ...state,
    formState: setValue(
      state.formState,
      state.backupFormState ?? state.formState.value
    ),
  })),
  on(setCalculateFormUpdatedAfterAddressCheckAction, (state) => {
    return {
      ...state,
      updatedAfterAddressCheck: true,
    };
  }),
  on(clearCalculateFormUpdatedAfterAddressCheckAction, (state) => {
    return {
      ...state,
      updatedAfterAddressCheck: false,
    };
  }),
  on(enableCalculateManualHouseNumberAdditionInputAction, (state) => {
    return {
      ...state,
      manualHouseNumberAddition: true,
    };
  }),
  on(disableCalculateManualHouseNumberAdditionInputAction, (state) => {
    return {
      ...state,
      manualHouseNumberAddition: false,
    };
  }),
  on(setApiCallsPending, (state) => {
    return {
      ...state,
      apiCallsPending: true,
    };
  }),
  on(setApiCallsFinished, (state) => {
    return {
      ...state,
      apiCallsPending: false,
    };
  }),
  // eslint-disable-next-line complexity
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // The average amount of returned electricity is roughly estimated to be
    // 225 times the number of solar panels the user has.
    const estimationMultiplier = 225;
    const peakFraction = 2 / 3;
    const offPeakFraction = 1 / 3;

    if (
      [postalCodeFormControlId, houseNumberFormControlId].includes(
        action.controlId
      )
    ) {
      return {
        ...state,
        updatedAfterAddressCheck: true,
      };
    }

    if (
      action.controlId === houseNumberAdditionFormControlId &&
      action.value === AlternativeHousenumberExtensions.OTHER
    ) {
      let formState = state.formState;
      formState = setValue(formState, {
        ...formState.value,
        houseNumberAddition: '',
      });

      return {
        ...state,
        formState,
        manualHouseNumberAddition: true,
      };
    }

    if (
      action.controlId === electricityReturnFormControlId &&
      state.formState.value.electricityReturn != null
    ) {
      const returnedElectricity = state.formState.value.electricityReturn;
      const formState = setValue(state.formState, {
        ...state.formState.value,
        returnedElectricityPeak: Math.round(peakFraction * returnedElectricity),
        returnedElectricityOffPeak: Math.round(
          offPeakFraction * returnedElectricity
        ),
      });

      return {
        ...state,
        formState,
      };
    }

    if (
      (action.controlId === returnedElectricityPeakFormControlId ||
        action.controlId === returnedElectricityOffPeakFormControlId) &&
      state.formState.value.returnedElectricityPeak != null &&
      state.formState.value.returnedElectricityOffPeak != null
    ) {
      const formState = setValue(state.formState, {
        ...state.formState.value,
        electricityReturn:
          state.formState.value.returnedElectricityPeak +
          state.formState.value.returnedElectricityOffPeak,
      });

      return {
        ...state,
        formState,
      };
    }

    if (
      action.controlId === numberOfSolarPanelsFormControlId &&
      state.formState.value.numberOfSolarPanels != null
    ) {
      const returnedElectricity =
        estimationMultiplier * state.formState.value.numberOfSolarPanels;

      let formState = setValue(state.formState, {
        ...state.formState.value,
        electricityReturn: returnedElectricity,
        returnedElectricityPeak: Math.round(peakFraction * returnedElectricity),
        returnedElectricityOffPeak: Math.round(
          offPeakFraction * returnedElectricity
        ),
      });

      formState = updateGroup<FullCalculateFormValues>({
        electricityReturn: (s) => markAsDirty(markAsTouched(s)),
        returnedElectricityPeak: (s) => markAsDirty(markAsTouched(s)),
        returnedElectricityOffPeak: (s) => markAsDirty(markAsTouched(s)),
      })(formState);

      return {
        ...state,
        formState,
      };
    }

    return state;
  })
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.formState,
  (_, state) => validateForm(state)
);

export function calculateReducer(
  state: CalculateFormState = initialCalculateFormState,
  action: Action
): CalculateFormState {
  return wrappedReducer(state, action);
}
