<div class="position-relative h-100">
  <ng-container>
    @if (variant === 'card') {
      <wl-commodity-offer-card
        [offer]="offer"
        [showLoadingState]="showLoadingState"
      >
        <div>
          <essent-commodity-offer-header
            [addLabelSpacer]="ensureHorizontalAlignment"
            [duration]="offer?.durationTitle"
            [productTitle]="offer?.productTitle"
            [incentive]="offer?.incentiveTitle"
            [highlight]="offer?.isHighlightedLabel"
            [showPriceTag]="false"
          ></essent-commodity-offer-header>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <ng-container *ngTemplateOutlet="offerBody"></ng-container>
        </div>
      </wl-commodity-offer-card>
    }

    @if (variant === 'accordion') {
      <wl-commodity-offer-accordion
        [offer]="offer"
        [opened]="active"
        [showLoadingState]="showLoadingState"
      >
        <ng-template #accordionHeader>
          <essent-commodity-offer-header
            [addLabelSpacer]="false"
            [duration]="offer?.durationTitle"
            [productTitle]="offer?.productTitle"
            [priceDurationLabel]="content?.priceDurationLabel"
            [price]="offer?.expectedMonthlyAmount"
            [incentive]="offer?.incentiveTitle"
            [highlight]="offer?.isHighlightedLabel"
            [showPriceTag]="true"
          ></essent-commodity-offer-header>
        </ng-template>
        <ng-template #accordionBody>
          <ng-container *ngTemplateOutlet="offerBody"></ng-container>
        </ng-template>
      </wl-commodity-offer-accordion>
    }
  </ng-container>
</div>

<ng-template #offerBody>
  @if (variant === 'accordion') {
    <div class="d-flex justify-content-between mb-3">
      <div>{{ content?.proposedBudgetBillBefore }}</div>
      <div>
        <div class="fw-bold">
          {{ offer?.budgetBillAmount! | roundedCurrency
          }}{{ content?.priceDurationLabel }}
        </div>
        <div class="text-end">{{ content?.priceDurationLabelSuffix }}</div>
      </div>
    </div>
  }
  @if (variant === 'card') {
    <wl-commodity-offer-price-tag
      [offer]="offer"
      [content]="content"
      class="mt-2"
    ></wl-commodity-offer-price-tag>
  }
  <wl-commodity-offer-view-tariffs
    [tariffModalDescriptionLabel]="content?.tariffModalDescriptionLabel"
    [tariffModalLinkTextLabel]="content?.tariffModalLinkTextLabel"
    (viewTariffs)="viewTariffs.emit()"
    class="mt-3"
  ></wl-commodity-offer-view-tariffs>
  <essent-sme-commodity-product-breakdown
    [offer]="offer"
    [content]="content"
    [showMemberGetMember]="showMemberGetMember"
  ></essent-sme-commodity-product-breakdown>
  <div class="flex-grow-1"></div>

  <div class="d-flex flex-column">
    <button
      class="btn btn-primary mt-2 w-100"
      (click)="selectOffer.emit()"
      wlAutomationId="offer-submit-button"
      wlCSCapture
    >
      {{ content?.offerSubmitButtonLabel }}
    </button>
    @if (content?.moreInfoPriceAndConditionLinkLabel) {
      <div class="d-flex justify-content-center">
        <button
          class="btn p-0 border-0 btn-link mt-2 mb-1"
          (click)="openMoreInfo.emit()"
          wlAutomationId="info-card-button"
          wlCSCapture
        >
          {{ content?.moreInfoPriceAndConditionLinkLabel }}
        </button>
      </div>
    }
  </div>
</ng-template>
