<p class="most-searched__title" id="autocompleteLabel">Veelgezocht</p>
<ul class="reset-list-styles" aria-labelledby="autocompleteLabel">
  @for (query of queries; track $index) {
    <li
      tabindex="-1"
      class="most-searched__item"
      [wlAutomationId]="'search-most-searched-' + $index"
    >
      <wl-search-suggestion [query]="query" [value]="query">
      </wl-search-suggestion>
    </li>
  }
</ul>
