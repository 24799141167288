import {
  Validators,
  type AbstractControl,
  type FormGroup,
  type ValidatorFn,
} from '@angular/forms';
import {
  emailRegex,
  isPhoneNumber,
  LASTNAME_REGEXP,
} from '@innogy/utils-deprecated';
import { isValidIBAN } from 'ibantools';

export class RfaValidators extends Validators {
  /**
   * @description
   * Validator that validates the value as a phone number.
   * ---
   * @usageNotes
   *
   * ### Validate that the field matches a valid phone pattern
   *
   * ```typescript
   * const control = new FormControl('01234a6789', RfaValidators.phone);
   *
   * console.log(control.errors); // {phone: true}
   * ```
   * ---
   * @returns An error map with the `phone` property if the validation check fails, otherwise `null`.
   */
  static phone(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (Object.keys(isPhoneNumber(control.value)).length > 0) {
        return {
          phone: true,
        };
      }
    }
    return null;
  }

  /**
   * @description
   * Validator that validates the value as a last name.
   * ---
   * @usageNotes
   *
   * ### Validate that the field matches a valid lastname pattern
   *
   * ```typescript
   * const control = new FormControl('01234a6789', RfaValidators.lastName);
   *
   * console.log(control.errors); // {lastName: true}
   * ```
   * ---
   * @returns An error map with the `lastName` property if the validation check fails, otherwise `null`.
   */
  static lastName(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const matches = control.value.match(LASTNAME_REGEXP);
      return matches ? null : { lastName: true };
    }
    return null;
  }

  /**
   * @description
   * Validator that requires a function that returns a boolean for a custom validator.
   * It provides the controls parent so other controllers can be accessed and compared.
   * Use this together with validationTriggers to update the affected controls.
   * You can give an optional errorKey to have more control
   * ---
   * @usageNotes
   *
   * ### Validate that a value is higher than min value dynamically
   *
   * ```typescript
   * const form = new FormGroup({
   *  minValue: new FormControl<number>(100)
   *  maxValue: new FormControl<number>(150, {
   *    validators: [
   *      RfaValidators.conditional((parent: FormGroup) => {
   *        return parent?.controls.minValue < parent?.controls.maxValue
   *      })
   *    ],
   *    validationTriggers: [
   *      (parent) => { return parent.controls.minValue.valueChanges }
   *    ]
   *  }
   * })
   * ```
   * ---
   * @returns An error map with the [errorKey] property if the validation check fails, otherwise `null`.
   */
  static conditional<T extends FormGroup>(
    isValid: (parent: T | undefined) => boolean,
    errorKey = 'condition'
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const valid = isValid(control.parent as T);
        if (!valid) {
          return {
            [errorKey]: true,
          };
        }
      }
      return null;
    };
  }

  /**
   * @description
   * Validator that validates the value as an iban.
   * ---
   * @usageNotes
   *
   * ### Validate that the field matches a valid iban pattern
   *
   * ```typescript
   * const control = new FormControl('goat1234', RfaValidators.iban);
   *
   * console.log(control.errors); // {iban: true}
   * ```
   * ---
   * @returns An error map with the `iban` property if the validation check fails, otherwise `null`.
   */
  static iban(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      const trimmedSpaces = control.value.replaceAll(' ', '');
      return isValidIBAN(trimmedSpaces) ? null : { iban: true };
    }
    return null;
  }

  /**
   * @description
   * Validator that requires the control's value to pass an email validation test.
   * Adjusted for specific Essent email regex.
   * ---
   * @usageNotes
   *
   * ### Validate that the field matches a valid email pattern
   *
   * ```typescript
   * const control = new FormControl('bad@', RfaValidators.email);
   *
   * console.log(control.errors); // {email: true}
   * ```
   * ---
   * @returns An error map with the `email` property if the validation check fails, otherwise `null`.
   */
  static override email(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (control.value) {
      const matches = control.value.match(emailRegex);
      return matches ? null : { email: true };
    }
    return null;
  }
}
