<wl-aom-costs-per-unit
  [costPerUnitVM]="costPerUnitVM()"
  [rendering]="rendering()"
  [isVATIncluded]="isVATIncluded()"
  [fields]="fields()"
  [isDynamicProduct]="offer()?.isDynamicProduct"
></wl-aom-costs-per-unit>
@if (fields()?.AomDynamicPricingDisclaimer && isDynamicProduct) {
  <div *wlGenericRichText="fields()?.AomDynamicPricingDisclaimer"></div>
}
<p class="h4 mt-3 text-black" wlCSCapture>
  {{ monthlyBudgetPaymentHeading || fields()?.BudgetBillAmountTitle.value }}
</p>
<wl-rich-text-wrapper
  [field]="
    {
      value:
        monthlyBudgetPaymentParagraph ||
        fields()?.BudgetBillAmountParagraph.value,
    }
      | interpolate
        : {
            mode: 'rich_text',
            variables: {
              kwhNormal: kwhNormal,
              kwhLow: kwhLow,
              gas: gas,
              budgetBillAmount: budgetBillAmount | roundedCurrency,
            },
          }
  "
>
  <p class="section-content" wlCSCapture></p>
</wl-rich-text-wrapper>

@if (hasDiscount) {
  <p
    wlCSCapture
    class="h4 mt-3 text-black"
    *scText="
      fields()?.CashbackTitle
        | interpolate
          : {
              variables: {
                cashback:
                  offer()?.discountPrice | currency: 'EUR' : 'symbol' : '1.2',
              },
            }
    "
  ></p>
  <p class="section-content">
    <span *scText="fields()?.CashbackParagraphPart1" wlCSCapture></span>
    <span class="text-green fw-bold ms-1" wlCSCapture>{{
      fields()?.CashbackParagraphPart2?.value + '.'
        | interpolate
          : {
              variables: {
                cashback:
                  offer()?.discountPrice | currency: 'EUR' : 'symbol' : '1.2',
              },
            }
    }}</span>
    <br />
    <span *scText="fields()?.CashbackParagraphPart3" wlCSCapture></span>
  </p>
} @else {
  @if (offer()?.incentiveFullDescription) {
    <p class="h4 mt-3 text-black">
      <span *scText="fields()?.IncentiveTitlePrefix" wlCSCapture></span>
      {{ offer()?.incentiveTitle }}
    </p>
    <p
      wlCSCapture
      class="section-content"
      [innerHtml]="
        fields()?.IncentiveBodyPrefix?.value +
        ' ' +
        offer()?.incentiveFullDescription
      "
    ></p>
  }
}
<ng-template #description>
  @if (offer()?.incentiveFullDescription) {
    <p class="h4 mt-3 text-black">
      <span *scText="fields()?.IncentiveTitlePrefix" wlCSCapture></span>
      {{ offer()?.incentiveTitle }}
    </p>
    <p
      wlCSCapture
      class="section-content"
      [innerHtml]="
        fields()?.IncentiveBodyPrefix?.value +
        ' ' +
        offer()?.incentiveFullDescription
      "
    ></p>
  }
</ng-template>

<p class="h4 mt-3 text-black" *scText="fields()?.AomOfferTitle" wlCSCapture></p>
<div class="section-content">
  @if (!kwhLow) {
    <wl-rich-text-wrapper
      wlAutomationId="aom-paragraph-single"
      [field]="
        fields()?.AomOfferParagraph
          | interpolate
            : {
                mode: 'rich_text',
                variables: {
                  kwh: kwhNormal,
                  gas: gas,
                  solarReturn: solarReturn,
                },
              }
      "
    >
      <p></p>
    </wl-rich-text-wrapper>
  } @else {
    <wl-rich-text-wrapper
      wlAutomationId="aom-paragraph-double"
      [field]="
        fields()?.AomOfferParagraphDoubleMeter
          | interpolate
            : {
                mode: 'rich_text',
                variables: {
                  kwhNormal: kwhNormal,
                  kwhLow: kwhLow,
                  gas: gas,
                  solarReturn: solarReturn,
                  solarReturnLow: solarReturnLow,
                },
              }
      "
    >
      <p class="mb-3"></p>
    </wl-rich-text-wrapper>
  }
</div>

<wl-aom-cost-table
  [rendering]="rendering()"
  [offer]="offer()"
  [fields]="fields()"
></wl-aom-cost-table>

<div class="cost-section mt-3">
  <div class="d-flex justify-content-between w-100 h5 text-black">
    <span *scText="fields()?.ExpectedYearlyCost" wlCSCapture></span>
    <span wlCSCapture>
      {{
        offer()?.beforeDiscountExpectedYearlyAmount
          | currency: 'EUR' : 'symbol' : '1.2'
      }}
    </span>
  </div>
  @if (hasDiscount) {
    <div class="d-flex justify-content-between w-100 h5 text-green">
      <span>
        <span *scText="fields()?.DiscountFirstYear" wlCSCapture></span>
        @if (fields()?.DiscountFirstYearTooltip?.value) {
          <wl-tooltip
            class="ps-1"
            [tooltip]="{
              description: fields()?.DiscountFirstYearTooltip?.value,
            }"
          ></wl-tooltip>
        }
      </span>
      <span wlCSCapture>
        - {{ offer()?.discountPrice | currency: 'EUR' : 'symbol' : '1.2' }}
      </span>
    </div>
  }
  <hr class="hr" />
  <div class="d-flex justify-content-between w-100 h5 text-body">
    <span *scText="fields()?.YearlyCost" wlCSCapture></span>
    <span wlCSCapture>{{
      offer()?.expectedYearlyAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
  <div class="d-flex justify-content-between w-100">
    <span>
      <span *scText="fields()?.MonthlyCost" wlCSCapture></span>
      @if (fields()?.MonthlyCostTooltip?.value) {
        <wl-tooltip
          class="ps-1"
          [tooltip]="{
            description: fields()?.MonthlyCostTooltip?.value,
          }"
        ></wl-tooltip>
      }
    </span>
    <span wlCSCapture>{{
      offer()?.expectedMonthlyAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
  <div class="d-flex justify-content-between w-100">
    <span>
      <span *scText="fields()?.TermCost" wlCSCapture></span>
      @if (fields()?.TermCostTooltip?.value) {
        <wl-tooltip
          class="ps-1"
          [tooltip]="{
            description: fields()?.TermCostTooltip?.value,
          }"
        ></wl-tooltip>
      }
    </span>
    <span wlCSCapture>{{
      offer()?.budgetBillAmount | currency: 'EUR' : 'symbol' : '1.2'
    }}</span>
  </div>
</div>

@if (disclaimer !== '') {
  <wl-disclaimer [disclaimer]="disclaimer"></wl-disclaimer>
}
