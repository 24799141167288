@if (offer && content) {
  <div class="commodity-offer-price-tag">
    <div class="commodity-offer-price-tag__title">
      <span>{{ content.monthlyCostsLabel }}</span>
      @if (showMonthlyCostsLabelDiscountInfix()) {
        <span>&nbsp;{{ content.monthlyCostsLabelDiscountInfix }}</span>
      }
      <span class="d-block">
        {{ content.monthlyCostsLabelSuffix }}
      </span>
    </div>
    <div class="commodity-offer-price-tag__monthly-amount">
      <span class="h1 mb-0">
        {{ offer.expectedMonthlyAmount | roundedCurrency }}
      </span>
      <span class="lh-sm">{{ content.priceDurationLabel }}</span>
    </div>

    @if (showBudgetbillProposal) {
      <wl-commodity-offer-budgetbill-proposal
        class="w-100"
        [offer]="offer"
        [content]="content"
      ></wl-commodity-offer-budgetbill-proposal>
    }
  </div>
}
