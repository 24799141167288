import { Component, HostBinding, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { getSiteContext } from '@core/analytics';
import {
  getBrandLogoLinkUrl,
  getLanguage,
  getMainNavigation,
  getServiceNavigationRootName,
  getShowNavigation,
  getSitecoreContextWithStatus,
  getTopNavigation,
  JssRouteBuilderService,
} from '@core/jss-routing';
import { showCategories } from '@innogy/navigation/store';
import {
  getSettingsObject,
  getSettingsValue,
  login,
  loginUrl,
  myEnvironmentActive,
} from '@core/settings';
import { ActiveLinkService } from '@innogy/utils-deprecated';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getIsLoggedIn } from '@account/entity';
import { getLoginUrl } from '@account/utils';

@Component({
  selector: 'wl-sticky-header-container',
  templateUrl: './sticky-header-container.component.html',
})
export class StickyHeaderContainerComponent {
  @HostBinding('class')
  class = 'd-block sticky-top';

  public language$ = this.store$.pipe(select(getLanguage));

  public readonly isLoggedIn$ = this.store$.pipe(select(getIsLoggedIn));

  private readonly mainNavigationInfo$ = this.store$.pipe(getMainNavigation);
  public isMainNavigationAvailable$ = this.mainNavigationInfo$.pipe(
    map((info) => info.isNavigationAvailable)
  );
  public mainNavigationItems$ = this.mainNavigationInfo$.pipe(
    map((info) => info.navItems)
  );

  private readonly topNavigationInfo$ = this.store$.pipe(getTopNavigation);
  public isTopNavigationAvailable$ = this.topNavigationInfo$.pipe(
    map((info) => info.isNavigationAvailable)
  );
  public topNavigationItems$ = this.topNavigationInfo$.pipe(
    map((info) => info.navItems)
  );

  public openNavigation$ = this.store$.select(
    getSettingsObject('navigation', 'OpenNavigationItem')
  );

  private readonly myEnvironmentActive$ = this.store$.pipe(
    select(myEnvironmentActive)
  );
  private readonly hasContext$ = this.store$.pipe(
    getSitecoreContextWithStatus,
    map((context) => context != null)
  );
  public readonly serviceNavigationActive$ = combineLatest([
    this.myEnvironmentActive$,
    this.hasContext$,
    this.isLoggedIn$,
  ]).pipe(
    map(
      ([active, hasContext, isLoggedIn]) => active && hasContext && isLoggedIn
    )
  );
  public serviceNavigationLink$ = this.store$.pipe(select(loginUrl));
  public serviceNavigationTitle$ = this.store$.pipe(
    getServiceNavigationRootName
  );
  public showCategories$ = this.store$.pipe(
    showCategories(this.store$, this.urlBuilder, this.activeLinkService)
  );

  public mainNavigationTitle$ = this.store$.pipe(
    select(getSettingsValue('navigation', 'MainNavigationTitle'))
  );

  public brandLogoLinkUrl$ = this.store$.select(getBrandLogoLinkUrl);

  public loginUrlLabel$ = this.store$.pipe(select(login));
  public loginUrl? = getLoginUrl(this.config);
  public placement$ = getSiteContext(this.store$, this.config);
  public showNavigation$ = this.store$.select(getShowNavigation);

  constructor(
    private readonly store$: Store<any>,
    private readonly urlBuilder: JssRouteBuilderService,
    private readonly activeLinkService: ActiveLinkService,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}
}
