import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./lib/search-lazy.module').then((module) => module.SearchLazyModule);

const defineImport = (path: string) => ({
  path,
  loadChildren: loadChildrenFunc,
});

const searchLazyImports: ComponentNameAndModule[] = [
  defineImport('SearchResults'),
];
export { searchLazyImports };
