@if (viewState$ | async; as viewState) {
  @if (viewState.activeOfferset) {
    <div class="d-block pt-3"><!-- Spacer --></div>
    <wl-horizontal-offer-duration-filter
      [activeDuration]="viewState.activeDuration"
      (changeDurationClick)="
        setActiveOffersByNameAndDuration(
          $event,
          viewState.activeOfferset?.offerset ?? ''
        )
      "
      [durations]="viewState.presentDurations"
    ></wl-horizontal-offer-duration-filter>
    <div class="mb-4">
      @if (isActiveDurationLoaded$ | async) {
        <wl-horizontal-offer-multiple-cards-container
          [hasMemberGetMember]="(hasMemberGetMember$ | async) || false"
          [offers]="viewState.activeOffers"
          [addOn]="viewState.addOn"
          [rendering]="rendering"
          [moreInfoButtonClick]="moreInfoButtonClick"
          (showTarifs)="showTarifs($event)"
          (addOnToggle)="
            onAddOnToggle(
              $event,
              viewState.regularOfferset,
              viewState.addOn?.offerSet
            )
          "
          (submitProposition)="onSubmitProposition($event)"
          (showMoreIncentiveInfo)="onShowMoreIncentiveInfo($event)"
          wlCSCapture
        ></wl-horizontal-offer-multiple-cards-container>
      } @else {
        <wl-horizontal-offer-loader
          [mask]="skeletonLoaderMask"
        ></wl-horizontal-offer-loader>
      }
    </div>
    <wl-commodity-offer-calculation-based-on
      [variant]="'narrow'"
      [label]="rendering?.fields?.CalculationBasedOnLabel?.value"
      [address]="viewState.supplyAddressVM"
      [consumptions]="viewState.consumptionValues"
      [electricityLabel]="'ElectricityUsageUnit' | translate"
      [gasLabel]="'GasUsageUnit' | translate"
      [adjustCalculationLabel]="
        rendering?.fields?.AdjustCalculationLabel?.value
      "
      (adjustCalculation)="onCalculationChanged()"
      class="justify-content-center"
    ></wl-commodity-offer-calculation-based-on>
  }
}
