import { Component, Input, type OnInit } from '@angular/core';
import type { TrackErrorModel } from '@core/analytics';

import { RfaFormControl } from '../../../../level1';
import type { RfaCombination } from '../../RfaComponents';
import type { RfaComponentOptions } from '../../../models';
import type { RfaErrorTrackerOptions } from '../../../models/rfa-tracker-options.interface';
import { errorOptionsToConfig } from '../../../helpers/tracker-options-to-config';

interface ErrorData {
  error: string;
  msg: string;
  analytics: RfaErrorTrackerOptions;
}

@Component({
  selector: 'rfa-errors',
  templateUrl: './rfa-errors.component.html',
  styleUrls: ['./rfa-errors.component.scss'],
})
export class RfaErrorsComponent implements OnInit {
  @Input({ required: true }) control!: RfaFormControl<any, RfaCombination>;
  @Input() newDesign = false;

  errors!: ErrorData[];

  ngOnInit(): void {
    const options = this.control.options as RfaComponentOptions;

    if (options?.errorMsgs) {
      this.errors = Object.entries(options.errorMsgs).map((entry) => {
        const [key, val] = entry;

        if (typeof val === 'string') {
          return {
            error: key,
            msg: val,
            analytics: {},
          };
        } else {
          return {
            error: key,
            msg: val.msg,
            analytics: val.analytics,
          };
        }
      });
    } else {
      this.errors = [];
    }
  }

  errorTrackConfig(error: ErrorData): TrackErrorModel {
    const options = this.control.options as RfaComponentOptions;

    return errorOptionsToConfig(error.analytics, {
      fieldName: options.label,
      description: error.msg,
    });
  }
}
