<label class="toggle" [wlAutomationId]="toggleId">
  @if (formControl) {
    <input
      [wlTrackValueChange]="{
        rendering: rendering,
        label: fieldName,
        stepName: stepName,
        formName: formName,
        sendValue: true,
        inputLabel: formControl.value ? 'true' : 'false',
      }"
      (change)="onChange($event)"
      [id]="toggleId"
      type="checkbox"
      [wlAutomationId]="toggleId + '-toggle'"
      [ngrxFormControlState]="formControl"
      [attr.checked]="formControl.value ? 'checked' : null"
      [title]="name || ''"
    />
  } @else {
    <input
      [wlTrackValueChange]="{
        rendering: rendering,
        label: fieldName,
        stepName: stepName,
        formName: formName,
        sendValue: true,
        inputLabel: checked.toString(),
      }"
      [id]="toggleId"
      type="checkbox"
      (change)="onChange($event)"
      [wlAutomationId]="toggleId + '-toggle'"
      [attr.checked]="checked ? 'checked' : null"
      [title]="name || ''"
    />
  }

  <span class="toggle__handle"></span>
  <span class="toggle__label" *wlGenericRichText="labelText"></span>
</label>
