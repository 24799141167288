import { analyticsHash } from '@core/analytics';
import type { MetadataUserDefinedProp } from '@innogy/sitecore-forms/models';
import { CONTROL_METADATA_KEY } from '@innogy/sitecore-forms/models';
import type { FormGroupControls, KeyValue } from 'ngrx-forms';

import { parseControlValue } from './generic-form-utils';

/**
 * extract datatracking payload from a set of formcontrols that have been built using the generic-forms reducer builder.
 * @param formControls formcontrols that have been constructed via the generic-forms reducer builder.
 * @returns payload that can be attached to a tool-laststep in key-value format.
 */
export function analyticsPayloadFromGenericFormControls<
  TFormGroupState extends KeyValue,
>(formControls: FormGroupControls<TFormGroupState>) {
  const payload: Record<string, unknown> = {};

  Object.entries(formControls).forEach(([, controlState]) => {
    const prop: MetadataUserDefinedProp | undefined =
      controlState.userDefinedProperties[CONTROL_METADATA_KEY];

    if (!prop || !prop.trackFieldValue) {
      return;
    }

    const value = parseControlValue(controlState.value);

    payload[prop.fieldLabel] = prop.hashWhenTracking
      ? analyticsHash(value)
      : value;
  });

  return payload;
}
