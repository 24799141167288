import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnContext } from '@legacy/common-ui-shared-interfaces';
import { getImageSettingsFromRendering } from '@legacy/common-ui-utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import {
  getDropLinkObject,
  getDroplinkValue,
  getFieldValue,
  getTreelistEntries,
} from '@core/jss-utils';
import type {
  FaqStructuredData,
  FaqStructuredDataEntity,
} from '@core/structured-data';
import { StructuredDataType } from '@core/structured-data';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import type { FaqAccordionItem, FaqAccordionViewModel } from '../faq.model';

@Component({
  selector: 'wl-faq-container',
  templateUrl: './faq-container.component.html',
})
export class FaqContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;
  faqAccordionViewModel!: FaqAccordionViewModel;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.faqAccordionViewModel = this.getFaqAccordionViewModel();
  }

  getFaqAccordionViewModel(): FaqAccordionViewModel {
    const items = this.itemsToFaqAccordionItems(this.faqItems);

    return {
      items,
      structuredData: this.createStructuredData(items),
      feedbackSettings: this.feedbackSettings,
      headerClass: this.textColor || '',
      isVertical: this.isVertical,
    };
  }

  get feedbackSettings() {
    return getDropLinkObject(this.rendering?.fields, 'FeedbackSettings');
  }

  get faqItems() {
    return getTreelistEntries(this.rendering?.fields, 'Items');
  }

  get hasFirstExpanded(): boolean {
    return getFieldValue<boolean>(this.rendering, 'CollapseFirst') ?? false;
  }

  get textColor(): string | undefined {
    return getDroplinkValue(this.rendering?.fields, 'TextColor');
  }

  get isVertical(): boolean {
    return this.context ? this.context.colWidth <= 6 : false;
  }

  private createStructuredData(items: FaqAccordionItem[]): FaqStructuredData {
    const faqs: FaqStructuredDataEntity[] = items.map((item) => {
      return {
        '@type': StructuredDataType.Question,
        name: item.question,
        acceptedAnswer: {
          '@type': StructuredDataType.Answer,
          text: item.answer,
        },
      };
    });

    return {
      '@context': 'https://schema.org',
      '@type': StructuredDataType.FaqPage,
      mainEntity: faqs,
    };
  }

  private itemsToFaqAccordionItems(
    items: ComponentRendering[]
  ): FaqAccordionItem[] {
    const anchor = this.route.snapshot.fragment;

    return items.map((item, i) => {
      const id = 'accordion-item-' + (item as unknown as { id: string }).id;
      const questionObject = getDropLinkObject(item.fields, 'Question');
      const question = getFieldValue<string>(questionObject, 'Question', '');
      const alternativeQuestion = getFieldValue<string>(
        item,
        'AlternativeQuestion',
        ''
      );
      const answer = getFieldValue<string>(item, 'Answer', '');
      const imageSettings = getImageSettingsFromRendering(item);
      const hasImage = !!imageSettings.image.src;
      const clickData = {
        subject: 'faq',
        name: alternativeQuestion || question,
      };
      const impressionData = {
        ...clickData,
        open: 'click',
      };

      return {
        id,
        question: alternativeQuestion || question,
        answer,
        hasImage,
        isCollapsed: !((this.hasFirstExpanded && i === 0) || id === anchor),
        clickData,
        impressionData,
        imageSettings: hasImage ? imageSettings : undefined,
      };
    });
  }
}
