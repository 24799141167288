<div class="d-flex flex-column gap-1">
  @if (monthlyAmountLabel && monthlyAmount) {
    <div class="d-flex justify-content-between fw-bold">
      <div>
        <span *scText="monthlyAmountLabel"></span>
        @if (!!monthlyAmountTooltip) {
          <wl-tooltip
            [tooltip]="{
              description: monthlyAmountTooltip,
            }"
            [color]="'white'"
            class="ps-1"
          >
          </wl-tooltip>
        }
      </div>
      <div>{{ monthlyAmount | roundedCurrency }}</div>
    </div>
  }
  <div class="d-flex justify-content-between">
    <div>
      <span *wlGenericRichText="budgetBillLabel"></span>
      @if (!!budgetBillTooltip) {
        <wl-tooltip
          [tooltip]="{
            description: budgetBillTooltip,
          }"
          [color]="'white'"
          class="ps-1"
        >
        </wl-tooltip>
      }
    </div>
    <div>{{ budgetBillAmount | roundedCurrency }}</div>
  </div>
</div>
