<div
  class="cost-row d-flex g-1 justify-content-between align-items-center p-1"
  [class.cost-row--green]="hasDiscount"
  [class.fw-bold]="boldLabel"
>
  <div class="col d-flex">
    <div *scText="label" wlCSCapture></div>
    @if (!!tooltip) {
      <div class="ps-1">
        <wl-tooltip
          [tooltip]="{
            description: tooltip,
          }"
        ></wl-tooltip>
      </div>
    }
  </div>
  <div class="col-auto fw-bold" wlCSCapture>
    {{ hasDiscount ? '-' : '' }}
    {{ cost | currency: 'EUR' : 'symbol' : '1.2' }}
  </div>
</div>
