@if (supplyAddress?.street && supplyAddress?.city) {
  <span wlCSMask>
    {{ supplyAddress?.street }} {{ supplyAddress?.houseNumber }}
    @if (supplyAddress?.houseNumberExtension) {
      <span> {{ supplyAddress?.houseNumberExtension }}</span>
    }
    ,
    {{ supplyAddress?.city }}
  </span>
} @else {
  <span wlCSMask>
    {{ supplyAddress?.postcode }}, {{ supplyAddress?.houseNumber }}
    @if (supplyAddress?.houseNumberExtension) {
      <span>
        {{ supplyAddress?.houseNumberExtension }}
      </span>
    }
  </span>
}
