<div
  class="input-group flex-nowrap w-100"
  [class.input-group--with-suffix]="suffix"
>
  <span
    class="input-group-text input-group-prefix"
    [class.is-valid]="valid"
    [class.is-invalid]="invalid"
  >
    @if (icon) {
      <fa-icon [icon]="icon" size="lg"></fa-icon>
    }
  </span>
  <ng-content></ng-content>
  @if (suffix) {
    <span
      [class.is-valid]="valid"
      [class.is-invalid]="invalid"
      class="input-group-text input-group-suffix"
    >
      {{ suffix }}
    </span>
  }
</div>
