@if (!offer?.isHighlighted) {
  <div class="offer-highlighted-spacing"></div>
}
@if (offer?.isHighlighted) {
  <wl-offer-label wlAutomationId="offer-label" [offer]="offer">
  </wl-offer-label>
}
@if (offer) {
  <wl-partial-card
    id="vertical-offer"
    wlAutomationId="vertical-offer"
    [borderRadius]="offer?.isHighlighted ? 'bottom' : 'all'"
  >
    <div slot="header">
      <wl-partial-sticker-header
        [title]="offer?.productTitle"
        [subtitle]="offer?.productSubtitle"
        [sticker]="{
          src: offer?.incentiveImage ?? '',
          alt: 'sticker behorende bij offer',
        }"
      >
      </wl-partial-sticker-header>
    </div>
    <div slot="content">
      <div
        [class.mb-1]="
          showDurationTitle || (!showDurationTitle && !showOfferDurations)
        "
      ></div>
      @if (showDurationTitle && showOfferDurations) {
        <div
          class="fw-bold"
          wlAutomationId="duration-title"
          *scText="rendering.fields.DurationTitle"
        ></div>
      }
      <div class="items-container">
        @if (showOfferDurations && activeDuration) {
          <wl-vertical-offer-duration
            wlAutomationId="offer-duration"
            [durations]="durations"
            [activeDuration]="activeDuration"
            (changeDuration)="durationChanged($event)"
          ></wl-vertical-offer-duration>
        }
        <div>
          @if (showTarrifModalButton) {
            <div class="d-flex justify-content-between mb-1">
              <b *scText="rendering.fields.OfferBreakdownTitle"></b>
              <wl-vertical-offer-trigger-aom-button
                wlAutomationId="offer-trigger-aom-component"
                [rendering]="rendering"
                [isCompactMode]="toggleCompactMode"
                (showTariffs)="openAomModal.emit(offer)"
              ></wl-vertical-offer-trigger-aom-button>
            </div>
          }
          @if (showBreakdown) {
            <wl-offer-breakdown
              wlAutomationId="offer-breakdown"
              [rendering]="rendering"
              [offer]="offer"
            ></wl-offer-breakdown>
          }
          <wl-offer-budgetbill
            [rendering]="rendering"
            [offer]="offer"
            [hasMemberGetMember]="hasMemberGetMember"
          ></wl-offer-budgetbill>
        </div>
        <!-- border-0 is hacky, border zit hardcoded in toggle-with-info... -->
        @if (activeDuration && addOn && showProductAddons) {
          <wl-toggle-with-info
            [rendering]="rendering"
            class="d-flex bg-light rounded"
            [title]="{ value: addOn?.title }"
            [description]="{ value: addOn?.description }"
            [tooltip]="addOn?.tooltip"
            wlAutomationId="offer-add-on"
            [checked]="!!isAddOnChecked"
            (toggle)="addOnToggled($event)"
            containerClass="border-0"
          >
          </wl-toggle-with-info>
        }
        <div>
          <button
            class="btn btn-sales-primary w-100"
            wlAutomationId="offer-submit-button"
            *scText="rendering.fields.OfferSubmitLabel"
            (click)="selectProposition.emit(offer)"
          ></button>
          @if (showOfferSubmitMicro) {
            <p
              class="mt-1 mb-0 text-center"
              *scText="rendering.fields.OfferSubmitMicro"
            ></p>
          }
        </div>
        @if (showTarrifBreakdownDropdown) {
          <wl-vertical-offer-dropdown
            wlAutomationId="offer-dropdown"
            [rendering]="rendering"
            [offer]="offer"
            (showTarifs)="openAomModal.emit($event)"
            [initialDropdownState]="xpEditorActive"
          ></wl-vertical-offer-dropdown>
        }
        @if (showUspList) {
          <hr class="m-0" />
          <wl-offer-usps [usps]="offer.usps"></wl-offer-usps>
          <hr class="m-0" />
        }
        @if (showMoreInfoBlock) {
          <wl-offer-more-info-block
            [rendering]="rendering"
            [offer]="offer"
            [moreInfoButtonClick]="moreInfoButtonClick"
          ></wl-offer-more-info-block>
        }
        <hr class="m-0" />
        <wl-commodity-offer-calculation-based-on
          [variant]="'narrow'"
          [label]="rendering?.fields?.CalculationBasedOnLabel?.value"
          [address]="supplyAddressVm ?? undefined"
          [consumptions]="consumptionValuesVm"
          [electricityLabel]="'ElectricityUsageUnit' | translate"
          [gasLabel]="'GasUsageUnit' | translate"
          [adjustCalculationLabel]="
            rendering?.fields?.OpenModalAdjustCalculation?.value
          "
          (adjustCalculation)="openCalculateModal.emit($event)"
        ></wl-commodity-offer-calculation-based-on>
      </div>
    </div>
  </wl-partial-card>
} @else {
  <div wlAutomationId="skeleton-loader" class="border rounded bg-white p-4">
    <wl-svg-skeleton-loader
      [mask]="skeletonLoaderMask"
    ></wl-svg-skeleton-loader>
  </div>
}
