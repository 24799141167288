import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getMetaDataActions } from '@essent/new-customer';
import { parseBacFlowFunnelSettings } from '@innogy/become-a-customer/shared';
import type { FunnelSettings } from '@innogy/become-a-customer/shared/interfaces';
import { BAC_FLOW_ID_COOKIE_KEY, getScopedCookieKey } from '@legacy/common';
import { openGenericModal } from '@innogy/common-ui/modals';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { jssRenderingToObject, normalizeUid } from '@core/jss-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import type { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { getMetaDataValues } from '../bac/5.meta-data';
import { getFlowId, setFlowIdFromCookieAction } from '../bac/flow-id';
import { setIsCustomerAction } from '../bac/is-customer';
import { setMGMSaleIdAction } from '../bac/member-get-member';
import { setPartnerIdAction } from '../bac/partner-id';
import { getCalculateRendering } from '../calculate';
import { performChannelRecognitionAction } from '../channel-recognition';
import {
  clearFunnelSettings,
  loadFunnelSettingsAction,
  openModalFromFunnelSettingsAction,
  redirectToFunnelStartPageAction,
  setBACFunnelStepAction,
  setFunnelInitializedAction,
  setFunnelSettingsAction,
  setFunnelSettingsFromGraphqlAction,
  setFunnelSettingsFromGraphqlErrorAction,
  setFunnelSettingsFromRenderingAction,
} from './funnel-settings.actions';
import {
  getFallbackStartPageUrl,
  getFunnelSettings,
} from './funnel-settings.selector';
import { FunnelSettingsService } from './funnel-settings.service';

@Injectable()
export class FunnelEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly cookieService: CookieService,
    private readonly funnelSettingsService: FunnelSettingsService,
    private readonly errorHandler: ErrorHandler,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store$: Store,
    private readonly injector: Injector
  ) {}

  flowId$ = this.store$.select(getFlowId);
  funnelSettings$ = this.store$.select(getFunnelSettings);
  isXpEditorActive$ = this.store$.select(getIsExperienceEditorActive);
  fallbackStartPageUrl$ = this.store$.select(getFallbackStartPageUrl);
  calculateRendering$ = this.store$.select(getCalculateRendering);

  public readonly onInitFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBACFunnelStepAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ step }, funnelSettings]) =>
          !funnelSettings.initialized || step === 'Calculate'
      ),
      map(([{ step }]) => loadFunnelSettingsAction({ sourceStep: step }))
    )
  );

  public readonly clearFunnelSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearFunnelSettings),
        tap(() =>
          this.router.navigate([], {
            queryParams: {
              funnel_id: null,
            },
            queryParamsHandling: 'merge',
          })
        )
      ),
    { dispatch: false }
  );

  public readonly determineFunnelSettingsSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFunnelSettingsAction),
      concatLatestFrom(() => [this.activatedRoute.queryParamMap]),
      map(([, params]) => {
        const funnelId: string | null = params.get('funnel_id');

        if (funnelId) {
          return setFunnelSettingsFromGraphqlAction({
            guid: funnelId,
          });
        }

        return setFunnelSettingsFromRenderingAction();
      })
    )
  );

  public readonly channelRecognitionOnInit$ = createEffect(() =>
    this.actions$.pipe(
      // Should be performed after funnelsettings init because of disableChannelRecognition setting
      ofType(setFunnelSettingsAction),
      map(() => {
        const { type, segment } = this.injector.get(ENVIRONMENT_CONFIG);
        const flowId = this.cookieService.get(
          getScopedCookieKey(BAC_FLOW_ID_COOKIE_KEY, type, segment)
        );

        if (flowId) {
          // This flowId is used to retrieve metaData to see if a partnerId has already been set.
          // If this is not the case, we will performChannelRecognitionAction() anyway in onGetMetadataSuccess$.
          return setFlowIdFromCookieAction({ flowId });
        }

        return performChannelRecognitionAction();
      })
    )
  );

  public readonly onSetFlowIdFromCookieAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFlowIdFromCookieAction),
      mergeMap(({ flowId }) => [
        getMetaDataActions.requestAction({
          payload: { flowId },
        }),
      ])
    )
  );

  public readonly onRedirectToFunnelStartPageAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToFunnelStartPageAction),
        concatLatestFrom(() => [
          this.isXpEditorActive$,
          this.funnelSettings$,
          this.fallbackStartPageUrl$,
        ]),
        filter(([_, isExperienceEditorActive]) => !isExperienceEditorActive),
        tap(([_, __, { id, startPage }, fallbackStartPageUrl]) => {
          const [currentPath] = this.router.url.split('?');
          // If we are already on the startpage no redirect is needed!
          if (currentPath === startPage.href) {
            return [];
          }

          if (startPage.href) {
            return this.router.navigate([startPage.href], {
              queryParams: { funnel_id: id },
            });
          }

          if (fallbackStartPageUrl?.href) {
            return this.router.navigate([fallbackStartPageUrl?.href]);
          }

          return this.errorHandler.handleError(
            'Could not redirect to start page because no funnel settings and funnelFallbackLink are available'
          );
        })
      ),
    { dispatch: false }
  );

  public readonly onGetMetadataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetaDataActions.successAction),
      mergeMap((action) => {
        const actions: Action[] = [];

        const { is_customer, partner_id, mgmco_saleid } = getMetaDataValues(
          action.payload?.metadata
        );

        if (is_customer) {
          actions.push(
            setIsCustomerAction({
              isCustomer: is_customer === 'true' ?? false,
            })
          );
        }

        if (partner_id) {
          actions.push(
            setPartnerIdAction({
              partnerId: partner_id,
            })
          );
        }

        if (mgmco_saleid) {
          actions.push(
            setMGMSaleIdAction({
              saleId: mgmco_saleid,
            })
          );
        }

        actions.push(performChannelRecognitionAction());

        return actions;
      })
    )
  );
  /**
   * Set the funnel settings to the settings provided by the rendering of the
   * calculate component.
   */
  public readonly setFunnelSettingsFromRendering$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFunnelSettingsFromRenderingAction),
      concatLatestFrom(() => this.calculateRendering$),
      mergeMap(([, rendering]) => {
        if (!rendering) {
          return [redirectToFunnelStartPageAction()];
        }

        const settings = jssRenderingToObject<FunnelSettings>(
          rendering.fields.FunnelSettings,
          true
        );

        if (settings) {
          return [
            setFunnelSettingsAction({
              payload: parseBacFlowFunnelSettings(settings),
            }),
          ];
        }

        return [];
      })
    )
  );

  public readonly redirectToStartPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFunnelSettingsAction),
      switchMap(({ sourceStep }) =>
        this.actions$.pipe(
          ofType(setFunnelSettingsAction),
          take(1),
          mapTo(sourceStep)
        )
      ),
      map((sourceStep) =>
        sourceStep === 'Calculate'
          ? setFunnelInitializedAction()
          : redirectToFunnelStartPageAction()
      )
    )
  );

  public readonly onFetchFunnelSettingsFromGraphql$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFunnelSettingsFromGraphqlAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ guid }, funnelSettings]) => funnelSettings.id !== normalizeUid(guid)
      ),
      mergeMap(([{ guid }]) => this.funnelSettingsService.getByGuid(guid)),
      map((funnelSettings) =>
        setFunnelSettingsAction({
          payload: parseBacFlowFunnelSettings(funnelSettings),
        })
      ),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((error: any) =>
        of(
          setFunnelSettingsFromRenderingAction(),
          setFunnelSettingsFromGraphqlErrorAction({ error })
        )
      )
    )
  );

  public readonly openModalFromFunnelSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openModalFromFunnelSettingsAction),
      concatLatestFrom(() => this.funnelSettings$),
      mergeMap(([action, funnelSettings]) => {
        const modalProperties = funnelSettings[action.source];
        if (!modalProperties) {
          this.errorHandler.handleError(
            `No modal was configured for the modal: ${action.source}`
          );
          return [];
        }
        return [
          openGenericModal({
            ...modalProperties,
            source: action.source,
            additionalPayload: {
              // Keep the additionalPayload properties set from sitecore
              ...(modalProperties.additionalPayload || {}),
              // Add the additionalPayload properties set when dispatching this action
              ...(action.additionalPayload || {}),
            },
            interpolatablePayload: {
              ...action.interpolatablePayload,
            },
          }),
        ];
      })
    )
  );
}
