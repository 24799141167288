import { Component, type OnInit, input } from '@angular/core';
import {
  ConsumptionValuesVM,
  CostPerUnitVM,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@core/jss-models';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

import { offerHasDiscount } from '../../helpers';
import { MonthlyBudgetPaymentSection } from './aom-content.model';

@Component({
  selector: 'wl-aom-content',
  templateUrl: './aom-content.component.html',
  styleUrls: [
    './aom-content.component.ed.scss',
    './aom-content.component.essent.scss',
  ],
})
export class AomContentComponent implements OnInit {
  consumptionValuesVM = input<ConsumptionValuesVM>();
  costPerUnitVM = input<CostPerUnitVM>();
  fields = input.required<ComponentFields>();
  isVATIncluded = input(false);
  offer = input<Offer>();
  rendering = input<InnogyComponentRendering>();

  monthlyBudgetPaymentSection!: MonthlyBudgetPaymentSection;

  get kwhNormal() {
    return this.consumptionValuesVM()?.electricity.supplyNormal;
  }

  get kwhLow() {
    return this.consumptionValuesVM()?.electricity.supplyLow;
  }

  get gas() {
    return this.consumptionValuesVM()?.gas;
  }

  get solarReturn() {
    return this.consumptionValuesVM()?.electricity.returnSupplyNormal;
  }

  get solarReturnLow() {
    return this.consumptionValuesVM()?.electricity.returnSupplyLow;
  }

  get budgetBillAmount() {
    return this.offer()?.budgetBillAmount || 0;
  }

  get hasDiscount() {
    return offerHasDiscount(this.offer());
  }

  get disclaimer() {
    return this.offer()?.disclaimerContent ?? '';
  }

  get isDynamicProduct() {
    return !!this.offer()?.isDynamicProduct;
  }

  get monthlyBudgetPaymentHeading(): string {
    return this.isDynamicProduct
      ? this.monthlyBudgetPaymentSection.dynamicMonthlyBudgetPaymentHeading
      : this.monthlyBudgetPaymentSection.fixedMonthlyBudgetPaymentHeading;
  }

  get monthlyBudgetPaymentParagraph(): string {
    return this.isDynamicProduct
      ? this.monthlyBudgetPaymentSection.dynamicMonthlyBudgetPaymentParagraph
      : this.monthlyBudgetPaymentSection.fixedMonthlyBudgetPaymentParagraph;
  }

  ngOnInit(): void {
    this.monthlyBudgetPaymentSection = new MonthlyBudgetPaymentSection(
      this.fields()
    );
  }
}
