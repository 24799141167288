@if (showSticker) {
  <div class="sticker-container mx-n2">
    @if (offer?.incentiveImage) {
      <img
        [src]="offer?.incentiveImage"
        [alt]="offer?.incentiveValue"
        class="sticker-container--img"
      />
    } @else {
      @if (rendering?.fields?.Sticker; as sticker) {
        <img
          *scImage="sticker"
          [alt]="offer?.incentiveValue"
          class="sticker-container--img"
        />
      }
    }
  </div>
}
