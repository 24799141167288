@if (showAddressTypeSelector) {
  <div class="mb-2">
    <wl-radio
      [formsControl]="formState.controls.addressType"
      [radioItems]="radioItems"
      [rendering]="rendering"
      [inline]="true"
      fieldName="address-type"
    ></wl-radio>
  </div>
}
<div class="address-form__card">
  <wl-postal-address-form
    [rendering]="$any(rendering)"
    [content]="content"
    [trackStep]="trackStep"
    [formState]="formState"
    [floatingLabels]="floatingLabels"
  ></wl-postal-address-form>
</div>
@if (showAddressNotFoundError()) {
  <div class="mb-1">
    <wl-info-card
      [title]="getAddressNotFoundErrorTitle()"
      [message]="getAddressNotFoundErrorMessage()"
      [border]="true"
      [shadow]="false"
      [automationId]="automationId + '-address-not-found'"
    ></wl-info-card>
  </div>
}
@if (formState.isValidationPending) {
  <fa-icon size="lg" icon="spinner-third" animation="spin"></fa-icon>
}
@if (streetAndCityInputsVisible()) {
  @if (formState.value.addressType === AddressType.ADDRESS) {
    <div class="row address-form__card">
      <div class="col-12">
        <wl-text
          [rendering]="rendering"
          [formsControl]="formState.controls.street"
          [label]="content.StreetLabel"
          [placeholder]="content.StreetPlaceholder"
          [floatingLabel]="floatingLabels"
          [sendValue]="sendValues"
          [hashValue]="hashValues"
          [skipDirtyCheck]="true"
          updateOn="blur"
          fieldName="street"
        >
          @if (formState.controls.street.isTouched) {
            @if (formState.controls.street.errors.required) {
              <wl-input-error
                [automationId]="automationId + '-street-required-error'"
                [rendering]="rendering"
                [label]="content.StreetRequiredError"
              ></wl-input-error>
            }
          }
        </wl-text>
      </div>
    </div>
  }
  <div class="row address-form__card">
    <div class="col-12">
      <wl-text
        [rendering]="rendering"
        [formsControl]="formState.controls.city"
        [label]="content.CityLabel"
        [placeholder]="content.CityPlaceholder"
        [floatingLabel]="floatingLabels"
        [sendValue]="sendValues"
        [hashValue]="hashValues"
        [skipDirtyCheck]="true"
        updateOn="blur"
        fieldName="city"
      >
        @if (formState.controls.city.isTouched) {
          @if (formState.controls.city.errors.required) {
            <wl-input-error
              [automationId]="automationId + '-city-required-error'"
              [rendering]="rendering"
              [label]="content.CityRequiredError"
            ></wl-input-error>
          }
        }
      </wl-text>
    </div>
  </div>
}
