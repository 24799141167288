import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';

import {
  CommodityOfferLabels,
  CommodityOfferLayoutType,
} from '../../commodity-offers.model';

@Component({
  selector: 'essent-consumer-retention-commodity-offer',
  templateUrl: './essent-consumer-retention-commodity-offer.component.html',
})
export class EssentConsumerRetentionCommodityOfferComponent {
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;
  @Input() variant?: CommodityOfferLayoutType;
  @Input() active = false;
  @Input() showLoadingState = false;
  @Input() ensureHorizontalAlignment = false;
  @Input() selectableOffer = true;
  @Output() selectOffer = new EventEmitter<void>();
  @Output() viewTariffs = new EventEmitter<void>();

  get setMinimumHeightOfferCardBody() {
    if (!this.selectableOffer) {
      return { 'min-height': 'auto' };
    } else if (this.content?.showBudgetbillAmount) {
      return { 'min-height': '195px' };
    }

    return { 'min-height': '130px' };
  }
}
