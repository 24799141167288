<div class="search-bar__search" wlKeyboardNavigation>
  <form novalidate class="search-bar__form">
    <input
      wlAutomationId="search-bar"
      #searchInput
      autocapitalize="off"
      autocomplete="off"
      autocorrect="off"
      spellcheck="false"
      maxlength="99"
      type="search"
      placeholder="Zoeken naar..."
      name="search"
      class="form-control search-bar__input"
      [(ngModel)]="searchQuery"
      aria-autocomplete="list"
      aria-label="Zoekbalk"
    />
    <button
      type="submit"
      class="btn btn-primary btn-search"
      aria-label="Zoekopdracht verzenden"
      (click)="submit($event)"
    >
      <fa-icon class="fa-icon fa-xl" icon="search" />
    </button>
  </form>
  <wl-search-popover
    [class.search-bar__popover--visible]="elementHasFocus$ | async"
    class="search-bar__popover"
    [searchQuery]="searchQuery"
  >
  </wl-search-popover>
  @if (elementHasFocus$ | async) {
    <div wlDisableBodyScrolling minBreakpoint="md"></div>
  }
</div>
