import { Injectable } from '@angular/core';
import { getQueryParameterHistory } from '@core/jss-routing';
import { AddressTypes } from '@innogy/customer-details/store';
import {
  getInsulationAddressCheckSuccess,
  type InsulationNewCustomerConfirmationRequestBody,
  postCreateLeadInsulation,
  postCreateLeadInsulationSuccess,
  postInsulationConfirmation,
  postInsulationConfirmationError,
  postInsulationConfirmationSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import { mapGenderToSalutation } from '@innogy/utils-deprecated';
import { selectUrl } from '@innogy/utils-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { type FormGroupState, ResetAction } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import type { FormAddress } from '@innogy/common-ui/forms';
import type { ClientEnvironmentConfig } from '@core/config-models';
import { getAppConfig } from '@core/config-store';
import { getBrand, type PersonalDetails } from '@innogy/eplus/shared';
import type { CreateLeadInsulationSchema } from '@integration/api-client/feh';

import { selectGetInsulationNewCustomerAddressCheckData } from '../insulation-api';
import { insulationSelectorKey } from '../insulation-form.state';
import {
  clearInsulationFunnelInitializedAction,
  handleInsulationFunnelGenericErrorAction,
  onInsulationFunnelNavigationAction,
  resetInsulationProductSelectionAction,
  selectInsulationFunnelSettings,
} from '../insulation-funnel';
import type { InsulationFunnelState } from '../insulation-funnel/insulation-funnel.reducer';
import { hideInsulationProductOverviewAction } from '../insulation-products';
import {
  prefillInsulationOrderFormAddressStateAction,
  resetInsulationOrderFormAction,
} from './insulation-order-form.actions';
import {
  insulationOrderAddressFormId,
  insulationOrderConfirmationFormId,
  insulationOrderPersonalDetailsFormId,
} from './insulation-order-form.reducer';
import {
  selectInsulationOrderFormAddressFormState,
  selectInsulationOrderFormPersonalDetailsFormState,
} from './insulation-order-form.selectors';

@Injectable()
export class InsulationOrderFormEffects {
  currentDate = new Date();
  funnelSettings$ = this.store$.select(selectInsulationFunnelSettings);
  addressCheckData$ = this.store$.select(
    selectGetInsulationNewCustomerAddressCheckData
  );
  insulationOrderFormPersonalDetails$ = this.store$.select(
    selectInsulationOrderFormPersonalDetailsFormState
  );
  insulationOrderFormAddress$ = this.store$.select(
    selectInsulationOrderFormAddressFormState
  );
  queryParamHistory$ = this.store$.select(getQueryParameterHistory);

  currentUrl$ = this.store$.select(selectUrl);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  public readonly prefillInsulationAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInsulationAddressCheckSuccess),
      mergeMap((action) => [
        prefillInsulationOrderFormAddressStateAction({
          payload: {
            addressType: AddressTypes.ADDRESS,
            city: action.payload.address.city,
            houseNumberAddition: action.payload.address.housenumberaddition,
            postalCode: action.payload.address.postalcode,
            communicationNumber: action.payload.address.housenumber,
            street: action.payload.address.street,
          },
        }),
      ])
    )
  );

  public readonly onResetInsulationAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetInsulationOrderFormAction),
      mergeMap(() => [
        new ResetAction(insulationOrderAddressFormId),
        new ResetAction(insulationOrderPersonalDetailsFormId),
      ])
    )
  );

  public readonly onSubmitInsulationOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === insulationOrderConfirmationFormId),
      concatLatestFrom(() => [
        this.funnelSettings$,
        this.insulationOrderFormPersonalDetails$,
        this.insulationOrderFormAddress$,
        this.currentUrl$,
        this.queryParamHistory$,
        this.store$.select(getAppConfig),
      ]),
      map(
        ([
          ,
          funnelSettings,
          personalDetails,
          address,
          currentUrl,
          queryParamHistory,
          config,
        ]) =>
          config?.lambdaInsulationCreateLead.enabled
            ? postCreateLeadInsulation({
                payload: this.mapCreateLeadPayload(
                  funnelSettings,
                  personalDetails,
                  address,
                  currentUrl,
                  queryParamHistory,
                  config
                ),
              })
            : postInsulationConfirmation({
                payload: this.mapGenericFormSubmit(
                  funnelSettings,
                  personalDetails,
                  address,
                  currentUrl,
                  queryParamHistory
                ),
              })
      )
    )
  );

  public readonly onSubmitInsulationOrderFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        postInsulationConfirmationSuccess,
        postCreateLeadInsulationSuccess
      ),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onInsulationFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );

  public readonly resetInsulationOrderFormAndProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ payload }, funnelSettings]) =>
          payload.routerState.url === funnelSettings.successPage?.href
      ),
      // Even though we wait for navigated events, apparently
      // an extra delay is needed in order to prevent firing tool-step events
      // after form rest on the former page.
      // .5s is chosen arbitrarily, yet seems to work even under 3g conditions.
      delay(500),
      mergeMap(() => [
        resetInsulationProductSelectionAction(),
        hideInsulationProductOverviewAction(),
        resetInsulationOrderFormAction(),
        clearInsulationFunnelInitializedAction(),
        resetProgressiveForm({ formId: insulationSelectorKey }),
      ])
    )
  );

  public readonly onSubmitInsulationOrderFormError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postInsulationConfirmationError),
      tap(({ payload }) => {
        console.error('Error while submitting insulation order', payload.error);
      }),
      map(handleInsulationFunnelGenericErrorAction)
    )
  );

  private mapCreateLeadPayload(
    funnelSettings: InsulationFunnelState,
    personalDetails: FormGroupState<PersonalDetails>,
    address: FormGroupState<FormAddress>,
    currentUrl: string,
    queryParamHistory: { [key: string]: string },
    config: ClientEnvironmentConfig
  ): CreateLeadInsulationSchema {
    return {
      type: 'insulation',
      lead: {
        title: mapGenderToSalutation(personalDetails.value.salutation) ?? '',
        initials: personalDetails.value.initials,
        prefix: personalDetails.value.prefix,
        lastName: personalDetails.value.lastName,
        addresses: {
          communication: {
            postcode: address.value.postalCode,
            street: address.value.street,
            houseNumber: address.value.communicationNumber?.toString() ?? '',
            houseNumberExtension:
              address.value.houseNumberAddition.trim() || undefined,
            city: address.value.city,
          },
        },
        phone: personalDetails.value.phoneNumber,
        email: personalDetails.value.emailAddress,
      },
      context: {
        channel: 'web',
        requestType: 'Advies',
        brand: getBrand(config.brand),
      },
      metadata: {
        createdOn: this.currentDate.toISOString(),
        source: 'Online',
        environment: 'Consument',
        referrer: currentUrl,
        leadTrackingId: 'DirectOther',
        e2eTrackingId: funnelSettings.trackingId ?? '',
        gclid: queryParamHistory['gclid'] ?? '',
        temperature: 4, // HACK: to be fixed by FEH
      },
      products: funnelSettings.selectedProducts.map((product) => ({
        category: 'insulation',
        subCategory: product.productType.value,
      })),
    };
  }

  private mapGenericFormSubmit(
    funnelSettings: InsulationFunnelState,
    personalDetails: FormGroupState<PersonalDetails>,
    address: FormGroupState<FormAddress>,
    currentUrl: string,
    queryParamHistory: { [key: string]: string }
  ): InsulationNewCustomerConfirmationRequestBody {
    return {
      salutation: mapGenderToSalutation(personalDetails.value.salutation) ?? '',
      initials: personalDetails.value.initials,
      lastNamePrefix: personalDetails.value.prefix,
      lastName: personalDetails.value.lastName,
      emailAddress: personalDetails.value.emailAddress,
      street: address.value.street,
      postalCode: address.value.postalCode,
      houseNumber: address.value.communicationNumber?.toString() || '',
      houseNumberSuffix: address.value.houseNumberAddition,
      city: address.value.city,
      typeOfContract: 'Koop',
      telephoneNumber: personalDetails.value.phoneNumber,
      referrer: currentUrl,
      products: funnelSettings.selectedProducts.map((p) => p.productType.value),
      leadTracking: 'DirectOther',
      e2eTrackingId: funnelSettings.trackingId ?? '',
      gclid: queryParamHistory['gclid'] ?? '',
    };
  }
}
