<div class="w-100 h-100 d-block">
  @switch (brand) {
    @case ('ed') {
      <wl-not-found title="Oeps, de stekker is eruit."></wl-not-found>
    }

    @default {
      <wl-not-found title="Oeps, we zijn even niet thuis"></wl-not-found>
    }
  }
</div>
