import { Injectable } from '@angular/core';
import type { CommerceMapperContext, CommerceSolar } from '@core/analytics';
import {
  analyticsRoundNumericValue,
  defaultCommerceMapperContext,
  parseTrackProductConfig,
  ProductCategorie,
  ProductType,
  TrackProductService,
} from '@core/analytics';
import type { SolarPanelsProductWithCalculations } from '@innogy/eplus/temporary-core-modules';

@Injectable({
  providedIn: 'root',
})
export class SolarPanelsProductTrackingConfig {
  constructor(private readonly trackProductService: TrackProductService) {}

  config = parseTrackProductConfig({
    productToCommerce: (
      product: SolarPanelsProductWithCalculations,
      context: CommerceMapperContext
    ): CommerceSolar => {
      context = {
        ...context,
        listName: this.trackProductService.contextualizeListName(
          context.listName
        ),
      };
      return SolarPanelsProductTrackingConfig.mapToCommerce(product, context);
    },
  });

  static mapToCommerce = (
    product: SolarPanelsProductWithCalculations,
    context: CommerceMapperContext = defaultCommerceMapperContext
  ): CommerceSolar => ({
    productNaam: product.content.title,
    productID: product.suiteCrmId,
    productType: ProductType.Solar,
    productCategorie: ProductCategorie.Zonnepanelen,
    productPositie: `${context.index}`,
    productLijst: context.listName,
    productAantal: `${product.recommendation.amount}`,
    prijs: analyticsRoundNumericValue(product.costs),
    besparing: analyticsRoundNumericValue(product.yearlySavings),
    productGarantie: `${product.productGuarantee}`,
    opbrengst: `${product.recommendation.yearlyYield}`,
    vermogen: `${product.performance}`,
    vermogensGarantie: `${product.performanceGuaranteedAfterYears}`,
  });
}
