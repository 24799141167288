import { Status, type AsyncState } from '@essent/common';
import {
  getCheckPostcodeError,
  getCheckPostcodeSuccess,
  type GetCheckPostcodeResponse,
} from '@essent/feh';
import { createReducer, on } from '@ngrx/store';
import type { ContactFormType, CustomerType } from '@innogy/eplus/shared';
import type { PostHousescanV1Response } from '@integration/api-client/feh';

import { updateFormConfig } from './sustainability.actions';
import { FehApiActions } from '../../api';

// createLeadResult
export const sustainabilityCreateLeadResultId =
  'sustainabilityCreateLeadResult';

export type SustainabilityCreateLeadResultState =
  AsyncState<PostHousescanV1Response>;

export const sustainabilityCreateLeadResultReducer =
  createReducer<SustainabilityCreateLeadResultState>(
    {
      status: Status.IDLE,
    },
    on(FehApiActions.postHousescanV1.successAction, (_, params) => ({
      status: Status.SUCCESS,
      data: params.payload,
    })),
    on(FehApiActions.postHousescanV1.errorAction, (_, params) => ({
      status: Status.ERROR,
      error: params.payload,
    }))
  );

// postcodeValidation
export const sustainabilityPostcodeValidationId =
  'sustainabilityPostcodeValidation';

export type SustainabilityPostcodeValidationState =
  AsyncState<GetCheckPostcodeResponse>;

export const sustainabilityPostcodeValidationReducer =
  createReducer<SustainabilityPostcodeValidationState>(
    {
      status: Status.IDLE,
    },
    on(getCheckPostcodeSuccess, (_, params) => ({
      status: Status.SUCCESS,
      data: params.payload,
    })),
    on(getCheckPostcodeError, (_, params) => ({
      status: Status.ERROR,
      error: params.payload,
    }))
  );

// formConfig
export const sustainabilityFormConfigId = 'sustainabilityFormConfig';

export interface SustainabilityFormConfigState {
  formType: ContactFormType;
  customerType: CustomerType;
  scanId: string;
  product: string;
}

export const sustainabilityFormConfigReducer =
  createReducer<SustainabilityFormConfigState>(
    {
      formType: '',
      customerType: 'Consument',
      scanId: '',
      product: '',
    },
    on(updateFormConfig, (state, params) => ({
      ...state,
      ...params,
    }))
  );
