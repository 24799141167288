<div
  class="d-flex segmented-control"
  wlAutomationId="segmented-control w-100 d-flex"
>
  @for (entry of entries; track trackById($index, entry)) {
    <button
      (click)="clicked(entry)"
      class="segmented-control__item d-flex justify-content-center rounded"
      [class.segmented-control__item--selected]="selected?.id === entry.id"
      [class.fw-bold]="selected?.id === entry.id"
      [wlAutomationId]="'segmented-control-item-' + entry.id"
      wlTrackLink
      [trackLinkInfo]="{
        eventType: 'button-click',
        type: 'button',
        field: entry.text,
      }"
      wlTrackImpression="button-impression"
      trackImpressionMode="button"
    >
      @if (entry.icon) {
        <span class="d-flex justify-content-center align-items-center">
          <fa-icon class="d-block mx-1" [icon]="entry.icon"></fa-icon>
          <span class="segmented-control__item__text">{{ entry.text }}</span>
        </span>
      } @else {
        {{ entry.text }}
      }

      <ng-template #text>
        {{ entry.text }}
      </ng-template>
    </button>
  }
</div>
