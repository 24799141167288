import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';

@Component({
  selector: 'wl-horizontal-links-list',
  templateUrl: './horizontal-links-list.component.html',
  styleUrls: [
    './horizontal-links-list.component.essent.scss',
    './horizontal-links-list.component.ed.scss',
  ],
})
export class HorizontalLinksListComponent {
  @Input() rendering?: InnogyComponentRendering;
}
