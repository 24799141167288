import { createReducer, on } from '@ngrx/store';
import type { PostHeatpumpV1Data } from '@integration/api-client/feh';

import type { MultipleAnswerQuestion } from './questions/multiple-answer-question.interface';
import type { SingleAnswerQuestion } from './questions/single-answer-question.interface';
import { QuestionnaireStructure } from './questions/questionnaire-structure';
import {
  initializeHeatpumpsQuestionnaireAction,
  showHeatpumpsQuestionnaireNextQuestionAction,
  submitHeatpumpsQuestionnaireSingleAnswerAction,
  submitHeatpumpsQuestionnaireMultipleAnswerAction,
  showHeatpumpsQuestionnairePreviousQuestionAction,
} from './heatpumps-questionnaire.actions';

export const heatpumpsQuestionnaireKey = 'questionnaire';

export interface HeatpumpsQuestionnaireState {
  structure: {
    [K in keyof Partial<
      PostHeatpumpV1Data['requestBody']['metadata']['building']
    >]: SingleAnswerQuestion | MultipleAnswerQuestion;
  };
  currentQuestion: SingleAnswerQuestion | MultipleAnswerQuestion;
  submittedAnswers: Partial<
    PostHeatpumpV1Data['requestBody']['metadata']['building']
  >;
  history: string[];
}

// Top of the structure = the first question
const firstQuestion = Object.keys(
  QuestionnaireStructure
)[0] as keyof PostHeatpumpV1Data['requestBody']['metadata']['building'];

export const initialState: HeatpumpsQuestionnaireState = {
  structure: {},
  currentQuestion: QuestionnaireStructure[firstQuestion] as NonNullable<
    SingleAnswerQuestion | MultipleAnswerQuestion
  >,
  submittedAnswers: {},
  history: [firstQuestion],
};

export const heatpumpsQuestionnaireReducer = createReducer(
  initialState,
  on(initializeHeatpumpsQuestionnaireAction, () => ({
    ...initialState,
    structure: QuestionnaireStructure,
  })),
  on(submitHeatpumpsQuestionnaireSingleAnswerAction, (state, props) => ({
    ...state,
    submittedAnswers: {
      ...state.submittedAnswers,
      [state.currentQuestion.apiKey]: props.answer.apiValue ?? 'invalid',
    },
  })),
  on(submitHeatpumpsQuestionnaireMultipleAnswerAction, (state, props) => ({
    ...state,
    submittedAnswers: {
      ...state.submittedAnswers,
      [state.currentQuestion.apiKey]: props.answers
        .map((answer) => answer.apiValue || 'invalid')
        .join(', '),
    },
  })),
  on(showHeatpumpsQuestionnaireNextQuestionAction, (state, props) => ({
    ...state,
    currentQuestion: state.structure[
      props.next as keyof PostHeatpumpV1Data['requestBody']['metadata']['building']
    ] as NonNullable<SingleAnswerQuestion | MultipleAnswerQuestion>,
    history: state.history.concat([props.next]),
  })),
  on(showHeatpumpsQuestionnairePreviousQuestionAction, (state) =>
    navigateToPreviousQuestion(state)
  )
);

function navigateToPreviousQuestion(
  state: HeatpumpsQuestionnaireState
): HeatpumpsQuestionnaireState {
  if (state.history.length > 1) {
    const updatedHistory = [...state.history];
    updatedHistory.pop();
    const previousQuestion = updatedHistory[updatedHistory.length - 1];

    return {
      ...state,
      currentQuestion: state.structure[
        previousQuestion as keyof PostHeatpumpV1Data['requestBody']['metadata']['building']
      ] as NonNullable<SingleAnswerQuestion | MultipleAnswerQuestion>,
      history: updatedHistory,
    };
  } else {
    return { ...state };
  }
}
