@if (control && control.enabled) {
  <div class="form-section">
    <rfa-label [control]="control"></rfa-label>
    @if (newDesign) {
      <rfa-errors [control]="control" [newDesign]="true"></rfa-errors>
    }
    <textarea
      class="form-control"
      [id]="control.id"
      [formControl]="control"
      [placeholder]="placeholder"
      [autocomplete]="autocompleteId"
      [wlTrackFieldfocus]="focusChangeTrackConfig()"
    ></textarea>
    @if (!newDesign) {
      <rfa-errors [control]="control"></rfa-errors>
    }
  </div>
}
