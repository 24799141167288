import { type GenericProductData } from '@legacy/common-ui-models';

import type { BuildingType } from './new-customer/address-check/building-types';

export enum InsulationProductType {
  VLOERISOLATIE = 'vloerisolatie',
  SPOUWMUURISOLATIE = 'spouwmuurisolatie',
  DAKISOLATIE = 'dakisolatie',
  ANDERS = 'anders',
}

export type InsulationProductData = GenericProductData & {
  productType: {
    value: InsulationProductType;
  };
  pricePerSquareMeter: number;
  startingInstallationCosts?: number;
  minimumInstallationCosts?: number;
  gasSavingsPerSquareMeterPerYear: number;
  buildingConstructionYearMinimum: number;
  unsuitableBuildingTypes: Array<{
    label: string;
    value: BuildingType;
  }>;
};

export type InsulationProductWithCalculations = InsulationProductData & {
  installationCosts: number;
  paybackPeriodInYears: number | null;
  yearlySavings: number;
};
