import type { ToolTrackingConfig } from '@core/analytics';
import { createToolStepConfig, parseToolConfig } from '@core/analytics';

import {
  QuestionnaireStructure,
  selectHeatpumpsQuestionnaireAnalyticsPayload,
  type MultipleAnswerQuestion,
  type SingleAnswerQuestion,
} from '../heatpumps-questionnaire';

export function generateHeatpumpsFunnelSteps(
  questions: (SingleAnswerQuestion | MultipleAnswerQuestion)[]
) {
  const questionnaireSteps = () => {
    let step = 0;
    return questions.map((elem) => {
      step = step + 1;
      return {
        step,
        stepName: elem.trackingStepName,
        id: elem.trackingStepName,
      };
    });
  };

  return [
    {
      step: 0,
      stepName: '',
      id: 'heatpumps-check',
    },
    ...questionnaireSteps(),
  ] as const;
}

const heatpumpsFunnelSteps = generateHeatpumpsFunnelSteps(
  Object.values(QuestionnaireStructure)
);

export type HeatpumpsFunnelToolTrackingSteps =
  (typeof heatpumpsFunnelSteps)[number]['id'];

const heatpumpsFunnelToolTrackingConfig: ToolTrackingConfig<HeatpumpsFunnelToolTrackingSteps> =
  {
    toolName: 'warmtepomp check',
    steps: heatpumpsFunnelSteps,
    toolComplete: createToolStepConfig(
      selectHeatpumpsQuestionnaireAnalyticsPayload,
      (payload) => ({
        step: heatpumpsFunnelSteps[heatpumpsFunnelSteps.length - 1].step,
        payload,
      })
    ),
  };

export const parsedheatpumpsFunnelToolTrackingConfig = parseToolConfig(
  heatpumpsFunnelToolTrackingConfig
);
