<wl-modal (closeModal)="closeModal()" [modalTitle]="rendering?.title">
  <ng-template wlModalBody>
    @if (formState$ | async; as formState) {
      <wl-usage-questionnaire
        [rendering]="rendering"
        [formState]="formState"
        [gasHidden]="gasHidden"
      />
    }
  </ng-template>
</wl-modal>
