import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@core/analytics';
import type { InnogyComponentRendering } from '@core/jss-models';
import { uppercaseConverter } from '@innogy/utils-deprecated';
import { FormGroupState } from 'ngrx-forms';

import type {
  PostalAddressFormRendering,
  PostalAddressFormValues,
} from './model/postal-address-form.model';
import { PostalAddressFormContent } from './model/postal-address-form.model';
import type { FormAddress } from '../address-form/state/address-form.types';

@Component({
  selector: 'wl-postal-address-form[rendering][content][trackStep]',
  templateUrl: './postal-address-form.component.html',
})
export class PostalAddressFormComponent {
  @Input()
  rendering?: InnogyComponentRendering & { fields: PostalAddressFormRendering };
  @Input()
  formState!: FormGroupState<PostalAddressFormValues | FormAddress>;
  @Input()
  content!: PostalAddressFormContent;
  @Input()
  floatingLabels = false;
  @Input()
  trackStep!: TrackToolStep;

  public readonly _uppercaseConverter = uppercaseConverter;

  get automationId(): string {
    return this.formState.id;
  }
}
