import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@core/jss-models';
import { ColumnContext } from '@legacy/common-ui-shared-interfaces';

import type { ComposableCardConfig } from '../composables/card/card.model';
import { getComposableCardConfigFromRendering } from '../composables/card/card.model';
import type { ListConfig } from './list.model';
import { getListConfigFromRendering } from './list.model';

@Component({
  selector: 'wl-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.scss'],
})
export class ListContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  listConfig?: ListConfig;
  cardConfig?: ComposableCardConfig;

  ngOnInit() {
    if (this.rendering) {
      this.listConfig = getListConfigFromRendering(this.rendering);
      this.cardConfig = getComposableCardConfigFromRendering(this.rendering);
    }
  }
}
