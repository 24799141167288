@if (showStep) {
  <wl-partial-card
    class="mb-2 progressive-form-card"
    [divider]="isActive || (isValid && isSubmitted) || isReadonly"
    [class.valid-and-submitted]="(isValid && isSubmitted) || isReadonly"
    [class.untouched]="isFutureStep || isSkipped"
    [wlAutomationId]="formStepId"
    [id]="formStepId"
  >
    <ng-container slot="header">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="h4 my-0 d-flex align-items-center" wlCSCapture>
            @if ((isValid && isSubmitted) || isReadonly) {
              <fa-icon class="me-1" icon="circle-check"></fa-icon>
            }
            {{ title }} {{ progression }}
          </h3>
        </div>
        <div>
          @if (canBeEdited && !isReadonly) {
            <button
              class="ms-2 btn btn-outline-secondary btn-sm"
              [wlAutomationId]="changeButtonAutomationId"
              (click)="setEditingFormStep.emit()"
              wlCSCapture
            >
              <span class="d-none d-sm-block">{{ changeButtonText }}</span>
              <span class="d-block d-sm-none">
                <fa-icon icon="pen"></fa-icon>
              </span>
            </button>
          }
        </div>
      </div>
      @if (isActive) {
        <p class="my-0" wlCSCapture>{{ subtitle }}</p>
      }
    </ng-container>
    <ng-container slot="content">
      <ng-content></ng-content>
    </ng-container>
  </wl-partial-card>
}
