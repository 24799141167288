@if (!floatingIncentive) {
  <ng-container *ngTemplateOutlet="labels"></ng-container>
}
<div class="d-flex flex-row">
  <div class="d-flex flex-column flex-grow-1">
    @if (floatingIncentive) {
      <ng-container *ngTemplateOutlet="floatingLabels"></ng-container>
    }
    <span class="offer-card-header__title mb-0" wlCSCapture>{{
      duration
    }}</span>
    <span class="secondary-text" wlCSCapture>{{ productTitle }}</span>
  </div>
  @if (showPriceTag) {
    <div class="d-flex flex-column flex-shrink-0 ms-1">
      @if (price) {
        <span class="offer-card-header__budgetbillamount mb-0" wlCSCapture>{{
          price | roundedCurrency
        }}</span>
      }
      <span class="small-text text-end" wlCSCapture>{{
        priceDurationLabel
      }}</span>
    </div>
  }
</div>

<ng-template #labels>
  <!-- optional spacer to make sure items on full card view can be aligned horizontally -->
  @if (addLabelSpacer && !highlight && !incentive) {
    <ng-container *ngTemplateOutlet="spacer"></ng-container>
  }
  <div class="d-flex">
    @if (!!highlight) {
      <wl-commodity-offer-label
        [style]="'highlight'"
        [label]="highlight"
      ></wl-commodity-offer-label>
    }
    @if (incentive) {
      <wl-commodity-offer-label
        [style]="'incentive'"
        [label]="incentive"
        [class.ms-1]="!!highlight"
      ></wl-commodity-offer-label>
    }
  </div>
</ng-template>

<ng-template #floatingLabels>
  <!-- optional spacer to make sure items on full card view can be aligned horizontally -->
  @if (addLabelSpacer && !highlight) {
    <ng-container *ngTemplateOutlet="spacer"></ng-container>
  }
  @if (!!highlight) {
    <wl-commodity-offer-label
      [style]="'text'"
      [label]="highlight"
    ></wl-commodity-offer-label>
  }
  @if (incentive) {
    <div class="offer-card-header__incentive--floating">
      <wl-commodity-offer-label
        [style]="'incentive'"
        [label]="incentive"
      ></wl-commodity-offer-label>
    </div>
  }
</ng-template>

<ng-template #spacer>
  <div
    [ngStyle]="{
      visibility: 'visible',
      height: '21px',
    }"
  ></div>
</ng-template>
