<table class="table table-borderless mb-0" wlAutomationId="costs.electricity">
  <thead>
    <tr>
      <th id="energy-title" colspan="2" scope="col">
        @if (energyTitle) {
          <span class="energy-title" *scText="energyTitle" wlCSCapture></span>
        }
      </th>
    </tr>
  </thead>
  <tbody>
    @if (deliveryTitle?.value) {
      <tr>
        <th id="delivery-title" headers="energy-title" colspan="2" scope="col">
          <span class="h5" *scText="deliveryTitle" wlCSCapture></span>
        </th>
      </tr>
    }
    @if (isDynamicProduct) {
      <tr>
        <td colspan="2">
          <span class="h6" *scText="expectedPriceTitle" wlCSCapture></span>
        </td>
      </tr>
    }
    @for (tariffPeriod of tariffPeriods; track tariffPeriod; let i = $index) {
      <tr>
        <th [id]="'tariff-' + i" scope="row">
          @if (unit) {
            <em wlCSCapture>{{ 'per' | translate }} {{ unit | translate }}</em>
          }
          <br />
          <em
            [innerHTML]="tariffPeriod.description | safeHtml"
            wlCSCapture
          ></em>
        </th>
        <td
          [headers]="'delivery-title tariff-' + i"
          class="text-end"
          wlCSCapture
        >
          {{ tariffPeriod?.summerPrice | currency: 'EUR' : 'symbol' : '1.5' }}
        </td>
      </tr>
    }
    <tr wlAutomationId="costs.electricityDelivery">
      <th id="fixed-delivery" scope="row" class="fst-italic">
        @if (fixedDeliveryCostsLabel) {
          <span *scText="fixedDeliveryCostsLabel" wlCSCapture></span>
        }
      </th>
      <td class="text-end" headers="delivery-title fixed-delivery" wlCSCapture>
        {{ fixedDeliveryCosts }}
      </td>
    </tr>
    @if (showRedeliveryCosts) {
      <tr aria-hidden="true">
        <td colspan="2">
          <hr class="my-1" />
        </td>
      </tr>
      <tr>
        <th id="redelivery-title" headers="energy" colspan="2" scope="col">
          @if (redeliveryTitle) {
            <span class="h5" *scText="redeliveryTitle" wlCSCapture></span>
          }
        </th>
      </tr>
      <tr>
        <td colspan="2">
          {{ fixedRedeliveryCostsScale }}
        </td>
      </tr>
      @if (fixedRedeliveryCostsLabel) {
        <tr wlAutomationId="costs.electricityRedelivery">
          <th
            id="fixed-redelivery"
            headers="redelivery-title"
            scope="row"
            class="fst-italic"
          >
            <ng-container>
              <span *scText="fixedRedeliveryCostsLabel" wlCSCapture></span>
            </ng-container>
          </th>
          <td
            class="text-end"
            headers="redelivery-title fixed-redelivery"
            wlCSCapture
          >
            {{ fixedRedeliveryCosts }}
          </td>
        </tr>
      }
      <tr>
        <td colspan="2">
          <span
            *wlGenericRichText="redeliveryDescription"
            class="font-size-sm microcopy fst-italic"
          ></span>
        </td>
      </tr>
    }
  </tbody>
</table>
