import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type {
  ContentContext,
  ContentPattern,
  ContentTheme,
} from '@legacy/common-ui-partials';
import type { InnogyComponentRendering } from '@core/jss-models';
import {
  getDroplinkValue,
  getFieldValue,
  getLinkFieldValue,
} from '@core/jss-utils';
import type {
  LinkField,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export type BasicContentVariantInternal = 'ngContentBetween';
export type BasicContentVariant =
  | 'minimal'
  | 'placeholder'
  | 'vertical-icon'
  | 'vertical-image';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type ItemAlignment =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'left'
  | 'between'
  | 'right';

export type ItemSize = 3 | 4 | 6 | 8 | 9;
export type HeaderSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | '';

export interface BasicContentSettings {
  variant?: BasicContentVariant;
  itemAlignment?: ItemAlignment;
  itemSize?: ItemSize;
  theme?: ContentTheme;
  pattern?: ContentPattern;
  context?: ContentContext;
  alignButtonsVertically?: boolean;
  borderStyle?: string;
  backgroundColor?: string;
  stretchVertical?: boolean;
  icon?: BasicContentIcon;
}

export const getBasicContentSettingsFromRendering = (
  rendering: InnogyComponentRendering
): BasicContentSettings => ({
  variant: getDroplinkValue(rendering.fields, 'Variant')?.split(
    '|'
  )[0] as BasicContentVariant,
  itemAlignment: getDroplinkValue(rendering.fields, 'Variant')?.split(
    '|'
  )[1] as ItemAlignment,
  itemSize: Number(
    getDroplinkValue(rendering.fields, 'Variant')?.split('|')[2] ?? 6
  ) as ItemSize,
  // Not yet used for Essent.
  theme:
    (getDroplinkValue(rendering.fields, 'Theme') as ContentTheme) ?? 'default',
  // Not yet used for Essent.
  pattern: getDroplinkValue(rendering.fields, 'Pattern') as ContentPattern,
  context:
    (getDroplinkValue(rendering.fields, 'Context') as ContentContext) ??
    'default',
  // deprecated for ED.
  borderStyle: getDroplinkValue(rendering.fields, 'BorderStyle') ?? '',
  // deprecated for ED in favor of 'theme'.
  backgroundColor: getDroplinkValue(rendering.fields, 'BackgroundColor') ?? '',
  stretchVertical: getFieldValue(rendering, 'StretchVertical'),
  icon: {
    name: getDroplinkValue(rendering.fields, 'Icon') as IconProp,
    color: getDroplinkValue(rendering.fields, 'IconColor') ?? '',
    placement:
      getDroplinkValue(rendering.fields, 'HeadingIconPlacement') ?? 'left',
  },
});

export interface BasicContentHeading {
  tag?: HeadingTag;
  size?: HeaderSize;
  text?: TextField;
  style?: string;
}

export interface BasicContentIcon {
  name?: IconProp;
  color?: string;
  placement?: string;
}

interface BasicContentButton {
  link?: LinkField;
  style?: string;
}
export interface BasicContentValues {
  centerText?: boolean;
  heading?: BasicContentHeading;
  subheading?: {
    text?: TextField;
    style?: string;
  };
  text?: {
    text?: RichTextField;
    style?: string;
  };
  buttons?: {
    alignVertically?: boolean;
    primary?: BasicContentButton;
    secondary?: BasicContentButton;
  };
  microcopy?: {
    text?: TextField;
    style?: string;
  };
}

export const getBasicContentValuesFromRendering = (
  rendering: InnogyComponentRendering
): BasicContentValues => ({
  centerText: getFieldValue(rendering, 'CenterText', false),
  heading: {
    tag:
      (getDroplinkValue(rendering.fields, 'HeadingTag') as HeadingTag) ?? 'h2',
    size: getDroplinkValue(rendering.fields, 'HeadingSize') as HeadingTag,
    text: rendering.fields?.['HeadingText'] as TextField,
    style: getDroplinkValue(rendering.fields, 'HeadingStyle') ?? '',
  },
  subheading: {
    text: rendering.fields?.['SubheadingText'] as TextField,
    style: getDroplinkValue(rendering.fields, 'SubheadingStyle') ?? '',
  },
  text: {
    text: rendering.fields?.['Text'] as RichTextField,
    style: getDroplinkValue(rendering.fields, 'TextStyle') ?? '',
  },
  buttons: {
    alignVertically: getFieldValue(rendering, 'AlignButtonsVertically', false),
    primary: {
      link: getLinkFieldValue(rendering, 'PrimaryButtonLink'),
      style: getDroplinkValue(rendering.fields, 'PrimaryButtonStyle') ?? '',
    },
    secondary: {
      link: getLinkFieldValue(rendering, 'SecondaryButtonLink'),
      style: getDroplinkValue(rendering.fields, 'SecondaryButtonStyle') ?? '',
    },
  },
  microcopy: {
    text: rendering.fields?.['MicrocopyText'] as TextField,
    style: getDroplinkValue(rendering.fields, 'MicrocopyStyle') ?? '',
  },
});
