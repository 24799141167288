import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import type { State as BaseState } from '@innogy/utils-state';
import { InjectionToken } from '@angular/core';

import { accountReducer } from './account.reducer';
import type { State as StateFromAccount } from './account.reducer';

export interface AccountState {
  readonly account: StateFromAccount;
}

export interface State extends BaseState {
  readonly account: AccountState;
}

export const accountReducers: ActionReducerMap<AccountState, Action> = {
  account: accountReducer,
};
export const ACCOUNT_FEATURE_REDUCERS = new InjectionToken<
  typeof accountReducers
>('Account Reducers');
export const accountSelectorKey = 'account';
export const getAccountState =
  createFeatureSelector<AccountState>(accountSelectorKey);
