import { Injectable } from '@angular/core';
import type { BaseActionType } from '@essent/common';
import { Status } from '@essent/common';
import {
  getInstallationAddresses as getInstallationAddressesAction,
  getInstallationAddressesError,
  getInstallationAddressesSuccess,
} from '@essent/installation-address';
import type { PutAdditionalInformationPayload } from '@essent/new-customer';
import {
  AdditionalInformationType,
  putAdditionalInformation,
} from '@essent/new-customer';
import { closeResidentialModal } from '@innogy/common-ui/modals';
import {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import {
  ClearAsyncErrorAction,
  SetAsyncErrorAction,
  SetValueAction,
  StartAsyncValidationAction,
  UnfocusAction,
} from 'ngrx-forms';
import { filter, mergeMap } from 'rxjs/operators';

import { getResidentialInformationState } from '../../bac/15.additional-information';
import { setResidentialAction } from '../../bac/15.additional-information/additional-information.actions';
import { getFlowId, noFlowIdErrorAction } from '../../bac/flow-id';
import { setPropositionOfferStatusAction } from '../../bac/offers';
import { openModalFromFunnelSettingsAction } from '../../funnel';
import { ORDER_REFRESH_OFFER_ACTION_ID } from '../order.actions';
import {
  ADDRESS_VERIFICATION_ERROR_ID,
  CORRESPONDENCE_ADDRESS_ACTION_ID,
  houseNumberCorrespondenceFormControlId,
  postalCodeCorrespondenceFormControlId,
  residenceOrWorkplaceFormControlId,
} from './order-address.reducer';
import { getOrderAddressFormState } from './order-address.selector';

@Injectable()
export class OrderAddressEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  private readonly formValues$ = this.store$.pipe(
    select(getOrderAddressFormState)
  );
  private readonly flowId$ = this.store$.pipe(select(getFlowId));
  private readonly prospectIsResidential$ = this.store$.pipe(
    select(getResidentialInformationState)
  );

  /**
   * Fetch correspondence installation address when address fields are valid en lost focus
   */
  public readonly fetchCorrespondenceInstallationAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfocusAction>(UnfocusAction.TYPE),
      filter(
        (action) =>
          action.controlId === postalCodeCorrespondenceFormControlId ||
          action.controlId === houseNumberCorrespondenceFormControlId
      ),
      concatLatestFrom(() => [this.formValues$]),
      // eslint-disable-next-line complexity
      mergeMap(([_, state]) => {
        const isPostalValid =
          state.controls.postalCodeCorrespondence &&
          !state.controls.postalCodeCorrespondence.errors.isPostalCode &&
          state.value.postalCodeCorrespondence;
        const isHouseNumberValid =
          state.controls.houseNumberCorrespondence &&
          !state.controls.houseNumberCorrespondence.errors.requiredNumber &&
          state.value.houseNumberCorrespondence;
        if (isPostalValid && isHouseNumberValid) {
          return [
            getInstallationAddressesAction({
              actionId: CORRESPONDENCE_ADDRESS_ACTION_ID,
              payload: {
                postcode: state.value.postalCodeCorrespondence ?? '',
                houseNumber:
                  state.value.houseNumberCorrespondence?.toString() ?? '',
              },
            }),
            // @TODO: move these actions to reducer,
            // there's an updateFn for them in NGRX-forms.
            new StartAsyncValidationAction(
              state.controls.postalCodeCorrespondence?.id ?? '',
              ADDRESS_VERIFICATION_ERROR_ID
            ),
            new StartAsyncValidationAction(
              state.controls.houseNumberCorrespondence?.id ?? '',
              ADDRESS_VERIFICATION_ERROR_ID
            ),
          ];
        }
        return [];
      })
    )
  );

  // @TODO: move to reducer, we don't need an effect for this.
  // there is an updateFn to handle this in ngrx-forms
  public readonly verifyCorrespondenceAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallationAddressesSuccess),
      filter((action) => action.actionId === CORRESPONDENCE_ADDRESS_ACTION_ID),
      concatLatestFrom(() => [this.formValues$]),
      mergeMap(([_, state]) => {
        return [
          new ClearAsyncErrorAction(
            state.controls.postalCodeCorrespondence?.id ?? '',
            ADDRESS_VERIFICATION_ERROR_ID
          ),
          new ClearAsyncErrorAction(
            state.controls.houseNumberCorrespondence?.id ?? '',
            ADDRESS_VERIFICATION_ERROR_ID
          ),
        ];
      })
    )
  );

  // @TODO: move to reducer, we don't need an effect for this.
  // there is an updateFn to handle this in ngrx-forms
  public readonly invalidateCorrespondenceAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallationAddressesError),
      filter((action) => action.actionId === CORRESPONDENCE_ADDRESS_ACTION_ID),
      concatLatestFrom(() => [this.formValues$]),
      mergeMap(([_, state]) => {
        return [
          new SetAsyncErrorAction(
            state.controls.postalCodeCorrespondence?.id ?? '',
            ADDRESS_VERIFICATION_ERROR_ID,
            false
          ),
          new SetAsyncErrorAction(
            state.controls.houseNumberCorrespondence?.id ?? '',
            ADDRESS_VERIFICATION_ERROR_ID,
            false
          ),
        ];
      })
    )
  );

  /**
   * Displays a modal with a confirmative question when the user chooses a non-residential function
   */
  public readonly showResidenceModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<boolean>>(SetValueAction.TYPE),
      filter(
        (action) =>
          action.controlId === residenceOrWorkplaceFormControlId &&
          action.value === false
      ),
      mergeMap(() => {
        return [
          openModalFromFunnelSettingsAction({
            source: GenericModalSources.RESIDENTIAL,
            additionalPayload: {
              residenceOrWorkplaceFormControlId,
            },
          }),
        ];
      })
    )
  );

  /**
   * Determines whether or not to fetch a new offer when the user chooses a residential function
   */
  public readonly switchResidentialViaAffirmingButton$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<boolean>>(SetValueAction.TYPE),
      // Continue when clicking on the 'yes' button in the residential radio.
      filter(
        (action) =>
          action.controlId === residenceOrWorkplaceFormControlId && action.value
      ),
      concatLatestFrom(() => [this.prospectIsResidential$, this.flowId$]),
      // Continue when the clicked value is not equal to the value in our state.
      filter(
        ([action, prospectIsResidential]) =>
          action.value !== prospectIsResidential
      ),
      mergeMap(([action, prospectIsResidential, flowId]) => {
        if (!flowId) {
          return [noFlowIdErrorAction()];
        }

        const actions: any[] = [];
        const shouldRefreshOffer = prospectIsResidential !== null;
        const putAdditionalPayloadInformationPayload: BaseActionType<PutAdditionalInformationPayload> =
          {
            payload: {
              metaData: {
                flowId,
              },
              payload: [
                {
                  name: AdditionalInformationType.RESIDENTIAL,
                  value: action.value,
                },
              ],
            },
          };

        if (shouldRefreshOffer) {
          putAdditionalPayloadInformationPayload.actionId =
            ORDER_REFRESH_OFFER_ACTION_ID;
          actions.push(
            setPropositionOfferStatusAction({ status: Status.PENDING })
          );
        }

        actions.push(
          setResidentialAction({ value: action.value }),
          putAdditionalInformation(putAdditionalPayloadInformationPayload)
        );

        return actions;
      })
    )
  );

  public readonly switchResidentialViaModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<any>(closeResidentialModal),
      concatLatestFrom(() => [
        this.flowId$,
        this.formValues$,
        this.prospectIsResidential$,
      ]),
      /**
       * SECONDARY represents a user choosing 'my house actually DOES have a residential function.
       * Continue when that choice, represented by true/false, is not equal to the value in our state.
       */
      filter(
        ([action, , , prospectIsResidential]) =>
          action.result !== GenericModalResults.CLOSE &&
          (action.result === GenericModalResults.SECONDARY) !==
            prospectIsResidential
      ),
      mergeMap(([action, flowId, , prospectIsResidential]) => {
        if (!flowId) {
          return [noFlowIdErrorAction()];
        }

        const isResidential = action.result === GenericModalResults.SECONDARY;

        const actions: any[] = [];
        const putAdditionalPayloadInformationPayload: BaseActionType<PutAdditionalInformationPayload> =
          {
            payload: {
              metaData: {
                flowId,
              },
              payload: [
                {
                  name: AdditionalInformationType.RESIDENTIAL,
                  value: isResidential,
                },
              ],
            },
          };

        const shouldRefreshOffer = () => {
          // If there is no known value and we clicked SECONDARY, don't refresh.
          if (
            prospectIsResidential === null &&
            action.result === GenericModalResults.SECONDARY
          ) {
            return false;
          }
          // Else, refresh when that choice, represented by true/false, is not equal to the value in our state.
          const resultIsResidential =
            action.result === GenericModalResults.SECONDARY;
          return resultIsResidential !== prospectIsResidential;
        };

        if (shouldRefreshOffer()) {
          putAdditionalPayloadInformationPayload.actionId =
            ORDER_REFRESH_OFFER_ACTION_ID;
          actions.push(
            setPropositionOfferStatusAction({ status: Status.PENDING })
          );
        }

        actions.push(
          setResidentialAction({ value: isResidential }),
          putAdditionalInformation(putAdditionalPayloadInformationPayload)
        );

        return actions;
      })
    )
  );
}
/* eslint-disable max-lines */
