import type { PostHeatpumpV1Data } from '@integration/api-client/feh';

import type { MultipleAnswerQuestion } from './multiple-answer-question.interface';
import type { SingleAnswerQuestion } from './single-answer-question.interface';

// Need to define some repeating consts to keep SonarQube happy
const singleAnswerQuestion = 'single-answer-question';
const weetIkNiet = 'Weet ik niet';
const orderPage = 'orderPage';
const nextQuestion = 'next-question';
const infoModal = 'info-modal';
const link = 'link';

type BuildingType =
  PostHeatpumpV1Data['requestBody']['metadata']['building']['buildingType'];
type HeatDistributionType =
  PostHeatpumpV1Data['requestBody']['metadata']['building']['heatDistributionSystem'];
type ConstructionPeriodType =
  PostHeatpumpV1Data['requestBody']['metadata']['building']['constructionPeriod'];

/*
  This structure is used by the reducer and effects to navigate from question to question.
  Each answer has a 'target' that either refers to another question or a completely different webpage.
  The target mapping is implemented in the questionnaire effects.
 */
export const QuestionnaireStructure: {
  [K in keyof PostHeatpumpV1Data['requestBody']['metadata']['building']]:
    | SingleAnswerQuestion
    | MultipleAnswerQuestion;
} = {
  isHomeOwner: {
    type: singleAnswerQuestion,
    apiKey: 'isHomeOwner',
    question: 'Ben je eigenaar van de woning?',
    trackingStepName: '- eigenaar woning',
    options: [
      {
        label: 'Ja, ik ben eigenaar',
        target: {
          type: nextQuestion,
          questionName: 'buildingType',
        },
        apiValue: true,
      },
      {
        label:
          'Nee, ik huur de woning maar ik heb wel toestemming van de verhuurder',
        target: {
          type: nextQuestion,
          questionName: 'buildingType',
        },
        apiValue: false,
      },
      {
        label:
          'Nee, ik huur de woning en ik heb geen toestemming van de verhuurder',
        target: {
          type: link,
          funnelKey: 'permission',
        },
        apiValue: null,
      },
    ],
    percentage: 10,
  },
  buildingType: {
    type: singleAnswerQuestion,
    apiKey: 'buildingType',
    question: 'Wat voor type woning heb je?',
    trackingStepName: '- type woning',
    options: [
      {
        label: 'Appartement',
        target: {
          type: nextQuestion,
          questionName: 'hasMechanicalVentilation',
        },
        apiValue: 'Appartement' as BuildingType,
      },
      {
        label: 'Tussenwoning',
        target: {
          type: nextQuestion,
          questionName: 'hasMechanicalVentilation',
        },
        apiValue: 'Tussenwoning' as BuildingType,
      },
      {
        label: 'Hoekwoning',
        target: {
          type: nextQuestion,
          questionName: 'constructionPeriod',
        },
        apiValue: 'Hoekwoning',
      },
      {
        label: 'Twee-onder-één-kap woning',
        target: {
          type: nextQuestion,
          questionName: 'constructionPeriod',
        },
        apiValue: 'Twee-onder-een-kap woning' as BuildingType,
      },
      {
        label: 'Vrijstaande woning',
        target: {
          type: nextQuestion,
          questionName: 'constructionPeriod',
        },
        apiValue: 'Vrijstaande woning' as BuildingType,
      },
    ],
    percentage: 30,
  },
  hasMechanicalVentilation: {
    type: singleAnswerQuestion,
    apiKey: 'hasMechanicalVentilation',
    question: 'Heeft je woning mechanische ventilatie?',
    trackingStepName: '- mechanische ventilatie',
    options: [
      {
        label: 'Ja, mijn woning heeft mechanische ventilatie',
        target: {
          type: nextQuestion,
          questionName: 'constructionPeriod',
        },
        apiValue: true,
      },
      {
        label: 'Nee, mijn woning heeft geen mechanische ventilatie',
        target: {
          type: link,
          funnelKey: 'mechanicalVentilation',
        },
        apiValue: false,
      },
    ],
    percentage: 40,
    info: {
      label: 'Uitleg over mechanische ventilatie',
      target: {
        type: infoModal,
        funnelKey: 'infoMechanicalVentilation',
      },
    },
  },
  constructionPeriod: {
    type: singleAnswerQuestion,
    apiKey: 'constructionPeriod',
    question: 'Wat is het bouwjaar van je woning?',
    trackingStepName: '- bouwjaar woning',
    options: [
      {
        label: 'Tot 1930',
        target: {
          type: nextQuestion,
          questionName: 'installedInsulation',
        },
        apiValue: 'Pre-1930' as ConstructionPeriodType,
      },
      {
        label: '1931 - 1994',
        target: {
          type: nextQuestion,
          questionName: 'installedInsulation',
        },
        apiValue: '1931-1994' as ConstructionPeriodType,
      },
      {
        label: 'Vanaf 1995',
        target: {
          type: nextQuestion,
          questionName: 'hasSolarPanels',
        },
        apiValue: 'Post-1995' as ConstructionPeriodType,
      },
    ],
    percentage: 50,
  },
  installedInsulation: {
    type: 'multiple-answer-question',
    apiKey: 'installedInsulation',
    question: 'Over welke isolatiemaatregelen beschikt je woning?',
    subtitle: 'Meerdere opties zijn hier mogelijk',
    trackingStepName: '- isolatiemaatregelen',
    options: [
      {
        label: 'Spouwmuurisolatie',
        value: 1,
        apiValue: 'Spouwmuurisolatie',
      },
      {
        label: 'Vloerisolatie',
        value: 1,
        apiValue: 'Vloerisolatie',
      },
      {
        label: 'Dakisolatie',
        value: 1,
        apiValue: 'Dakisolatie',
      },
      {
        label: 'Dubbel/HR++ glas',
        value: 0,
        apiValue: 'Dubbel/HR++ glas',
      },
      {
        label: weetIkNiet,
        value: 2,
        apiValue: null,
      },
    ],
    valueNeeded: 2,
    goodAnswer: {
      target: {
        type: nextQuestion,
        questionName: 'hasSolarPanels',
      },
    },
    badAnswer: {
      target: {
        type: link,
        funnelKey: 'insulation',
      },
    },
    percentage: 55,
  },
  hasSolarPanels: {
    type: singleAnswerQuestion,
    apiKey: 'hasSolarPanels',
    question: 'Heb je zonnepanelen?',
    trackingStepName: '- zonnepanelen',
    options: [
      {
        label: 'Ja, ik heb zonnepanelen',
        target: {
          type: nextQuestion,
          questionName: 'boilerOwnership',
        },
        apiValue: true,
      },
      {
        label: 'Nee, ik heb geen zonnepanelen',
        target: {
          type: nextQuestion,
          questionName: 'boilerOwnership',
        },
        apiValue: false,
      },
    ],
    percentage: 60,
  },
  boilerOwnership: {
    type: singleAnswerQuestion,
    apiKey: 'boilerOwnership',
    question: 'Heb je een cv-ketel?',
    subtitle:
      'We leggen je graag uit waarom het van belang is dat je een cv-ketel hebt en wie onze service partners zijn.',
    trackingStepName: '- eigenaar cv-ketel',
    info: {
      label: 'Uitleg over cv-ketels en servicepartners',
      target: {
        type: infoModal,
        funnelKey: 'infoBoiler',
      },
    },
    options: [
      {
        label: 'Ja, ik heb een gekochte cv-ketel',
        target: {
          type: nextQuestion,
          questionName: 'boilerAgeLessThan12Y',
        },
        apiValue: true,
      },
      {
        label: 'Ja, ik huur een cv-ketel van één van de servicepartners',
        target: {
          type: nextQuestion,
          questionName: 'boilerAgeLessThan12Y',
        },
        apiValue: false,
      },
      {
        label: 'Ja, ik huur een cv-ketel van iemand anders',
        target: {
          type: link,
          funnelKey: 'boiler',
        },
        apiValue: null,
      },
      {
        label: 'Nee, ik heb geen cv-ketel',
        target: {
          type: link,
          funnelKey: 'boiler',
        },
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: {
          type: nextQuestion,
          questionName: 'boilerAgeLessThan12Y',
        },
        apiValue: 'Onbekend',
      },
    ],
    percentage: 70,
  },
  boilerAgeLessThan12Y: {
    type: singleAnswerQuestion,
    apiKey: 'boilerAgeLessThan12Y',
    question: 'Wat is de leeftijd van je cv-ketel?',
    trackingStepName: '- leeftijd cv-ketel',
    options: [
      {
        label: 'Jonger dan 12 jaar',
        target: {
          type: nextQuestion,
          questionName: 'heatDistributionSystem',
        },
        apiValue: true,
      },
      {
        label: 'Ouder dan 12 jaar',
        target: {
          type: nextQuestion,
          questionName: 'heatDistributionSystem',
        },
        apiValue: false,
      },
      {
        label: weetIkNiet,
        target: {
          type: nextQuestion,
          questionName: 'heatDistributionSystem',
        },
        apiValue: null,
      },
    ],
    percentage: 80,
  },
  heatDistributionSystem: {
    type: singleAnswerQuestion,
    apiKey: 'heatDistributionSystem',
    question: 'Hoe wordt je woning verwarmd?',
    subtitle:
      'Je woning kan op verschillende manieren verwarmd worden. We leggen het graag uit.',
    trackingStepName: '- verwarming',
    info: {
      label: 'Uitleg over verwarming',
      target: {
        type: infoModal,
        funnelKey: 'infoHeating',
      },
    },
    options: [
      {
        label: 'Vloer- of lage temperatuur verwarming',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Lage temperatuur verwarming' as HeatDistributionType,
      },
      {
        label: 'Radiatoren en vloerverwarming',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue:
          'Radiatoren en lage temperatuur verwarming' as HeatDistributionType,
      },
      {
        label: 'Radiatoren',
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Radiator',
      },
      {
        label: 'Luchtverwarming',
        target: {
          type: link,
          funnelKey: 'heating',
        },
        apiValue: null,
      },
      {
        label: 'Convectoren',
        target: {
          type: link,
          funnelKey: 'heating',
        },
        apiValue: null,
      },
      {
        label: weetIkNiet,
        target: {
          type: link,
          funnelKey: orderPage,
        },
        apiValue: 'Onbekend' as HeatDistributionType,
      },
    ],
    percentage: 90,
  },
};
