import { createNamedApi } from '@essent/common';

import { PostCreateLeadBoilerService } from './post-create-lead-boiler.service';

export const postCreateLeadBoilerApi = createNamedApi(
  '[PostLeadsBoiler] Post Create Lead Boiler',
  PostCreateLeadBoilerService
);

export const {
  postCreateLeadBoiler,
  postCreateLeadBoilerActions,
  postCreateLeadBoilerClear,
  postCreateLeadBoilerError,
  postCreateLeadBoilerSuccess,
} = postCreateLeadBoilerApi;
