@if (control && control.enabled) {
  <div class="form-section">
    <rfa-label [control]="control"></rfa-label>
    @if (newDesign) {
      <rfa-errors [control]="control" [newDesign]="true"></rfa-errors>
    }
    <div class="position-relative">
      <input
        type="number"
        class="form-control"
        [id]="control.id"
        [name]="autocompleteId"
        [formControl]="control"
        [placeholder]="placeholder"
        [autocomplete]="autocompleteId"
        [wlTrackFieldfocus]="focusChangeTrackConfig()"
      />
      @if (control.options.tooltip) {
        <div class="tooltip-container">
          <wl-tooltip
            [tooltip]="{
              description: control.options.tooltip,
            }"
          ></wl-tooltip>
        </div>
      }
    </div>
    @if (!newDesign) {
      <rfa-errors [control]="control"></rfa-errors>
    }
  </div>
}
