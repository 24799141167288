import { Subject } from 'rxjs';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { Component, inject, type OnDestroy, type OnInit } from '@angular/core';
import { SearchService } from '@innogy/search-services';
import { AutomationIdDirective } from '@innogy/utils-automation-id';

import { SearchSuggestionComponent } from '../search-suggestion/search-suggestion.component';

@Component({
  selector: 'wl-search-most-searched',
  standalone: true,
  imports: [SearchSuggestionComponent, AutomationIdDirective],
  templateUrl: './search-most-searched.component.html',
  styleUrls: [
    './search-most-searched.component.ed.scss',
    './search-most-searched.component.essent.scss',
  ],
})
export class SearchMostSearchedComponent implements OnInit, OnDestroy {
  queries = ['Stroom en gascontract afsluiten', 'Zonnepanelen', 'Warmtepomp'];

  readonly #fetchQueriesFromAlgolia =
    inject(ENVIRONMENT_CONFIG).search.mostSearchedUseService;
  readonly #searchService = inject(SearchService);
  readonly onDestroy$ = new Subject();

  async ngOnInit() {
    if (this.#fetchQueriesFromAlgolia) {
      this.queries = await this.#getSuggestions();
    }
  }

  async #getSuggestions() {
    const { hits } = await this.#searchService.getMostCommonSuggestions();
    const result: string[] = [];

    hits.forEach((hit) => {
      if (hit.query) {
        result.push(hit.query);
      }
    });

    return result;
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
