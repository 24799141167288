<div
  class="card availability"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
>
  <div class="card-body d-flex">
    @if (icon) {
      <fa-icon
        class="px-2"
        [ngClass]="{
          availability__open: isOpen,
          availability__closed: !isOpen,
        }"
        [icon]="icon | scIcon"
      ></fa-icon>
    }

    <div>
      @if (isOpen) {
        <div>
          <div
            class="availability__open"
            *scText="rendering?.fields?.OpenText"
          ></div>
        </div>
      }
      @if (!isOpen) {
        <div>
          <div
            class="availability__closed"
            *scText="rendering?.fields?.ClosedText"
          ></div>
        </div>
      }
      @if (additionalText) {
        <div class="availability__additional">
          {{ additionalText }}
        </div>
      }
    </div>
  </div>
</div>
