/* eslint-disable @typescript-eslint/member-ordering */
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import type { LinkFieldValue } from '@core/jss-models';
import { JssStateMetaTitleUpdated } from '@core/jss-routing';
import { getSettingsValue } from '@core/settings';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wl-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: [],
})
export class ServerErrorComponent implements OnInit, OnDestroy {
  private readonly store$ = inject(Store);

  private readonly errorCodeServerErrorSelector = () =>
    getSettingsValue<LinkFieldValue>('errorCodes', '500');
  private readonly errorCodeServerError$ = this.store$.pipe(
    select(this.errorCodeServerErrorSelector())
  );
  private readonly onDestroy$ = new Subject();

  ngOnInit() {
    this.errorCodeServerError$
      .pipe(
        filter((val) => val !== null),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (errorName) =>
          errorName &&
          this.store$.dispatch(
            new JssStateMetaTitleUpdated(errorName.toString())
          )
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
