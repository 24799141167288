<div
  ngbAccordion
  [destroyOnHide]="false"
  wlTrackAccordion
  [trackAccordionInfo]="{
    subject: offer?.productTitle ?? '',
    name: offer?.productTitle ?? '',
  }"
>
  <div ngbAccordionItem #item="ngbAccordionItem" [collapsed]="!opened">
    <div ngbAccordionHeader>
      @if (!showLoadingState) {
        <button class="accordion-button px-2 d-flex" ngbAccordionButton>
          <div class="flex-grow-1 me-1">
            <ng-container
              [ngTemplateOutlet]="accordionHeader"
              [ngTemplateOutletContext]="{
                collapsed: item.collapsed,
              }"
            ></ng-container>
          </div>
        </button>
      }
      @if (showLoadingState) {
        <button class="accordion-button accordion-button--no-icon px-2 py-2">
          <wl-svg-skeleton-loader [mask]="maskHeader"></wl-svg-skeleton-loader>
        </button>
      }
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @if (!showLoadingState) {
            <ng-container [ngTemplateOutlet]="accordionBody"></ng-container>
          }
          @if (showLoadingState) {
            <wl-svg-skeleton-loader [mask]="maskBody"></wl-svg-skeleton-loader>
          }
        </ng-template>
      </div>
    </div>
  </div>
</div>
