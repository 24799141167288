import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { OrderState } from '@innogy/become-a-customer/state';
import {
  addOrderRenderingAction,
  becomeACustomerOrderSelectorKey,
  getCorrespondenceInstallationAddressHouseNumberExtensionAsDropdownItems,
  getFunnelSettings,
  getOrderAddressProgressiveFormState,
  getOrderConfirmationProgressiveFormState,
  getOrderPaymentProgressiveFormState,
  getOrderPersonalProgressiveFormState,
  getPropositionOffer,
  getSelectedCorrespondenceInstallationAddressState,
  getSupplyAddressVM,
  openModalFromFunnelSettingsAction,
  showAomModalAction,
} from '@innogy/become-a-customer/state';
import type { RadioItem } from '@innogy/common-ui/forms';
import {
  SIMPLE_PROGRESSIVE_FORMS_CONFIG,
  SimpleProgressiveFormsConfigService,
} from '@innogy/common-ui/progressive-forms';
import { GenericModalSources } from '@legacy/common-ui-shared-interfaces';
import type { InnogyComponentRendering } from '@core/jss-models';
import { getFieldValue } from '@core/jss-utils';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'wl-order-fulfillment-container',
  templateUrl: './order-fulfillment-container.component.html',
  providers: [
    {
      provide: SIMPLE_PROGRESSIVE_FORMS_CONFIG,
      useValue: {
        scrollIntoViewWhenActive: true,
        enableAutomaticFocusing: true,
        totalStepsInFlow: 4,
      },
    },
    SimpleProgressiveFormsConfigService,
  ],
})
export class OrderFulfillmentContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering | any;

  fulfillmentPersonalProgressiveFormState$ = this.store$.select(
    getOrderPersonalProgressiveFormState
  );
  fulfillmentPaymentProgressiveFormState$ = this.store$.select(
    getOrderPaymentProgressiveFormState
  );
  fulfillmentAddressProgressiveFormState$ = this.store$.select(
    getOrderAddressProgressiveFormState
  );
  fulfillmentConfirmationProgressiveFormState$ = this.store$.select(
    getOrderConfirmationProgressiveFormState
  );
  offer$ = this.store$.select(getPropositionOffer);
  correspondenceInstallationAddressState$ = this.store$.select(
    getSelectedCorrespondenceInstallationAddressState
  );
  correspondenceHouseNumberExtensions$ = this.store$.select(
    getCorrespondenceInstallationAddressHouseNumberExtensionAsDropdownItems
  );
  funnelSettings$ = this.store$.select(getFunnelSettings);
  supplyAddressVM$ = this.store$.select(getSupplyAddressVM);
  progressiveFormSelectorKey = becomeACustomerOrderSelectorKey;

  genderOptions?: RadioItem[];
  residenceOrWorkplaceOptions?: RadioItem[];
  exclusivelyCorporateUsageOptions?: RadioItem[];

  labelsShownAboveFields = false;

  constructor(
    private readonly store$: Store<OrderState>,
    readonly progressiveFormService: ProgressiveFormService
  ) {}

  ngOnInit(): void {
    this.labelsShownAboveFields = getFieldValue(
      this.rendering,
      'LabelsShownAboveFields',
      false
    );

    this.store$.dispatch(
      addOrderRenderingAction({ rendering: this.rendering })
    );

    if (this.rendering?.fields) {
      const fields = this.rendering?.fields;

      this.genderOptions = this.mapField(
        fields.salutationOptions,
        this.mapToString
      );
      this.residenceOrWorkplaceOptions = this.mapField(
        fields.residenceOrWorkplaceOptions,
        this.mapToBoolean
      );
      this.exclusivelyCorporateUsageOptions = this.mapField(
        fields.exclusivelyCorporateUsageOptions,
        this.mapToString
      );
    }
  }

  changeSupplyAddress() {
    this.store$.dispatch(
      openModalFromFunnelSettingsAction({
        source: GenericModalSources.CHANGE_SUPPLY_ADDRESS,
      })
    );
  }

  openAOMModal(offer: Offer) {
    this.store$.dispatch(
      showAomModalAction({
        offer,
        showFooter: false,
        rendering: this.rendering,
      })
    );
  }

  isStepEnabled(stepId: string): Observable<boolean> {
    return this.funnelSettings$.pipe(
      take(1),
      map((funnelSettings) => funnelSettings.enabledOrderSteps.includes(stepId))
    );
  }

  private mapField(field: any, mapFunction: any) {
    return field?.map(mapFunction);
  }

  private readonly mapToString = ({ fields }: any): RadioItem => ({
    label: fields.label.value,
    value: fields.value.value,
    sublabel: fields.sublabel,
    tooltip: fields.tooltip?.value
      ? {
          description: fields.tooltip.value,
        }
      : undefined,
    trackingLabel: fields.trackingLabel?.value,
    disabled: fields.disabled?.value ?? false,
  });

  private readonly mapToBoolean = (option: any): RadioItem => ({
    ...this.mapToString(option),
    value: option.fields.value.value === 'true',
  });
}
