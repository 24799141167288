import { Injectable } from '@angular/core';
import type { CommerceMapperContext, ICommerce } from '@core/analytics';
import {
  analyticsRoundNumericValue,
  defaultCommerceMapperContext,
  parseTrackProductConfig,
  ProductCategorie,
  ProductType,
  TrackProductService,
} from '@core/analytics';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';

@Injectable({
  providedIn: 'root',
})
export class StoreProductTrackingConfig {
  constructor(private readonly trackProductService: TrackProductService) {}

  static mapToCommerce = (
    product: StoreProductData,
    context: CommerceMapperContext = defaultCommerceMapperContext,
    productType?: ProductType | string,
    productCategory?: ProductCategorie | string
  ): ICommerce => ({
    productNaam: product.content.title,
    productID: product.suiteCrmId,
    productType: productType || ProductType.Unknown,
    productCategorie: productCategory || ProductCategorie.Unknown,
    productPositie: `${context.index}`,
    productAantal: `${context.numberOfProducts}`,
    productLijst: context.listName,
    prijs: analyticsRoundNumericValue(product.price),
  });

  getConfig = (productType?: string, productCategory?: string) =>
    parseTrackProductConfig({
      productToCommerce: (
        product: StoreProductData,
        context: CommerceMapperContext
      ): ICommerce => {
        context = {
          ...context,
          listName: this.trackProductService.contextualizeListName(
            context.listName
          ),
        };
        return StoreProductTrackingConfig.mapToCommerce(
          product,
          context,
          productType,
          productCategory
        );
      },
    });
}
