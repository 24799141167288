import { Component, Input } from '@angular/core';

import type { BoilersAdviceFormRendering } from '../../models/boilers-advice-form-rendering.interface';

@Component({
  selector: 'wl-boilers-advice-form-container',
  templateUrl: './boilers-advice-form-container.component.html',
})
export class BoilersAdviceFormContainerComponent {
  @Input({ required: true }) rendering!: BoilersAdviceFormRendering;
}
