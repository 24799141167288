import { Injectable } from '@angular/core';
import { GenericModalPosition } from '@legacy/common-ui-shared-interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { GenericLightboxComponent } from '../generic-lightbox.component';
import { initializeGenericLightbox } from './generic-lightbox-helpers';
import { openGenericLightbox } from './generic-lightbox.actions';

@Injectable()
export class GenericLightboxEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal
  ) {}

  public readonly openGenericLightbox$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openGenericLightbox),
        tap((action) => {
          this.modalService.dismissAll();
          const modal = this.modalService.open(GenericLightboxComponent, {
            windowClass: `${action.position ?? GenericModalPosition.LEFT}`,
            scrollable: true,
            keyboard: true,
          });
          initializeGenericLightbox(modal, action);
        })
      ),
    { dispatch: false }
  );
}
