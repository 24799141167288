<div
  class="dropdown d-inline-flex flex-column border py-1"
  [class.dropdown--expanded]="expanded"
>
  <button
    class="dropdown__title px-2 btn btn-link text-decoration-none m-0 p-0"
    wlTrackLink
    wlAutomationId="commodity-offer-dropdown"
    [trackLinkInfo]="{
      field: 'button',
      type: 'button',
      eventType: 'button-click',
    }"
    [attr.aria-expanded]="expanded"
    aria-controls="dropdown"
    (click)="toggle()"
  >
    <span>Tarieven bekijken</span>
    <fa-icon
      class="dropdown__title__icon ms-2"
      [icon]="expanded ? 'angle-up' : 'angle-down'"
    ></fa-icon>
  </button>
  <div class="dropdown__wrapper">
    <div id="dropdown" class="dropdown__content text-start px-2">
      <!-- table for electricity -->
      @if (electricityDropdownVM) {
        <ed-commodity-vertical-offer-dropdown-table
          [energyTitle]="content?.dropdownElectricityTitle"
          [fixedDeliveryCostLabel]="content?.dropdownFixedDeliveryCostsLabel"
          [fixedDeliveryCostValue]="electricityDropdownVM?.fixedDeliveryCosts"
          [tariffPeriods]="electricityDropdownVM?.tariffPeriods"
          [fixedRedeliveryTitle]="content?.dropdownRedeliveryTitle"
          [fixedRedeliveryCostScaleValue]="
            electricityDropdownVM?.fixedRedeliveryCostsScale
          "
          [fixedRedeliveryCostLabel]="
            content?.dropdownFixedRedeliveryCostsLabel
          "
          [fixedRedeliveryCostValue]="
            electricityDropdownVM?.fixedRedeliveryCosts
          "
          [dynamicPricingExpectedDeliveryRateTitle]="
            dynamicPricingExpectedDeliveryRateTitle
          "
        ></ed-commodity-vertical-offer-dropdown-table>
      }
      <!-- table for gas -->
      @if (gasDropdownVM) {
        <ed-commodity-vertical-offer-dropdown-table
          [energyTitle]="content?.dropdownGasTitle"
          [fixedDeliveryCostLabel]="content?.dropdownFixedDeliveryCostsLabel"
          [fixedDeliveryCostValue]="gasDropdownVM?.fixedDeliveryCosts"
          [tariffPeriods]="gasDropdownVM?.tariffPeriods"
          [dynamicPricingExpectedDeliveryRateTitle]="
            dynamicPricingExpectedDeliveryRateTitle
          "
        ></ed-commodity-vertical-offer-dropdown-table>
      }
      <hr />
      <div class="text-center">
        <div>Bedragen zijn inclusief 21% btw</div>
        <div class="d-flex justify-content-center">
          <button
            class="btn p-0 border-0 btn-link mt-2 mb-1"
            (click)="viewTariffs.emit()"
            wlAutomationId="info-card-button"
            wlCSCapture
          >
            Alle tarieven bekijken
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
