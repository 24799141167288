<wl-experience-editor>
  <ng-template wlLive>
    @if (isEnabled) {
      <wl-content-card
        [title]="rendering?.fields?.Title"
        wlTrackImpression="contentblock-impression"
        [trackImpressionInfo]="rendering"
        trackImpressionName="chatbot"
        wlAutomationId="chatbot"
      >
        <div class="border-top mb-2 pt-2">
          <img *scImage="rendering?.fields?.Avatar" class="me-2" /><span
            *scText="rendering?.fields?.Name"
          ></span>
        </div>
        <div id="chatbot">
          <div
            class="chatbot__online"
            id="chatbot__online"
            [ngStyle]="{ display: 'none' }"
          >
            <wl-rich-text-wrapper>
              <div
                class="d-flex flex-column bg-light rounded p-2 mb-2"
                *wlGenericRichText="rendering?.fields?.Online"
              ></div>
            </wl-rich-text-wrapper>

            <button
              class="btn btn-primary w-100"
              *scText="rendering?.fields?.ButtonText"
              wlTrackLink
              [trackLinkInfo]="{
                rendering: rendering,
                field: rendering?.fields?.ButtonText?.value,
                eventType: 'button-click',
              }"
            ></button>
          </div>

          <div id="chatbot__offline" [ngStyle]="{ display: 'none' }">
            <wl-rich-text-wrapper>
              <div
                class="d-flex flex-column bg-light rounded p-2 mb-2"
                *wlGenericRichText="rendering?.fields?.Offline"
              ></div>
            </wl-rich-text-wrapper>

            <button
              class="btn btn-primary w-100"
              disabled
              *scText="rendering?.fields?.ButtonText"
              wlTrackLink
              [trackLinkInfo]="{
                rendering: rendering,
                field: rendering?.fields?.ButtonText?.value,
                eventType: 'button-click',
              }"
            ></button>
          </div>

          <div id="chatbot__busy" [ngStyle]="{ display: 'none' }">
            <wl-rich-text-wrapper>
              <div
                class="d-flex flex-column bg-light rounded p-2 mb-2"
                *wlGenericRichText="rendering?.fields?.Busy"
              ></div>
            </wl-rich-text-wrapper>

            <button
              class="btn btn-primary w-100"
              disabled
              *scText="rendering?.fields?.ButtonText"
              wlTrackLink
              [trackLinkInfo]="{
                rendering: rendering,
                field: rendering?.fields?.ButtonText?.value,
                eventType: 'button-click',
              }"
            ></button>
          </div>
        </div>
      </wl-content-card>
    }
  </ng-template>
  <ng-template wlPreview>
    <wl-content-card [title]="rendering?.fields?.Title">
      <div class="border-top mb-2 pt-2">
        <img *scImage="rendering?.fields?.Avatar" class="me-2" /><span
          *scText="rendering?.fields?.Name"
        ></span>
      </div>

      <wl-rich-text-wrapper>
        <div
          class="d-flex flex-column bg-light rounded p-2 mb-2"
          *wlGenericRichText="rendering?.fields?.Online"
        ></div>
      </wl-rich-text-wrapper>

      <wl-rich-text-wrapper>
        <div
          class="d-flex flex-column bg-light rounded p-2 mb-2"
          *wlGenericRichText="rendering?.fields?.Offline"
        ></div>
      </wl-rich-text-wrapper>

      <wl-rich-text-wrapper>
        <div
          class="d-flex flex-column bg-light rounded p-2 mb-2"
          *wlGenericRichText="rendering?.fields?.Busy"
        ></div>
      </wl-rich-text-wrapper>

      <button
        class="btn btn-primary w-100"
        *scText="rendering?.fields?.ButtonText"
      ></button>
    </wl-content-card>
  </ng-template>
</wl-experience-editor>
