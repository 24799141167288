import { Injectable } from '@angular/core';
import { closeGenericModal } from '@innogy/common-ui/modals';
import {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, mergeMap } from 'rxjs/operators';

import { triggerChatbotAction } from '../chatbot';
import { getFunnelSettings } from '../funnel';

@Injectable()
export class NoOffersModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store
  ) {}

  funnelSetting$ = this.store$.select(getFunnelSettings);

  public readonly onModalPrimaryResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          action.source === GenericModalSources.NO_OFFERS &&
          action.result === GenericModalResults.PRIMARY
      ),
      concatLatestFrom(() => this.funnelSetting$),
      mergeMap(([, funnelSettings]) => [
        triggerChatbotAction({
          fallbackUrl: funnelSettings.chatbotFallbackUrl,
        }),
      ])
    )
  );
}
