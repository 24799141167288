import type { FunnelSettings } from '@innogy/become-a-customer/shared/interfaces';
import type { GenericModalProps } from '@legacy/common-ui-shared-interfaces';

/**
 * Takes normalized funnelsettings provided by sitecore (either via JSS rendering or GraphQL)
 * and applies parsing of the modals and available funnel steps.
 */
export const parseBacFlowFunnelSettings = (funnelSettings: FunnelSettings) => {
  return {
    ...funnelSettings,
    // TODO: enfore realistic typing
    enabledOrderSteps: funnelSettings.enabledOrderSteps.map(
      (v: any) => v.value
    ),
    changeSupplyAddressModal: mapAdditionalPayload(
      funnelSettings.changeSupplyAddressModal,
      ['redirectUrl']
    ),
    consumptionTresholdExceededModal: mapAdditionalPayload(
      funnelSettings.consumptionTresholdExceededModal,
      ['redirectUrl']
    ),
    exitBacSalesFunnelModal: mapAdditionalPayload(
      funnelSettings.exitBacSalesFunnelModal,
      ['exitUrl']
    ),
  } as FunnelSettings;
};

const mapAdditionalPayload = (
  input: GenericModalProps | undefined,
  propsToMap: string[]
) => {
  if (!input) {
    return {};
  }

  const res: GenericModalProps = { ...input, additionalPayload: {} };

  propsToMap.forEach((prop) => {
    if (res[prop] !== undefined) {
      (res.additionalPayload as { [key: string]: any })[prop] = res[prop];
    }
  });

  return res;
};
