<div ngbAccordion [closeOthers]="true">
  @for (category of rendering?.fields?.Categories; track category) {
    <div ngbAccordionItem>
      <div ngbAccordionHeader>
        <button class="accordion-button" ngbAccordionButton>
          <wl-partial-accordion-title
            [icon]="category.fields?.Icon | scIcon"
            [title]="category.fields?.LabelName?.value"
          ></wl-partial-accordion-title>
        </button>
      </div>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            @for (categoryItem of category.fields?.Items; track categoryItem) {
              <div
                class="p-2 d-flex"
                (click)="
                  navigateToLink.emit(categoryItem.fields?.link.value.href)
                "
              >
                @if (categoryItem.fields?.icon?.value) {
                  <fa-icon
                    class="my-auto me-2"
                    [icon]="categoryItem.fields?.icon | scIcon"
                    [fixedWidth]="true"
                  ></fa-icon>
                }
                <div *wlGenericRichText="categoryItem.fields?.title"></div>
              </div>
            }
          </ng-template>
        </div>
      </div>
    </div>
  }
</div>
