import { DOCUMENT } from '@angular/common';
import { inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import type { EnvironmentConfig } from '@core/config-models';
import type {
  JssStateAllTopLevelPlaceholdersLoaded,
  JssStateMetaTitleUpdated,
  JssStatePlaceholderLoaded,
} from '@core/jss-routing';
import { JssStateActionTypes } from '@core/jss-routing';
import { NotFound, NotFoundRouteName, ServerError } from '@core/models';
import { PlatformService } from '@core/platform';
import { WINDOW } from '@innogy/utils-dom';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import type { Observable } from 'rxjs';
import { zip } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  pairwise,
  share,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import urlParser from 'url-parse';

import type { PageInfo } from './page-info.model';
import { getSiteContext, preparePageName } from './page-name';
import { getSiteContextAbbreviation } from './utils';

@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #actions$ = inject(Actions);
  readonly #store$ = inject(Store);
  readonly #injector = inject(Injector);
  readonly #cookieService = inject(CookieService);
  readonly #platformService = inject(PlatformService);
  readonly #windowRef = inject(WINDOW);
  readonly #document = inject(DOCUMENT);

  private readonly navigationEnd$: Observable<NavigationEnd> =
    this.#router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter((event) => !event.url.startsWith(`/${NotFoundRouteName}`)),
      filter((event) => !event.url.startsWith(`/${NotFound}`)),
      filter((event) => !event.url.startsWith(`/${ServerError}`))
    );

  private readonly navigationEndPairs$ = this.navigationEnd$.pipe(
    startWith(null),
    pairwise()
  );

  private readonly titleUpdated$ = this.#actions$.pipe(
    ofType<JssStateMetaTitleUpdated>(JssStateActionTypes.META_TITLE_UPDATED),
    map((action) => action.payload)
  );

  /**
   * Only listen for main placeholder, is defined in jss-route component
   */
  // TODO: Use ROOT_PLACEHOLDER_KEY instead of string comparison
  private readonly rootPlaceholderLoaded$ = this.#actions$.pipe(
    ofType<JssStatePlaceholderLoaded>(JssStateActionTypes.PLACEHOLDER_LOADED),
    filter(
      (action) => action.payload === 'entry' || action.payload === 'ew-entry'
    )
  );

  public readonly allTopLevelPlaceholdersLoaded$ = this.#actions$.pipe(
    ofType<JssStateAllTopLevelPlaceholdersLoaded>(
      JssStateActionTypes.ALL_TOP_LEVEL_PLACEHOLDERS_LOADED
    )
  );

  // eslint-disable  max-len
  /**
   * Get it while its HOT 🔥
   * @see https://bitbucket.org/xploregroup/xploregroup-webanalytics-analyticstracker/src/12df54826db9d5d9e559af7b897d197aa056fb32/analyticstracker.js?at=master&fileviewer=file-view-default#analyticstracker.js-80
   */
  // eslint-enable  max-len
  public readonly pageInfo$ = zip(
    this.navigationEndPairs$,
    this.titleUpdated$,
    this.rootPlaceholderLoaded$
  ).pipe(
    map(([[lastRoute], title, placeholderLoaded]) => ({
      lastRoute,
      title,
      placeholderLoaded,
    })),
    withLatestFrom(this.#activatedRoute.queryParams, (input, queryParams) => ({
      ...input,
      queryParams,
    })),
    mergeMap((input) =>
      getSiteContext(this.#store$, this.#injector.get(ENVIRONMENT_CONFIG)).pipe(
        map((context) => ({ ...input, context }))
      )
    ),
    map(({ lastRoute, title, queryParams, context }): PageInfo => {
      const parsedUrl = urlParser(this.#document.URL);

      return {
        url: this.getUrlWithoutParams(),
        domain: parsedUrl.hostname,
        referrer: this.getReferrer(lastRoute),
        category: context,
        title,
        userAgent:
          (this.#windowRef && this.#windowRef.navigator.userAgent) || '',
        platform: (this.#windowRef && this.#windowRef.navigator.platform) || '',
        path: this.getPathWithoutParams(),
        params: queryParams,
        timestamp: format(this.getDate(), 'yyyy-MM-dd HH:mm:ss'),
        name: this.getPageName(),
        consent: this.getCookieConsent(),
        environment: this.getEnvironment(
          this.#injector.get(ENVIRONMENT_CONFIG)
        ),
      };
    }),
    share()
  );

  /**
   * Couldn't mock date with Jest & Zone, so used this workaround
   */
  /* istanbul ignore next */
  private getDate() {
    return new Date();
  }

  private getReferrer(lastRoute: NavigationEnd | null): string {
    // Navigated from external site, document.referrer is set
    if (lastRoute == null && this.#platformService.isClient()) {
      return this.#document.referrer;
    } else if (this.#document.location != null && lastRoute) {
      const location = this.#document.location;
      // Internal navigation, use lastRoute as referrer
      return `${location.protocol}//${location.host}${lastRoute.url}`;
    }
    return '';
  }

  public getPageName() {
    return preparePageName(this.#document.URL);
  }

  public getUrlWithoutParams() {
    if (this.#document.location == null) {
      return '';
    }
    const location = this.#document.location;
    return `${location.protocol}//${location.host}${location.pathname}`;
  }

  private getPathWithoutParams() {
    if (this.#document.location == null) {
      return '';
    }
    return this.#document.location.pathname;
  }

  getSiteContextAbbreviation(config: EnvironmentConfig) {
    return getSiteContextAbbreviation(config);
  }

  getEnvironment(config: EnvironmentConfig) {
    return config.environment;
  }

  /**
   * Get the cookie consent.
   *
   * @returns Consent, false if not exisiting
   */
  getCookieConsent() {
    const consentLevel = this.#cookieService.get('consentLevel');

    if (!consentLevel) {
      return false;
    }

    return consentLevel;
  }
}
