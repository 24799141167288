import { EnvConfigBrand } from '@core/config-models';
import type { brand2 } from '@integration/api-client/feh';

export const getBrand = (brand: EnvConfigBrand): brand2 => {
  switch (brand) {
    case EnvConfigBrand.ED:
      return 'energiedirect';
    case EnvConfigBrand.Essent:
      return 'essent';
    default:
      throw new Error('Energiewonen is not supported');
  }
};
