import type { OnInit } from '@angular/core';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import type { KeyValue } from 'ngrx-forms';
import { TrackToolStep } from '@core/analytics';
import { ScrollService } from '@innogy/utils-deprecated';

import type { SimpleProgressiveFormSubmitContext } from '../../models/simple-progressive-form-card.model';
import { SimpleProgressiveFormCardContent } from '../../models/simple-progressive-form-card.model';
import { SimpleProgressiveFormsConfigService } from '../../services/simple-progressive-forms-config.service';

@Component({
  selector: 'wl-simple-progressive-form-card',
  templateUrl: './simple-progressive-form-card.component.html',
  styleUrls: [
    './simple-progressive-form-card.component.essent.scss',
    './simple-progressive-form-card.component.ed.scss',
  ],
})
export class SimpleProgressiveFormCardComponent<TValue extends KeyValue>
  implements OnInit
{
  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly config: SimpleProgressiveFormsConfigService,
    private readonly scrollService: ScrollService
  ) {}

  @Input() progressiveFormState?: ProgressiveFormGroupState<TValue> | null;

  @Input() content?: SimpleProgressiveFormCardContent;
  @Input() trackingConfig?: TrackToolStep;

  /**
   * When set to true, the component will go into `async` mode, meaning
   * that an action is dispatched to mark the state as having pending actions.
   * As soon as the state is marked to have no more pending actions, the submission will continue.
   * Useful in cases where we want to wait for async validation or API calls to finish before submitting and
   * passing the active flag to another form step.
   */
  @Input() asyncSubmission = false;

  @Output() submitted = new EventEmitter<SimpleProgressiveFormSubmitContext>();

  @ContentChild('summary', { static: true }) summaryRef?: TemplateRef<any>;
  @ContentChild('content', { static: true }) contentRef?: TemplateRef<any>;

  @ViewChild('formRef') formRef!: TemplateRef<any>;

  enableAutomaticFocusing = this.config.enableAutomaticFocusing;
  totalSteps = this.config.totalStepsInFlow;

  ngOnInit(): void {
    if (!this.progressiveFormState) {
      throw new Error(
        'No progressive form state provided to progressive form card component'
      );
    }
  }

  scrollIntoViewIfEnabled() {
    if (this.config.scrollIntoViewWhenActive) {
      this.scrollService.scrollToElementAnimated(this.elementRef.nativeElement);
    }
  }
}
