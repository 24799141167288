import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { CalculateState } from '@innogy/become-a-customer/state';
import {
  addCalculateRenderingAction,
  calculateFormLoadedAction,
  calculateFormSubmitAction,
  calculateQuestionnaireOpenModalAction,
  getCalculateViewModel,
  getFunnelSettings,
  houseNumberAdditionFormControlId,
  resetHousenumberExtensionAction,
  setBACFunnelStepAction,
  doubleMeterFormControlId,
  clearFunnelSettings,
  submitAlternateAddressAction,
} from '@innogy/become-a-customer/state';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import { TypedComponentRendering } from '@core/jss-models';
import { Store } from '@ngrx/store';
import { DisableAction, SetValueAction } from 'ngrx-forms';
import { map } from 'rxjs';

import { scToCalculateContentMapper } from '../calculate.mapper';

@Component({
  selector: 'wl-calculate-container',
  templateUrl: './calculate-container.component.html',
  styleUrls: ['./calculate-container.component.scss'],
})
export class CalculateContainerComponent implements OnInit {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;

  @Input()
  content?: CalculateComponentContent;

  @Input()
  isInModal?: boolean;

  viewModel$ = this.store$.select(getCalculateViewModel);
  funnelSettings$ = this.store$.select(getFunnelSettings);

  constructor(private readonly store$: Store<CalculateState>) {}

  ngOnInit() {
    if (!this.isInModal) {
      this.store$.dispatch(clearFunnelSettings());
    }
    this.dispatchAttachRendering();
    this.bootstrapCalculateForm();
    this.dispatchSetFunnelStep();

    if (this.rendering && !this.content) {
      this.content = scToCalculateContentMapper(this.rendering);
    }

    if (this.content?.enableDoubleMeterCheckbox) {
      this.store$.dispatch(new SetValueAction(doubleMeterFormControlId, true));
    }
  }

  submit() {
    this.store$.dispatch(calculateFormSubmitAction());
  }

  openModal(gasHidden: boolean) {
    this.store$.dispatch(calculateQuestionnaireOpenModalAction({ gasHidden }));
  }

  submitAlternateAddress() {
    this.store$.dispatch(submitAlternateAddressAction());
  }

  get usageWizard$() {
    return this.funnelSettings$.pipe(map((settings) => settings.usageWizard));
  }

  hideManualHousenumberInput() {
    this.store$.dispatch(resetHousenumberExtensionAction());
  }

  private bootstrapCalculateForm() {
    this.store$.dispatch(new DisableAction(houseNumberAdditionFormControlId));

    this.store$.dispatch(calculateFormLoadedAction());
  }

  private dispatchAttachRendering() {
    if (this.rendering) {
      this.store$.dispatch(
        addCalculateRenderingAction({ rendering: this.rendering })
      );
    }
  }

  private dispatchSetFunnelStep() {
    this.store$.dispatch(setBACFunnelStepAction({ step: 'Calculate' }));
  }
}
