@for (layer of navigationLayers; track layer; let index = $index) {
  <ul class="navigation-layer" [attr.data-layer]="index + 1">
    @for (menuItem of layer; track menuItem) {
      <li class="navigation-layer__entry">
        @if (menuItem.children?.length) {
          <button
            (click)="navItemSelected(menuItem, index)"
            class="navigation-layer__button"
            [attr.tabindex]="tabIndexForLayer(index, navigationLayers.length)"
          >
            <wl-partial-list-item
              [label]="menuItem.name"
              [iconTrailing]="'chevron-right'"
            >
            </wl-partial-list-item>
          </button>
        }
        @if (!menuItem.children?.length) {
          <a
            class="navigation-layer__link"
            [attr.tabindex]="tabIndexForLayer(index, navigationLayers.length)"
            (click)="navItemSelected(menuItem, index)"
            *wlGenericLink="{ value: { href: menuItem.path } }"
          >
            <wl-partial-list-item [label]="menuItem.name">
            </wl-partial-list-item>
          </a>
        }
      </li>
    }
  </ul>
}
