@if (viewModel$ | async; as vm) {
  @switch (content?.variant) {
    @case ('horizontal') {
      <wl-horizontal-commodity-offer
        [offers]="vm.offers"
        [content]="content"
        [showMemberGetMember]="vm.hasMemberGetMember"
        (selectOffer)="selectOffer($event)"
        (openMoreInfo)="openMoreInfo($event)"
        (viewTariffs)="viewTariffs($event)"
        (adjustCalculation)="adjustCalculation()"
        [supplyAddress]="vm.address"
        [consumptionValues]="vm.consumption"
      />
    }
    @case ('vertical') {
      <wl-vertical-commodity-offer
        [offers]="vm.offers"
        [content]="content"
        [showMemberGetMember]="vm.hasMemberGetMember"
        (selectOffer)="selectOffer($event)"
        (openMoreInfo)="openMoreInfo($event)"
        (viewTariffs)="viewTariffs($event)"
        (adjustCalculation)="adjustCalculation()"
        [supplyAddress]="vm.address"
        [consumptionValues]="vm.consumption"
      />
    }
    @case ('mixed') {
      <wl-mixed-commodity-offer
        [offers]="vm.offers"
        [content]="content"
        [showMemberGetMember]="vm.hasMemberGetMember"
        (selectOffer)="selectOffer($event)"
        (openMoreInfo)="openMoreInfo($event)"
        (viewTariffs)="viewTariffs($event)"
        (adjustCalculation)="adjustCalculation()"
        [supplyAddress]="vm.address"
        [consumptionValues]="vm.consumption"
      />
    }
  }
}
