import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ROOT_PLACEHOLDER_KEY } from '@core/config-angular';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { EMPTY as empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { ComponentRendering } from '@core/jss-proxy';
import { JssPlatformService } from '@core/jss-platform';

import {
  JssStateFatalError,
  JssStatePlaceholderLoaded,
} from '../+state/jss-route.actions';
import { getRenderingData } from '../+state/jss.selectors';

@Component({
  selector: 'wl-jss-route',
  templateUrl: './jss-route.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JssRouteComponent {
  readonly #store$ = inject(Store);
  readonly rootPlaceholderKey = inject(ROOT_PLACEHOLDER_KEY);
  readonly jssPlatform = inject(JssPlatformService);

  rendering$ = this.#store$.pipe(
    getRenderingData,
    catchError((error: unknown) => {
      this.#store$.dispatch(
        new JssStateFatalError({ route: 'ServerError', error: error as Error })
      );
      return empty;
    })
  ) as unknown as Observable<ComponentRendering>;

  triggerLoaded(placeholderName?: string) {
    this.#store$.dispatch(new JssStatePlaceholderLoaded(placeholderName));
  }
}
