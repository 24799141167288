import type { ToolTrackingConfig } from '@core/analytics';
import {
  createToolStepConfig,
  E2E_TRACKING_ID_KEY,
  parseToolConfig,
} from '@core/analytics';
import type { ScForm } from '@innogy/sitecore-forms/models';
import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { analyticsPayloadFromGenericFormControls } from './helpers/analytics-payload-from-generic-form-state';
import type { ScFormState } from './create-generic-form-reducer';

const genericFormSteps = (formTitle: string) =>
  [
    {
      step: 0,
      stepName: `generiek formulier - ${formTitle}`,
      id: 'form',
    },
  ] as const;

export const parseGenericFormToolTrackingConfig = (
  form: ScForm,
  selector: MemoizedSelector<
    Record<string, unknown>,
    ScFormState,
    DefaultProjectorFn<ScFormState>
  >,
  trackingId: string
) => {
  const formName = form.fields.Name.value ?? '';

  const genericFormToolTrackingConfig: ToolTrackingConfig<'form'> = {
    toolName: formName,
    steps: genericFormSteps(formName),
    toolComplete: createToolStepConfig(selector, (payload) => ({
      step: 1,
      payload: {
        ...analyticsPayloadFromGenericFormControls(
          payload.form.formState.controls
        ),
        [E2E_TRACKING_ID_KEY]: trackingId,
      },
    })),
  };

  return parseToolConfig(genericFormToolTrackingConfig);
};
