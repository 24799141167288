import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-commodity-offer-reference-id',
  templateUrl: './commodity-offer-reference-id.component.html',
  styleUrls: [
    './commodity-offer-reference-id.component.ed.scss',
    './commodity-offer-reference-id.component.essent.scss',
  ],
})
export class CommodityOfferReferenceIdComponent {
  @Input() public flowId: string | undefined;
  @Input() public referenceIdLabel: string | undefined;
}
