import type { BoilersAdviceFormRendering } from './boilers-advice-form-rendering.interface';

export const mockBoilersAdviceFormRendering: BoilersAdviceFormRendering = {
  componentName: 'BoilersAdviceForm',
  fields: {
    Salutation: { value: 'Aanhef' },
    SalutationRequiredError: { value: 'Vul alsjeblieft je geslacht in' },
    Mr: { value: 'De heer' },
    Mrs: { value: 'Mevrouw' },
    Neither: { value: 'Geen van beide' },
    Name: { value: 'Naam' },
    Initials: { value: 'Voorletter(s)' },
    InitialsRequiredError: { value: 'Vul tussen 1-5 voorletter(s) in.' },
    Insertion: { value: 'Tussenvoegsel' },
    LastName: { value: 'Achternaam' },
    LastNameRequiredError: { value: 'Vul je achternaam in.' },
    Address: { value: 'Adres' },
    PostalCode: { value: 'Postcode' },
    PostalCodeRequiredError: { value: 'Vul je postcode in.' },
    HouseNumber: { value: 'Huisnummer' },
    HouseNumberRequiredError: { value: 'Vul je huisnummer in.' },
    HouseNumberAddition: { value: 'Toevoeging' },
    Street: { value: 'Straat' },
    StreetRequiredError: { value: 'Vul je straatnaam in.' },
    City: { value: 'Plaats' },
    CityRequiredError: { value: 'Vul je woonplaats in.' },
    Phone: { value: 'Telefoonnummer' },
    PhoneIncorrectError: {
      value: 'Vul een geldig vast of mobiel telefoonnummer in.',
    },
    PhoneRequiredError: {
      value: 'Vul een geldig vast of mobiel telefoonnummer in.',
    },
    Email: { value: 'E-mailadres' },
    EmailIncorrectError: { value: 'Vul een geldig e-mailadres in.' },
    EmailRequiredError: { value: 'Vul een e-mailadres in.' },
    Conditions: { value: 'Voorwaarden' },
    ConsentContact: {
      value:
        'De servicepartner van Essent mag naar aanleiding van deze aanvraag contact met mij opnemen.',
    },
    ConsentContactRequired: {
      value:
        'Wij hebben jouw toestemming nodig om je te benaderen over deze aanvraag.',
    },
    Promotion: { value: 'Aanbiedingen' },
    Optional: { value: '(optioneel)' },
    ConsentPromotion: {
      value:
        'Ik wil graag per e-mail op de hoogte blijven van interessante aanbiedingen en acties van Essent en haar partners.',
    },
    Submit: { value: 'Volgende' },
    SubmissionFailedTitle: { value: 'Verzenden mislukt' },
    SubmissionFailedMessage: {
      value:
        'Er is helaas iets fout gegaan bij het verzenden van uw formulier. Probeer het later nog eens.',
    },
    OnSuccessRedirect: {
      value: {
        href: '/cv-ketels/bedankt',
        linktype: 'internal',
      },
    },
  },
};
