import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';
import { createAction, union } from '@ngrx/store';
import { type UserInfoResponse, SelectedAccountTypes } from '@account/models';

export const loadUserInfo = createAction(SelectedAccountTypes.LOAD_USER_INFO);
export const loadUserInfoSuccess = createBaseAction<UserInfoResponse>(
  SelectedAccountTypes.LOAD_USER_INFO_SUCCESS
);
export const loadUserInfoError = createBaseAction<HttpErrorResponse>(
  SelectedAccountTypes.LOAD_USER_INFO_ERROR
);
export const loadCustomerInfoError = createBaseAction<Error>(
  SelectedAccountTypes.LOAD_CUSTOMER_FAILURE
);
const actions = union({
  loadUserInfo,
  loadUserInfoSuccess,
  loadUserInfoError,
  loadCustomerInfoError,
});
export type AccountsActionsUnion = typeof actions;
