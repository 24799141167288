<form [formGroup]="formData" (ngSubmit)="onSubmit()">
  <rfa-radio-group [control]="formData.controls.salutation"></rfa-radio-group>

  <p class="fw-bold">{{ rendering.fields.Name?.value }}</p>
  <div class="row">
    <div class="col-md-2">
      <rfa-text-input [control]="formData.controls.initials"></rfa-text-input>
    </div>
    <div class="col-md-2">
      <rfa-text-input [control]="formData.controls.insertion"></rfa-text-input>
    </div>
    <div class="col-md-8">
      <rfa-text-input [control]="formData.controls.lastName"></rfa-text-input>
    </div>
  </div>

  <div>
    <p class="fw-bold">{{ rendering.fields.Address?.value }}</p>
    <div class="row">
      <div class="col-md-4">
        <rfa-text-input
          [control]="formData.controls.postalCode"
        ></rfa-text-input>
      </div>
      <div class="col-md-2">
        <rfa-text-input
          [control]="formData.controls.houseNumber"
        ></rfa-text-input>
      </div>
      <div class="col-md-2">
        <rfa-text-input
          [control]="formData.controls.houseNumberAddition"
        ></rfa-text-input>
      </div>
    </div>
    @if (addressApiState().status === 'SUCCESS') {
      <div class="row">
        <div class="col-md-6">
          <rfa-text-input [control]="formData.controls.street"></rfa-text-input>
        </div>
        <div class="col-md-6">
          <rfa-text-input [control]="formData.controls.city"></rfa-text-input>
        </div>
      </div>
    }
  </div>
  <rfa-text-input [control]="formData.controls.phone"></rfa-text-input>
  <rfa-text-input [control]="formData.controls.email"></rfa-text-input>
  <div>
    <p class="fw-bold">{{ rendering.fields.Conditions?.value }}</p>
    <rfa-checkbox [control]="formData.controls.consentContact"></rfa-checkbox>
  </div>
  <div>
    <p>
      <span class="fw-bold">{{ rendering.fields.Promotion?.value }}</span>
      {{ rendering.fields.Optional?.value }}
    </p>
    <rfa-checkbox [control]="formData.controls.consentPromotion"></rfa-checkbox>
  </div>
  <div>
    <button
      class="btn mt-2 w-100 btn--icon-prefixed submit-button btn-primary"
      type="submit"
    >
      {{ rendering.fields.Submit?.value }}
    </button>
  </div>
</form>

@if (isError()) {
  <wl-info-card
    class="d-block mt-2"
    [type]="'danger'"
    [title]="rendering.fields.SubmissionFailedTitle"
    [message]="rendering.fields.SubmissionFailedMessage"
    [border]="true"
    [shadow]="false"
  ></wl-info-card>
}
