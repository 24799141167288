import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { select, Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExperienceEditorUtilsService {
  readonly doc = inject<Document>(DOCUMENT);
  readonly store$ = inject(Store);

  private readonly experienceEditorActive$ = this.store$.pipe(
    select(getIsExperienceEditorActive)
  );

  preventHashLinks(event: MouseEvent) {
    const currentTarget = event.currentTarget;
    let target = event.target as HTMLElement | null;

    // Traverse up to find the listening anchor or until the currenttarget is found.
    while (target != null && target !== currentTarget) {
      const isAnchorWithHashLink = target?.attributes
        .getNamedItem('href')
        ?.value.startsWith('#');
      if (isAnchorWithHashLink) {
        event.preventDefault();
        return;
      }
      target = target.parentElement;
    }
    return;
  }

  private onExperienceEditorEnabled() {
    this.doc?.removeEventListener('click', this.preventHashLinks, false);
    this.doc?.addEventListener('click', this.preventHashLinks, false);
  }

  load() {
    return lastValueFrom(
      this.experienceEditorActive$.pipe(
        tap((experienceEditorActive) => {
          if (experienceEditorActive) {
            this.onExperienceEditorEnabled();
          }
        }),
        take(1)
      ),
      { defaultValue: undefined }
    );
  }
}
