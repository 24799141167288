import type { HttpErrorResponse } from '@angular/common/http';
import type {
  AddressCheckResult,
  GetAddressCheckPayload,
} from '@essent/address';
import { createAction, props } from '@ngrx/store';
import type { LinkFieldValue } from '@core/jss-models';

import type { BoilersAdviceFormData } from '../models/boilers-advice-form-data.interface';

const prefix = '[Eplus Boilers]';

export const getAddress = createAction(
  `${prefix} Get Address`,
  props<GetAddressCheckPayload>()
);

export const getAddressSuccess = createAction(
  `${prefix} Get Address success`,
  props<AddressCheckResult>()
);

export const getAddressError = createAction(
  `${prefix} Get Address error`,
  props<{ response: HttpErrorResponse }>()
);

export const submitAdviceForm = createAction(
  `${prefix} Submit Advice Form`,
  props<{
    formData: BoilersAdviceFormData;
    onSuccessRedirectPage: LinkFieldValue;
  }>()
);

export const submitAdviceFormSuccess = createAction(
  `${prefix} Submit Advice Form Success`
);

export const submitAdviceFormError = createAction(
  `${prefix} Submit Advice Form Error`
);
