import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { AccountEffects } from './account.effects';

@NgModule({
  imports: [EffectsModule.forFeature([AccountEffects])],
  providers: [AccountEffects, provideHttpClient(withInterceptorsFromDi())],
})
export class AccountEffectsModule {}
