import type { OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { InnogyComponentRendering } from '@core/jss-models';
import { getItemLinkFieldValue } from '@core/jss-utils';

@Component({
  selector: 'wl-icon-list-item',
  templateUrl: './icon-list-item.component.html',
  styleUrls: [
    './icon-list-item.component.ed.scss',
    './icon-list-item.component.essent.scss',
  ],
})
export class IconListItemComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() icon?: IconProp;
  @Input() iconColor = 'text-dark';

  @HostBinding('class') hostClasses = 'd-flex mb-2';

  ngOnInit(): void {
    if (!this.rendering || !this.rendering.fields) {
      return;
    }
    this.icon = getItemLinkFieldValue<IconProp>(
      this.rendering.fields,
      'iconName'
    );
    this.iconColor = getItemLinkFieldValue(
      this.rendering.fields,
      'iconColor',
      'text-dark'
    );
  }
}
