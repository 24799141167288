<wl-progressive-form-step-wrapper
  [title]="rendering?.fields?.Title?.value"
  [subtitle]="rendering?.fields?.SubTitle?.value"
  [formState]="formState"
  [rendering]="rendering"
  [stepNumber]="trackStep.step"
  [stepName]="trackStep.stepName"
  [toolName]="trackStep.toolName"
  [submitButtonText]="rendering?.fields?.SubmitText?.value"
  [submitButtonIcon]="rendering?.fields?.SubmitButtonIcon?.fields?.value?.value"
  [submitButtonAutomationId]="'generic-form-idx-' + trackStep.step + '.submit'"
  [progressiveFormSelectorKey]="progressiveFormSelectorKey"
  [isReadonly]="isReadonly"
  (submit)="submitForm(formState.isValid)"
>
  <ng-template wlProgressiveFormBody>
    @if (rendering.fields.RichTextPrefixField?.value) {
      <wl-rich-text-wrapper [field]="rendering.fields.RichTextPrefixField"
        ><p class="py-2"></p
      ></wl-rich-text-wrapper>
    }

    <wl-generic-form
      [trackStep]="trackStep"
      [form]="rendering.fields.Form"
      [formState]="formState"
    ></wl-generic-form>
  </ng-template>
  <ng-template wlProgressiveFormSummary>
    <div wlCSMask>
      <p class="mb-0" *wlGenericRichText="interpolatedSummary"></p>
    </div>
  </ng-template>
</wl-progressive-form-step-wrapper>
