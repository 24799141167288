import { Component, Input } from '@angular/core';
import { CHAR_LIMIT_FOR_SUGGESTIONS } from '@innogy/search-services';

import { SearchAutocompleteComponent } from '../search-autocomplete/search-autocomplete.component';
import { SearchMostSearchedComponent } from '../search-most-searched/search-most-searched.component';

@Component({
  selector: 'wl-search-popover',
  standalone: true,
  imports: [SearchMostSearchedComponent, SearchAutocompleteComponent],
  templateUrl: './search-popover.component.html',
  styleUrls: ['./search-popover.component.essent.scss'],
})
export class SearchPopoverComponent {
  @Input({ required: true })
  searchQuery = '';

  get showMostSearched() {
    return (
      !this.searchQuery || this.searchQuery.length < CHAR_LIMIT_FOR_SUGGESTIONS
    );
  }
}
