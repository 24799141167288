@if (offer) {
  <div class="d-flex flex-column">
    @if (
      selectCommodity(offer, energyType.ELECTRICITY);
      as electricityOverview
    ) {
      <essent-commodity-offer-product-breakdown-row
        [description]="content?.electricityLabel"
        [value]="electricityOverview.totalPrice.expectedYearlyAmount"
      ></essent-commodity-offer-product-breakdown-row>
    }
    @if (selectCommodity(offer, energyType.GAS); as gasOverview) {
      <essent-commodity-offer-product-breakdown-row
        [description]="content?.gasLabel"
        [value]="gasOverview.totalPrice.expectedYearlyAmount"
      ></essent-commodity-offer-product-breakdown-row>
    }
    @if (offer.discountPrice) {
      <essent-commodity-offer-product-breakdown-row
        [description]="content?.cashbackLabel"
        rowStyle="discount"
        [prefix]="'-'"
        [value]="offer.discountPrice"
      ></essent-commodity-offer-product-breakdown-row>
    }
    @if (offer.expectedYearlyAmount) {
      <essent-commodity-offer-product-breakdown-row
        [description]="yearlyCostsLabel(offer)"
        textStyle="bold"
        [value]="offer.expectedYearlyAmount"
      ></essent-commodity-offer-product-breakdown-row>
    }
    @if (showMemberGetMember && content?.memberGetMemberSharedValue; as value) {
      <div class="d-flex justify-content-center mt-2">
        <div class="referral-sticker px-2">
          <span class="referral-sticker__text">
            {{
              value
                | interpolate: { variables: { mgmValue: getMGMValue(offer) } }
            }}
          </span>
        </div>
      </div>
    }
    @if (content?.offerSubmitDescriptionLabel) {
      <p class="mt-2">
        <span class="description">{{
          content?.offerSubmitDescriptionLabel
        }}</span>
        @if (!!content?.offerSubmitDescriptionTooltip) {
          <wl-tooltip
            [tooltip]="{ description: content?.offerSubmitDescriptionTooltip }"
          ></wl-tooltip>
        }
      </p>
    }
  </div>
}
