import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { combineReducers, StoreModule } from '@ngrx/store';
import { combineEffectsWithProviders } from '@essent/common';
import { EffectsModule } from '@ngrx/effects';

import {
  tariffBreakdownReducers,
  tariffBreakdownSelectorKey,
} from './tariff-breakdown.state';
import { TariffBreakdownEffects } from './tariff-breakdown.effects';
import { getMarketingModelContractApi } from './get-marketing-model-contract';

const { EFFECTS, PROVIDERS } = combineEffectsWithProviders(
  getMarketingModelContractApi
);

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      tariffBreakdownSelectorKey,
      combineReducers(tariffBreakdownReducers)
    ),
    EffectsModule.forFeature(EFFECTS),
  ],
  providers: [provideBootstrapEffects([TariffBreakdownEffects]), PROVIDERS],
})
export class SharedTariffBreakdownStoreModule {}
