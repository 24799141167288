import { createAction, props } from '@ngrx/store';
import type {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';

export const closeResidentialModal = createAction(
  '[Residential Modal] Close',
  props<{
    source: GenericModalSources;
    result: GenericModalResults;
    additionalPayload?: any;
  }>()
);
