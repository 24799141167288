@if (viewState$ | async; as viewState) {
  <wl-vertical-offer
    [rendering]="rendering"
    [durations]="viewState.durations"
    [activeDuration]="viewState.activeDuration"
    [offer]="viewState.activeOffer"
    [addOn]="viewState.addOn"
    [hasMemberGetMember]="(hasMemberGetMember$ | async) || false"
    [activeOfferSet]="viewState.activeOfferSet"
    [consumptionValuesVm]="viewState.consumption"
    [supplyAddressVm]="viewState.supplyAddress"
    [xpEditorActive]="viewState.isExperienceEditorActive"
    [moreInfoButtonClick]="moreInfoButtonClick(viewState.activeOffer)"
    (changeDuration)="onChangeDuration($event, viewState.activeOfferSet)"
    (openAomModal)="openAOMModal($event)"
    (openCalculateModal)="openCalculateModal()"
    (selectProposition)="selectProposition(viewState.activeOffer)"
    (toggleAddon)="
      onAddOnToggle(
        $event,
        viewState.activeDuration,
        viewState.regularOfferset,
        viewState.addOn?.offerSet
      )
    "
    (trackProductChange)="onTrackProductChange()"
  ></wl-vertical-offer>
}
