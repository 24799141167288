import { Component, HostBinding, Input } from '@angular/core';

import { AspectRatio, ImageLoadingStrategy, ObjectFit } from '../../models';

@Component({
  selector: 'wl-partial-image[src][alt]',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() src!: string;
  @Input() alt!: string;
  @Input() caption?: string;
  @Input() ratio?: AspectRatio;
  @Input() loading?: ImageLoadingStrategy;
  @Input() objectPosition?: string;
  @Input() objectFit?: ObjectFit;

  @HostBinding('attr.supports-aspect-ratio')
  /**
   * Parses the Aspect Ratio field value to a string containing a percentage.
   * Only respects the first two numbers in the string, e.g. "16/9/12" will be parsed as "16/9".
   * An invalid aspect ratio (or no defined aspect ratio) will return null.
   *
   * @todo: when safari 14 is no longer supported, this component can be refactored to utilized the
   *        css level `aspect-ratio` property. This would remove the need to infer the value into a
   *        padding-top based solution.
   */
  @HostBinding('style.paddingTop')
  get aspectRatioAsPercentage(): `${number}%` | null {
    if (this.ratio) {
      try {
        const separator = '/';
        const [basis, divider] = this.ratio
          .split(separator, 2)
          .map((n) => parseInt(n));
        const percentage = (1 / (basis / divider)) * 100;
        if (isNaN(percentage)) {
          return null;
        }
        return `${percentage}%`;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }
}
