import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { PlatformService } from '@core/platform';
import { WINDOW } from '@innogy/utils-dom';
import { bufferIf, waitForData } from '@innogy/utils-rxjs';
import type { EventTrack, PageTrack } from 'angulartics2';
import { Angulartics2 } from 'angulartics2';
import { merge, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  withEventTrackEnhancers,
  withPageTrackEnhancers,
} from '../provider/analytics-enhancer';
import { AnalyticsEnhancerSources } from '../provider/analytics-enhancer-sources.service';
import { AdobeLaunchService } from './adobe-launch.service';
import type { PageInfo } from './page-info.model';
import { PageInfoService } from './page-info.service';
import { TrackingSideEffectsService } from './tracking-side-effects.service';

declare global {
  interface Window {
    _satellite: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Angulartics2AdobeLaunchService {
  readonly #angulartics2 = inject(Angulartics2);
  readonly #launchService = inject(AdobeLaunchService);
  readonly #enhancerSources = inject(AnalyticsEnhancerSources);
  readonly #pageInfoService = inject(PageInfoService);
  readonly #trackingSideEffects = inject(TrackingSideEffectsService);
  readonly #platformService = inject(PlatformService);
  readonly #document = inject(DOCUMENT);
  readonly #windowRef = inject(WINDOW);

  previousPath = '';

  private readonly bufferUntilLaunchLoaded$ =
    this.#launchService.launchLoaded$.pipe(map((loaded) => !loaded));

  private readonly enhancedPageInfo$ = this.#pageInfoService.pageInfo$.pipe(
    withPageTrackEnhancers(this.#enhancerSources)
  );

  private readonly pageTrack$ = this.#angulartics2.pageTrack.pipe(
    this.#angulartics2.filterDeveloperMode()
  );

  private readonly eventTrack$ = this.#angulartics2.eventTrack.pipe(
    this.#angulartics2.filterDeveloperMode()
  );

  private readonly enhancedPageTrack$ = zip(
    this.pageTrack$,
    this.enhancedPageInfo$
  ).pipe(map(([pageTrack, pageInfo]) => ({ pageTrack, pageInfo })));

  private readonly enhancedEventTrack$ = this.eventTrack$.pipe(
    withEventTrackEnhancers(this.#enhancerSources)
  );

  startTracking() {
    const eventTrackWithPageInfo$ = this.enhancedEventTrack$.pipe(
      waitForData(this.enhancedPageTrack$)
    );

    const bufferedPageTrack$ = bufferIf(
      this.enhancedPageTrack$,
      this.bufferUntilLaunchLoaded$
    ).pipe(tap((page) => this.handleTrackPage(page)));

    const bufferedPageTrackSideEffects$ = bufferIf(
      this.enhancedPageTrack$,
      this.bufferUntilLaunchLoaded$
    ).pipe(
      this.#trackingSideEffects.pageTrackSideEffects(),
      tap(({ event, page }) => this.trackEvent(event, page.pageInfo))
    );

    const bufferedEventTrack$ = bufferIf(
      eventTrackWithPageInfo$,
      this.bufferUntilLaunchLoaded$
    ).pipe(tap(([event, page]) => this.trackEvent(event, page.pageInfo)));

    return merge(
      bufferedPageTrack$,
      bufferedEventTrack$,
      bufferedPageTrackSideEffects$
    );
  }

  private trackEvent(ev: Partial<EventTrack>, pageInfo: PageInfo) {
    if (ev.action != null) {
      this.triggerEvent(ev.action, pageInfo, ev.properties);
    }
  }

  private trackPage(pageInfo: PageInfo) {
    this.triggerEvent('page-impression', pageInfo);
  }

  private triggerEvent(
    event: string,
    pageInfo: PageInfo,
    info?: Record<string, unknown>
  ) {
    const result = {
      event,
      info,
      pageInfo,
    };
    const cEvent = new CustomEvent(event, { detail: result, bubbles: true });
    this.#document.body.dispatchEvent(cEvent);

    this.sendToQATool(result);
  }

  private sendToQATool(data: any) {
    if (this.#platformService.isClient()) {
      try {
        const storage = this.#windowRef.sessionStorage;
        const eventLog = storage.getItem('eventLog');
        const newLog = `${eventLog},${JSON.stringify(data)}`;
        storage.setItem('eventLog', newLog);
        this.#windowRef.postMessage('analytics-track-change', '*');
      } catch (e) {
        console.warn('Session storage not supported');
      }
    }
  }

  private handleTrackPage(page: {
    pageInfo: PageInfo;
    pageTrack: Partial<PageTrack>;
  }) {
    const splitPreviousPath = this.previousPath.split('?')[0];
    const splitNextPath = page.pageTrack.path?.split('?')[0];
    const isChangedPath = splitPreviousPath !== splitNextPath;

    this.previousPath = page.pageTrack.path ?? '';
    if (isChangedPath) {
      this.trackPage(page.pageInfo);
    }
  }
}
