<div class="border rounded border p-2" [class]="containerClass">
  <div
    class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center"
  >
    <div class="pe-1">
      @if (title) {
        <label [for]="elementId">
          <strong *scText="title"></strong>
        </label>
      }
      @if (tooltip) {
        <wl-tooltip
          class="ms-1"
          [tooltip]="{
            description: tooltip,
          }"
        ></wl-tooltip>
      }
    </div>

    <wl-toggle
      [rendering]="rendering"
      [checked]="checked"
      (toggle)="onToggle($event)"
      [toggleId]="elementId"
    ></wl-toggle>
  </div>
  @if (description) {
    <div *wlGenericRichText="description"></div>
  }
</div>
