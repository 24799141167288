import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { CoreJssUtilsModule } from '@core/jss-utils';
import { UtilsModule } from '@innogy/utils-deprecated';
import { EffectsModule } from '@ngrx/effects';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { ExitFunnelModalEffects } from './+state/exit-funnel/exit-funnel.effects';
import { GenericModalEffects } from './+state/generic-modal.effects';
import { ResidentialModalEffects } from './+state/residential/residential.effects';
import { GenericModalComponent } from './generic-modal.component';

const EFFECTS = [
  GenericModalEffects,
  ExitFunnelModalEffects,
  ResidentialModalEffects,
];
@NgModule({
  declarations: [GenericModalComponent],
  imports: [
    CoreAnalyticsModule,
    JssModule,
    CoreJssUtilsModule,
    UtilsModule,
    CommonModule,
    CommonUiSharedModule,
    CommonUiUtilityComponentsModule,
    EffectsModule.forFeature(EFFECTS),
  ],
})
export class GenericModalModule {}
