import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, NavigationEnd } from '@angular/router';
import { SEARCH_RESULTS_URL } from '@innogy/search-services';
import { filter, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly #destroyRef = inject(DestroyRef);

  searchNavigationEnabled = signal<boolean>(false);

  /**
   * Handler that sets the searchNavigationEnabled to false when we route to the search results page.
   */
  readonly _navigationEnabledHandler = inject(Router)
    .events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter((event) => event.url.startsWith(SEARCH_RESULTS_URL)),
      tap(() => this.searchNavigationEnabled.set(false)),
      takeUntilDestroyed(this.#destroyRef)
    )
    .subscribe();
}
