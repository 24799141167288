import {
  Component,
  computed,
  Input,
  type OnDestroy,
  type OnInit,
  type Signal,
} from '@angular/core';
import { ReactiveFormsAbstractionsModule } from '@innogy/eplus/rfa';
import { Store } from '@ngrx/store';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { Status, type AsyncState } from '@essent/common';
import type {
  AddressCheckResult,
  GetAddressCheckPayload,
} from '@essent/address';

import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';

import { getAddress, submitAdviceForm } from '../../store/boilers.actions';
import {
  selectBoilersAddressState,
  selectBoilersAdviceFormSubmitStatus,
  selectBoilersSelectedAddress,
} from '../../store/boilers.selectors';
import {
  boilersAdviceForm,
  type IBoilersAdviceForm,
} from '../../forms/boilers-advice-form.form';
import type { BoilersAdviceFormRendering } from '../../models/boilers-advice-form-rendering.interface';
import type { BoilersAdviceFormData } from '../../models/boilers-advice-form-data.interface';

@Component({
  selector: 'wl-boilers-advice-form',
  standalone: true,
  imports: [ReactiveFormsAbstractionsModule, CommonUiUtilityComponentsModule],
  templateUrl: './boilers-advice-form.component.html',
})
export class BoilersAdviceFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public rendering!: BoilersAdviceFormRendering;
  public formData!: IBoilersAdviceForm;
  public addressApiState: Signal<AsyncState<AddressCheckResult>>;
  public selectedAddress: Signal<GetAddressCheckPayload | null>;
  public submitStatus: Signal<Status>;
  public isError = computed(() => this.submitStatus() === Status.ERROR);
  private readonly onDestroy = new Subject<void>();

  public constructor(private readonly store: Store) {
    this.addressApiState = this.store.selectSignal(selectBoilersAddressState);
    this.selectedAddress = this.store.selectSignal(
      selectBoilersSelectedAddress
    );
    this.submitStatus = this.store.selectSignal(
      selectBoilersAdviceFormSubmitStatus
    );
  }

  public ngOnInit(): void {
    this.formData = boilersAdviceForm(this.rendering);
    this.formData.valueChanges
      .pipe(debounceTime(2000), takeUntil(this.onDestroy))
      .subscribe(() => this.onChange());

    this.store
      .select(selectBoilersAddressState)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((addressState) => {
        if (addressState.status === Status.SUCCESS) {
          this.formData.controls.street.setValue(addressState.data?.street);
          this.formData.controls.city.setValue(addressState.data?.city);
        }
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
  }

  public onChange() {
    const postcode = this.formData.controls.postalCode.value.trim();
    const houseNumber = this.formData.controls.houseNumber.value.trim();
    if (this.isPostalcodeChanged(postcode, houseNumber)) {
      this.store.dispatch(
        getAddress({
          postcode,
          houseNumber,
        })
      );
    }
  }

  public async onSubmit() {
    this.formData.markAllAsTouched();
    if (this.formData.valid) {
      this.store.dispatch(
        submitAdviceForm({
          formData: this.formData.value as BoilersAdviceFormData,
          onSuccessRedirectPage: this.rendering.fields.OnSuccessRedirect
            ?.value || { href: '' },
        })
      );
    }
  }

  private isPostalcodeChanged(postalCode: string, houseNumber: string) {
    const selectedAddress = this.selectedAddress();
    if (selectedAddress) {
      return !(
        selectedAddress.postcode === postalCode &&
        selectedAddress.houseNumber === houseNumber
      );
    } else {
      return postalCode.length > 0 && houseNumber.length > 0;
    }
  }
}
