@if (!floatingLabel && label) {
  <label [for]="formsControl?.id" class="fw-bold mb-1">{{ label }}</label>
}
<div [class.form-floating]="floatingLabel && label" class="position-relative">
  <input
    [wlTrackFieldfocus]="{
      rendering: rendering,
      stepName: stepName,
      field: fieldName,
      sendValue: sendValue,
      hashValue: hashValue,
      step: step,
    }"
    wlHasValueClass="has-value"
    [wlHasValueClassValue]="formsControl?.value"
    type="email"
    [ngrxFormControlState]="formsControl"
    [ngrxEnableFocusTracking]="true"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [placeholder]="placeholder || ' '"
    [id]="formsControl?.id"
    [name]="formsControl?.id"
    autocomplete="email"
    class="form-control"
    [class.pe-6]="!!tooltip"
    aria-required="true"
    [wlAutomationId]="formsControl?.id"
    (paste)="onPaste($event)"
    [wlAutocompleteDisabled]="preventPaste"
  />
  @if (floatingLabel && label) {
    <label [for]="formsControl?.id">
      {{ label }}
    </label>
  }
  @if (!!tooltip) {
    <div
      class="position-absolute h-100 px-2 top-0 bottom-0 end-0 d-flex flex-column justify-content-center"
    >
      <wl-tooltip
        [tooltip]="{
          description: tooltip,
        }"
      ></wl-tooltip>
    </div>
  }
  <ng-content></ng-content>
</div>
