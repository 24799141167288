@if (addressString && consumptionIconLabels) {
  <span class="label item" wlCSCapture>{{ label }}</span>
  <span class="address item" wlCSMask>{{ addressString }}</span>
  <div class="consumptions item">
    @for (consumptionLabel of consumptionIconLabels; track consumptionLabel) {
      <wl-partial-icon-label
        [icon]="consumptionLabel.icon"
        [label]="consumptionLabel.label"
        [fixedWidth]="false"
        [colored]="brand === 'ed'"
      ></wl-partial-icon-label>
    }
    <button
      (click)="adjustCalculation.emit()"
      class="btn btn-link adjust-calculation"
      wlAutomationId="change-calculation-button"
      wlCSCapture
    >
      {{ adjustCalculationLabel }}
    </button>
  </div>
}
