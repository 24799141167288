<div class="question-scroll-anchor" #currentQuestionAnchor></div>
<section class="pt-5 pb-5" [class.bg-question]="!(step % 2)">
  <div class="container text-center">
    <div class="chevron-down d-flex flex-column align-items-center">
      @if (step > 1) {
        <fa-icon
          icon="chevron-down"
          size="2x"
          class="d-flex align-items-center justify-content-center"
          [class.bg-question]="!(step % 2)"
          [class.bg-white]="step % 2"
        ></fa-icon>
      }
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="d-none d-sm-block pt-5 pb-5"><!-- Spacer --></div>
        <p class="h3">{{ title }}</p>
        @if (!isFinalStep && !showOptionsBelowDescription) {
          <div class="row justify-content-center">
            @for (option of options; track option; let i = $index) {
              <wl-how-to-questionnaire-option
                class="col-12 custom-col-xs-6 my-1"
                [rendering]="option"
                (selected)="onOptionSelected($event, i)"
                [active]="selectedOption === i"
              ></wl-how-to-questionnaire-option>
            }
          </div>
        }
        <wl-rich-text-wrapper
          class="text-start"
          [field]="description"
          [rendering]="rendering"
          ><div></div
        ></wl-rich-text-wrapper>
        @if (!isFinalStep && showOptionsBelowDescription) {
          <div class="row justify-content-center mt-2">
            @for (option of options; track option; let i = $index) {
              <wl-how-to-questionnaire-option
                class="col-12 custom-col-xs-6 my-1"
                [rendering]="option"
                (selected)="onOptionSelected($event, i)"
                [active]="selectedOption === i"
              ></wl-how-to-questionnaire-option>
            }
          </div>
        }
        @if (ctaLink && (ctaTxt || ctaLink.text)) {
          <div class="mt-5 mb-5">
            <a
              class="btn btn-primary"
              *wlGenericLink="ctaLink"
              wlTrackLink
              [trackLinkInfo]="{
                rendering: rendering,
                field: ctaTxt || ctaLink.text,
                linkurl: ctaLink.href,
                eventType: 'button-click',
                type: 'button',
              }"
              wlTrackImpression="button-impression"
              trackImpressionMode="button"
              [trackImpressionName]="ctaTxt || ctaLink.text"
              [trackImpressionLinkurl]="ctaLink.href"
              [trackImpressionInfo]="rendering"
            >
              {{ ctaTxt || ctaLink.text }}
            </a>
          </div>
        }
        @if (step > 1) {
          <div>
            <button
              class="btn btn-link text-primary"
              (click)="backClicked.emit(isFinalStep)"
            >
              {{
                isFinalStep
                  ? backToBeginningButtonText
                  : backToPreviousButtonText
              }}
            </button>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="d-none d-sm-block pt-3 pb-3"><!-- Spacer --></div>
</section>

<div class="question-scroll-anchor" #nextQuestionAnchor></div>
@if (nextQuestion) {
  <wl-how-to-questionnaire-question
    [rendering]="nextQuestion"
    [containerRendering]="containerRendering"
    [questions]="questions"
    [backToPreviousButtonText]="backToPreviousButtonText"
    [backToBeginningButtonText]="backToBeginningButtonText"
    [step]="step + 1"
    (backClicked)="onBackClicked($event)"
  ></wl-how-to-questionnaire-question>
}
