import { createAction, props } from '@ngrx/store';
import type {
  GenericModalProps,
  CloseGenericModalProps,
} from '@legacy/common-ui-shared-interfaces';

export const openGenericModal = createAction(
  '[Generic Modal] Open',
  props<GenericModalProps>()
);

export const closeGenericModal = createAction(
  '[Generic Modal] Close',
  props<CloseGenericModalProps>()
);
