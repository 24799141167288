@if (backLink$ | async; as backLink) {
  <a
    class="btn btn-secondary btn-icon"
    wlTrackImpression="button-impression"
    trackImpressionMode="button"
    trackImpressionName="aanbod"
    [trackImpressionLinkurl]="backLink.href"
    [trackImpressionInfo]="rendering"
    wlTrackLink
    [trackLinkInfo]="{
      rendering: rendering,
      field: 'aanbod',
      linkurl: backLink.href,
      eventType: 'button-click',
    }"
    *wlGenericLink="
      { value: backLink };
      extras: { queryParamsHandling: 'preserve' }
    "
    wlCSCapture
  >
    <fa-icon icon="pen"></fa-icon>
  </a>
}
