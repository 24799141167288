import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import type { ComposableCardConfig } from '@innogy/common-ui/common-renderings';
import { getComposableCardConfigFromRendering } from '@innogy/common-ui/common-renderings';
import type { ContentContext } from '@legacy/common-ui-partials';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type {
  ParsedToolTrackingConfig,
  TrackedToolComponent,
} from '@core/analytics';
import { generateAnalyticsE2EId, TrackToolService } from '@core/analytics';
import { getFieldValue } from '@core/jss-utils';
import type { ScFormFormGroupState } from '@innogy/sitecore-forms/models';
import { GenericFormRendering } from '@innogy/sitecore-forms/models';
import { GenericFormService } from '@innogy/sitecore-forms/services';
import type { ScFormState } from '@innogy/sitecore-forms/store';
import {
  parseGenericFormToolTrackingConfig,
  submitGenericFormAction,
} from '@innogy/sitecore-forms/store';
import { Store } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import type { Observable } from 'rxjs';

@Component({
  selector: 'wl-generic-form-container[rendering]',
  templateUrl: './generic-form-container.component.html',
})
export class GenericFormContainerComponent
  implements OnInit, TrackedToolComponent<'form'>
{
  @Input() rendering!: GenericFormRendering;

  viewModel$?: Observable<ScFormState>;
  cardConfig?: ComposableCardConfig;

  trackToolConfig: ParsedToolTrackingConfig<'form'> | undefined = undefined;
  e2eTrackingId = generateAnalyticsE2EId(this.config);

  context: ContentContext = 'default';

  private initializeForm() {
    const form = this.rendering.fields.Form;

    this.genericFormService.registerForm(form);
    const { selector: formStateSelector, asObservable: viewModel } =
      this.genericFormService.selectFormStateForForm(form.id);

    this.viewModel$ = viewModel;
    this.trackToolConfig = parseGenericFormToolTrackingConfig(
      form,
      formStateSelector,
      this.e2eTrackingId
    );
  }

  ngOnInit() {
    this.initializeForm();

    this.cardConfig = getComposableCardConfigFromRendering(this.rendering);

    // this should work, but it doesn't:
    // import { getDroplinkValue } from '@innogy/jss-integration';
    // getDroplinkValue(this.rendering.fields.Form.fields, 'Context') as ContentContext || 'default'
    this.context =
      this.rendering.fields.Form.fields.Context?.fields.value?.value ||
      'default';
  }

  submit(event: Event, formGroupState: FormGroupState<ScFormFormGroupState>) {
    event.preventDefault();

    const integrationSettingsPath =
      this.rendering.fields.Form.fields.Integration?.value ?? '';

    this.store$.dispatch(
      submitGenericFormAction({
        formId: this.rendering.fields.Form.id,
        formGroupState,
        integrationSettingsPath,
        e2eTrackingId: this.e2eTrackingId,
      })
    );

    if (formGroupState.isValid && this.trackToolConfig) {
      this.trackToolService.completeTool(this.trackToolConfig);
    }
  }

  displayForm(vm: ScFormState) {
    return (
      (!vm.feedback?.isPosted || !!vm.feedback.redirectOnSuccessPage?.href) &&
      Boolean(vm.form.formState)
    );
  }

  displaySubmissionSucceededText(vm: ScFormState) {
    return (
      vm.feedback?.isPosted &&
      !vm.feedback.redirectOnSuccessPage?.href &&
      !!getFieldValue(this.rendering, 'SubmissionSucceededText')
    );
  }

  displaySubmissionFailedText(vm: ScFormState) {
    return (
      vm.feedback?.submitError &&
      !!getFieldValue(this.rendering, 'SubmissionFailedMessage')
    );
  }

  constructor(
    private readonly store$: Store,
    private readonly genericFormService: GenericFormService,
    private readonly trackToolService: TrackToolService,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}
}
