import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { ToolTrackValues } from '@core/analytics';
import {
  AnalyticsActionTypes,
  analyticsHash,
  analyticsRoundNumericValue,
  lowerCaseObjectKeys,
  TrackToolService,
} from '@core/analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mapOfferToCommerce } from '../../helpers/map-offer-to-commerce';
import type {
  OrderFormLaststepExtraValues,
  OrderFormLaststepResult,
} from './order-form-laststep.model';

@Injectable({
  providedIn: 'root',
})
export class OrderFormLaststepService implements OnDestroy {
  private readonly onDestroy$ = new Subject();

  public readonly trackOrderLaststep = (
    orderFlowResultValues: OrderFormLaststepResult,
    offer: Offer
  ) => {
    const trackingValues: ToolTrackValues = {
      step: 5,
      stepName: '',
      type: AnalyticsActionTypes.LAST_STEP,
      rendering: {
        componentName: 'order',
      },
    };

    const {
      transactieId,
      leadId,
      testAanmelding,
      stroomverbruikJaar,
      gasverbruikJaar,
      typeKlant,
      isKlant,
      aantalPersonen,
      typeAansluiting,
      typeMeter,
      zonnepanelen,
      emailNieuwsbrief,
      emailAanbiedingen,
      prijs,
      vatIncluded,
      customerEmailAddress,
    } = orderFlowResultValues;

    const extraValues: OrderFormLaststepExtraValues = {
      result: {
        transactieId,
        omzet: analyticsRoundNumericValue(vatIncluded ? prijs : prijs * 1.21),
        leadId,
        testAanmelding,
        stroomverbruikJaar,
        gasverbruikJaar,
        typeKlant,
        isKlant,
        aantalPersonen,
        typeAansluiting,
        typeMeter,
        zonnepanelen,
        emailNieuwsbrief,
        emailAanbiedingen,
      },
      commerce: mapOfferToCommerce(offer),
      emailaddressHash: customerEmailAddress
        ? analyticsHash(customerEmailAddress)
        : undefined,
    };

    return this.trackToolService
      .trackTool(trackingValues, {
        ...extraValues,
        result: lowerCaseObjectKeys(extraValues.result),
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  };

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  constructor(private readonly trackToolService: TrackToolService) {}

  /**
   * Utilities
   */
}
