<section class="structured-content structured-content--{{ theme }}">
  <div
    class="structured-content__wrapper"
    [class.structured-content__wrapper--centered]="centered"
  >
    <div
      class="structured-content__heading"
      [class.structured-content--centered]="centered"
    >
      <div class="structured-content__icon">
        @if (icon) {
          <fa-icon [icon]="icon"></fa-icon>
        }
        @if (!icon) {
          <ng-content select="fa-icon[slot='icon']"></ng-content>
        }
      </div>
      @if (heading) {
        <h3>{{ heading }}</h3>
      }
      @if (!heading) {
        <ng-content select="[slot='heading']"></ng-content>
      }
    </div>
    <div
      class="structured-content__sub-heading"
      [class.structured-content--centered]="centered"
    >
      @if (subheading) {
        <h4 class="intro">{{ subheading }}</h4>
      }
      @if (!subheading) {
        <ng-content select="[slot='subheading']"></ng-content>
      }
    </div>
    <div
      class="structured-content__paragraph"
      [class.structured-content--centered]="centered"
    >
      <ng-content select="[slot='paragraph']"></ng-content>
    </div>
  </div>
  <div class="structured-content__projection">
    <ng-content select="[slot='content']"></ng-content>
  </div>
  <div
    class="structured-content__buttons"
    [class.structured-content--centered]="centered"
    [class.structured-content__buttons--inline]="layoutButtonsInline"
  >
    <ng-content select="[slot='button-primary']"></ng-content>
    <ng-content select="[slot='button-secondary']"></ng-content>
  </div>
</section>
