@if (!jssPlatform.isEditorActive()) {
  <sc-placeholder
    (loaded)="triggerLoaded($event)"
    [name]="rootPlaceholderKey"
    [rendering]="(rendering$ | async)!"
  ></sc-placeholder>
} @else {
  <sc-placeholder
    ngSkipHydration
    (loaded)="triggerLoaded($event)"
    [name]="rootPlaceholderKey"
    [rendering]="(rendering$ | async)!"
  ></sc-placeholder>
}
