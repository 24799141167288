import {
  createAsyncStateReducer,
  createInitialAsyncState,
} from '@essent/common';
import type { PostalAddressFormValues } from '@innogy/common-ui/forms';
import { extendPostalAddressFormReducer } from '@innogy/common-ui/forms';
import type {
  TariffBreakdownFormValues,
  TariffBreakdownState,
} from '@innogy/shared/tariff-breakdown/models';
import type { FormGroupValidatorMap } from '@innogy/utils-deprecated';
import { ModelContractRate } from '@integration/marketing-api-models';
import type { ActionReducer } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';

import {
  getMarketingModelContract,
  getMarketingModelContractClear,
  getMarketingModelContractError,
  getMarketingModelContractSuccess,
} from './get-marketing-model-contract';

export const tariffBreakdownComponentKey = 'tariffBreakdownComponent';

const initialFormValues: TariffBreakdownFormValues = {
  postalCode: '',
  communicationNumber: NaN,
  houseNumberAddition: '',
  returnSupply: false,
  rate: ModelContractRate.SINGLE,
};

const validators: FormGroupValidatorMap<
  TariffBreakdownFormValues,
  PostalAddressFormValues
> = {};

const {
  extendedControlIds,
  initialExtendedFormGroupState: initialTariffBreakdownFormGroupState,
  extendedReducer: tariffBreakdownFormStateReducer,
} = extendPostalAddressFormReducer<TariffBreakdownFormValues>({
  formId: tariffBreakdownComponentKey,
  baseInitialState: initialFormValues,
  extendingValidators: validators,
});

export const tariffBreakdownFormControlIds = extendedControlIds;

export const initialTariffBreakdownState: TariffBreakdownState = {
  tariffBreakdown: createInitialAsyncState(),
  formState: initialTariffBreakdownFormGroupState,
};

const _getMarketingModelContractReducer = createAsyncStateReducer(
  getMarketingModelContract,
  getMarketingModelContractSuccess,
  getMarketingModelContractError,
  getMarketingModelContractClear
);

export const tariffBreakdownReducer: ActionReducer<TariffBreakdownState> =
  combineReducers({
    tariffBreakdown: _getMarketingModelContractReducer,
    formState: tariffBreakdownFormStateReducer,
  });
