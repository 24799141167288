import type { ToolTrackingConfig } from '@core/analytics';
import { createToolStepConfig, parseToolConfig } from '@core/analytics';

import { selectSolarPanelsWizardAnalyticsPayload } from './solar-panels-wizard.selectors';

const solarPanelsWizardSteps = [
  {
    step: 0,
    stepName: 'postcode check',
    id: 'wizard',
  },
] as const;

export type SolarPanelsWizardToolTrackingSteps =
  (typeof solarPanelsWizardSteps)[number]['id'];

const solarPanelsWizardToolTrackingConfig: ToolTrackingConfig<SolarPanelsWizardToolTrackingSteps> =
  {
    steps: solarPanelsWizardSteps,
    toolComplete: createToolStepConfig(
      selectSolarPanelsWizardAnalyticsPayload,
      (payload) => ({
        step: 1,
        payload: {
          ['stroomverbruik jaar']: payload.consumption,
        },
      })
    ),
  };

export const parsedSolarPanelsWizardToolTrackingConfig = (toolName?: string) =>
  parseToolConfig({
    ...solarPanelsWizardToolTrackingConfig,
    ...(toolName && { toolName }),
  });
