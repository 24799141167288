@if (label && !floatingLabel) {
  <label
    [for]="formsControl?.id"
    wlAutomationId="non-floating-label"
    class="fw-bold mb-1"
    >{{ label }}</label
  >
}
<div [class.form-floating]="label && floatingLabel" class="position-relative">
  <input
    wlHasValueClass="has-value"
    [wlHasValueClassValue]="formsControl?.value"
    type="tel"
    [wlTrackFieldfocus]="{
      rendering: rendering,
      stepName: stepName,
      field: fieldName,
      sendValue: sendValue,
      hashValue: hashValue,
      step: step,
    }"
    [ngrxFormControlState]="formsControl"
    [ngrxEnableFocusTracking]="true"
    [wlValidationClasses]="formsControl"
    [placeholder]="placeholder || ' '"
    [id]="formsControl?.id"
    [name]="formsControl?.id"
    autocomplete="phone"
    class="form-control"
    [class.pe-6]="!!tooltip"
    aria-required="true"
    [wlAutomationId]="formsControl?.id"
  />
  @if (label && floatingLabel) {
    <label wlAutomationId="floating-label" [for]="formsControl?.id">{{
      label
    }}</label>
  }
  @if (!!tooltip) {
    <div
      class="position-absolute h-100 px-2 top-0 bottom-0 end-0 d-flex flex-column justify-content-center"
    >
      <wl-tooltip
        [tooltip]="{
          description: tooltip,
        }"
      ></wl-tooltip>
    </div>
  }
  <ng-content></ng-content>
</div>
