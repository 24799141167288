import { Component, inject } from '@angular/core';

import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from '@innogy/search-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'wl-search-header',
  standalone: true,
  imports: [
    UtilsEnvironmentModule,
    RouterModule,
    SearchBarComponent,
    FontAwesomeModule,
  ],
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.essent.scss'],
})
export class SearchHeaderComponent {
  showSearchNavigation = inject(NavigationService).searchNavigationEnabled;

  close() {
    this.showSearchNavigation.set(false);
  }
}
