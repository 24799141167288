import type { GenericModalProps } from '@legacy/common-ui-shared-interfaces';
import { createAction, props } from '@ngrx/store';

export const emailOfferButtonClicked = createAction(
  '[Offer Saver] Email offer button clicked',
  props<{
    emailAddress: string;
    modalContent: {
      offerEmailed: GenericModalProps;
      offerEmailFailed: GenericModalProps;
    };
  }>()
);
