<div class="listItem" [class.listItem__active]="active">
  @if (iconLeading) {
    <div class="listItem__iconLeading">
      <fa-icon class="iconLeading" [icon]="iconLeading"></fa-icon>
    </div>
  }
  <ng-container *ngTemplateOutlet="labelSlot"></ng-container>
  @if (iconTrailing) {
    <div class="listItem__iconTrailing">
      <fa-icon class="iconTrailing" [icon]="iconTrailing"></fa-icon>
    </div>
  }
</div>

<ng-template #labelSlot>
  @if (label) {
    <div class="listItem__label">
      {{ label }}
    </div>
  }
  @if (!label) {
    <ng-content select="[slot='label']"></ng-content>
  }
</ng-template>
