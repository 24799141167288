import { FormGroup } from '@angular/forms';
import {
  RfaComponents,
  RfaFormControl,
  RfaTransformers,
  RfaValidators,
} from '@innogy/eplus/rfa';
import { getFieldValue } from '@core/jss-utils';

import type { BoilersAdviceFormRendering } from '../models/boilers-advice-form-rendering.interface';
import { mockBoilersAdviceFormRendering } from '../models/boilers-advice-form-rendering.mock';

const defaultRequiredError = 'Veld verplicht';

export function boilersAdviceForm(rendering: BoilersAdviceFormRendering) {
  return new FormGroup({
    salutation: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Salutation', 'Aanhef'),
        entries: [
          { text: getFieldValue(rendering, 'Mr', 'Dhr.'), value: 'Dhr.' },
          { text: getFieldValue(rendering, 'Mrs', 'Mevr.'), value: 'Mevr.' },
          {
            text: getFieldValue(rendering, 'Neither', 'Geen van beide'),
            value: '',
          },
        ],
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'SalutationRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.RadioGroup
    ),
    initials: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Initials', 'Initialen'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'InitialsRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
        transformers: [RfaTransformers.betweeny('.'), RfaTransformers.caps],
      },
      RfaComponents.TextInput
    ),
    insertion: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Insertion', 'Tussenvoegsel'),
      },
      RfaComponents.TextInput
    ),
    lastName: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'LastName', 'Achternaam'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'LastNameRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    postalCode: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'PostalCode', 'Postcode'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'PostalCodeRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    houseNumber: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'HouseNumber', 'Huisnummer'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'HouseNumberRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    houseNumberAddition: new RfaFormControl(
      '',
      {
        label: getFieldValue(
          rendering,
          'HouseNumberAddition',
          'Huisnummer toevoeging'
        ),
      },
      RfaComponents.TextInput
    ),
    street: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Street', 'Straat'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'StreetRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    city: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'City', 'Plaats'),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'CityRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    phone: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Phone', 'Telefoonnummer'),
        errorMsgs: {
          phone: getFieldValue(
            rendering,
            'PhoneIncorrectError',
            'Veld incorrect'
          ),
          required: getFieldValue(
            rendering,
            'PhoneRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.phone, RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    email: new RfaFormControl(
      '',
      {
        label: getFieldValue(rendering, 'Email', 'E-mail adres'),
        errorMsgs: {
          email: getFieldValue(
            rendering,
            'EmailIncorrectError',
            'Veld incorrect'
          ),
          required: getFieldValue(
            rendering,
            'EmailRequiredError',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.email, RfaValidators.required],
      },
      RfaComponents.TextInput
    ),
    consentContact: new RfaFormControl(
      false,
      {
        label: getFieldValue(
          rendering,
          'ConsentContact',
          'Neem contact met mij op'
        ),
        errorMsgs: {
          required: getFieldValue(
            rendering,
            'ConsentContactRequired',
            defaultRequiredError
          ),
        },
        validators: [RfaValidators.requiredTrue],
      },
      RfaComponents.Checkbox
    ),
    consentPromotion: new RfaFormControl(
      false,
      {
        label: getFieldValue(
          rendering,
          'ConsentPromotion',
          'Stuur mij aanbiedingen'
        ),
      },
      RfaComponents.Checkbox
    ),
  });
}

export const mockBoilersAdviceForm = boilersAdviceForm(
  mockBoilersAdviceFormRendering
);
export type IBoilersAdviceForm = typeof mockBoilersAdviceForm;
