@for (input of form.fields.Inputs; track input) {
  <div class="w-100 mb-2">
    @switch (input.fields.Type.value) {
      @case ('address') {
        @if (input.fields | cast: toAddressInputFields; as fields) {
          <wl-sc-address
            [fields]="fields"
            [formState]="formState"
            [trackStep]="trackStep"
            [floatingLabels]="form.fields.FloatingLabels.value"
            [responsive]="true"
          ></wl-sc-address>
        }
      }
      @case ('text') {
        @if (input.fields | cast: toTextFormInputFields; as fields) {
          <wl-sc-text
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-text>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('checkbox') {
        @if (input.fields | cast: toCheckboxFormInputFields; as fields) {
          <wl-sc-checkbox
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
          ></wl-sc-checkbox>
          <ng-container
            *ngTemplateOutlet="
              requiredTrueError;
              context: { $implicit: fields }
            "
          ></ng-container>
        }
      }
      @case ('checkboxSet') {
        @if (input.fields | cast: toCheckboxSetFormInputFields; as fields) {
          <wl-sc-checkbox-set
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
          ></wl-sc-checkbox-set>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('email') {
        @if (input.fields | cast: toEmailFormInputFields; as fields) {
          <wl-sc-email
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-email>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isEmailError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isEqualError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('name') {
        @if (input.fields | cast: toNameFormInputFields; as fields) {
          <wl-sc-name
            [fields]="fields"
            [formState]="formState"
            [trackStep]="trackStep"
            [floatingLabels]="form.fields.FloatingLabels.value"
          ></wl-sc-name>
        }
      }
      @case ('payment') {
        @if (input.fields | cast: toPaymentFormInputFields; as fields) {
          <wl-sc-payment
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-payment>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isIBANError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('dropdown') {
        @if (input.fields | cast: toDropdownFormInputFields; as fields) {
          <wl-sc-dropdown
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabels]="form.fields.FloatingLabels.value"
          ></wl-sc-dropdown>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('phone') {
        @if (input.fields | cast: toPhoneFormInputFields; as fields) {
          <wl-sc-phone
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-phone>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isPhoneError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('radio') {
        @if (input.fields | cast: toRadioFormInputFields; as fields) {
          <wl-sc-radio
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-radio>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        }
      }
      @case ('widget') {
        @if ((input.fields | cast: toWidgetInputFields).Widget; as widget) {
          <wl-widget
            [name]="'widget-placeholder'"
            [rendering]="widget"
            [props]="{ formState: formState, isVisible: true }"
          ></wl-widget>
        }
      }
      @default {
        form component for type: {{ input.fields.Type.value }} not defined yet
      }
    }
  </div>
}

<ng-template #requiredError let-fields>
  @if (
    controlForInput(fields).isTouched &&
    controlForInput(fields).errors?.required
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.required-error'"
      [messageField]="fields.RequiredError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>

<ng-template #requiredTrueError let-fields>
  @if (
    controlForInput(fields).isTouched &&
    (controlForInput(fields).errors?.required ||
      controlForInput(fields).errors?.requiredTrue)
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.required-true-rror'"
      [messageField]="fields.RequiredError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>

<ng-template #isEmailError let-fields>
  @if (
    controlForInput(fields).isTouched &&
    controlForInput(fields).errors?.isEmailAddress
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.is-email-error'"
      [messageField]="fields.IsEmailError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>

<ng-template #isEqualError let-fields>
  @if (
    controlForInput(fields).isTouched && controlForInput(fields).errors?.equalTo
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.is-equal-error'"
      [messageField]="fields.IsEqualError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>

<ng-template #isIBANError let-fields>
  @if (
    controlForInput(fields).isTouched &&
    controlForInput(fields).errors?.isFriendlyFormatIBAN
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.is-iban-error'"
      [messageField]="fields.IsIBANError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>

<ng-template #isPhoneError let-fields>
  @if (
    controlForInput(fields).isTouched &&
    controlForInput(fields).errors?.isPhoneNumber
  ) {
    <wl-input-error
      [automationId]="fields.Label?.value + '.is-phone-error'"
      [messageField]="fields.IsPhoneError"
      [messageDescription]="fields.Label?.value"
      [rendering]="{ componentName: trackStep.toolName }"
      [stepName]="trackStep.stepName"
    ></wl-input-error>
  }
</ng-template>
