import { Injectable } from '@angular/core';
import { getPaymentDetailsSuccess } from '@essent/financial';
import {
  emobilityActions,
  prefillEmobilityPaymentDetailsStateAction,
} from '@innogy/emobility-shared';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import { isNotNullish } from '@innogy/utils-rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SetValueAction } from 'ngrx-forms';

import {
  chargePointColorControlId,
  chargePointFixedCableControlId,
  chargePointTypeControlId,
} from './lead-form-charge-point-configuration.reducer';
import {
  initializeLeadForm,
  setEmobilityLeadFormType,
} from './lead-form.actions';
import {
  getPropositionType,
  isEmobilityChargeCardTypeEnabled,
  isEmobilityChargePointConfigurationEnabled,
  selectChargePointOptions,
} from './lead-form.selectors';
import { getLeadFormSteps, leadFormSelectorKey } from './lead-form.state';

@Injectable()
export class LeadFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly progressiveFormService: ProgressiveFormService,
    private readonly store$: Store<any>
  ) {}

  public readonly prefillPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentDetailsSuccess),
      mergeMap((action) => [
        prefillEmobilityPaymentDetailsStateAction({
          payload: {
            iban: action.payload.paymentDetails.iban,
          },
        }),
      ])
    )
  );

  public readonly prefillChargePointOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emobilityActions.getEmobilityProductsV2.successAction),
      withLatestFrom(this.store$.select(selectChargePointOptions)),
      mergeMap(([_, options]) => {
        const actions: SetValueAction<unknown>[] = [];

        if (options.colors.length === 1) {
          actions.push(
            new SetValueAction(chargePointColorControlId, options.colors.at(0))
          );
        }

        if (options.cableOptions.length === 1) {
          actions.push(
            new SetValueAction(
              chargePointFixedCableControlId,
              options.cableOptions.at(0)
            )
          );
        }

        if (options.stationTypes.length === 1) {
          actions.push(
            new SetValueAction(
              chargePointTypeControlId,
              options.stationTypes.at(0)
            )
          );
        }

        return actions;
      })
    )
  );

  public readonly setLeadFormSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeLeadForm),
      withLatestFrom(
        this.store$.select(getPropositionType).pipe(filter(isNotNullish)),
        this.store$.select(isEmobilityChargeCardTypeEnabled),
        this.store$.select(isEmobilityChargePointConfigurationEnabled),
        (
          action,
          proposition,
          isChargeCardTypeEnabled,
          isChargePointConfigurationEnabled
        ) => ({
          proposition,
          isChargeCardTypeEnabled,
          isChargePointConfigurationEnabled:
            isChargePointConfigurationEnabled &&
            action.payload.chargePointConfigurationEnabled,
        })
      ),
      mergeMap(
        ({
          proposition,
          isChargeCardTypeEnabled,
          isChargePointConfigurationEnabled,
        }) => [
          setEmobilityLeadFormType({ proposition }),
          this.progressiveFormService.setFormConfigAction({
            progressOnValidSubmit: true,
            formId: leadFormSelectorKey,
            formSteps: getLeadFormSteps(
              proposition,
              isChargeCardTypeEnabled,
              isChargePointConfigurationEnabled
            ),
            scrollToInitialStep: false,
          }),
          this.progressiveFormService.initProgressiveForm({
            formId: leadFormSelectorKey,
          }),
        ]
      )
    )
  );
}
