import { createFeatureSelector, createSelector } from '@ngrx/store';

import { boilersSelectorKey, type BoilersState } from './boilers.reducer';

export const selectBoilersFeature =
  createFeatureSelector<BoilersState>(boilersSelectorKey);

export const selectBoilersAddressState = createSelector(
  selectBoilersFeature,
  (state) => state.addressCheck
);

export const selectBoilersSelectedAddress = createSelector(
  selectBoilersFeature,
  (state) => state.selectedPostalCode
);

export const selectBoilersAdviceFormSubmitStatus = createSelector(
  selectBoilersFeature,
  (state) => state.adviceFormSubmitStatus.status
);

export const selectBoilersAdviceFormSubmitRedirect = createSelector(
  selectBoilersFeature,
  (state) => state.adviceFormRedirectUrl
);
