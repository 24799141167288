import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import type { ProductDuration } from '@essent/new-customer';
import type { GetOfferDuration } from '@innogy/become-a-customer/shared/interfaces';
import type { FilterEntry } from '@legacy/common-ui-utility-components';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import type { EnvConfigBrand } from '@core/config-models';
import { EnvironmentConfig } from '@core/config-models';

@Component({
  selector: 'wl-vertical-offer-duration',
  templateUrl: './vertical-offer-duration.component.html',
  styleUrls: [
    './vertical-offer-duration.component.ed.scss',
    './vertical-offer-duration.component.essent.scss',
  ],
})
export class VerticalOfferDurationComponent {
  @Input()
  public durations?: GetOfferDuration[];
  @Input()
  public activeDuration?: ProductDuration | string;
  @Input()
  public offerDurationTitle?: string;

  @Output()
  public changeDuration = new EventEmitter<GetOfferDuration>();

  brand?: EnvConfigBrand;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {
    this.brand = this.envConfig.brand;
  }

  isSelectedOffer(offerDuration: GetOfferDuration) {
    return (
      offerDuration.offerId === this.activeDuration ||
      offerDuration.duration === this.activeDuration
    );
  }

  filterEntries(
    durations: GetOfferDuration[]
  ): FilterEntry<GetOfferDuration>[] {
    return durations.map((duration) => ({
      id: duration.duration,
      text: duration.durationTitle,
      value: duration,
    }));
  }

  selectedFilterEntry(
    durations: GetOfferDuration[]
  ): FilterEntry<GetOfferDuration> | undefined {
    const selected = durations.find((duration) =>
      this.isSelectedOffer(duration)
    );
    return selected
      ? { id: selected.duration, text: selected.durationTitle, value: selected }
      : undefined;
  }
}
