import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';
import { getHasMultipleAccounts } from '@account/entity';

import { getCustomerDetailsState } from './feature.selectors';
import * as fromAddress from './communication-address.reducer';
import type { CorrespondenceDetailsWithStates } from '../customer-details.model';

export const getCorrespondenceDetailsState = createSelector(
  getCustomerDetailsState,
  (feature) => feature.correspondenceDetails
);

export const getCorrespondenceDetails = createSelector(
  getCorrespondenceDetailsState,
  fromAddress.getDetails
);

const getCorrespondenceDetailsError = createSelector(
  getCorrespondenceDetailsState,
  fromAddress.getError
);

const getCorrespondenceDetailsStatus = createSelector(
  getCorrespondenceDetailsState,
  fromAddress.getStatus
);

export const getPrimaryPhoneNumber = createSelector(
  getCorrespondenceDetails,
  (details) =>
    details?.correspondence?.phone?.find?.((number) => number.primary)
      ?.phoneNumber
);

export const getCorrespondenceAddress = createSelector(
  getCorrespondenceDetails,
  (details) => details?.correspondence?.address
);

export const getCanEditEmailAddress = createSelector(
  getHasMultipleAccounts,
  (hasMultipleAccounts) => hasMultipleAccounts !== true
);

export const getCorrespondenceDetailsAndStates = createSelector(
  getCorrespondenceDetails,
  getPrimaryPhoneNumber,
  getCorrespondenceDetailsError,
  getCorrespondenceDetailsStatus,
  getCanEditEmailAddress,
  getHasMultipleAccounts,
  (
    correspondenceDetails,
    primaryPhoneNumber,
    correspondenceDetailsError,
    status,
    canEditEmailAddress,
    hasMultipleAccounts
  ): CorrespondenceDetailsWithStates => {
    const showError = !!correspondenceDetailsError;
    const showLoading =
      (status === Status.IDLE || status === Status.PENDING) && !showError;
    const showAddress = !!correspondenceDetails && !showError && !showLoading;

    return {
      correspondenceDetails,
      primaryPhoneNumber,
      correspondenceDetailsError,
      showError,
      showLoading,
      showAddress,
      canEditEmailAddress,
      hasMultipleAccounts,
    };
  }
);
