import {
  FormControl,
  type FormControlState,
  type FormGroup,
} from '@angular/forms';
import type { Type } from '@angular/core';

import type {
  RfaCombination,
  RfaComponents,
} from '../../level2/components/RfaComponents';

/**
 * @description
 * An extended version of the Angular FormControl
 * ---
 * @usageNotes
 *
 * ```typescript
 * const control = new RfaFormControl<string>('');
 *
 * // RfaFormControl has more options
 * const control2 = new RfaFormControl<string>('', {
 *    transformers: [RfaTransformers.betweeny('.'), RfaTransformers.caps],
 *    ...
 * });
 * ```
 */
export class RfaFormControl<
  TValue extends CType['valueType'],
  CType extends RfaCombination = typeof RfaComponents._None,
> extends FormControl {
  public prevValue: TValue | FormControlState<TValue> = this.value;

  public override readonly value!: TValue;
  public readonly component?: Type<any>;
  public readonly options: CType['optionsType'];
  public readonly id: string;

  constructor(value: TValue, options?: CType['optionsType'], component?: CType);
  constructor(value: TValue, options: CType['optionsType'], component: CType) {
    super(value, options);
    this.options = options ?? { nonNullable: true };
    this.component = component?.componentType ?? undefined;
    this.id = this.options.id ?? this.generateRandomAutocompleteId();

    // set default nonNullable to true instead of false
    if (this.options.nonNullable === undefined) {
      this.options.nonNullable = true;
    }

    // watch for validation triggers and update validation on triggers activation
    if (this.options.validationTriggers) {
      this.options.validationTriggers.forEach((triggerFn) => {
        // must be set after parent is initialized, setTimeout forces this. Other solutions are welcome
        setTimeout(() => {
          if (this.parent) {
            triggerFn(this.parent as FormGroup).subscribe(() => {
              this.updateValueAndValidity();
            });
          }
        });
      });
    }

    // on value change, update the value by the given transformers
    this.valueChanges.subscribe(() => {
      this.administerTransformers();
    });
  }

  /**
   * @description
   * Loop through the transformers and update value accordingly.
   */
  private administerTransformers() {
    this.options.transformers?.forEach((transformer) => {
      const newValue = transformer(this);
      this.setValue(newValue, { emitEvent: false });
    });
  }

  /**
   * @description
   * Generates random string to be used as disabled autocomplete
   * ---
   * See also [autocomplete directive](../../../../../../utils/deprecated/src/lib/autocomplete/autocomplete.directive.ts)
   */
  generateRandomAutocompleteId(): string {
    return Math.random().toString(36).slice(2);
  }

  /**
   * @description
   * Adjusted hasError method to include touched check so it has the expected Essent behavior
   */
  override hasError(errorCode: string): boolean {
    if (!this.errors) {
      return false;
    }
    return this.errors[errorCode] !== undefined && this.touched;
  }
}
