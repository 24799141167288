import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-commodity-offer-customer-information',
  templateUrl: './commodity-offer-customer-information.component.html',
  styleUrls: [
    './commodity-offer-customer-information.component.ed.scss',
    './commodity-offer-customer-information.component.essent.scss',
  ],
})
export class CommodityOfferCustomerInformationComponent {
  @Input() public customerId: string | undefined;
  @Input() public customerIdLabel: string | undefined;
}
