import type { UsageQuestionnaireProps } from '@innogy/common-ui/sales';
import type {
  GenericModalProps,
  GenericModalSitecoreSources,
} from '@legacy/common-ui-shared-interfaces';
import type {
  SolarPanelsCalculationParameters,
  SolarPanelsNewCustomerAddressCheckResponse,
  SolarPanelsProductData,
  SolarPanelsProductWithCalculations,
} from '@innogy/eplus/temporary-core-modules';
import type {
  BaseComponentFields,
  BaseSourceProperties,
  DropdownField,
  ScLinkField,
} from '@core/jss-models';
import type {
  ComponentFields,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

import type {
  JSSSharedEplusFunnelSettingsFields,
  SharedEplusFunnelSettingsInterface,
} from './shared-eplus-funnel-settings.interface';

export interface SolarPanelsFunnelSettings {
  calculationParameters: SolarPanelsCalculationParameters;
  products: SolarPanelsProductData[];
}

export interface SolarPanelsFunnelSettingsFieldInterface {
  ConfirmationCheckboxAllowContactMoreInfoText: TextField;
  HomeBatteryInterestLabel: TextField;
  HomeBatteryInterestDescription: TextField;
  FunnelSettings: SolarPanelsFunnelSettingsFields | null;
}

export type SolarPanelsFunnelSettingsInterface =
  SharedEplusFunnelSettingsInterface & {
    startPage?: ScLinkField;
    suitabilityCheckPage?: ScLinkField;
    resultOverviewPage?: ScLinkField;
    houseIsUnsuitablePage?: ScLinkField;
    houseNotFoundPage?: ScLinkField;
    houseIsMonumentPage?: ScLinkField;
    houseHasInsufficientRoofAreaPage?: ScLinkField;
    showProductOverviewPage?: boolean;
    showResultOverviewPage?: boolean;
    showSuitabilityCheckPage?: boolean;
    addressNotFoundErrorModal?: GenericModalProps;
    usageWizard?: UsageQuestionnaireProps;
  };

export type UsageQuestionnaire = {
  SubmitLabel: TextField;
  supplyTypeOptionsLabel: TextField;
  errorMessage: TextField;
  residentsOptions: DropdownField[];
  Subtitle: TextField;
  supplyTypeOptionsPlaceHolder: TextField;
  housingTypeOptions: DropdownField[];
  residentsOptionsLabel: TextField;
  supplyTypeOptions: DropdownField[];
  Title: TextField;
  housingTypeOptionsLabel: TextField;
  residentsOptionsPlaceHolder: TextField;
  housingTypeOptionsPlaceHolder: TextField;
  componentName: TextField;
} & ComponentFields;

export type SolarPanelsFunnelSettingsFields = BaseComponentFields & {
  fields?: JSSSharedEplusFunnelSettingsFields & {
    CalculationParameters?: BaseSourceProperties;
    StartPage?: {
      value: ScLinkField;
    };
    SuitabilityCheckPage?: {
      value: ScLinkField;
    };
    ResultOverviewPage?: {
      value: ScLinkField;
    };
    HouseIsUnsuitablePage?: {
      value: ScLinkField;
    };
    HouseNotFoundPage?: {
      value: ScLinkField;
    };
    HouseIsMonumentPage?: {
      value: ScLinkField;
    };
    HouseHasInsufficientRoofAreaPage?: {
      value: ScLinkField;
    };
    ShowProductPage?: {
      value: boolean;
    };
    ShowResultOverviewPage?: {
      value: boolean;
    };
    EnableSuitabilityCheckPage?: {
      value: boolean;
    };
    AddressNotFoundErrorModal?: {
      fields: GenericModalSitecoreSources;
    };
    UsageQuestionnaire?: {
      fields: UsageQuestionnaire;
    };
  };
};

export interface SolarPanelsResultOverviewVieModel {
  product: SolarPanelsProductWithCalculations;
  address: SolarPanelsNewCustomerAddressCheckResponse;
}

export type SolarPanelsFunnelStep =
  | 'wizard'
  | 'suitabilityCheck'
  | 'productOverview'
  | 'resultOverview'
  | 'orderForm';

export enum SolarPanelsSuitabilitySimpleAnswer {
  YES = 'ja',
  NO = 'nee',
  UNKNOWN = 'weet ik niet',
}

export enum SolarPanelsSuitabilityRoofType {
  SLOPED_ROOF_TILES = 'schuin dak met dakpannen',
  FLAT_ROOF_BITUMEN = 'plat dak met bitumen',
  SPECIAL = 'anders',
  UNKNOWN = 'weet ik niet',
}
