import { createSelector } from '@ngrx/store';
import { analyticsHash } from '@core/analytics';

import { eplusSolarPanelsFeature } from '../solar-panels-form.state';
import {
  selectSelectedSolarPanelsProduct,
  selectSolarPanelsTrackingId,
} from '../solar-panels-funnel/solar-panels-funnel.selectors';
import { selectSolarPanelsSuitabilityCheckAnalyticsPayload } from '../solar-panels-suitability-check/solar-panels-suitability-check.selectors';
import {
  solarPanelsOrderAddressFormId,
  solarPanelsOrderConfirmationFormId,
  solarPanelsOrderFormKey,
  solarPanelsOrderPersonalDetailsFormId,
} from './solar-panels-order-form.reducer';

const selectSolarPanelsOrderFormState = createSelector(
  eplusSolarPanelsFeature,
  (form) => form[solarPanelsOrderFormKey]
);

export const selectSolarPanelsOrderFormAddress = createSelector(
  selectSolarPanelsOrderFormState,
  (form) => form[solarPanelsOrderAddressFormId].progressiveForm
);

export const selectSolarPanelsOrderFormAddressFormState = createSelector(
  selectSolarPanelsOrderFormAddress,
  (progressiveForm) => progressiveForm.formState
);

export const selectSolarPanelsOrderFormPersonalDetails = createSelector(
  selectSolarPanelsOrderFormState,
  (form) => form[solarPanelsOrderPersonalDetailsFormId].progressiveForm
);

export const selectSolarPanelsOrderFormPersonalDetailsFormState =
  createSelector(
    selectSolarPanelsOrderFormPersonalDetails,
    (progressiveForm) => progressiveForm.formState
  );

export const selectSolarPanelsOrderFormConfirmation = createSelector(
  selectSolarPanelsOrderFormState,
  (form) => form[solarPanelsOrderConfirmationFormId].progressiveForm
);

export const selectSolarPanelsOrderFormConfirmationFormState = createSelector(
  selectSolarPanelsOrderFormConfirmation,
  (progressiveForm) => progressiveForm.formState
);
export const selectSolarPanelsOrderFormViewModel = createSelector(
  selectSolarPanelsOrderFormPersonalDetails,
  selectSolarPanelsOrderFormAddress,
  selectSolarPanelsOrderFormConfirmation,
  (
    personalDetailsProgressiveFormState,
    addressProgressiveFormState,
    confirmationProgressiveFormState
  ) => ({
    personalDetailsProgressiveFormState,
    addressProgressiveFormState,
    confirmationProgressiveFormState,
  })
);

export const selectSolarPanelsOrderFormAnalyticsPayload = createSelector(
  selectSolarPanelsSuitabilityCheckAnalyticsPayload,
  selectSelectedSolarPanelsProduct,
  selectSolarPanelsOrderFormPersonalDetails,
  selectSolarPanelsTrackingId,
  (suitabilityCheckPayload, selectedProduct, personalDetails, trackingId) => ({
    ...suitabilityCheckPayload,
    product: selectedProduct,
    email: analyticsHash(personalDetails.formState.controls.emailAddress.value),
    homeBatteryInterest:
      personalDetails.formState.controls.homeBatteryInterest.value,
    trackingId,
  })
);
