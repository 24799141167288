<div class="d-flex offer-durations">
  @if (offerDurationTitle) {
    <p>{{ offerDurationTitle }}</p>
  }
  @if (durations && durations.length > 0) {
    <!-- temp specific filter for ED until Essent has a new design for this -->
    @if (brand === 'ed') {
      <wl-filter
        [entries]="filterEntries(durations)"
        [selected]="selectedFilterEntry(durations)"
        wlCSCapture
        (entrySelected)="changeDuration.emit($event)"
      ></wl-filter>
    }
    <!-- temp specific filter for Essent until it has a new design (CTR-240) -->
    @if (brand === 'essent') {
      <div class="offer-duration d-flex" wlAutomationId="filter-button">
        @for (duration of durations; track duration.duration) {
          <button
            class="btn offer-duration__button me-1 py-1 px-2"
            wlAutomationId="offer-duration-button-{{ duration.duration }}"
            [ngClass]="{
              'offer-duration__button--active': isSelectedOffer(duration),
            }"
            (click)="changeDuration.emit(duration)"
          >
            {{ duration.durationTitle }}
          </button>
        }
      </div>
    }
  }
</div>
