import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { CoreExperienceEditorComponentsModule } from '@core/experience-editor-components';
import { UtilsModule } from '@innogy/utils-deprecated';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';
import { StoreModule } from '@ngrx/store';

import { UsageQuestionnaireComponent } from './usage-questionnaire/usage-questionnaire.component';
import { UsageQuestionnaireModalComponent } from './usage-questionnaire-modal/usage-questionnaire-modal.component';
import { estimateConsumptionByHouseTypeReducer } from './api-state/estimate-consumption-by-house.reducer';
import { commonUiSalesSelectorKey } from './api-state/estimate-consumption-by-house.selector';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CoreAnalyticsModule,
    CoreExperienceEditorComponentsModule,
    CommonUiUtilityComponentsModule,
    CommonUiFormsModule,
    JssModule,
    UtilsModule,
    NgbModalModule,
    CommonUiSharedModule,
    NgrxFormsModule,
    StoreModule.forFeature(commonUiSalesSelectorKey, [
      estimateConsumptionByHouseTypeReducer,
    ]),
  ],
  declarations: [UsageQuestionnaireComponent, UsageQuestionnaireModalComponent],
  exports: [UsageQuestionnaireComponent, UsageQuestionnaireModalComponent],
})
export class CommonUiSalesModule {}
