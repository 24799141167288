import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/eplus.lazy.module').then((module) => module.EplusLazyModule);

const defineImport = (path: string) => ({
  path,
  loadChildren,
});

const eplusWidgets: ComponentNameAndModule[] = [
  defineImport('ServicePartnerDisplayPlaceholderWidget'),
];

const eplusLazyImports: ComponentNameAndModule[] = [
  defineImport('SolarPanelsWizard'),
  defineImport('SolarPanelsSuitabilityCheck'),
  defineImport('SolarPanelsProductOverview'),
  defineImport('SolarPanelsOrderForm'),
  defineImport('SolarPanelsReceipt'),
  defineImport('SolarPanelsResultOverview'),
  defineImport('SolarPanelsResultOverviewSummary'),
  defineImport('InsulationWizard'),
  defineImport('InsulationProductOverview'),
  defineImport('InsulationReceipt'),
  defineImport('InsulationWizard'),
  defineImport('InsulationOrderForm'),
  defineImport('SustainabilityWizard'),
  defineImport('StoreProductOverview'),
  defineImport('StoreReceipt'),
  defineImport('SustainabilityContactForms'),
  defineImport('SustainabilityReturnToScanButton'),
  defineImport('SolarPaybackWizard'),
  defineImport('HeatPumpOrderForm'),
  defineImport('HeatPumpWizard'),
  defineImport('HeatPumpQuestionnaire'),
  defineImport('BoilerAdviceForm'),
  ...eplusWidgets,
];

export { eplusLazyImports };
