@if (electricityOverview) {
  <wl-price-overview-table
    [title]="fields?.ElectricityCostTitle?.value"
    [faIcon]="'plug'"
    [priceOverview]="electricityOverview"
    [rendering]="rendering"
  ></wl-price-overview-table>
}
@if (gasOverview) {
  <wl-price-overview-table
    [title]="fields?.GasCostTitle?.value"
    [faIcon]="'fire-flame'"
    [priceOverview]="gasOverview"
    [rendering]="rendering"
  ></wl-price-overview-table>
}
