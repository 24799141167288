@if (consumptionValues.electricity; as electricity) {
  @if (electricity.supplyLow) {
    <div class="d-flex flex-column">
      @if (electricity.supplyNormal; as amount) {
        <span
          wlAutomationId="electricitySupplyHigh"
          class="pe-2 d-flex align-items-center"
          wlCSCapture
        >
          <fa-icon
            icon="sun"
            class="fa-icon me-2 consumption-icon"
            size="lg"
          ></fa-icon>
          {{ amount }} {{ 'ElectricityUsageUnit' | translate }}</span
        >
      }
      @if (electricity.supplyLow; as amount) {
        <span
          wlAutomationId="electricitySupplyLow"
          class="pe-2 d-flex align-items-center"
          wlCSCapture
        >
          <fa-icon
            icon="moon"
            class="fa-icon me-2 consumption-icon"
            size="lg"
          ></fa-icon>
          {{ amount }} {{ 'ElectricityUsageUnit' | translate }}
        </span>
      }
    </div>
  } @else {
    @if (electricity.supplyNormal; as amount) {
      <span
        wlAutomationId="electricitySupplyNormal"
        class="pe-2 d-flex align-items-center"
        wlCSCapture
      >
        <fa-icon
          icon="plug"
          class="fa-icon me-2 consumption-icon"
          size="lg"
        ></fa-icon>
        {{ amount }} {{ 'ElectricityUsageUnit' | translate }}
      </span>
    }
  }
}

@if (consumptionValues.gas) {
  <div wlAutomationId="gasSupply">
    <span class="pe-2 d-flex align-items-center" wlCSCapture>
      <fa-icon
        icon="fire-flame"
        class="fa-icon me-2 consumption-icon"
        size="lg"
      ></fa-icon>
      {{ consumptionValues.gas }}
      <span [innerHtml]="'GasUsageUnit' | translate" wlCSCapture></span>
    </span>
  </div>
}

@if (consumptionValues.electricity.returnSupplyNormal; as returnSupply) {
  <div wlAutomationId="returnSupplyNormal">
    <span class="pe-2 d-flex align-items-center" wlCSCapture>
      <fa-icon
        icon="solar-panel"
        class="fa-icon me-2 consumption-icon"
        size="lg"
      ></fa-icon>
      {{ returnSupply }} {{ 'ElectricityUsageUnit' | translate }}
    </span>
  </div>
}
