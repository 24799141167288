import type { GenericModalProps } from '@legacy/common-ui-shared-interfaces';
import type { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';

export const initializeGenericModal = (
  modal: NgbModalRef,
  props: GenericModalProps
): void => {
  modal.componentInstance.source = props.source;
  modal.componentInstance.title = props.title;
  modal.componentInstance.body = props.body;
  modal.componentInstance.primaryButtonText =
    props.primaryButtonText ?? modal.componentInstance.primaryButtonText;
  modal.componentInstance.primaryButtonStyle =
    props.primaryButtonStyle ?? modal.componentInstance.primaryButtonStyle;
  modal.componentInstance.chatbotSelectorClass =
    props.chatbotSelectorClass ?? modal.componentInstance.chatbotSelectorClass;
  modal.componentInstance.secondaryButtonText =
    props.secondaryButtonText ?? modal.componentInstance.secondaryButtonText;
  modal.componentInstance.secondaryButtonStyle =
    props.secondaryButtonStyle ?? modal.componentInstance.secondaryButtonStyle;
  modal.componentInstance.tracking = props.tracking;
  modal.componentInstance.additionalPayload = props.additionalPayload;
  modal.componentInstance.interpolatablePayload = props.interpolatablePayload;
  modal.componentInstance.autoCloseTime =
    props.autoCloseTime ?? modal.componentInstance.autoCloseTime;

  modal.result.then(noop, (reason) => {
    if (
      reason === ModalDismissReasons.ESC ||
      reason === ModalDismissReasons.BACKDROP_CLICK
    ) {
      modal.componentInstance.close();
    }
  });
};
