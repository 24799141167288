@if (image.src) {
  <wl-image-card-button
    [src]="image.src"
    [title]="title"
    (clicked)="onSelect()"
    [highlighted]="active"
  ></wl-image-card-button>
}
@if (!image.src) {
  <wl-icon-card-button
    [iconName]="icon | scIcon"
    [title]="title"
    [highlighted]="active"
    variantMobile="large"
    iconSize="3x"
    iconSizeMobile="2x"
    (clicked)="onSelect()"
  ></wl-icon-card-button>
}
