import type { BaseService } from '@essent/common';
import type { CreateLeadBoilerSchema } from '@integration/api-client/feh';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_PUBLIC_PREFIX } from '@core/config-angular';
import type { Observable } from 'rxjs';

@Injectable()
export class PostCreateLeadBoilerService
  implements BaseService<CreateLeadBoilerSchema, unknown>
{
  readonly apiVersion = 'v1';
  readonly endpoint: string;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {
    this.endpoint = `${this.apiUrl || ''}/feh/leads/boiler/${this.apiVersion}`;
  }

  call$(param: CreateLeadBoilerSchema): Observable<unknown> {
    return this.httpClient.post(this.endpoint, param);
  }
}
