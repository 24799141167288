import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { RenderingField } from '@core/jss-proxy';

import type {
  InterpolatableText,
  InterpolationMode,
  InterpolationVariables,
} from './text-interpolation.service';
import { TextInterpolationService } from './text-interpolation.service';

export interface InterpolationOptions {
  mode?: InterpolationMode;
  variables: InterpolationVariables;
}

@Pipe({
  name: 'interpolate',
})
export class TextInterpolationPipe implements PipeTransform {
  constructor(
    private readonly textInterpolationService: TextInterpolationService
  ) {}
  /**
   * Interpolates input with specified variables.
   *
   * Usage:
   *
   * `string | interpolate: { variables: { variable1: 'substitution1', variable2: 'substitution2' } }`
   *
   * Can also be used with the `async`-pipe on a per-value basis:
   *
   * `string | interpolate: { variables: { variable1: substitution$ | async, variable2: 'substitution2' } }`
   *
   */
  transform(text: undefined, options: InterpolationOptions): undefined;
  transform(text: string, options: InterpolationOptions): string;
  transform<T>(
    text: RenderingField<T>,
    options: InterpolationOptions
  ): RenderingField<T>;
  transform(
    text: InterpolatableText,
    options: InterpolationOptions
  ): InterpolatableText {
    return this.textInterpolationService.interpolate(
      text,
      options.variables,
      options.mode ?? 'plain_text'
    );
  }
}
