import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmobilitySharedModule } from '@innogy/emobility-shared';
import { combineReducers, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { QuotationAcceptanceEffects } from './quotation-acceptance.effects';
import { emobilityCaseReducers, quotationAcceptanceKey } from './state';

@NgModule({
  imports: [
    CommonModule,
    EmobilitySharedModule,
    StoreModule.forFeature(
      quotationAcceptanceKey,
      combineReducers(emobilityCaseReducers)
    ),
    EffectsModule.forFeature(QuotationAcceptanceEffects),
  ],
})
export class EmobilityQuotationAcceptanceStoreModule {}
