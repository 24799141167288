import { createBaseAction } from '@essent/common';
import type {
  BACFunnelStep,
  FunnelSettings,
} from '@innogy/become-a-customer/shared/interfaces';
import type { GenericModalSources } from '@legacy/common-ui-shared-interfaces';
import { createAction, props } from '@ngrx/store';

const FUNNEL_SETTINGS_PREFIX = `[become-a-customer-funnel-settings]`;

export const loadFunnelSettingsAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} load funnel settings`,
  props<{ sourceStep: BACFunnelStep }>()
);

export const setFunnelInitializedAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Init funnel`
);

export const setFunnelSettingsAction = createBaseAction<FunnelSettings>(
  `${FUNNEL_SETTINGS_PREFIX} Set funnel settings`
);

export const clearFunnelSettings = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Clear funnel settings`
);

export const setFunnelSettingsFromRenderingAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Set funnel settings from JSS rendering`
);

export const setFunnelSettingsFromGraphqlAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Set funnel settings from GraphQL query`,
  props<{ guid: string }>()
);

export const setFunnelSettingsFromGraphqlErrorAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Error while setting from GraphQL query`,
  props<{ error: Error }>()
);

export const setBACFunnelStepAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Set active BAC funnel step`,
  props<{ step: BACFunnelStep }>()
);

export const redirectToFunnelStartPageAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Redirect to starting page of funnel`
);

export const openModalFromFunnelSettingsAction = createAction(
  `${FUNNEL_SETTINGS_PREFIX} Open modal via funnel settings`,
  props<{
    source: GenericModalSources;
    additionalPayload?: any;
    interpolatablePayload?: any;
  }>()
);
