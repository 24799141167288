@if (icon) {
  <fa-icon
    [icon]="icon"
    [fixedWidth]="true"
    class="me-1 me-md-2 my-auto"
  ></fa-icon>
}
@if (title) {
  <span class="accordion-title me-2">{{ title }}</span>
}
@if (after) {
  <span class="ms-auto me-1 me-md-2">{{ after }}</span>
}
