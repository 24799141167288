import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import type { KeyValue } from 'ngrx-forms';

@Pipe({
  name: 'showProgressiveFormsEditButton',
})
export class ShowProgressiveFormsEditButtonPipe<TValue extends KeyValue>
  implements PipeTransform
{
  transform(state: ProgressiveFormGroupState<TValue>) {
    return state.isSubmitted && !state.isActive && !state.isInert;
  }
}
