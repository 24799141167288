@if (navItem | navItemToJssRoute: language; as route) {
  @if (navItem?.path) {
    <a
      class="nav-item nav-link"
      (click)="clicked.emit(navItem)"
      [title]="navItem?.name"
      *wlGenericLink="route"
      wlCSCapture
    >
      {{ navItem?.name }}
    </a>
  } @else {
    <a
      class="nav-item nav-link"
      (click)="clicked.emit(navItem)"
      [title]="navItem?.name"
    >
      {{ navItem?.name }}
    </a>
  }
}
