import { props, createAction } from '@ngrx/store';
import type { ToolTrackValues } from '@core/analytics';

import type { HowToQuestionnaireChoicesPerStep } from './how-to-questionnaire.reducer';

const scope = '[how-to-questionnaire]';

export const trackStep = createAction(
  `${scope} Track Step`,
  props<{ trackingValues: ToolTrackValues }>()
);

export const addChoices = createAction(
  `${scope} Add Choices`,
  props<{ choices: HowToQuestionnaireChoicesPerStep }>()
);

export const removeChoices = createAction(
  `${scope} Remove Choices`,
  props<{ step: number }>()
);
