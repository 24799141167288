import { getSelectedContractGroupId } from '@contracting/entity';
import { getBackLink } from '@core/jss-routing';
import { createSelector } from '@ngrx/store';

import type { ChangeInvoiceAddressWithInfo } from '../payment-details.model';
import { getPaymentDetailsState } from './index';
import { getPaymentDetailsWithInfo } from './payment-details.selectors';
import { getUpdatePaymentDetailsWithStates } from './update-payment-method.selectors';

export const getChangeInvoiceAddressForm = createSelector(
  getPaymentDetailsState,
  (state) => state.changeInvoiceAddressForm
);

export const getChangeInvoiceAddressFormState = createSelector(
  getChangeInvoiceAddressForm,
  (state) => state.changeInvoiceAddressForm
);

export const getChangeInvoiceAddressTechnicalError = createSelector(
  getChangeInvoiceAddressForm,
  (state) => state.technicalError
);

export const getChangeInvoiceAddressWithInfo = createSelector(
  getUpdatePaymentDetailsWithStates,
  getChangeInvoiceAddressFormState,
  getPaymentDetailsWithInfo,
  getBackLink,
  getChangeInvoiceAddressTechnicalError,
  getSelectedContractGroupId,
  (
    status,
    formState,
    paymentDetails,
    backLink,
    technicalError,
    selectedContractGroupId
  ): ChangeInvoiceAddressWithInfo => {
    return {
      status,
      formState,
      invoiceAddress: paymentDetails.paymentDetails?.invoiceAddress,
      backLink,
      technicalError,
      selectedContractGroupId,
    };
  }
);
