/* eslint-disable rxjs-angular/prefer-takeuntil */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
  type ElementRef,
  type OnInit,
  AfterViewInit,
  DestroyRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeyboardNavigationDirective } from '@innogy/utils-accessibility';
import { UtilsDomModule } from '@innogy/utils-dom';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import {
  CHAR_MINIMAL_FOR_SEARCH,
  SEARCH_RESULTS_URL,
  SearchService,
} from '@innogy/search-services';
import { debounceTime, filter, Subject } from 'rxjs';

import { SearchPopoverComponent } from '../search-popover/search-popover.component';

@Component({
  selector: 'wl-search-bar',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    SearchPopoverComponent,
    KeyboardNavigationDirective,
    AutomationIdDirective,
    UtilsDomModule,
  ],
  templateUrl: './search-bar.component.html',
  styleUrls: [
    './search-bar.component.essent.scss',
    './search-bar.component.ed.scss',
  ],
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  readonly #router = inject(Router);
  readonly #focus$ = new Subject<boolean>();
  readonly #searchService = inject(SearchService);
  readonly #destroyRef = inject(DestroyRef);

  elementHasFocus$ = this.#focus$.pipe(debounceTime(150));

  @Output() removedFocus = new EventEmitter();

  @Input() autofocus = false;
  @Input() inheritQueryFromParams = false;

  @HostListener('focusin', [])
  applyFocus() {
    this.#focus$.next(true);
  }
  @HostListener('document:keydown.escape', [])
  @HostListener('focusout', [])
  removeFocus() {
    this.#focus$.next(false);
  }

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild(SearchPopoverComponent) popover!: SearchPopoverComponent;

  searchQuery = '';

  async ngOnInit() {
    if (this.inheritQueryFromParams) {
      await this.#useQueryFromParams();
    }

    this.#listenToFocusChanges();
  }

  async ngAfterViewInit() {
    if (this.autofocus) {
      this.searchInput.nativeElement.focus();
    }
  }

  submit(event: Event) {
    event.preventDefault();
    if (this.searchQuery.length >= CHAR_MINIMAL_FOR_SEARCH) {
      this.#router.navigate([SEARCH_RESULTS_URL], {
        queryParams: { q: this.searchQuery },
      });
    }
  }

  async #useQueryFromParams() {
    this.searchQuery = await this.#searchService.getSearchQueryFromParams();
  }

  #listenToFocusChanges() {
    this.elementHasFocus$
      .pipe(
        filter((focus) => !focus),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => this.removedFocus.emit());
  }
}
