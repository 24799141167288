@if (control && control.enabled) {
  <div class="form-section">
    <rfa-label [control]="control"></rfa-label>
    @if (newDesign) {
      <rfa-errors [control]="control" [newDesign]="true"></rfa-errors>
    }
    <select
      class="form-select"
      [id]="control.id"
      [formControl]="control"
      [wlTrackValueChange]="valueChangeTrackConfig()"
    >
      <option selected value="">{{ emptyValueText }}</option>
      @for (entry of entries; track entry) {
        <option [value]="entry.value">
          {{ entry.text }}
        </option>
      }
    </select>
    @if (!newDesign) {
      <rfa-errors [control]="control"></rfa-errors>
    }
  </div>
}
