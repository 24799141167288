import { Component, EventEmitter, Output, Input } from '@angular/core';
import {
  FontAwesomeModule,
  type SizeProp,
} from '@fortawesome/angular-fontawesome';
import { AutomationIdDirective } from '@innogy/utils-automation-id';

@Component({
  selector: 'wl-search-trigger',
  standalone: true,
  imports: [FontAwesomeModule, AutomationIdDirective],
  templateUrl: './search-trigger.component.html',
  styleUrls: ['./search-trigger.component.essent.scss'],
})
export class SearchTriggerComponent {
  @Output() triggered = new EventEmitter<Event>();

  @Input() size: SizeProp = '1x';

  clicked(event: Event) {
    this.triggered.emit(event);
  }
}
