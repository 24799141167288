import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { DeviceInfo } from '@innogy/shared/responsive-tools';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import type { BreadcrumbListStructuredData } from '@core/structured-data';
import { LocationService } from '@core/jss-seo';

import { generateStructuredDataFromRoutes } from './breadcrumb.helpers';
import type { BreadcrumbNavItem } from './breadcrumb.model';

@Component({
  selector: 'wl-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [
    './breadcrumb.component.essent.scss',
    './breadcrumb.component.ed.scss',
  ],
})
export class BreadcrumbComponent implements OnInit {
  @Input() overlap = false;
  @Input() greyBackground = false;
  @Input() whiteText = false;
  @Input() includeCurrentPage = true;
  @Input() routes: BreadcrumbNavItem[] = [];

  currentPage?: BreadcrumbNavItem;
  showFullPathOnMobile = false;
  structuredData?: BreadcrumbListStructuredData;

  MAX_MOBILE_ITEMS = 2;

  constructor(
    private readonly mediaObserver: MediaObserverService,
    private readonly location: LocationService
  ) {}

  deviceInfo$ = this.mediaObserver.deviceInfo$;

  ngOnInit() {
    this.structuredData = generateStructuredDataFromRoutes(
      this.routes,
      this.location.origin
    );

    if (this.routes.length > 0) {
      this.currentPage = this.routes.pop();
    }
  }

  onShowFullPathClick() {
    this.showFullPathOnMobile = true;
  }

  showFullPath(deviceInfo: DeviceInfo): boolean {
    return (
      !deviceInfo.isMobile ||
      this.routes.length <= this.MAX_MOBILE_ITEMS ||
      this.showFullPathOnMobile
    );
  }
}
