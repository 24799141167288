import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiPartialsModule } from '@legacy/common-ui-partials';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { CoreExperienceEditorComponentsModule } from '@core/experience-editor-components';
import { CoreJssUtilsModule } from '@core/jss-utils';
import { UtilsContentsquareModule } from '@legacy/utils-contentsquare';
import { AnalyticsMapPipe, UtilsModule } from '@innogy/utils-deprecated';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { AomContentComponent } from './aom/aom-content/aom-content.component';
import { AomCostTableComponent } from './aom/aom-content/aom-cost-table/aom-cost-table.component';
import { PriceOverviewTableComponent } from './aom/aom-content/aom-cost-table/price-overview-table/price-overview-table.component';
import { AomCostsPerUnitWithSummerWinterComponent } from './aom/aom-content/aom-costs-per-unit/aom-costs-per-unit-with-summer-winter/aom-costs-per-unit-with-summer-winter.component';
import { AomCostsPerUnitWithoutSummerWinterComponent } from './aom/aom-content/aom-costs-per-unit/aom-costs-per-unit-without-summer-winter/aom-costs-per-unit-without-summer-winter.component';
import { AomCostsPerUnitComponent } from './aom/aom-content/aom-costs-per-unit/aom-costs-per-unit.component';
import { DisclaimerComponent } from './aom/aom-content/disclaimer/disclaimer.component';
import { AomFooterComponent } from './aom/aom-footer/aom-footer.component';
import { AomModalComponent } from './aom/aom-modal/aom-modal.component';
import { OfferBreakdownRowComponent } from './offer/offer-breakdown-row/offer-breakdown-row.component';
import { OfferBreakdownComponent } from './offer/offer-breakdown/offer-breakdown.component';
import { OfferBudgetbillSmeComponent } from './offer/offer-budgetbill-sme/offer-budgetbill-sme.component';
import { OfferBudgetbillComponent } from './offer/offer-budgetbill/offer-budgetbill.component';
import { OfferLabelComponent } from './offer/offer-label/offer-label.component';
import { OfferMoreInfoBlockComponent } from './offer/offer-more-info-block/offer-more-info-block.component';
import { OfferUspsComponent } from './offer/offer-usps/offer-usps.component';
import { SelectCommodityPipe } from './pipe';
import { VerticalOfferModalPlaceholderComponent } from './vertical-offer/vertical-offer-modal-placeholder/vertical-offer-modal-placeholder.component';

@NgModule({
  imports: [
    CommonModule,
    JssModule,
    CommonUiSharedModule,
    FontAwesomeModule,
    CommonUiUtilityComponentsModule,
    CoreAnalyticsModule,
    NgbAccordionModule,
    TranslatePipe,
    TranslateDirective,
    UtilsModule,
    CoreExperienceEditorComponentsModule,
    CommonUiSharedModule,
    UtilsContentsquareModule,
    CommonUiPartialsModule,
    CoreJssUtilsModule,
  ],
  declarations: [
    AomModalComponent,
    AomContentComponent,
    VerticalOfferModalPlaceholderComponent,
    AomCostsPerUnitComponent,
    AomCostsPerUnitWithSummerWinterComponent,
    AomCostsPerUnitWithoutSummerWinterComponent,
    AomCostTableComponent,
    PriceOverviewTableComponent,
    DisclaimerComponent,
    AomFooterComponent,
    SelectCommodityPipe,
    OfferBudgetbillSmeComponent,
    OfferBreakdownComponent,
    OfferBudgetbillComponent,
    OfferLabelComponent,
    OfferBreakdownRowComponent,
    OfferUspsComponent,
    OfferMoreInfoBlockComponent,
  ],
  exports: [
    AomModalComponent,
    AomContentComponent,
    VerticalOfferModalPlaceholderComponent,
    AomCostsPerUnitComponent,
    SelectCommodityPipe,
    OfferBudgetbillSmeComponent,
    OfferBreakdownComponent,
    OfferBudgetbillComponent,
    OfferLabelComponent,
    OfferBreakdownRowComponent,
    OfferUspsComponent,
    OfferMoreInfoBlockComponent,
  ],
  providers: [AnalyticsMapPipe, CurrencyPipe],
})
export class BecomeACustomerSharedModule {}
