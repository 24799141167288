@if (placement$ | async; as placement) {
  @if (rendering?.fields; as fields) {
    <footer
      wlTrackImpression="navigation-impression"
      trackImpressionMode="any"
      [trackImpressionInfo]="{
        name: 'footernavigation',
        placement: placement,
      }"
    >
      <wl-footer-upper [rendering]="rendering"></wl-footer-upper>
      <wl-footer-lower [rendering]="rendering"></wl-footer-lower>
    </footer>
  }
}
