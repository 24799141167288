<ng-container *ngIf="viewModel$ | async as vm">
  <ng-container *ngIf="vm.allSalesFunnelsDisabled === true; else calculateForm">
    <wl-info-card
      [title]="vm.allSalesFunnelsDisabledMessageTitle"
      [message]="vm.allSalesFunnelsDisabledMessageText"
      [buttonLink]="vm.allSalesFunnelsDisabledMessageLink"
      type="warning"
    ></wl-info-card>
  </ng-container>
  <ng-template #calculateForm>
    <wl-calculate-form
      id="calculate-form"
      [rendering]="rendering"
      [content]="content"
      [formState]="vm.formState"
      [installationAddressStatus]="vm.installationAddressStatus"
      [installationAddressNotFound]="vm.installationAddressNotFound"
      [houseNumberExtensions]="vm.houseNumberExtensions"
      [installationAddresses]="vm.installationAddresses"
      [showManualHouseNumberAdditionInput]="vm.houseNumberAdditionInputEnabled"
      [showSpinnerWhenSubmitButtonDisabled]="
        vm.showSpinnerWhenSubmitButtonDisabled
      "
      [submitButtonDisabled]="vm.isSubmitButtonDisabled"
      [isInModal]="isInModal"
      (submitCalculate)="submit()"
      (openModal)="openModal($event)"
      (hideHousenumberInput)="hideManualHousenumberInput()"
      (submitAlternateAddress)="submitAlternateAddress()"
    ></wl-calculate-form>
  </ng-template>
</ng-container>
