<div class="mb-1">
  @if (rendering?.body) {
    <p>{{ rendering?.body }}</p>
  }
</div>
<form
  novalidate
  [ngrxFormState]="formState"
  [wlTrackStep]="{
    rendering: { componentName: 'verbruik inschatten' },
    step: 0.1,
    stepName: 'verbruik inschatten',
  }"
>
  <div class="mb-2">
    <div class="mb-2">
      @if (rendering?.businessTypeLabel) {
        <label for="business">{{ rendering?.businessTypeLabel }}</label>
      }
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'type pand',
          inputLabel: formControls.businessType?.value ?? '',
          sendValue: true,
          trackFocus: true,
          stepName: 'verbruik inschatten',
        }"
        [dropdownItems]="rendering?.businessType || []"
        [formsControl]="formControls.businessType"
        [placeholder]="rendering?.businessTypePlaceholder"
        [floatingLabel]="false"
        icon="user"
        labelForId="businessType"
        wlAutomationId="usageQuestionnaire.businessType"
      ></wl-dropdown>
    </div>
    @if (formControls.businessType | isInvalid) {
      <div class="col-12">
        <wl-input-error
          [messageField]="rendering?.errorMessage"
          [messageDescription]="rendering?.businessTypePlaceholder"
          [rendering]="{ componentName: 'verbruik inschatten' }"
          stepName="verbruik inschatten"
        ></wl-input-error>
      </div>
    }
  </div>
  @if (!gasHidden) {
    <div class="mb-2" wlAutomationId="energy-type-dropdown">
      <div class="mb-2">
        @if (rendering?.supplyTypeOptionsLabel) {
          <label for="supplyType">{{
            rendering?.supplyTypeOptionsLabel
          }}</label>
        }
        <wl-dropdown
          [wlTrackValueChange]="{
            step: 0.1,
            rendering: { componentName: 'verbruik inschatten' },
            label: 'type aansluiting',
            inputLabel: formControls.supplyType?.value | analyticsMap,
            sendValue: true,
            trackFocus: true,
            stepName: 'verbruik inschatten',
          }"
          [dropdownItems]="rendering?.supplyTypeOptions || []"
          [formsControl]="formControls.supplyType"
          [placeholder]="rendering?.supplyTypeOptionsPlaceHolder"
          [floatingLabel]="false"
          icon="plug"
          labelForId="supplyType"
          wlAutomationId="usageQuestionnaire.supplyType"
        ></wl-dropdown>
      </div>
      @if (formControls.supplyType | isInvalid) {
        <div class="col-12">
          <wl-input-error
            [messageField]="rendering?.errorMessage"
            [messageDescription]="rendering?.supplyTypeOptionsPlaceHolder"
            [rendering]="{ componentName: 'verbruik inschatten' }"
            stepName="verbruik inschatten"
          ></wl-input-error>
        </div>
      }
    </div>
  }
  <input
    type="submit"
    class="btn btn-primary text-center-ed w-100-ed"
    wlAutomationId="usageQuestionnaire.submit"
    [value]="rendering?.submitLabel"
  />
</form>
