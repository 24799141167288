import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import type { JssNavItem } from '@core/jss-models';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';

import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'wl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.essent.scss', './navbar.component.ed.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input()
  navItems!: JssNavItem[];

  @Input()
  language!: string;

  @Input()
  brandLogoLinkUrl!: string;

  readonly #searchFeatureEnabled = inject(ENVIRONMENT_CONFIG).search.enabled;

  showSearchHeader = inject(NavigationService).searchNavigationEnabled;

  public searchEnabled() {
    return this.#searchFeatureEnabled;
  }

  public showSearchbar() {
    this.showSearchHeader.set(true);
  }
}
