<table class="w-100">
  <caption></caption>
  <tbody>
    @if (dynamicPricingExpectedDeliveryRateTitle) {
      <tr>
        <th scope="col">
          <strong>{{ dynamicPricingExpectedDeliveryRateTitle }}</strong>
        </th>
      </tr>
    }
    <tr>
      <th>
        <strong>{{ energyTitle }}</strong>
      </th>
      <th></th>
    </tr>
    @for (tariffPeriod of tariffPeriods; track tariffPeriod) {
      <tr>
        <td [innerHTML]="tariffPeriod.description | safeHtml"></td>
        <td class="text-end align-top">
          {{ tariffPeriod.price | currency: 'EUR' : 'symbol' : '1.5' }}
        </td>
      </tr>
    }
    <tr class="mb-1">
      <td>
        <em>{{ fixedDeliveryCostLabel }}</em>
      </td>
      <td class="text-end align-top">
        <em>{{
          fixedDeliveryCostValue | currency: 'EUR' : 'symbol' : '1.5'
        }}</em>
      </td>
    </tr>

    @if (fixedRedeliveryCostScaleValue) {
      <tr wlAutomationId="fixedRedelivery.title">
        <td colspan="2">
          <strong>{{ fixedRedeliveryTitle }}</strong>
        </td>
      </tr>
      <tr wlAutomationId="fixedRedelivery.scale">
        <td colspan="2">
          {{ fixedRedeliveryCostScaleValue }}
        </td>
      </tr>
      <tr wlAutomationId="fixedRedelivery.value">
        <td>
          <em>{{ fixedRedeliveryCostLabel }}</em>
        </td>
        <td class="text-end align-top">
          <em>{{
            fixedRedeliveryCostValue | currency: 'EUR' : 'symbol' : '1.5'
          }}</em>
        </td>
      </tr>
    }
  </tbody>
</table>
