import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@core/analytics';
import { ScPaymentFormInput } from '@innogy/sitecore-forms/models';
import { ibanConverter } from '@innogy/utils-deprecated';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-payment[control][trackStep][fields]',
  templateUrl: './sc-payment.component.html',
})
export class ScPaymentComponent {
  @Input() control!: FormState<string>;
  @Input() fields!: ScPaymentFormInput;
  @Input() floatingLabel = false;
  @Input() trackStep!: TrackToolStep;
  public readonly _ibanConverter = ibanConverter;
}
