@if (offer) {
  <div class="d-flex flex-column" wlCSCapture>
    @if (
      offer | selectCommodity: energyType.ELECTRICITY;
      as electricityOverview
    ) {
      <wl-offer-breakdown-row
        [description]="rendering?.fields?.ElectricityLabel?.value"
        [value]="electricityOverview.totalPrice.expectedYearlyAmount"
      ></wl-offer-breakdown-row>
    }
    @if (offer | selectCommodity: energyType.GAS; as gasOverview) {
      <wl-offer-breakdown-row
        [description]="rendering?.fields?.GasLabel?.value"
        [value]="gasOverview.totalPrice.expectedYearlyAmount"
      ></wl-offer-breakdown-row>
    }
  </div>
}
