import { Component, Input, ViewChild } from '@angular/core';
import { ImageSettings } from '@legacy/common-ui-utility-components';
import { ColumnContext } from '@legacy/common-ui-shared-interfaces';

import { ComposableCardConfig } from '../../composables/card/card.model';
import type { HeaderSize } from '../basic-content-settings.model';
import {
  BasicContentValues,
  BasicContentSettings,
} from '../basic-content-settings.model';
import {
  standardHeadingSize,
  standardSubheadingSize,
} from '../basic-content.utils';

@Component({
  selector: 'wl-basic-content-variant',
  template: '',
})
export abstract class BasicContentVariantInheritableComponent {
  @Input() content?: BasicContentValues;
  @Input() settings?: BasicContentSettings;
  @Input() context?: ColumnContext;
  @Input() image?: ImageSettings;
  @Input() cardConfig?: ComposableCardConfig;

  @ViewChild('itemContainer') itemContainer?: any;
  public get headingSize(): HeaderSize {
    return standardHeadingSize(this.context?.colWidth);
  }

  public get subheadingSize(): HeaderSize {
    return standardSubheadingSize(this.context?.colWidth);
  }

  get isInColTwelve() {
    return this.context?.colWidth === 12;
  }

  get isInColSix() {
    return this.context?.colWidth === 6;
  }

  get isInColFour() {
    return this.context?.colWidth === 4;
  }

  get isInColThree() {
    return this.context?.colWidth === 3;
  }

  get isInColTwo() {
    return this.context?.colWidth === 2;
  }

  get isInSmallCol() {
    return this.context?.colWidth && this.context.colWidth <= 4;
  }

  get hasItemAlignedLeftOrRight() {
    return (
      this.settings?.itemAlignment === 'left' ||
      this.settings?.itemAlignment === 'right'
    );
  }

  get hasItemAlignedTopOrBetween() {
    return (
      this.settings?.itemAlignment === 'top' ||
      this.settings?.itemAlignment === 'between'
    );
  }

  get hasBackgroundOrBorderStyle() {
    return !!this.settings?.backgroundColor || !!this.settings?.borderStyle;
  }

  get hasNoItem() {
    const ngContentChild: HTMLElement | undefined =
      this.itemContainer?.nativeElement?.childNodes[0];
    return (
      this.settings?.variant === 'minimal' ||
      (ngContentChild?.localName === 'sc-placeholder' &&
        ngContentChild.children.length === 0) ||
      ngContentChild === null
    );
  }

  get buttonsAlignment(): string[] {
    return [
      this.content?.buttons?.alignVertically ? 'flex-column' : 'flex-row',
      this.content?.centerText
        ? 'align-items-center justify-content-center'
        : 'align-items-start justify-content-start',
    ];
  }
}
