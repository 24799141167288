/* eslint-disable rxjs-angular/prefer-takeuntil */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CHAR_LIMIT_FOR_SUGGESTIONS,
  SEARCH_DEBOUNCE_TIME,
  SearchService,
} from '@innogy/search-services';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { BehaviorSubject, debounceTime, Subject, tap } from 'rxjs';

import { SearchSuggestionComponent } from '../search-suggestion/search-suggestion.component';

type AutocompleteSuggestion = {
  id: string;
  value: string;
  query: string;
};

@Component({
  selector: 'wl-search-autocomplete',
  standalone: true,
  imports: [CommonModule, SearchSuggestionComponent, AutomationIdDirective],
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.essent.scss'],
})
export class SearchAutocompleteComponent implements OnInit {
  readonly #searchService = inject(SearchService);
  readonly #query$ = new Subject<string>();
  readonly #destroyRef = inject(DestroyRef);
  readonly autocompleteSuggestions$ = new BehaviorSubject<
    AutocompleteSuggestion[]
  >([]);

  suggestionCharLimit = CHAR_LIMIT_FOR_SUGGESTIONS;
  query = '';

  @Input({ required: true }) set searchQuery(query: string) {
    if (query) {
      this.query = query;
      this.#query$.next(query);
    }
  }

  ngOnInit() {
    this.#query$
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        tap(async (q) => {
          if (q === undefined || q.length < this.suggestionCharLimit) {
            this.autocompleteSuggestions$.next([]);
          } else {
            const { hits } = await this.#searchService.getQuerySuggestions(q);
            const suggestions = hits
              .filter((hit) => hit.query)
              .map((hit) => ({
                id: hit.objectID,
                value: hit._highlightResult?.query?.value ?? hit.query,
                query: hit.query,
              }));
            this.autocompleteSuggestions$.next(
              suggestions as AutocompleteSuggestion[]
            );
          }
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }
}
