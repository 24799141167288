<div
  class="text-danger input-error"
  [wlAutomationId]="automationId"
  [wlTrackError]="{
    rendering: rendering,
    field: field || label | lowercase | stripSymbols,
    description: description | lowercase | stripSymbols,
    type: errorType,
    stepName: stepName,
    step: step,
    toolName: toolName,
  }"
>
  @if (messageField) {
    <wl-rich-text-wrapper [field]="{ value: field }" [rendering]="rendering">
      <small></small>
    </wl-rich-text-wrapper>
  }
  @if (label) {
    <small>{{ label }}</small>
  }
</div>
