<div class="position-relative h-100">
  <ng-container>
    @if (variant === 'card') {
      <wl-commodity-offer-card
        [offer]="offer"
        [showLoadingState]="showLoadingState"
      >
        <div class="mb-2">
          <essent-commodity-offer-header
            [duration]="offer?.durationTitle"
            [addLabelSpacer]="ensureHorizontalAlignment"
            [priceDurationLabel]="content?.priceDurationLabel"
            [productTitle]="offer?.productTitle"
            [price]="offer?.expectedMonthlyAmount"
            [highlight]="offer?.isHighlightedLabel"
            [incentive]="offer?.incentiveTitle"
            [floatingIncentive]="false"
            [showPriceTag]="false"
          ></essent-commodity-offer-header>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <ng-container *ngTemplateOutlet="offerBody"></ng-container>
        </div>
      </wl-commodity-offer-card>
    }

    @if (variant === 'accordion') {
      <wl-commodity-offer-accordion
        [opened]="active"
        [showLoadingState]="showLoadingState"
        [offer]="offer"
      >
        <ng-template #accordionHeader let-collapsed="collapsed">
          <essent-commodity-offer-header
            [addLabelSpacer]="false"
            [duration]="offer?.durationTitle"
            [priceDurationLabel]="content?.priceDurationLabel"
            [price]="offer?.expectedMonthlyAmount"
            [incentive]="offer?.incentiveTitle"
            [productTitle]="offer?.productTitle"
            [highlight]="offer?.isHighlightedLabel"
            [floatingIncentive]="false"
            [showPriceTag]="collapsed"
          ></essent-commodity-offer-header>
        </ng-template>
        <ng-template #accordionBody>
          <ng-container *ngTemplateOutlet="offerBody"></ng-container>
        </ng-template>
      </wl-commodity-offer-accordion>
    }
  </ng-container>
</div>

<ng-template #offerBody>
  @if (offer) {
    <div class="mb-3">
      <span class="text-decoration-line-through">
        @if (offer.incentiveId) {
          {{ offer.beforeDiscountExpectedMonthlyAmount | roundedCurrency }}
        }
        <!-- Add a spacer to make sure the price tags align with each other -->
        @if (!offer.incentiveId && ensureHorizontalAlignment) {
          <div class="mt-3"></div>
        }
      </span>

      <div wlAutomationId="offer-budget-bill-amount-section">
        <span class="h1 text-brand">
          {{ offer.expectedMonthlyAmount | roundedCurrency }}
        </span>
        <span class="lh-sm">{{ content?.priceDurationLabel }}</span>
      </div>
      @if (offer.incentiveId) {
        <div>
          <span>
            {{ content?.budgetbillAmountLabel }}
          </span>
        </div>
      }
      @if (offer.incentiveId && content?.moreInfoPriceAndConditionLinkLabel) {
        <button
          class="btn p-0 border-0 btn-link mt-1"
          (click)="openMoreInfo.emit()"
          wlAutomationId="more-info-button"
          wlCSCapture
        >
          {{ content?.moreInfoPriceAndConditionLinkLabel }}
        </button>
      }
    </div>
  }
  @if (offer) {
    <div class="mt-auto">
      @if (offer.usps) {
        <div class="mb-2">
          <wl-commodity-usp-list [usps]="offer.usps"></wl-commodity-usp-list>
        </div>
      }
      <button
        class="btn btn-primary w-100"
        (click)="selectOffer.emit()"
        wlAutomationId="offer-submit-button"
        wlCSCapture
      >
        {{ content?.offerSubmitButtonLabel }}
      </button>
      <div class="d-flex justify-content-center">
        <button
          class="btn p-0 border-0 btn-link mt-2 mb-1"
          (click)="viewTariffs.emit()"
          wlAutomationId="info-card-button"
          wlCSCapture
        >
          {{ content?.tariffModalLinkTextLabel }}
        </button>
      </div>
    </div>
  }
</ng-template>
