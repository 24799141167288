<div class="row d-flex justify-content-center">
  @for (duration of durations; track trackByDuration($index, duration)) {
    <div class="col-auto">
      <button
        wlAutomationId="duration-filter-button"
        class="btn offer-duration--button rounded"
        [class.offer-duration--button__active]="
          activeDuration === duration.duration
        "
        (click)="changeDurationClick.emit(duration.duration)"
      >
        {{ duration.durationTitle }}
      </button>
    </div>
  }
</div>
