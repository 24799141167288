import { Injectable } from '@angular/core';
import { AddressTypes } from '@innogy/customer-details/store';
import type {
  SolarPanelsNewCustomerAddressCheckResponseV1,
  SolarPanelsNewCustomerConfirmationRequestBodyV1,
  SolarPanelsProductWithCalculations,
} from '@innogy/eplus/temporary-core-modules';
import {
  getSolarPanelsAddressCheckSuccess,
  postSolarPanelsConfirmation,
  postSolarPanelsConfirmationError,
  postSolarPanelsConfirmationSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { getQueryParameterHistory } from '@core/jss-routing';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import { selectUrl } from '@innogy/utils-state';
import { mapGenderToSalutation } from '@innogy/utils-deprecated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ResetAction } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';

import { selectGetSolarPanelsNewCustomerAddressCheckData } from '../solar-panels-api';
import { solarPanelsSelectorKey } from '../solar-panels-form.state';
import {
  clearSolarPanelsFunnelInitializedAction,
  handleSolarPanelsFunnelGenericErrorAction,
  onSolarPanelsFunnelNavigationAction,
  resetSolarPanelsProductSelectionAction,
  selectSelectedSolarPanelsProduct,
  selectSolarPanelsFunnelSettings,
} from '../solar-panels-funnel';
import { hideSolarPanelsProductOverviewAction } from '../solar-panels-products';
import {
  hideSolarPanelsSuitabilityCheckFormAction,
  resetSuitabilityFormAction,
} from '../solar-panels-suitability-check';
import {
  prefillSolarPanelsOrderFormAddressStateAction,
  resetSolarPanelsOrderFormAction,
} from './solar-panels-order-form.actions';
import {
  selectSolarPanelsOrderFormAddressFormState,
  selectSolarPanelsOrderFormPersonalDetailsFormState,
} from './solar-panels-order-form.selectors';
import {
  solarPanelsOrderAddressFormId,
  solarPanelsOrderPersonalDetailsFormId,
  solarPanelsOrderConfirmationFormId,
} from './solar-panels-order-form.reducer';

@Injectable()
export class SolarPanelsOrderFormEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);
  addressCheckData$ = this.store$.select(
    selectGetSolarPanelsNewCustomerAddressCheckData
  );
  selectedProduct$ = this.store$.select(selectSelectedSolarPanelsProduct);
  solarPanelsOrderFormPersonalDetails$ = this.store$.select(
    selectSolarPanelsOrderFormPersonalDetailsFormState
  );
  solarPanelsOrderFormAddress$ = this.store$.select(
    selectSolarPanelsOrderFormAddressFormState
  );
  queryParamHistory$ = this.store$.select(getQueryParameterHistory);

  currentUrl$ = this.store$.select(selectUrl);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  public readonly prefillSolarPanelsAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSolarPanelsAddressCheckSuccess),
      mergeMap((action) => [
        prefillSolarPanelsOrderFormAddressStateAction({
          payload: {
            addressType: AddressTypes.ADDRESS,
            city: action.payload.address.city,
            houseNumberAddition: action.payload.address.housenumberaddition,
            postalCode: action.payload.address.postalcode,
            communicationNumber: action.payload.address.housenumber,
            street: action.payload.address.street,
          },
        }),
      ])
    )
  );

  public readonly onResetSolarPanelsAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetSolarPanelsOrderFormAction),
      mergeMap(() => [
        new ResetAction(solarPanelsOrderAddressFormId),
        new ResetAction(solarPanelsOrderPersonalDetailsFormId),
      ])
    )
  );

  public readonly onSubmitSolarPanelsOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === solarPanelsOrderConfirmationFormId),
      concatLatestFrom(() => [
        this.addressCheckData$,
        this.funnelSettings$,
        this.solarPanelsOrderFormPersonalDetails$,
        this.solarPanelsOrderFormAddress$,
        this.currentUrl$,
        this.queryParamHistory$,
      ]),
      filter(
        ([, addressCheckData, funnelSettings]) =>
          !!addressCheckData && !!funnelSettings.selectedProduct
      ),
      mergeMap(
        ([
          _,
          _addressCheckData,
          funnelSettings,
          personalDetails,
          address,
          currentUrl,
          queryParamHistory,
        ]) => {
          const addressCheckData =
            _addressCheckData as SolarPanelsNewCustomerAddressCheckResponseV1;
          const selectedProduct =
            funnelSettings.selectedProduct as SolarPanelsProductWithCalculations;

          const { amount: numberOfPanels } = selectedProduct.recommendation;

          return [
            postSolarPanelsConfirmation({
              payload: {
                salutation:
                  mapGenderToSalutation(personalDetails.value.salutation) ?? '',
                initials: personalDetails.value.initials,
                lastNamePrefix: personalDetails.value.prefix,
                lastName: personalDetails.value.lastName,
                emailAddress: personalDetails.value.emailAddress,
                street: address.value.street,
                postalCode: address.value.postalCode,
                houseNumber:
                  address.value.communicationNumber?.toString() ?? '',
                houseNumberSuffix: address.value.houseNumberAddition,
                city: address.value.city,
                totalPlantSurface: addressCheckData.building.roofArea.total,
                yearlyIncome: selectedProduct.yearlySavings,
                energyConsumption:
                  addressCheckData.calculationParameters.consumption,
                numberOfPanels,
                panelType: selectedProduct.suiteCrmId,
                yearlyYield: selectedProduct.recommendation.yearlyYield,
                costExclVat: numberOfPanels * selectedProduct.unitPrice,
                typeOfContract: 'Koop',
                branch: 'adviesgesprek',
                telephoneNumber: personalDetails.value.phoneNumber,
                referrer: currentUrl,
                leadTracking: 'DirectOther',
                e2eTrackingId: funnelSettings.trackingId ?? '',
                gclid: queryParamHistory['gclid'] ?? '',
                homeBatteryInterest: personalDetails.value.homeBatteryInterest,
              } as SolarPanelsNewCustomerConfirmationRequestBodyV1,
            }),
          ];
        }
      )
    )
  );

  public readonly onSubmitSolarPanelsOrderFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postSolarPanelsConfirmationSuccess),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onSolarPanelsFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );

  public readonly resetSolarOrderFormAndProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ payload }, funnelSettings]) =>
          payload.routerState.url === funnelSettings.successPage?.href
      ),
      // Even though we wait for navigated events, apparently
      // an extra delay is needed in order to prevent firing tool-step events
      // after form rest on the former page.
      // .5s is chosen arbitrarily, yet seems to work even under 3g conditions.
      delay(500),
      mergeMap(() => [
        resetSuitabilityFormAction(),
        hideSolarPanelsSuitabilityCheckFormAction(),
        resetSolarPanelsProductSelectionAction(),
        hideSolarPanelsProductOverviewAction(),
        resetSolarPanelsOrderFormAction(),
        clearSolarPanelsFunnelInitializedAction(),
        resetProgressiveForm({ formId: solarPanelsSelectorKey }),
      ])
    )
  );

  public readonly onSubmitSolarPanelsOrderFormError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postSolarPanelsConfirmationError),
      tap(({ payload }) => {
        console.error('Error while submitting solar order', payload.error);
      }),
      map(handleSolarPanelsFunnelGenericErrorAction)
    )
  );
}
