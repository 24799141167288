<div class="form-check">
  @if (formsControl) {
    <input
      [wlTrackValueChange]="{
        rendering: rendering,
        label: fieldName || '',
        inputLabel: formsControl.value?.toString(),
        sendValue: sendValue,
        hashValue: hashValue,
        trackFocus: false,
        stepName: stepName,
        step: step || 0,
      }"
      [id]="formsControl?.id"
      [name]="formsControl?.id"
      [ngrxFormControlState]="formsControl"
      [wlValidationClasses]="showValidation ? formsControl : undefined"
      type="checkbox"
      class="form-check-input"
      wlHasValueClass="has-value"
      [ngrxEnableFocusTracking]="true"
      [wlAutomationId]="formsControl?.id + '-input'"
    />
  }
  <label
    class="form-check-label"
    [for]="formsControl?.id"
    [wlAutomationId]="formsControl?.id"
  >
    {{ label }}
    <ng-content></ng-content>
  </label>
  @if (tooltip) {
    <wl-tooltip class="ms-1" [tooltip]="{ description: tooltip }"></wl-tooltip>
  }
</div>
