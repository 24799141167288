@if (offer) {
  <div class="d-flex flex-column">
    <div
      class="product-breakdown-table mb-2"
      [class.mb-3]="!content?.offerSubmitDescriptionLabel"
    >
      <ed-commodity-offer-product-breakdown-row
        [description]="content?.expectedYearlyCostLabel"
        [value]="offer.beforeDiscountExpectedYearlyAmount"
      ></ed-commodity-offer-product-breakdown-row>
      @if (offer.discountPrice) {
        <ed-commodity-offer-product-breakdown-row
          [description]="content?.cashbackLabel"
          rowStyle="discount"
          [prefix]="'-'"
          [value]="offer.discountPrice"
        ></ed-commodity-offer-product-breakdown-row>
      }
      @if (offer.expectedYearlyAmount) {
        <ed-commodity-offer-product-breakdown-row
          [description]="yearlyCostsLabel(offer)"
          rowStyle="darker"
          textStyle="bold"
          [value]="offer.expectedYearlyAmount"
        ></ed-commodity-offer-product-breakdown-row>
      }
    </div>

    @if (showMemberGetMember && content?.memberGetMemberSharedValue; as value) {
      <div class="d-flex justify-content-center">
        <div class="referral-sticker px-2">
          <span class="referral-sticker__text">
            {{
              value
                | interpolate: { variables: { mgmValue: getMGMValue(offer) } }
            }}
          </span>
        </div>
      </div>
    }
    @if (content?.offerSubmitDescriptionLabel) {
      <p>
        <span class="description">{{
          content?.offerSubmitDescriptionLabel
        }}</span>
        @if (!!content?.offerSubmitDescriptionTooltip) {
          <wl-tooltip
            [tooltip]="{ description: content?.offerSubmitDescriptionTooltip }"
          ></wl-tooltip>
        }
      </p>
    }
  </div>
}
