@if (progressiveFormState) {
  <div
    class="card rounded p-3 mb-2 simple-prog-form-card"
    [progressiveFormStep]="progressiveFormState"
    [id]="progressiveFormState.id"
    [wlAutomationId]="progressiveFormState.id + '-form-card'"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="progressive-form-header-area">
        <h3 class="h4 my-0 d-flex align-items-center" wlCSCapture>
          @if (progressiveFormState | showProgressiveFormsIcon) {
            <fa-icon class="me-1" icon="check-circle"></fa-icon>
          }
          <!-- the input content.title comes as string -->
          <span *wlGenericRichText="{ value: content?.title }"></span>
        </h3>
        @if (totalSteps && !progressiveFormState.isInert) {
          <span class="me-1">
            ({{ content?.stepNumber }}/{{ totalSteps }})
          </span>
        }
      </div>
      <div>
        @if (progressiveFormState | showProgressiveFormsEditButton) {
          <div
            class="ms-2"
            [wlAutomationId]="progressiveFormState.id + '-edit-button'"
            wlCSCapture
          >
            <button
              class="btn btn-sm btn-outline-primary d-none d-sm-block"
              [editProgressiveFormStep]="progressiveFormState"
            >
              {{ 'Change' | translate }}
            </button>
            <button
              class="btn btn-sm btn-icon btn-outline-primary d-sm-none"
              [editProgressiveFormStep]="progressiveFormState"
            >
              <fa-icon icon="pen"></fa-icon>
            </button>
          </div>
        }
      </div>
    </div>
    <ng-container>
      @if (progressiveFormState.isActive) {
        <p class="my-0" wlCSCapture>
          {{ content?.subtitle }}
        </p>
      }
      @if (progressiveFormState | showProgressiveFormsSeparator) {
        <hr class="my-2" wlCSCapture />
      }
    </ng-container>
    <div class="simple-progressive-form-card--form">
      <ng-container [ngTemplateOutlet]="formRef"></ng-container>
    </div>
    <div
      class="simple-progressive-form-card--summary"
      [ngbCollapse]="!(progressiveFormState | showProgressiveFormsSummary)"
    >
      <div [wlAutomationId]="progressiveFormState.id + '-summary'">
        <ng-container *ngTemplateOutlet="summaryRef ?? emptyRef"></ng-container>
      </div>
    </div>
  </div>
}

<ng-template #formRef>
  @if (progressiveFormState | showProgressiveFormsForm) {
    <ng-container
      [wlTrackStep]="{
        rendering: { componentName: trackingConfig?.toolName ?? '' },
        step: trackingConfig?.step ?? 0,
        stepName: trackingConfig?.stepName,
        toolName: trackingConfig?.toolName,
      }"
    ></ng-container>
  }
  @if (progressiveFormState) {
    <form
      novalidate
      [ngrxFormState]="progressiveFormState.formState"
      [focusOnFirstChildControl]="progressiveFormState"
      [enableFocusing]="enableAutomaticFocusing"
      [ngbCollapse]="!(progressiveFormState | showProgressiveFormsForm)"
      (shown)="scrollIntoViewIfEnabled()"
    >
      <div class="mb-4" [wlAutomationId]="progressiveFormState.id + '-content'">
        <ng-container *ngTemplateOutlet="contentRef ?? emptyRef"></ng-container>
      </div>
      <ng-container
        *ngTemplateOutlet="
          progressiveFormState.isUnsubmitted ? pristineButton : editingButtons
        "
      ></ng-container>
    </form>
  }
</ng-template>

<ng-template #emptyRef></ng-template>

<ng-template #pristineButton>
  @if (progressiveFormState) {
    <button
      type="submit"
      class="btn btn-primary progressive-form--submit-button"
      [submitProgressiveFormStep]="progressiveFormState"
      [asyncSubmission]="asyncSubmission"
      (submitted)="submitted.emit('submit')"
      [wlAutomationId]="progressiveFormState.id + '-submit-button'"
      wlCSCapture
    >
      {{ content?.submitButtonText }}
      @if (!progressiveFormState.hasPendingAsyncActions) {
        <fa-icon [icon]="content?.submitButtonIcon ?? 'arrow-down'"></fa-icon>
      }
      @if (progressiveFormState.hasPendingAsyncActions) {
        <fa-icon icon="spinner-third" animation="spin"></fa-icon>
      }
    </button>
  }
</ng-template>

<ng-template #editingButtons>
  @if (progressiveFormState) {
    <button
      type="submit"
      class="btn btn-primary progressive-form--submit-button"
      [submitProgressiveFormStep]="progressiveFormState"
      [asyncSubmission]="asyncSubmission"
      [updateFormState]="true"
      (submitted)="submitted.emit('edit')"
      [wlAutomationId]="progressiveFormState.id + '-update-button'"
      wlCSCapture
    >
      {{ 'SaveChanges' | translate }}
      @if (!progressiveFormState.hasPendingAsyncActions) {
        <fa-icon [icon]="content?.submitButtonIcon ?? 'arrow-down'"></fa-icon>
      }
      @if (progressiveFormState.hasPendingAsyncActions) {
        <fa-icon icon="spinner-third" animation="spin"></fa-icon>
      }
    </button>
    <button
      type="button"
      class="btn btn-link progressive-form--submit-button mt-2 mt-sm-0"
      [class.pe-none]="progressiveFormState.hasPendingAsyncActions"
      [restoreProgressiveFormStep]="progressiveFormState"
      [wlAutomationId]="progressiveFormState.id + '-revert-button'"
      wlCSCapture
    >
      {{ 'CancelChanges' | translate }}
    </button>
  }
</ng-template>
