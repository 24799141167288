import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { emobilityActions } from '@innogy/emobility-shared';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs';

@Injectable()
export class QuotationAcceptanceEffects {
  acceptQuotationSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(emobilityActions.postExternalPartnerQuotationV1.successAction),
        tap((action) => {
          if (action.actionId) {
            this.router.navigateByUrl(action.actionId);
          }
        })
      ),
    { dispatch: false }
  );
  constructor(
    private readonly actions: Actions,
    private readonly router: Router
  ) {}
}
