<div class="header-tools">
  <span class="h4 header-tools__header d-none d-lg-block" wlCSCapture>{{
    title
  }}</span>
  @if (isLoggedIn) {
    <div
      class="header-tools__user-details"
      wlAutomationId="header-tools-user-details"
    >
      <div subtitle>
        <fa-icon
          icon="user-check"
          class="header-tools__icon header-tools__icon--hidden"
        ></fa-icon>
        <span>{{ fullName }}</span>
      </div>
    </div>
    <div class="header-tools__content">
      <nav class="d-block d-lg-none">
        <wl-sidebar-nav
          [language]="language"
          [showCategories]="showCategories"
          [items]="navItems"
          (navItemClick)="navItemClick.emit(navItem)"
          [topNavigation]="true"
          wlCSCapture
        ></wl-sidebar-nav>
      </nav>
      <div
        class="header-tools--buttons"
        [ngClass]="{
          'bg-white': showCategories,
        }"
      >
        @if (hasMultipleAccounts) {
          <a
            role="button"
            wlAutomationId="service-navigation-switch-account"
            class="header-tools__link header-tools__link--accentuated header-tools__link--large header-tools__switch-account-button"
            [href]="switchAccountEndpointUrl"
          >
            <fa-icon icon="random" class="header-tools__icon"></fa-icon>
            <span class="header-tools__link-label">{{
              switchAccountButtonText
            }}</span>
          </a>
        }

        <button
          wlAutomationId="service-navigation-logout"
          wlCSCapture
          type="button"
          class="btn btn-link header-tools__link header-tools__link--accentuated header-tools__link--large header-tools__logout-button"
          (click)="logout()"
        >
          <fa-icon
            icon="right-from-bracket"
            class="header-tools__icon"
          ></fa-icon>
          <span class="header-tools__link-label">{{ logOutButtonText }}</span>
        </button>
      </div>
    </div>
  } @else {
    <a
      [href]="loginUrl"
      class="header-tools__link header-tools__link--accentuated header-tools__login-button"
      wlAutomationId="service-navigation-login"
    >
      <fa-icon
        icon="user"
        class="header-tools__icon header-tools__icon--hidden"
      ></fa-icon>
      <span class="header-tools__link-label">{{ loginUrlLabel }}</span>
    </a>
  }
</div>

<ng-template #login>
  <a
    [href]="loginUrl"
    class="header-tools__link header-tools__link--accentuated header-tools__login-button"
    wlAutomationId="service-navigation-login"
  >
    <fa-icon
      icon="user"
      class="header-tools__icon header-tools__icon--hidden"
    ></fa-icon>
    <span class="header-tools__link-label">{{ loginUrlLabel }}</span>
  </a>
</ng-template>
