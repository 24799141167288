<div
  class="commodity-offer-header"
  [ngClass]="'commodity-offer-header--' + alignment"
>
  @if (brand === 'essent') {
    <h1 class="h2 mb-2" wlCSCapture>
      {{ headerTitle }}
    </h1>
  }

  @if (brand === 'ed') {
    @if (showBrandHeader) {
      <wl-partial-brand-header
        [inlineHeadings]="false"
        [heading]="headerTitle"
        [stretchBackdrop]="true"
        [class.d-xl-none]="alignment === 'horizontal'"
      >
      </wl-partial-brand-header>
      <h1
        class="h1 text-center mb-4 d-none"
        [class.d-xl-block]="alignment === 'horizontal'"
        wlCSCapture
      >
        {{ headerTitle }}
      </h1>
    } @else {
      <h1 class="h2 mb-2 dark-header" wlCSCapture>
        {{ headerTitle }}
      </h1>
    }
  }
</div>
