import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { StoreModule } from '@ngrx/store';

import { EmailQuotationReactionEffects } from './+state/email-quotation-reaction.effects';
import { emailQuotationReactionReducer } from './+state/email-quotation-reaction.reducer';
import { quotationReactionSelectorKey } from './+state/email-quotation-reaction.store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      quotationReactionSelectorKey,
      emailQuotationReactionReducer
    ),
  ],
  providers: [provideBootstrapEffects([EmailQuotationReactionEffects])],
})
export class EmailQuotationReactionStoreModule {}
