import {
  type ComponentFields,
  type ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-angular';

export class MonthlyBudgetPaymentSection {
  fixedMonthlyBudgetPaymentHeading: string;
  fixedMonthlyBudgetPaymentParagraph: string;
  dynamicMonthlyBudgetPaymentHeading: string;
  dynamicMonthlyBudgetPaymentParagraph: string;

  constructor(rendering: ComponentRendering | ComponentFields) {
    this.fixedMonthlyBudgetPaymentHeading = getFieldValue(
      rendering,
      'FixedMonthlyBudgetPaymentHeading',
      ''
    );
    this.fixedMonthlyBudgetPaymentParagraph = getFieldValue(
      rendering,
      'FixedMonthlyBudgetPaymentParagraph',
      ''
    );
    this.dynamicMonthlyBudgetPaymentHeading = getFieldValue(
      rendering,
      'DynamicMonthlyBudgetPaymentHeading',
      ''
    );
    this.dynamicMonthlyBudgetPaymentParagraph = getFieldValue(
      rendering,
      'DynamicMonthlyBudgetPaymentParagraph',
      ''
    );
  }
}
