@if (autocompleteSuggestions$ | async; as suggestions) {
  @if (suggestions?.length) {
    <p class="visually-hidden" id="autocompleteLabel">
      Kies een zoeksuggestie:
    </p>
    <ul
      class="autocomplete__suggestions"
      aria-live="polite"
      aria-labelledby="autocompleteLabel"
    >
      @for (suggestion of suggestions; track suggestion.id) {
        <li
          tabindex="-1"
          class="autocomplete__suggestion"
          [wlAutomationId]="'search-typeahead-suggestion-' + $index"
        >
          <wl-search-suggestion [query]="suggestion.query">
            <span
              class="autocomplete__text"
              [innerHTML]="suggestion.value"
            ></span>
          </wl-search-suggestion>
        </li>
      }
    </ul>
  } @else if (query.length >= suggestionCharLimit) {
    <wl-search-suggestion [query]="query">
      Zoeken naar {{ query }}
    </wl-search-suggestion>
  }
}
