@if (label && !floatingLabel) {
  <label
    [for]="formsControl?.id"
    wlAutomationId="non-floating-label"
    class="fw-bold mb-1"
    >{{ label }}</label
  >
}
<div
  class="position-relative dropdown__select"
  [class.floating-label]="label && floatingLabel"
>
  @if (selectedOption?.icon || icon) {
    <div
      class="dropdown__select-icon d-flex flex-column justify-content-center"
    >
      <fa-icon
        class="pe-2"
        [icon]="selectedOption?.icon || icon"
        [class.color-danger]="formsControl | isInvalid: false"
      ></fa-icon>
    </div>
  }
  <div
    class="dropdown__select-handle d-flex flex-column justify-content-center"
  >
    <fa-icon
      class="pe-2"
      icon="chevron-down"
      [rotate]="ngSelectComponent.isOpen ? 180 : undefined"
    ></fa-icon>
  </div>
  <ng-select
    #ngSelectComponent
    [items]="dropdownItems"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [searchable]="false"
    [wlValidationClasses]="formsControl"
    [omitValidationClasses]="omitValidationClasses"
    [class.ng-select--icon]="icon"
    [class.ng-select--label]="label"
    [class.dropdown__value--position-lower]="label && floatingLabel"
    [class.dropdown__value--spacing-left]="selectedOption?.icon"
    [(ngModel)]="currentValue"
    (open)="onOpen()"
    (close)="onClose()"
    (change)="onSelect($event)"
    bindLabel="label"
    bindValue="value"
    [labelForId]="labelForId"
    [attr.id]="label ? formsControl?.id : null"
    [wlAutomationId]="formsControl?.id"
  >
    <ng-template ng-option-tmp let-item="item">
      <div
        class="d-flex dropdown-item"
        [wlAutomationId]="formsControl?.id + '-' + item.label"
      >
        @if (anyIcons) {
          <div
            class="icon-placeholder d-flex align-items-center justify-content-center"
          >
            @if (item.icon) {
              <fa-icon class="ng-fa-icon me-1" [icon]="item.icon"></fa-icon>
            }
          </div>
        }
        {{ item.label }}
      </div>
    </ng-template>
  </ng-select>
  @if (label && floatingLabel) {
    <label
      class="fixed-padding dropdown__floating-label"
      [class.focussed]="currentValue !== null"
      [class.dropdown__floating-label--spacing-left]="
        icon || selectedOption?.icon
      "
      [for]="formsControl?.id"
      >{{ label }}</label
    >
  }
</div>
