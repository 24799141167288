<div class="d-flex" wlAutomationId="filter-button">
  @for (entry of entries; track trackById($index, entry)) {
    <button
      (click)="clicked(entry)"
      class="filter-button"
      [ngClass]="{
        'filter-button--selected': selected?.id === entry.id,
        'flex-grow-1': styleIsStretched,
      }"
      [wlAutomationId]="'filter-button-' + entry.id"
    >
      @if (entry.icon) {
        <span class="d-flex justify-content-center">
          <fa-icon [icon]="entry.icon" class="me-0 me-md-1"></fa-icon>
          <span class="d-none d-xl-block d-md-block d-lg-none">{{
            entry.text
          }}</span>
        </span>
      } @else {
        {{ entry.text }}
      }

      <ng-template #text>
        {{ entry.text }}
      </ng-template>
    </button>
  }
</div>
