@if (formState) {
  <wl-progressive-form-step-card
    [title]="title"
    [subtitle]="subtitle"
    [formStepId]="formState?.id"
    [isSkipped]="isSkipped$ | async"
    [isValid]="isValidAndSubmitted"
    [isSubmitted]="isValidAndSubmitted"
    (setEditingFormStep)="setEditingFormStep()"
    [changeButtonText]="'Change' | translate"
    [canBeEdited]="canBeEdited$ | async"
    [isActive]="isCurrentFormStep$ | async"
    [isFutureStep]="isFutureFormStep$ | async"
    [hideSummary]="hideSummary"
    [isReadonly]="isReadonly$() | async"
    [showWhenFutureStep]="showWhenFutureStep"
    [showWhenSkipped]="showWhenSkipped"
    [showSeperator]="showSeperator"
    [showProgression]="showProgression"
    [currentStep]="currentStepIndex$ | async"
    [totalSteps]="totalSteps$ | async"
  >
    <div [class]="showSeperator ? 'mt-0' : 'mt-2'">
      <form
        class="mb-2"
        novalidate
        [ngrxFormState]="formState"
        [ngbCollapse]="isFormCollapsed$() | async"
        (shown)="formCollapseAnimationFinished(false)"
        (hidden)="formCollapseAnimationFinished(true)"
      >
        <!-- The collapse only hides the form visually. The tracking should only be rendered when the form is active -->
        @if (currentStepIndex$ | async; as currentStepIndex) {
          @if (isCurrentFormStep$ | async) {
            <div
              [wlTrackStep]="{
                rendering: rendering,
                step: stepNumber ?? currentStepIndex,
                stepName: stepName,
                toolName: toolName,
              }"
            ></div>
          }
        }
        @if (formBody) {
          <ng-container
            [ngTemplateOutlet]="formBody.templateRef"
          ></ng-container>
        }
        @if (showSubmitButton) {
          <wl-progressive-form-submit-button
            [isDisabled]="isDisabled"
            [submitButtonAutomationId]="submitButtonAutomationId"
            [submitButtonText]="
              (isCurrentEditedFormStep$ | async)
                ? ('SaveChanges' | translate)
                : submitButtonText
            "
            [submitButtonIcon]="submitButtonIcon"
            [isEditingCurrentStep]="isCurrentEditedFormStep$ | async"
            (submitProgessiveForm)="submitForm()"
            (cancelEditingFormStep)="cancelEditingFormStep()"
          ></wl-progressive-form-submit-button>
        }
      </form>

      <!-- Collapse the summary if this is the current visible form step -->
      @if (
        ((isInExperienceEditor$ | async) === false && isValidAndSubmitted) ||
        (isReadonly$() | async)
      ) {
        <div
          [ngbCollapse]="isSummaryCollapsed$() | async"
          wlAutomationId="progressiveFormStepWrapper.summary"
        >
          @if (formSummary) {
            <ng-container
              [ngTemplateOutlet]="formSummary.templateRef"
            ></ng-container>
          }
        </div>
      }
    </div>
  </wl-progressive-form-step-card>
}
