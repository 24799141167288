<wl-experience-editor>
  <ng-template wlLive>
    @if (placement$ | async; as placement) {
      <wl-button
        [config]="config"
        [rendering]="rendering"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="config?.text"
        [trackImpressionLinkurl]="config?.url"
        [trackImpressionInfo]="rendering"
      ></wl-button>
    }
  </ng-template>
  <ng-template wlPreview>
    @if (config?.icon) {
      <a role="button" class="btn btn--icon-prefixed" [ngClass]="config?.class">
        <em [ngClass]="config?.icon"></em>
        <span *scText="rendering?.fields?.text"></span>
      </a>
    } @else {
      <a
        role="button"
        class="btn btn-primary"
        [ngClass]="config?.class"
        *scText="rendering?.fields?.text"
      ></a>
    }
  </ng-template>
</wl-experience-editor>
