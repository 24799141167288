import { Status, type AsyncState } from '@essent/common';
import { createReducer, on } from '@ngrx/store';
import type {
  AddressCheckResult,
  GetAddressCheckPayload,
} from '@essent/address';
import type { ScLinkField } from '@core/jss-models';

import {
  getAddress,
  getAddressError,
  getAddressSuccess,
  submitAdviceForm,
  submitAdviceFormError,
  submitAdviceFormSuccess,
} from './boilers.actions';

export const boilersSelectorKey = 'eplus-boilers';

export interface BoilersState {
  selectedPostalCode: GetAddressCheckPayload | null;
  addressCheck: AsyncState<AddressCheckResult>;
  adviceFormSubmitStatus: AsyncState<void>;
  adviceFormRedirectUrl: ScLinkField | null;
}

export const initialState: BoilersState = {
  selectedPostalCode: null,
  addressCheck: { status: Status.IDLE },
  adviceFormSubmitStatus: { status: Status.IDLE },
  adviceFormRedirectUrl: null,
};

export const boilersReducer = createReducer(
  initialState,
  on(getAddress, (state, { postcode, houseNumber }) => ({
    ...state,
    selectedPostalCode: { postcode, houseNumber },
    addressCheck: { status: Status.PENDING },
  })),
  on(getAddressSuccess, (state, { street, city, country }) => ({
    ...state,
    addressCheck: {
      status: Status.SUCCESS,
      data: { street, city, country },
    },
  })),
  on(getAddressError, (state, error) => ({
    ...state,
    addressCheck: {
      status: Status.ERROR,
      error: error.response,
    },
  })),
  on(submitAdviceForm, (state, params) => ({
    ...state,
    adviceFormSubmitStatus: { status: Status.PENDING },
    adviceFormRedirectUrl: params.onSuccessRedirectPage,
  })),
  on(submitAdviceFormSuccess, (state) => ({
    ...state,
    adviceFormSubmitStatus: { status: Status.IDLE },
  })),
  on(submitAdviceFormError, (state) => ({
    ...state,
    adviceFormSubmitStatus: { status: Status.ERROR },
  }))
);
