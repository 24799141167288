import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core';
import { AppConfigService, ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import type { JssDictionary } from '@core/jss-models';
import { JSS_DICTIONARY, JssDictionaryResult } from '@core/jss-models';
import type { TranslateLoader } from '@ngx-translate/core';
import { catchError, EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { appNamespace } from './defaults';

@Injectable()
export class JssTranslationLoaderService implements TranslateLoader {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
    @Optional()
    @Inject(JSS_DICTIONARY)
    protected dictionary: JssDictionaryResult,
    private readonly errorHandler: ErrorHandler
  ) {}

  getTranslation(lang: string): Observable<JssDictionary> {
    const jssDictionary$ = this.loadJssDictionary(lang).pipe(
      catchError((err: unknown) => {
        this.errorHandler.handleError(err);
        return of({});
      })
    );

    const jsonTranslations$ = from(this.loadAppJsonTranslations(lang)).pipe(
      catchError((err: unknown) => {
        this.errorHandler.handleError(err);
        return of({});
      })
    );

    return forkJoin([jssDictionary$, jsonTranslations$]).pipe(
      map(([jssDictionary, jsonTranslations]) => ({
        ...jssDictionary,
        ...jsonTranslations,
      }))
    );
  }

  private loadJssDictionary(lang: string): Observable<JssDictionary> {
    if (this.dictionary) {
      return of(this.dictionary.phrases);
    }

    if (this.config.sitecore == null) {
      return EMPTY;
    }

    const options = {
      params: {
        sc_apikey: this.config.sitecore.apiKey,
        sc_site: this.config.app,
      },
    };

    return this.http
      .get<JssDictionaryResult>(
        `${this.config.sitecore.apiHost}${this.appConfigService.basePath}/sitecore/api/jss/dictionary/${this.config.app}/${lang}`,
        options
      )
      .pipe(
        map((dictionary) => dictionary.phrases),
        retry(1)
      );
  }

  private async loadAppJsonTranslations(lang: string): Promise<JssDictionary> {
    try {
      const translationsModule = await import(`./i18n/${lang}.json`);
      let translations = translationsModule.default || translationsModule;

      const brand = this.config.brand;

      try {
        const brandTranslationsModule = await import(
          `./i18n/${brand}.${lang}.json`
        );
        const brandTranslations =
          brandTranslationsModule.default || brandTranslationsModule;

        translations = {
          ...translations,
          ...brandTranslations,
        };
      } catch (brandError) {
        this.errorHandler.handleError(
          new Error(
            `Error loading '${brand}' translations for language '${lang}'`
          )
        );
      }
      return { [appNamespace]: translations };
    } catch (error) {
      this.errorHandler.handleError(
        new Error(
          `Error loading Multi-label translations for language '${lang}'`
        )
      );
    }
    return {};
  }
}
