import camelCase from 'lodash/camelCase';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';

import type { GraphqlJSSField } from './model/graphql-jss-fieldtypes';
import {
  isScMultiListField,
  isScValue,
  isScNestedItem,
  isScNestedMultiListField,
  isScNestedSimpleValueField,
} from './model/graphql-jss-fieldtypes';

export const sitecoreFieldsAsObject = <T>(fields: GraphqlJSSField[]): T => {
  const obj: any = {};
  fields?.forEach((field) => {
    if (isScMultiListField(field)) {
      obj[camelCase(field.name)] = field.targetItems.map((item) =>
        sitecoreFieldsAsObject(item.fields)
      );
    }
    if (field.jss) {
      if (isScValue(field.jss)) {
        obj[camelCase(field.name)] = field.jss.value;
      }
      if (isScNestedItem(field.jss)) {
        obj[camelCase(field.name)] =
          field.jss?.fields && sitecoreNestedFieldsAsObject(field.jss?.fields);
      }
    }
  });
  return obj as unknown as T;
};

export const sitecoreNestedFieldsAsObject = (nestedFields: {
  [key: string]: any;
}): { [key: string]: any } => {
  const obj: any = {};
  Object.keys(nestedFields).forEach((key) => {
    const currentItem = nestedFields[key];

    if (currentItem) {
      if (isScNestedMultiListField(currentItem)) {
        obj[camelCase(key)] = currentItem.map(
          (item) => item.fields && sitecoreNestedFieldsAsObject(item.fields)
        );
      }

      if (isScNestedSimpleValueField(currentItem)) {
        const nestedNestedFields = currentItem.fields;
        if (nestedNestedFields) {
          obj[camelCase(key)] = getFieldValue(nestedNestedFields, 'value');
        }
      } else if (isScNestedItem(currentItem)) {
        const nestedNestedFields = currentItem.fields;
        if (nestedNestedFields) {
          obj[camelCase(key)] =
            sitecoreNestedFieldsAsObject(nestedNestedFields);
        }
      }

      if (isScValue(currentItem)) {
        obj[camelCase(key)] = nestedFields[key].value;
      }
    } else {
      obj[camelCase(key)] = undefined;
    }
  });
  return obj;
};
