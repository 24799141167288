@switch (mode) {
  @case ('full') {
    <ng-container *ngTemplateOutlet="cardFull"></ng-container>
  }
  @case ('body') {
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  }
  @case ('content') {
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  }
}

<ng-template #cardFull>
  <wl-partial-card class="mb-3">
    <ng-container slot="content">
      <ng-container *ngTemplateOutlet="cardContent"></ng-container>
    </ng-container>
  </wl-partial-card>
</ng-template>

<ng-template #cardContent>
  @if (title || titleTranslationKey) {
    <div class="d-flex align-items-baseline" wlAutomationId="error-card-title">
      @if (icon) {
        <fa-icon [icon]="icon" class="me-1"></fa-icon>
      }
      @if (title) {
        <h3 [class.mb-0]="showDivider" *scText="title" wlCSCapture></h3>
      }
      @if (titleTranslationKey) {
        <h3 [class.mb-0]="showDivider" wlCSCapture>
          {{ titleTranslationKey | translate }}
        </h3>
      }
    </div>
  }
  @if (showDivider) {
    <hr />
  }
  <div class="d-flex error-card--message" [wlAutomationId]="automationId">
    <fa-icon class="icon icon--danger" icon="circle-info"></fa-icon>
    @if (message) {
      <wl-rich-text-wrapper [field]="message">
        <p class="text-small text-start ms-1 mb-0" wlCSCapture></p>
      </wl-rich-text-wrapper>
    }
    @if (messageTranslationKey) {
      <p class="text-small text-start ms-1 mb-0" wlCSCapture>
        {{ messageTranslationKey | translate: messageTranslationParams }}
      </p>
    }
  </div>
</ng-template>
