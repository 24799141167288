import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  type OnInit,
  HostBinding,
  inject,
} from '@angular/core';
import { TypedComponentRendering } from '@core/jss-models';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';
import { PlatformService } from '@core/platform';
import type { AlloyProposition } from '@core/analytics';
import { JssPlatformService } from '@core/jss-platform';
import { getFieldValue } from '@core/jss-utils';

import { ABTestingService } from '../services/ab-testing.service';
import type { ComponentTestingVariant } from '../ab-testing.model';

export type ComponentTestingFields = {
  TestIdentifier: TextField;
};

@Component({
  selector: 'wl-component-testing-container',
  templateUrl: './component-testing-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./component-testing-container.component.scss'],
})
export class ComponentTestingContainerComponent implements OnInit {
  @Input() rendering?: TypedComponentRendering<ComponentTestingFields>;

  readonly #abTestingService = inject(ABTestingService);
  readonly #cd = inject(ChangeDetectorRef);
  readonly #platform = inject(PlatformService);
  readonly #jssPlatformService = inject(JssPlatformService);

  testVariant?: ComponentTestingVariant;
  trackingData?: { shouldTrack: boolean; payload: AlloyProposition[] };

  get testIdentifier() {
    return getFieldValue<string>(this.rendering, 'TestIdentifier');
  }

  @HostBinding('class.identifier-missing')
  get identifierMissing() {
    return !this.testIdentifier && this.#jssPlatformService.isEditorActive();
  }

  async ngOnInit() {
    // In the case of XP-editor, or no test identifier present, load with default settings.
    if (this.#jssPlatformService.isEditorActive() || !this.testIdentifier) {
      this.testVariant = 'A';
      this.trackingData = {
        shouldTrack: false,
        payload: [],
      };
      this.#cd.markForCheck();
    } else if (this.#platform.isClient()) {
      return this.setAssignedVariant();
    }
    return void 0;
  }

  /**
   * Sets the assigned variant for the A/B test based on the provided test name.
   */
  private async setAssignedVariant() {
    // If a test identifier is set, try to resolve the A/B testing variant.
    if (this.testIdentifier) {
      const { variant, assignmentResolved, trackingPayload } =
        await this.#abTestingService.getAssignedVariant(
          this.testIdentifier,
          'A',
          ['A', 'B']
        );
      this.testVariant = variant;
      this.trackingData = {
        shouldTrack: assignmentResolved,
        payload: trackingPayload,
      };
    } else {
      throw new Error(
        'No test identifier is defined whilst trying to resolve a variant. This should not be happening.'
      );
    }
    // Mark the component for change detection.
    this.#cd.markForCheck();
  }

  public variantRendered(trackRender: boolean) {
    if (this.trackingData?.shouldTrack && trackRender) {
      this.#abTestingService.trackAssignment(this.trackingData.payload);
    }
  }
}
