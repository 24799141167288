import type { AbstractControl } from '@angular/forms';

/**
 * @description
 * A function that receives a control and updates its value when it changes
 */
export type TransformerFn = (
  control: AbstractControl & { prevValue: any }
) => any;

export class RfaTransformers {
  /**
   * @description
   * Transformer that transforms a given string to an uppercase string
   * ---
   * @usageNotes
   *
   * ```typescript
   * const control = new FehFormControl('', {transformers: [FehTransformers.caps]});
   *
   * control.setValue('abc');
   * console.log(control.value);    // 'ABC'
   * ```
   * ---
   * @returns A transformed string or void.
   */
  static readonly caps: TransformerFn = (control: AbstractControl) => {
    if (typeof control.value === 'string') {
      return control.value.toUpperCase();
    }
    return undefined;
  };

  /**
   * @description
   * Transformer that transforms a given string with a given character between every string character
   * ---
   * @usageNotes
   *
   * ```typescript
   * const control = new FehFormControl('', {transformers: [FehTransformers.betweeny('*')]});
   *
   * control.setValue('abc');
   * console.log(control.value);    // 'a*b*c*'
   * ```
   * ---
   * @returns A transformed string or void.
   */
  static betweeny(
    character: string,
    opts: { removeLastChar?: boolean } = {}
  ): TransformerFn {
    return (control: AbstractControl & { prevValue: any }) => {
      if (typeof control.value === 'string') {
        let value = control.value ?? '';
        const prevValue = control.prevValue;

        // When user backspaces, also remove the preceding initial instead of only the dot.
        if (
          prevValue &&
          value.length - prevValue.length === -1 &&
          prevValue.slice(-1) === character &&
          value.slice(-1) !== character
        ) {
          value = value.slice(0, -2);
        }

        // Remove the last dot (if any) in order to format the string correctly after processing
        if (value.slice(-1) === character) {
          value = value.substring(0, value.length - 1);
        }

        let newValue = '';
        value.split('').forEach((valChar) => {
          if (valChar !== character) {
            newValue += valChar + character;
          }
        });

        if (opts.removeLastChar) {
          newValue = newValue.slice(0, -1);
        }

        control.prevValue = newValue;
        return newValue;
      }
      return undefined;
    };
  }

  /**
   * @description
   * Transformer that transforms a given string to sarcasm case
   * ---
   * @usageNotes
   *
   * ```typescript
   * const control = new FehFormControl('', {transformers: [FehTransformers.sarcasm]});
   *
   * control.setValue('i love unicorn sync');
   * console.log(control.value);    // 'I lOvE uNiCoRn SyNc'
   * ```
   * ---
   * @returns A transformed string or void.
   */
  static readonly sarcasm: TransformerFn = (
    control: AbstractControl & { prevValue: any }
  ) => {
    if (typeof control.value === 'string') {
      const lower = control.value.toLowerCase();
      const upper = control.value.toUpperCase();
      let output = '';
      for (let i = 0, len = control.value.length; i < len; i++) {
        output += i % 2 === 0 ? lower[i] : upper[i];
      }
      return output;
    }
    return undefined;
  };

  /**
   * @description
   * Transformer that limits the amount of characters in a given string (formControl value must be string)
   * ---
   * @usageNotes
   *
   * ```typescript
   * const control = new FehFormControl('', {transformers: [FehTransformers.charLimit(25)]});
   *
   * control.setValue('Cheese is a type of dairy product produced in a range of flavors, textures, and forms by coagulation of the milk protein casein.');
   * console.log(control.value);    // 'Cheese is a type of dairy'
   * ```
   * ---
   * @returns A transformed string or void.
   */
  static charLimit(amount: number): TransformerFn {
    return (control: AbstractControl & { prevValue: any }) => {
      if (typeof control.value === 'string' && control.value.length > amount) {
        return control.value.slice(0, amount);
      }
      // if not a string (e.g. number) return current
      return control.value;
    };
  }
}
