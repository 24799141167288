import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@core/analytics';
import type {
  ScAddressFormInput,
  ScCheckboxFormInput,
  ScCheckboxSetFormInput,
  ScDropDownFormInput,
  ScEmailFormInput,
  ScFormFormGroupState,
  ScFormInput,
  ScNameFormInput,
  ScPaymentFormInput,
  ScPhoneFormInput,
  ScRadioFormInput,
  ScTextFormInput,
  ScWidgetInput,
} from '@innogy/sitecore-forms/models';
import { ScForm } from '@innogy/sitecore-forms/models';
import { controlNameForInput } from '@innogy/sitecore-forms/store';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wl-generic-form[form][formState][trackStep]',
  templateUrl: './generic-form.component.html',
})
export class GenericFormComponent {
  @Input() form!: ScForm;
  @Input() formState!: FormGroupState<ScFormFormGroupState>;
  @Input() trackStep!: TrackToolStep;

  toTextFormInputFields!: ScTextFormInput;
  toCheckboxFormInputFields!: ScCheckboxFormInput;
  toCheckboxSetFormInputFields!: ScCheckboxSetFormInput;
  toEmailFormInputFields!: ScEmailFormInput;
  toNameFormInputFields!: ScNameFormInput;
  toPaymentFormInputFields!: ScPaymentFormInput;
  toAddressInputFields!: ScAddressFormInput;
  toDropdownFormInputFields!: ScDropDownFormInput;
  toPhoneFormInputFields!: ScPhoneFormInput;
  toRadioFormInputFields!: ScRadioFormInput;
  toWidgetInputFields!: ScWidgetInput;

  controlForInput(input: ScFormInput) {
    return this.formState.controls[controlNameForInput(input)];
  }
}
