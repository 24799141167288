import { Component, Input } from '@angular/core';

import { RfaFormControl } from '../../../level1';
import { valueChangeOptionsToConfig } from '../../helpers/tracker-options-to-config';
import type { RfaComponents } from '../RfaComponents';
import type { RfaValueChangeTrackerOptions } from '../../models/rfa-tracker-options.interface';

@Component({
  selector: 'rfa-select',
  templateUrl: './rfa-select.component.html',
  styleUrls: ['../styles.scss'],
})
export class RfaSelectComponent {
  @Input({ required: true }) control!: RfaFormControl<
    (typeof RfaComponents.Select)['valueType'],
    typeof RfaComponents.Select
  >;
  @Input() newDesign = false;

  get label(): string {
    return this.control.options.label ?? '';
  }

  get emptyValueText(): string {
    return this.control.options.emptyValueText ?? '';
  }

  get entries(): { text: string; value: any }[] {
    return this.control.options.entries;
  }

  valueChangeTrackConfig() {
    const valueChangeOptions: RfaValueChangeTrackerOptions =
      this.control.options.analytics?.valueChangeTracker ?? {};

    return valueChangeOptionsToConfig(
      {
        ...valueChangeOptions,
        value: this.control.value.toString(),
      },
      {
        fieldName: this.control.options.label,
        hashValue: false,
      }
    );
  }
}
