import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  type TemplateRef,
} from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { SkeletonLoaderMask } from '@legacy/common-ui-shared';

import { accordionBodyMask, accordionHeaderMask } from '../svg-skeleton-masks';

@Component({
  selector: 'wl-commodity-offer-accordion',
  templateUrl: './commodity-offer-accordion.component.html',
  styleUrls: [
    './commodity-offer-accordion.component.ed.scss',
    './commodity-offer-accordion.component.essent.scss',
  ],
})
export class CommodityOfferAccordionComponent {
  @Input() offer?: Offer;
  @Input() opened = false;
  @Input() showLoadingState = false;

  @ContentChild('accordionHeader') accordionHeader!: TemplateRef<any> | null;
  @ContentChild('accordionBody') accordionBody!: TemplateRef<any> | null;

  @HostBinding('attr.data-offerid')
  get offerId() {
    return this.offer?.offerId;
  }

  get maskHeader(): SkeletonLoaderMask {
    return accordionHeaderMask;
  }

  get maskBody(): SkeletonLoaderMask {
    return accordionBodyMask;
  }
}
