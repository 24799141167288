<div class="order-overview-ed__card card rounded">
  <div class="card-body p-2">
    @if (offer) {
      <div class="d-flex justify-content-between align-items-center">
        <p class="h4 text-break mb-0">{{ offer?.productTitle }}</p>
        @if (rendering?.fields?.showEditButton?.value) {
          <wl-offer-overview-change-offer
            [rendering]="rendering"
            class="ms-1"
          ></wl-offer-overview-change-offer>
        }
      </div>
      <p
        class="mt-3 mb-0"
        *scText="rendering?.fields?.SummaryCalculationBasedOn"
      ></p>
      @if (consumptionValuesVM) {
        <wl-order-overview-consumption
          class
          [consumptionValues]="consumptionValuesVM"
        ></wl-order-overview-consumption>
      }
      <hr />
      <wl-order-overview-budget-bill
        [beforeDiscountYearlyLabel]="
          rendering?.fields?.BeforeDiscountExpectedYearlyLabel
        "
        [beforeDiscountYearlyAmount]="offer.beforeDiscountExpectedYearlyAmount"
        [cashbackAmount]="offer.discountPrice"
        [cashbackLabel]="rendering?.fields?.IncentiveLabel"
        [yearlyLabel]="yearlyCostLabel"
        [yearlyAmount]="offer.expectedYearlyAmount"
        [monthlyAmount]="offer.expectedMonthlyAmount"
        [monthlyAmountLabel]="rendering?.fields?.MonthlyAmountLabel"
        [monthlyAmountTooltip]="rendering?.fields?.MonthlyAmountTooltip?.value"
        [budgetBillAmount]="offer.budgetBillAmount"
        [budgetBillLabel]="rendering?.fields?.BudgetBillSuggestion"
        [budgetBillTooltip]="
          rendering?.fields?.BudgetBillSuggestionTooltip?.value
        "
      ></wl-order-overview-budget-bill>
      @if (displayMGM((hasMemberGetMember$ | async) || false)) {
        <div class="my-3 rounded-3 text-center order-overview-ed__mgm-badge">
          {{
            rendering?.fields?.MemberGetMemberSharedValue?.value || ''
              | interpolate
                : {
                    variables: {
                      mgmValue: mgmSharedValue | roundedCurrency,
                    },
                  }
          }}
        </div>
      }
      <hr />
      <div>
        <div class="d-flex mb-3 mt-3 justify-content-center">
          <button
            class="p-0 btn btn-link text-primary"
            [wlTrackLink]="rendering?.fields?.AOMModalLinkText?.value"
            [trackLinkInfo]="{
              rendering: {
                componentName: 'product detail',
              },
              field: rendering?.fields?.AOMModalLinkText?.value,
              eventType: 'link-click',
            }"
            wlAutomationId="show-tarifs"
            (click)="showTarifs.emit(offer)"
            *scText="rendering?.fields?.AOMModalLinkText"
          ></button>
          @if (!!rendering?.fields?.AOMModalTooltip?.value) {
            <span class="d-flex align-items-center ps-2">
              <wl-tooltip
                [tooltip]="{
                  description: rendering?.fields?.AOMModalTooltip?.value,
                }"
              ></wl-tooltip>
            </span>
          }
        </div>
        @if (offer.usps?.length) {
          <div>
            <div class="rounded p-2 order-overview-ed__usps">
              @if (offer.usps && offer.usps.length) {
                <wl-offer-usps [usps]="offer.usps"></wl-offer-usps>
              }
            </div>
          </div>
        }
      </div>
    } @else {
      <wl-svg-skeleton-loader [mask]="skeletonLoaderMask">
      </wl-svg-skeleton-loader>
    }
  </div>
</div>
