<section class="jumbotron" [wlBackground]="backgroundSettings">
  @if (mobileTopImage && mobileImage?.src) {
    <wl-partial-image
      [src]="mobileImage!.src ?? ''"
      [alt]="mobileImage!.alt ?? ''"
      ratio="4/3"
      objectPosition="center"
      objectFit="cover"
    ></wl-partial-image>
  }

  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7">
        <div class="jumbotron__left">
          <wl-partial-brand-header
            [theme]="theme"
            [inlineHeadings]="inlineHeadings"
            [heading]="heading"
            [subheading]="subheading"
            [stretchBackdrop]="true"
          >
            @if (showButtonsInHeading) {
              <div
                class="jumbotron-slot jumbotron-slot__buttons"
                [class.jumbotron-slot__buttons--inline]="layoutButtonsInline"
              >
                <ng-content
                  select="wl-partial-button[slot='button-primary']"
                ></ng-content>
                <ng-content
                  select="wl-partial-button[slot='button-secondary']"
                ></ng-content>
              </div>
            }
          </wl-partial-brand-header>
          <!-- used for rich-text / widgets -->
          <div class="jumbotron-slot">
            <ng-content select="[slot='content-left']"></ng-content>
          </div>

          @if (!showButtonsInHeading) {
            <div
              class="jumbotron-slot jumbotron-slot__buttons"
              [class.jumbotron-slot__buttons--inline]="layoutButtonsInline"
            >
              <ng-content
                select="wl-partial-button[slot='button-primary']"
              ></ng-content>
              <ng-content
                select="wl-partial-button[slot='button-secondary']"
              ></ng-content>
            </div>
          }
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="jumbotron__right">
          <!-- used for widgets -->
          <ng-content select="[slot='content-right']"></ng-content>
        </div>
      </div>
    </div>
  </div>
</section>
