import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { closeGenericModal } from '@innogy/common-ui/modals';
import {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import { ScrollService } from '@innogy/utils-deprecated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { becomeACustomerOrderSelectorKey } from '../order';
import {
  changeCalculationAction,
  resetAddressAction,
} from './calculate.actions';

@Injectable()
export class CalculateQuestionnaireEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly progressiveFormService: ProgressiveFormService,
    private readonly scrollService: ScrollService,
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig
  ) {}

  /**
   * Effect that scrolls the Calculate card component back into view after the Calculate usage wizard is closed.
   */
  public readonly scrollCalculateIntoViewAfterCalculation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeCalculationAction),
        tap((_) => {
          this.scrollService.scrollToAnchorAnimated('calculate-form');
        })
      ),
    { dispatch: false }
  );

  /**
   * Routes to another page when the supply address modal is closed.
   */
  public readonly routeOnModalClose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeGenericModal),
        filter(
          (action) =>
            isPrimaryChangeSupplyAddressModalClose(action) &&
            action.additionalPayload?.redirectUrl
        ),
        tap((action) => {
          const { href } = action.additionalPayload?.redirectUrl || {};
          if (href) {
            this.router.navigateByUrl(href);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Effect that is triggered when the address is changed via a supply address modal, spawns actions that:
   * - reset the address
   * - clears the progressive form state
   */
  public readonly resetAddressOnModalClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter((action) => isPrimaryChangeSupplyAddressModalClose(action)),
      mergeMap(() => [
        resetAddressAction(),
        this.progressiveFormService.resetProgressiveForm({
          formId: becomeACustomerOrderSelectorKey,
        }),
      ])
    )
  );
}

const isPrimaryChangeSupplyAddressModalClose = (action: {
  source: GenericModalSources;
  result: GenericModalResults;
}) =>
  action.source === GenericModalSources.CHANGE_SUPPLY_ADDRESS &&
  action.result === GenericModalResults.PRIMARY;
