<!-- TODO: When state is implemented, check if this form can be embedded in other
  personal details form like BAC and retention -->
<div class="mb-2 align-items-end">
  <div class="row g-1 mb-2">
    <div class="col-md-6 col-12 mb-2 mb-md-0">
      <wl-text
        wlAutocomplete="initials"
        [rendering]="rendering"
        [step]="trackStep.step"
        [stepName]="trackStep.stepName"
        fieldName="initials"
        [sendValue]="false"
        [valueConverter]="_initialsConverter"
        [formsControl]="formState.controls.initials"
        [placeholder]="initialsPlaceholder"
        [label]="initialsLabel"
        [floatingLabel]="floatingLabels"
      ></wl-text>
    </div>
    <div class="col-md-6 col-12">
      <wl-text
        wlAutocomplete="insertion"
        [rendering]="rendering"
        [step]="trackStep.step"
        [stepName]="trackStep.stepName"
        fieldName="insertion"
        [sendValue]="false"
        [formsControl]="formState.controls.insertion"
        [placeholder]="insertionPlaceholder"
        [label]="insertionLabel"
        [floatingLabel]="floatingLabels"
      ></wl-text>
    </div>
  </div>
  <div class="row g-1">
    <div class="col-12">
      <wl-text
        wlAutocomplete="lastName"
        [rendering]="rendering"
        [step]="trackStep.step"
        [stepName]="trackStep.stepName"
        fieldName="lastName"
        [sendValue]="false"
        [formsControl]="formState.controls.lastName"
        [placeholder]="lastNamePlaceholder"
        [label]="lastNameLabel"
        [floatingLabel]="floatingLabels"
      ></wl-text>
    </div>
  </div>
  <div class="row g-1">
    @if (formState | isInvalid) {
      <div class="col-12">
        <!-- Error messages -->
        @if (formState.controls.initials | isInvalid) {
          @if (formState.controls.initials) {
            <wl-input-error
              [automationId]="automationId + '-initials-required-error'"
              [messageField]="initialsRequiredError"
              messageDescription="initialen"
              [rendering]="rendering"
              [stepName]="trackStep.stepName"
            ></wl-input-error>
          }
        }
        @if (formState.controls.lastName | isInvalid) {
          @if (formState.controls.lastName?.errors?.required) {
            <wl-input-error
              [automationId]="automationId + '-lastname-required-error'"
              [messageField]="lastNameRequiredError"
              messageDescription="achternaam"
              [rendering]="rendering"
              [stepName]="trackStep.stepName"
            ></wl-input-error>
          }
          @if (formState.controls.lastName?.errors?.pattern) {
            <wl-input-error
              [automationId]="automationId + '-lastname-pattern-error'"
              [messageField]="lastNameInvalidError"
              messageDescription="achternaam"
              [rendering]="rendering"
              [stepName]="trackStep.stepName"
            ></wl-input-error>
          }
        }
      </div>
    }
  </div>
</div>
