@if (entries.length > 1) {
  <div class="d-flex justify-content-center">
    <wl-segmented-control
      [entries]="entries"
      [selected]="selectedEntry"
      (entrySelected)="changeContractType.emit($event)"
      class="d-block w-100"
    >
    </wl-segmented-control>
  </div>
}
