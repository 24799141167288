@if (computedVariant$ | async; as computedVariant) {
  @if (computedAlignment$ | async; as computedAlignment) {
    <button
      [wlAutomationId]="automationId"
      [class.text-start]="
        computedVariant !== 'large' && computedAlignment === 'left'
      "
      class="card rounded w-100 h-100"
      [class.highlighted]="highlighted"
      (click)="clicked.emit()"
      [ngClass]="[
        'background--' + background,
        'background-hover--' + hoverBackground,
      ]"
    >
      <span
        class="w-100 h-100 d-flex align-items-center"
        [class.flex-column]="computedVariant === 'large'"
        [class.card-body]="computedVariant === 'large'"
        [class.p-2]="computedVariant === 'small'"
        [class.justify-content-start]="
          computedVariant !== 'large' && computedAlignment === 'left'
        "
        [class.justify-content-center]="
          computedVariant === 'large' || computedAlignment === 'center'
        "
      >
        @if (iconName) {
          <div class="me-1">
            <fa-icon
              class="icon d-flex align-items-center justify-content-center"
              [icon]="iconName"
              [size]="
                (computedIconSize$ | async) ||
                (computedVariant === 'small' ? 'lg' : '2x')
              "
              [fixedWidth]="true"
            ></fa-icon>
          </div>
        }
        <span
          [class.ms-1]="iconName && computedVariant === 'small'"
          [class.mt-2]="iconName && computedVariant === 'large'"
        >
          @if (title) {
            <span
              class="title"
              [class.title--taxonomy]="context === 'taxonomy'"
              [class.h4]="computedVariant === 'large'"
              [class.color-link]="useLinkStyle"
              [attr.needs-fixed-width]="
                (computedVariant === 'small' &&
                  computedAlignment === 'center') ||
                null
              "
            >
              {{ title }}
            </span>
          }
          @if (subTitle) {
            <div
              class="subtitle"
              [class.color-gray]="!useLinkStyle"
              [class.color-link]="useLinkStyle"
            >
              {{ subTitle }}
            </div>
          }
        </span>
        @if (showNext) {
          <span class="d-flex align-items-center ms-auto">
            <fa-icon
              class="icon d-flex align-items-center justify-content-center"
              icon="chevron-right"
            ></fa-icon>
          </span>
        }
      </span>
    </button>
  }
}
