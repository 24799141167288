import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@core/jss-models';
import type { StreetAddress } from '@essent/new-customer';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { CurrentContract } from '@innogy/retention/store';
import { EnergyType } from '@integration/base-models';

import { CommodityOfferContent } from '../commodity-offers.model';

@Component({
  selector: 'wl-vertical-commodity-offer',
  templateUrl: './vertical-commodity-offer.component.html',
  styleUrls: [
    './vertical-commodity-offer.component.ed.scss',
    './vertical-commodity-offer.component.essent.scss',
  ],
})
export class VerticalCommodityOfferComponent {
  @Input() public rendering!: InnogyComponentRendering;
  @Input() content?: CommodityOfferContent;
  @Input() showMemberGetMember = false;
  @Input() offers?: Offer[];
  @Input() consumptionValues?: ConsumptionValuesVM;
  @Input() supplyAddress?: Partial<StreetAddress>;
  @Input() flowId?: string;
  @Input() accountId?: string;
  @Input() currentContract?: CurrentContract;
  @Output() selectOffer = new EventEmitter<Offer>();
  @Output() openMoreInfo = new EventEmitter<Offer>();
  @Output() viewTariffs = new EventEmitter<Offer>();
  @Output() adjustCalculation = new EventEmitter<void>();

  brand = inject(ENVIRONMENT_CONFIG).brand;
  segment = inject(ENVIRONMENT_CONFIG).segment;

  get showCurrentContract() {
    return !!(
      this.segment === 'consument' &&
      this.content?.showMyContract &&
      this.currentContract
    );
  }

  get currentContractImpression() {
    if (!this.currentContract) {
      return '';
    }

    let electricityProduct = 'n/a',
      electricityDuration = 'n/a',
      gasProduct = 'n/a',
      gasDuration = 'n/a';
    const hasElectricityProduct = this.currentContract.commodities.includes(
      EnergyType.ELECTRICITY
    );
    const hasGasProduct = this.currentContract.commodities.includes(
      EnergyType.GAS
    );

    if (hasElectricityProduct && hasGasProduct) {
      [electricityProduct, gasProduct] =
        this.currentContract.productTitle.split(' & ');
      electricityDuration = gasDuration = this.currentContract.duration;
    } else if (hasElectricityProduct) {
      electricityProduct = this.currentContract.productTitle;
      electricityDuration = this.currentContract.duration;
    } else if (gasProduct) {
      gasProduct = this.currentContract.productTitle;
      gasDuration = this.currentContract.duration;
    }

    return `Je huidige contract: <${electricityProduct}, ${electricityDuration}>, <${gasProduct}, ${gasDuration}>`;
  }
}
