@if (cashbackAmount) {
  <div
    class="d-flex justify-content-between mb-1"
    wlAutomationId="order-overview-budget-bill-before-discount"
  >
    <div><span *scText="beforeDiscountYearlyLabel" wlCSCapture></span></div>
    <div class="text-end">
      <span wlCSCapture>
        {{ beforeDiscountYearlyAmount | roundedCurrency }}
      </span>
    </div>
  </div>
  <div
    class="d-flex justify-content-between text-success mb-1 budget-bill-cashback-row"
    wlAutomationId="order-overview-budget-bill-cashback"
  >
    <div><span *scText="cashbackLabel" wlCSCapture></span></div>
    <div class="text-end">
      <span wlCSCapture> - {{ cashbackAmount | roundedCurrency }} </span>
    </div>
  </div>
}

<div class="d-flex justify-content-between fw-bold mb-1">
  <div>
    <span wlCSCapture>{{ yearlyLabel }}</span>
  </div>
  <div class="text-end">
    <span wlCSCapture>
      {{ yearlyAmount | roundedCurrency }}
    </span>
  </div>
</div>

<div [class.budget-bill-monthly-costs-hidden-mobile]="showCostsInHeader">
  <hr />

  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <span
          *wlGenericRichText="monthlyAmountLabel"
          class="fw-bold"
          wlCSCapture
        ></span>
        @if (!!monthlyAmountTooltip) {
          <wl-tooltip
            [tooltip]="{
              description: monthlyAmountTooltip,
            }"
            class="ps-1"
          >
          </wl-tooltip>
        }
      </div>
      <span class="monthly-amount__amount text-brand h3 ps-1" wlCSCapture>{{
        monthlyAmount | roundedCurrency
      }}</span>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <span *wlGenericRichText="budgetBillLabel" wlCSCapture></span>
        @if (!!budgetBillTooltip) {
          <wl-tooltip
            [tooltip]="{
              description: budgetBillTooltip,
            }"
            class="ps-1"
          >
          </wl-tooltip>
        }
      </div>
      <span class="ps-1" wlCSCapture>{{
        budgetBillAmount | roundedCurrency
      }}</span>
    </div>
  </div>
</div>
