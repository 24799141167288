@if (label && !floatingLabel) {
  <label
    [for]="formsControl?.id"
    wlAutomationId="non-floating-label"
    class="fw-bold mb-1"
    wlCSCapture
    >{{ label }}</label
  >
}
<div [class.form-floating]="label && floatingLabel" class="position-relative">
  <input
    [wlTrackFieldfocus]="{
      rendering: rendering,
      stepName: stepName,
      step: step,
      field: fieldName,
      sendValue: sendValue,
      hashValue: hashValue,
    }"
    [ngrxFormControlState]="formsControl"
    [ngrxValueConverter]="valueConverter"
    [ngrxUpdateOn]="updateOn"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [value]="formsControl?.value"
    [placeholder]="placeholder || ' '"
    [ngrxEnableFocusTracking]="true"
    type="text"
    [id]="formsControl?.id"
    [name]="formsControl?.id"
    class="form-control"
    [class.pe-6]="!!tooltip"
    aria-required="true"
    wlHasValueClass="has-value"
    [wlHasValueClassValue]="formsControl?.value"
    [wlAutomationId]="formsControl?.id"
  />
  @if (label && floatingLabel) {
    <label
      wlAutomationId="floating-label"
      wlCSCapture
      [for]="formsControl?.id"
      >{{ label }}</label
    >
  }
  @if (!!tooltip) {
    <div
      class="position-absolute h-100 px-2 top-0 bottom-0 end-0 d-flex flex-column justify-content-center"
    >
      <wl-tooltip
        [tooltip]="{
          description: tooltip,
        }"
      ></wl-tooltip>
    </div>
  }
  <ng-content></ng-content>
</div>
