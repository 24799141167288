<wl-composable-card [config]="cardConfig">
  <wl-basic-content-partial-top-between
    [settings]="settings"
    [content]="content"
    [context]="context"
    class="h-100"
    [showIconInHeader]="false"
  >
    <div
      *ngIf="settings?.icon?.name"
      class="d-flex justify-content-center p-2 w-100"
      [class.pt-4]="settings?.itemAlignment === 'top'"
    >
      <fa-icon
        [icon]="settings?.icon?.name | scIcon"
        size="3x"
        [ngClass]="[settings?.icon?.color]"
      ></fa-icon>
    </div>
  </wl-basic-content-partial-top-between>
</wl-composable-card>
