@if (hasLinks) {
  <div class="social-icons">
    @if (title) {
      <p class="fw-bold">
        {{ title }}
      </p>
    }
    <div class="social-icons__container" role="navigation" aria-label="Social">
      @for (social of socials; track social) {
        @if (getLink(social); as link) {
          @if (link.href) {
            <a
              *wlGenericLink="link"
              rel="nofollow"
              class="social-icons__link"
              [attr.aria-label]="social"
            >
              <fa-icon
                class="social-icons__icon"
                [icon]="['fab', socialIcons[social]]"
                size="2x"
                [fixedWidth]="true"
              ></fa-icon>
            </a>
          }
        }
      }
    </div>
  </div>
}
