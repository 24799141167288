import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ImageSettings } from '@legacy/common-ui-utility-components';
import { getImageSettingsFromRendering } from '@legacy/common-ui-utility-components';
import { InnogyComponentRendering } from '@core/jss-models';
import { ColumnContext } from '@legacy/common-ui-shared-interfaces';

@Component({
  selector: 'wl-image-wrapper',
  templateUrl: './image-wrapper.component.html',
  styleUrls: [],
})
export class ImageWrapperComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  imageSettings?: ImageSettings;

  ngOnInit() {
    if (this.rendering) {
      this.imageSettings = getImageSettingsFromRendering(this.rendering);
    }
  }
}
