<section class="offers">
  <wl-commodity-offer-header
    class="header"
    [headerTitle]="content?.componentTitle"
    alignment="vertical"
  />
  <wl-commodity-offer-calculation-based-on
    class="consumption"
    variant="narrow"
    [label]="content?.calculationBasedOnLabel"
    [address]="supplyAddress"
    [consumptions]="consumptionValues"
    [electricityLabel]="'ElectricityUsageUnit' | translate"
    [gasLabel]="'GasUsageUnit' | translate"
    [adjustCalculationLabel]="content?.adjustCalculationLabel"
    (adjustCalculation)="adjustCalculation.emit()"
  />
  @if (segments && segments.length > 1) {
    <div class="segmented-control">
      <span class="h6 d-block mb-1">{{ content?.segmentedControlLabel }}</span>
      <wl-filter
        [entries]="segments"
        [selected]="initiallySelectedSegment"
        (entrySelected)="showOffer($event)"
        [styleIsStretched]="true"
      />
    </div>
  }
  <div class="offer-card d-flex flex-column gap-3">
    @switch (brand) {
      @case ('ed') {
        @if (shownOffer) {
          <ed-consumer-acquisition-commodity-offer
            [offer]="shownOffer"
            [content]="content"
            variant="card"
            [showMemberGetMember]="showMemberGetMember"
            (selectOffer)="selectOffer.emit(shownOffer)"
            (openMoreInfo)="openMoreInfo.emit(shownOffer)"
            (viewTariffs)="viewTariffs.emit(shownOffer)"
          />
        } @else {
          <ed-consumer-acquisition-commodity-offer
            variant="card"
            [showLoadingState]="true"
          />
        }
      }
      @case ('essent') {
        @if (shownOffer) {
          <essent-consumer-acquisition-commodity-offer
            [offer]="shownOffer"
            [content]="content"
            variant="card"
            [showMemberGetMember]="showMemberGetMember"
            (selectOffer)="selectOffer.emit(shownOffer)"
            (openMoreInfo)="openMoreInfo.emit(shownOffer)"
            (viewTariffs)="viewTariffs.emit(shownOffer)"
          />
        } @else {
          <essent-consumer-acquisition-commodity-offer
            variant="card"
            [showLoadingState]="true"
          />
        }
      }
    }

    @if (content?.offerSaverShown) {
      @if (content?.offerSaver; as offerSaverContent) {
        <wl-offer-saver-button [content]="offerSaverContent" />
      }
    }
  </div>
  <footer>
    <wl-rich-text-wrapper [field]="{ value: content?.componentFootNote }">
      <div></div>
    </wl-rich-text-wrapper>
  </footer>
</section>
