import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CoreExperienceEditorComponentsModule } from '@core/experience-editor-components';
import { UtilsModule } from '@innogy/utils-deprecated';

import { ChatbotIadvizeStartScreenComponent } from './chatbot-iadvize-start-screen/chatbot-iadvize-start-screen.component';
import { ChatbotIadvizeComponent } from './chatbot-iadvize/chatbot-iadvize.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUiUtilityComponentsModule,
    CoreAnalyticsModule,
    JssModule,
    CoreExperienceEditorComponentsModule,
    UtilsModule,
  ],
  declarations: [ChatbotIadvizeComponent, ChatbotIadvizeStartScreenComponent],
  exports: [ChatbotIadvizeStartScreenComponent, ChatbotIadvizeComponent],
})
export class ChatbotIadvizeModule {}
