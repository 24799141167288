import { createAsyncStateReducer, type AsyncState } from '@essent/common';
import { emobilityActions } from '@innogy/emobility-shared';
import type { SuccessResponse } from '@integration/api-client/emobility';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const quotationAcceptanceKey = 'emobility-quotation-acceptance';
export const acceptLeaseQuotationKey = 'accept-lease-quotation';

export interface EmobilityQuotationAcceptanceState {
  [acceptLeaseQuotationKey]: AsyncState<SuccessResponse>;
}

export const emobilityCaseReducers: ActionReducerMap<EmobilityQuotationAcceptanceState> =
  {
    [acceptLeaseQuotationKey]: createAsyncStateReducer(
      emobilityActions.postExternalPartnerQuotationV1
    ),
  };

/* istanbul ignore next */
const emobilityQuotationAcceptanceFeature =
  createFeatureSelector<EmobilityQuotationAcceptanceState>(
    quotationAcceptanceKey
  );

/* istanbul ignore next */
const selectAcceptLeaseQuotationFeature = createSelector(
  emobilityQuotationAcceptanceFeature,
  (feature) => feature[acceptLeaseQuotationKey]
);

export const selectLeaseQuotationStatus = createSelector(
  selectAcceptLeaseQuotationFeature,
  (state) => state.status
);
