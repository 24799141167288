import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { InjectionToken, NgModule } from '@angular/core';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { UtilsDomModule } from '@innogy/utils-dom';

import { FormatAddressPipe } from './address/supply-address-format.pipe';
import { AutocompleteDirective } from './autocomplete/autocomplete.directive';
import { ClientOnlyDirective } from './directives/client-only.directive';
import { HasValueDirective } from './directives/has-value.directive';
import { ServerOnlyDirective } from './directives/server-only.directive';
import { ValidationClassesDirective } from './directives/validation-classes.directive';
import { InvoiceStatusPipe } from './invoice/invoice-status.pipe';
import { PaymentArrangementStatusLabelColorPipe } from './invoice/payment-arrangement-status-label-color.pipe';
import { PaymentArrangementStatusPipe } from './invoice/payment-arrangement-status.pipe';
import { ActiveLinkService } from './navigation/active-link.service';
import { AbsoluteNumberPipe } from './pipes/absolute-number.pipe';
import { AnalyticsMapPipe } from './pipes/analyticsMap.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CastPipe } from './pipes/cast.pipe';
import { DateFnsPipe } from './pipes/date-fns.pipe';
import { DateInputFormatterPipe } from './pipes/date-input-formatter.pipe';
import { DaysUntilTodayPipe } from './pipes/days-until-today.pipe';
import { DurationSecondsToHhMmPipe } from './pipes/duration-seconds-to-hh-mm.pipe';
import { EuroCurrencyPipe } from './pipes/euro-currency.pipe';
import { ExtensionToIconPipe } from './pipes/extension-to-icon.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FunctionalErrorPipe } from './pipes/functional-error.pipe';
import { FunctionalSiteErrorPipe } from './pipes/functional-site-error.pipe';
import { ScIconPipe } from './pipes/icon.pipe';
import { InstallmentsDueDatePipe } from './pipes/installments-due-date.pipe';
import { InstallmentsValuePipe } from './pipes/installments-value.pipe';
import { IsActiveLinkPipe } from './pipes/is-active-link.pipe';
import { IsInvalidPipe } from './pipes/is-invalid.pipe';
import { IsValidPipe } from './pipes/is-valid.pipe';
import { ErrorsPipe } from './pipes/map-errors.pipe';
import { RangesPipe } from './pipes/ranges.pipe';
import { RoundedCurrencyPipe } from './pipes/rounded-currency.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SubmitDisabledPipe } from './pipes/submit-disabled.pipe';
import { SupplyAddressQueryParamsPipe } from './pipes/supply-address.pipe';
import { ToStringPipe } from './pipes/toString.pipe';
import { TrustedHtmlPipe } from './pipes/trusted-html.pipe';
import { UnitOfMeasurementPipe } from './pipes/unit-of-measurement.pipe';
import { ToggleDirective } from './toggle/toggle.directive';

export interface ColorsConfig {
  brandColor: string;
  brandColorDark: string;
}

export const BRAND_COLORS = new InjectionToken<ColorsConfig>('BRAND_COLORS');

export const EXPORTS = [
  ClientOnlyDirective,
  ServerOnlyDirective,
  ValidationClassesDirective,
  AutocompleteDirective,
  FileSizePipe,
  ExtensionToIconPipe,
  TrustedHtmlPipe,
  UnitOfMeasurementPipe,
  FormatAddressPipe,
  HasValueDirective,
  IsInvalidPipe,
  IsValidPipe,
  SubmitDisabledPipe,
  IsActiveLinkPipe,
  SupplyAddressQueryParamsPipe,
  InstallmentsDueDatePipe,
  InstallmentsValuePipe,
  DateFnsPipe,
  DateInputFormatterPipe,
  ToggleDirective,
  InvoiceStatusPipe,
  PaymentArrangementStatusPipe,
  PaymentArrangementStatusLabelColorPipe,
  DaysUntilTodayPipe,
  FunctionalErrorPipe,
  SafePipe,
  AnalyticsMapPipe,
  ScIconPipe,
  FunctionalSiteErrorPipe,
  EuroCurrencyPipe,
  CastPipe,
  RangesPipe,
  RoundedCurrencyPipe,
  DurationSecondsToHhMmPipe,
  ToStringPipe,
  AbsoluteNumberPipe,
  ErrorsPipe,
  CapitalizePipe,
];

@NgModule({
  imports: [CommonModule, UtilsDomModule, AutomationIdDirective],
  declarations: [...EXPORTS],
  exports: [
    ...EXPORTS,
    /**
     * @deprecated should be imported directly, this is done for backwards compatibility
     */
    AutomationIdDirective,
  ],
  providers: [ActiveLinkService, IsInvalidPipe, IsValidPipe],
})
export class UtilsModule {
  static forRoot(config: ColorsConfig): ModuleWithProviders<UtilsModule> {
    return {
      ngModule: UtilsModule,
      providers: [
        {
          provide: BRAND_COLORS,
          useValue: config,
        },
      ],
    };
  }
}
