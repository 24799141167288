import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

export const emobilityQuotationAcceptanceLazyImports: ComponentNameAndModule[] =
  [
    {
      path: 'EmobilityLeaseQuotationAcceptance',
      loadChildren: () =>
        import('./lib/emobility-quotation-acceptance-lazy.module').then(
          (mod) => mod.EmobilityQuotationAcceptanceLazyModule
        ),
    },
  ];
