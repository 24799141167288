@if (newDesign) {
  <div class="errors">
    @for (errorData of errors; track errorData) {
      @if (control.hasError(errorData.error)) {
        <div
          class="error text-danger"
          [wlTrackError]="errorTrackConfig(errorData)"
        >
          <strong
            ><fa-icon icon="circle-exclamation"></fa-icon
            >{{ errorData.msg }}</strong
          >
        </div>
      }
    }
  </div>
} @else {
  @for (errorData of errors; track errorData) {
    @if (control.hasError(errorData.error)) {
      <div class="text-danger" [wlTrackError]="errorTrackConfig(errorData)">
        <small>{{ errorData.msg }}</small>
      </div>
    }
  }
}
