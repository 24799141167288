<div
  class="d-flex"
  [ngClass]="!imageSettings?.aspectRatio && imageSettings?.alignment"
>
  @if (!!imageSettings?.link?.value?.href) {
    <a *wlGenericLink="imageSettings?.link">
      <ng-container *ngTemplateOutlet="image"></ng-container>
    </a>
  } @else {
    <img
      [style.objectPosition]="imagePosition"
      [ngClass]="[ignoredBorderStyles]"
      wlAutomationId="image"
      loading="lazy"
      *scImage="imageSettings?.image"
      [alt]="imageSettings?.image?.alt"
    />
  }
</div>

<ng-template #image>
  <img
    [style.objectPosition]="imagePosition"
    [ngClass]="[ignoredBorderStyles]"
    wlAutomationId="image"
    loading="lazy"
    *scImage="imageSettings?.image"
    [alt]="imageSettings?.image?.alt"
  />
</ng-template>
