@if (offer) {
  <div
    class="d-flex gap-1 justify-content-center"
    [class.justify-content-between]="content?.priceDurationLabelSuffix"
  >
    <span>{{
      offer.isDynamicProduct
        ? content?.proposedBudgetBillBeforeForDynamic
        : content?.proposedBudgetBillBefore
    }}</span>

    <div>
      <span class="fw-bold">{{
        offer.budgetBillAmount! | roundedCurrency
      }}</span
      >&nbsp;<span>{{ content?.priceDurationLabel }}</span>
      @if (content?.priceDurationLabelSuffix) {
        <span class="d-block text-end">{{
          content?.priceDurationLabelSuffix
        }}</span>
      }
    </div>
    @if (
      content?.proposedBudgetBillBeforeTooltip ||
      content?.proposedBudgetBillBeforeTooltipForDynamic
    ) {
      <wl-tooltip
        [tooltip]="{
          description: offer.isDynamicProduct
            ? content?.proposedBudgetBillBeforeTooltipForDynamic
            : content?.proposedBudgetBillBeforeTooltip,
        }"
      >
      </wl-tooltip>
    }
  </div>
}
