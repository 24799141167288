import type { FormPaymentDetails } from '@innogy/shared/progressive-form/sitecore';
import { createAction, props } from '@ngrx/store';

export const PREFIX = '[Emobility Payment Details]';
export const paymentDetailsFormId = 'payment-details';

export const prefillEmobilityPaymentDetailsStateAction = createAction(
  `${PREFIX} prefill payment details`,
  props<{ payload: Partial<FormPaymentDetails> }>()
);
