import type { SearchClient, SearchResponse } from 'algoliasearch';
import { algoliasearch } from 'algoliasearch';
import type { SearchHit } from '@innogy/search-models';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';

import {
  KEYWORD_QUERYSTRING_NAME,
  MOST_SEARCHED_FOR_HITS,
  SEARCH_HITS_PER_PAGE,
} from './search.variables';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  readonly #route = inject(ActivatedRoute);
  readonly #algoliaConfig = inject(ENVIRONMENT_CONFIG).algoliaSearch;

  // client-side cache for most suggested items.
  #mostSuggested?: SearchResponse<SearchHit>;

  private readonly client: SearchClient = algoliasearch(
    this.#algoliaConfig.applicationId,
    this.#algoliaConfig.applicationKey
  );

  public searchQuery$: Observable<string> = this.#route.queryParams.pipe(
    map((params) => params[KEYWORD_QUERYSTRING_NAME])
  );

  public getSearchQueryFromParams() {
    return firstValueFrom(this.searchQuery$);
  }

  public executeQuery(query: string, page = 0) {
    return this.client.searchSingleIndex<SearchHit>({
      indexName: this.#algoliaConfig.indexName,
      searchParams: {
        query,
        page,
        hitsPerPage: SEARCH_HITS_PER_PAGE,
        highlightPreTag: '<strong>',
        highlightPostTag: '</strong>',
      },
    });
  }

  public getQuerySuggestions(query: string) {
    return this.client.searchSingleIndex<SearchHit>({
      indexName: this.#algoliaConfig.autoCompleteIndexName,
      searchParams: {
        query,
        hitsPerPage: 5,
        // 1 crying cat for bootstrap hardcoding :(
        highlightPreTag: '<span class="fw-normal">',
        highlightPostTag: '</span>',
      },
    });
  }

  public async getMostCommonSuggestions() {
    // Only retrieve data from algolia when no data is cached.
    if (!this.#mostSuggested) {
      this.#mostSuggested = await this.client.searchSingleIndex<SearchHit>({
        indexName: this.#algoliaConfig.autoCompleteIndexName,
        searchParams: {
          hitsPerPage: MOST_SEARCHED_FOR_HITS,
        },
      });
    }
    return this.#mostSuggested;
  }
}
