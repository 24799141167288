<div class="d-flex justify-content-between align-items-center p-1">
  <div class="d-flex flex-column">
    <div class="fw-bold" wlCSCapture>{{ title }}</div>
    @if (subTitleValue && discountPrice) {
      <div>
        <span class="fst-italic" [class.text-success]="!isBBA" wlCSCapture>
          {{ subTitleValue }}</span
        >
        @if (tooltipDescription) {
          <wl-tooltip
            [tooltip]="{
              description: tooltipDescription,
            }"
            class="ps-1"
          >
          </wl-tooltip>
        }
      </div>
    }
  </div>
  <div
    class="d-flex align-items-end price"
    [class.flex-column]="alignPriceHorizontal"
  >
    @if (costWithoutDiscount !== undefined) {
      <div
        [ngClass]="costWithoutDiscountClasses"
        wlAutomationId="costs-without-discount"
        wlCSCapture
      >
        {{ costWithoutDiscount | roundedCurrency }}
      </div>
    }
    <!-- In JavaScript the number zero is considered falsy. Only when there is discount
    and if it is not zero the costsWithDiscount should be displayed -->
    @if (discountPrice && costWithDiscount !== undefined) {
      <div
        class="text-success fw-bold"
        wlAutomationId="costs-with-discount"
        wlCSCapture
      >
        {{ costWithDiscount | roundedCurrency }}
      </div>
    }
  </div>
</div>
