import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, map, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  postHeatpumpsLead,
  postHeatpumpsLeadSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';
import type { PostHeatpumpV1Data } from '@integration/api-client/feh';

import { heatpumpsOrderConfirmationFormId } from './heatpumps-order-form.reducer';
import {
  selectHeatpumpsOrderFormCheckboxMapping,
  selectHeatpumpsOrderFormViewModel,
} from './heatpumps-order-form.selectors';
import { selectHeatpumpsAnswers } from '../heatpumps-questionnaire';
import {
  onHeatpumpsFunnelNavigationAction,
  selectHeatpumpsFunnelSettings,
} from '../heatpumps-funnel';

@Injectable()
export class HeatpumpsOrderFormEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<any>
  ) {}

  public readonly onSubmitHeatpumpsOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === heatpumpsOrderConfirmationFormId),
      concatLatestFrom(() => [
        this.store.select(selectHeatpumpsFunnelSettings),
        this.store.select(selectHeatpumpsOrderFormViewModel),
        this.store.select(selectHeatpumpsAnswers),
        this.store.select(selectHeatpumpsOrderFormCheckboxMapping),
      ]),
      map(
        ([
          _action,
          funnelSettings,
          formData,
          submittedAnswers,
          checkboxMapping,
        ]) => {
          const controls = {
            ...formData.addressProgressiveFormState.formState.controls,
            ...formData.confirmationProgressiveFormState.formState.controls,
            ...formData.personalDetailsProgressiveFormState.formState.controls,
          };
          const dataUsageKey = checkboxMapping['Option1'] || 'allow_data_usage';
          const promotionalUpdatesKey =
            checkboxMapping['Option2'] || 'allow_promotional_updates';

          const payload: PostHeatpumpV1Data = {
            requestBody: {
              type: 'heatPump',
              products: [{ category: 'Warmtepomp' }],
              metadata: {
                temperature: funnelSettings.leadTemperature ?? 4,
                building:
                  submittedAnswers as PostHeatpumpV1Data['requestBody']['metadata']['building'],
                createdOn: new Date().toISOString(),
              },
              lead: {
                title: this.mapSalutation(controls.salutation?.value),
                initials: controls.initials.value,
                lastName: controls.lastName.value,
                email: controls.emailAddress.value,
                phone: controls.phoneNumber.value,
                addresses: {
                  communication: {
                    postcode:
                      formData.addressProgressiveFormState.formState.controls
                        .postalCode.value,
                    houseNumber: String(
                      formData.addressProgressiveFormState.formState.controls
                        .communicationNumber.value
                    ),
                    houseNumberExtension:
                      formData.addressProgressiveFormState.formState.controls
                        .houseNumberAddition.value === ''
                        ? undefined
                        : formData.addressProgressiveFormState.formState
                            .controls.houseNumberAddition.value,
                    city: formData.addressProgressiveFormState.formState
                      .controls.city.value,
                    street:
                      formData.addressProgressiveFormState.formState.controls
                        .street.value,
                  },
                },
              },
              context: {
                requestType: 'Advies',
                channel: 'Online',
              },
              consent: {
                dataUsage: controls.checkboxes.value[dataUsageKey],
                promotion: controls.checkboxes.value[promotionalUpdatesKey],
              },
            },
          };

          return postHeatpumpsLead({ payload });
        }
      )
    )
  );

  public readonly onPostHeatpumpsLeadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postHeatpumpsLeadSuccess),
      concatLatestFrom(() => this.store.select(selectHeatpumpsFunnelSettings)),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onHeatpumpsFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );

  private mapSalutation(value: string | undefined): string {
    switch (value) {
      case 'male':
        return 'Dhr.';
      case 'female':
        return 'Mevr.';
      default:
        return '';
    }
  }
}
