<div class="ui-card__header">
  <ng-content select="[slot='header']"></ng-content>
</div>
<div class="ui-card__subheader">
  <ng-content select="[slot='subheader']"></ng-content>
</div>
@if (divider) {
  <hr class="ui-card__divider" />
}
<div class="ui-card__content">
  <ng-content select="[slot='content']"></ng-content>
</div>
