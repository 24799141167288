<!-- Spacer that takes care of highlights -->
<div class="d-block py-5"></div>
<div class="d-none d-lg-block">
  <div
    class="row justify-content-center"
    wlAutomationId="horizontal-offer-cards"
  >
    @for (offer of offers; track trackByOffers(i, offer); let i = $index) {
      <div
        [class]="[centerFirstColumn(i), getOfferColumnSize(offers.length)]"
        [wlAutomationId]="'horizontal-offer-card-' + i"
      >
        <wl-horizontal-offer-card
          [isRetention]="isRetention"
          [offer]="offer"
          [addOn]="addOn"
          [hasMemberGetMember]="hasMemberGetMember"
          [rendering]="rendering"
          [isAddOnChecked]="isAddOnChecked"
          [moreInfoButtonClick]="
            moreInfoButtonClick ? moreInfoButtonClick(offer) : undefined
          "
          (submitSelectedProposition)="submitProposition.emit($event)"
          (openAomModal)="showTarifs.emit($event)"
          (addOnToggle)="onAddOnToggle($event)"
          (showMoreIncentiveInfo)="showMoreIncentiveInfo.emit($event)"
          class="position-relative d-flex flex-column h-100"
          wlAutomationId="horizontal-offer-cards"
        ></wl-horizontal-offer-card>
      </div>
    }
  </div>
</div>

<wl-horizontal-offer-multiple-cards-carousel
  class="d-block d-lg-none"
  [offers]="offers"
  [rendering]="rendering"
  [addOn]="addOn"
  [hasMemberGetMember]="hasMemberGetMember"
  [isAddOnChecked]="isAddOnChecked"
  [moreInfoButtonClick]="moreInfoButtonClick ? moreInfoButtonClick : undefined"
  (addOnToggle)="onAddOnToggle($event)"
  (submitSelectedProposition)="submitProposition.emit($event)"
  (openAomModal)="showTarifs.emit($event)"
  (showMoreIncentiveInfo)="showMoreIncentiveInfo.emit($event)"
></wl-horizontal-offer-multiple-cards-carousel>
