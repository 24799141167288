import { Component, Input } from '@angular/core';

import type { RfaComponents } from '../../RfaComponents';
import type { RfaFormControl } from '../../../../level1';

@Component({
  selector: 'rfa-label',
  templateUrl: './rfa-label.component.html',
  styleUrls: ['./rfa-label.component.scss'],
})
export class RfaLabelComponent {
  @Input({ required: true }) control!: RfaFormControl<
    (typeof RfaComponents._Any)['valueType'],
    typeof RfaComponents._Any
  >;
}
