import type { RfaFormControlOptions } from '../../level1';
import type { RfaComponentOptions } from '../models';
import { RfaCheckboxComponent, type RfaCheckboxOptions } from './rfa-checkbox';
import type { RfaNumberInputOptions } from './rfa-number-input';
import { RfaNumberInputComponent } from './rfa-number-input';
import {
  RfaRadioGroupComponent,
  type RfaRadioGroupOptions,
} from './rfa-radio-group';
import { RfaSelectComponent, type RfaSelectOptions } from './rfa-select';
import { RfaTextAreaComponent, type RfaTextAreaOptions } from './rfa-text-area';
import type { RfaTextInputOptions } from './rfa-text-input';
import { RfaTextInputComponent } from './rfa-text-input';

export type RfaCombination =
  | typeof RfaComponents._None
  | typeof RfaComponents._Any
  | typeof RfaComponents.Checkbox
  | typeof RfaComponents.NumberInput
  | typeof RfaComponents.RadioGroup
  | typeof RfaComponents.Select
  | typeof RfaComponents.TextArea
  | typeof RfaComponents.TextInput;

export class RfaComponents {
  static readonly _None = {
    componentType: null,
    optionsType: {} as RfaFormControlOptions,
    valueType: 0 as any,
  };

  static readonly _Any = {
    componentType: null as any,
    optionsType: {} as RfaComponentOptions,
    valueType: 0 as any,
  };

  static readonly Checkbox = {
    componentType: RfaCheckboxComponent,
    optionsType: {} as RfaCheckboxOptions,
    valueType: true as boolean,
  };

  static readonly NumberInput = {
    componentType: RfaNumberInputComponent,
    optionsType: {} as RfaNumberInputOptions,
    valueType: 0 as number,
  };

  static readonly RadioGroup = {
    componentType: RfaRadioGroupComponent,
    optionsType: {} as RfaRadioGroupOptions,
    valueType: '' as string,
  };

  static readonly Select = {
    componentType: RfaSelectComponent,
    optionsType: {} as RfaSelectOptions,
    valueType: '' as string,
  };

  static readonly TextArea = {
    componentType: RfaTextAreaComponent,
    optionsType: {} as RfaTextAreaOptions,
    valueType: '' as string,
  };

  static readonly TextInput = {
    componentType: RfaTextInputComponent,
    optionsType: {} as RfaTextInputOptions,
    valueType: '' as string | number,
  };
}
