@if (offers.length > 0) {
  <ngb-carousel
    [class.first-slide]="isFirstOffer"
    [class.last-slide]="isLastOffer"
    [interval]="0"
    [wrap]="false"
    #ngbCarousel
    class="carousel"
    (slid)="setCurrentSlide($event)"
    (swipeleft)="carousel?.next()"
    (swiperight)="carousel?.prev()"
  >
    @for (offer of offers; track trackByOffers(i, offer); let i = $index) {
      <ng-template ngbSlide id="{{ i }}">
        <wl-horizontal-offer-card
          [wlAutomationId]="'horizontal-offer-card-' + i"
          class="d-block px-5 pb-5"
          [offer]="offer"
          [rendering]="rendering"
          [addOn]="addOn"
          [hasMemberGetMember]="hasMemberGetMember"
          [isAddOnChecked]="isAddOnChecked"
          [moreInfoButtonClick]="
            moreInfoButtonClick ? moreInfoButtonClick(offer) : undefined
          "
          (addOnToggle)="onAddOnToggle($event)"
          (submitSelectedProposition)="selectProposition($event)"
          (openAomModal)="openAomModal.emit($event)"
          (showMoreIncentiveInfo)="showMoreIncentiveInfo.emit($event)"
        ></wl-horizontal-offer-card>
      </ng-template>
    }
  </ngb-carousel>
}

@if (offers && offers.length > 1) {
  <div wlAutomationId="arrows" class="arrows d-flex justify-content-center">
    <button
      [class.disabled]="isFirstOffer"
      class="btn btn-brand prev-arrow mx-1"
      (click)="carousel?.prev()"
    >
      <fa-icon icon="chevron-left"></fa-icon>
    </button>
    <button
      [class.disabled]="isLastOffer"
      class="btn btn-brand next-arrow mx-1"
      (click)="carousel?.next()"
    >
      <fa-icon icon="chevron-right"></fa-icon>
    </button>
  </div>
}
