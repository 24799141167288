@if (config?.icon) {
  <a
    role="button"
    [wlTrackLink]="config?.icon"
    [trackLinkInfo]="{
      rendering: rendering,
      field: config?.icon,
      eventType: 'button-click',
    }"
    class="btn btn--icon-prefixed"
    [ngClass]="config?.class"
    href="{{ config?.url }}"
  >
    <em [ngClass]="config?.icon"></em> <span>{{ config?.text }}</span>
  </a>
} @else {
  <a
    class="btn"
    role="button"
    [wlTrackLink]="config?.text"
    [trackLinkInfo]="{
      rendering: rendering,
      field: config?.text,
      eventType: 'button-click',
    }"
    [ngClass]="config?.class"
    href="{{ config?.url }}"
    >{{ config?.text }}</a
  >
}
