import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreAnalyticsModule } from '@core/analytics';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';

import { RfaBasicFormExample1Component } from './level1';
import {
  RfaTextInputComponent,
  RfaNumberInputComponent,
  RfaRadioGroupComponent,
  RfaCheckboxComponent,
  RfaTextAreaComponent,
  RfaSelectComponent,
  RfaConditionalValidationComponent,
  RfaBasicFormExample2Component,
  RfaErrorsComponent,
  RfaLabelComponent,
} from './level2';
import {
  RfaFormBuilderComponent,
  RfaFormGroupBuilderComponent,
  RfaFormControlBuilderComponent,
  RfaBasicFormExample3Component,
  RfaStepComponent,
  RfaStepperComponent,
  RfaBasicStepperExampleComponent,
} from './level3';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CoreAnalyticsModule,
    CommonUiUtilityComponentsModule,
  ],
  declarations: [
    // utils
    RfaLabelComponent,
    RfaErrorsComponent,
    RfaFormBuilderComponent,
    RfaFormGroupBuilderComponent,
    RfaFormControlBuilderComponent,
    RfaStepComponent,
    RfaStepperComponent,
    // components
    RfaTextInputComponent,
    RfaNumberInputComponent,
    RfaRadioGroupComponent,
    RfaCheckboxComponent,
    RfaTextAreaComponent,
    RfaSelectComponent,
    // examples
    RfaConditionalValidationComponent,
    RfaBasicFormExample1Component,
    RfaBasicFormExample2Component,
    RfaBasicFormExample3Component,
    RfaBasicStepperExampleComponent,
  ],
  exports: [
    ReactiveFormsModule,
    //builders
    RfaFormBuilderComponent,
    RfaStepComponent,
    RfaStepperComponent,
    // components
    RfaTextInputComponent,
    RfaNumberInputComponent,
    RfaRadioGroupComponent,
    RfaCheckboxComponent,
    RfaTextAreaComponent,
    RfaSelectComponent,
    // examples
    // RfaConditionalValidationComponent,
    // RfaBasicFormExample1Component,
    // RfaBasicFormExample2Component,
    // RfaBasicFormExample3Component,
    // RfaBasicStepperExampleComponent,
  ],
})
export class ReactiveFormsAbstractionsModule {}
