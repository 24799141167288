/* eslint-disable max-classes-per-file */
import {
  Component,
  ContentChild,
  Directive,
  inject,
  TemplateRef,
} from '@angular/core';
import { getIsExperienceEditorActive } from '@core/jss-routing';
import { select, Store } from '@ngrx/store';

@Directive({ selector: 'ng-template[wlLive]' })
export class ExperienceEditorLiveDirective {
  readonly templateRef = inject(TemplateRef);
}

@Directive({ selector: 'ng-template[wlPreview]' })
export class ExperienceEditorPreviewDirective {
  readonly templateRef = inject(TemplateRef);
}

@Component({
  selector: 'wl-experience-editor',
  templateUrl: './experience-editor.component.html',
  host: { ngSkipHydration: 'true' },
})
export class ExperienceEditorComponent {
  @ContentChild(ExperienceEditorLiveDirective)
  content?: ExperienceEditorLiveDirective;
  @ContentChild(ExperienceEditorPreviewDirective)
  preview?: ExperienceEditorPreviewDirective;

  readonly #store$ = inject(Store);

  readonly experienceEditorActive$ = this.#store$.pipe(
    select(getIsExperienceEditorActive)
  );
}
