@if (offer) {
  <div class="commodity-price-breakdown">
    <wl-commodity-price-breakdown-row
      [title]="yearlyCostsLabel(offer)"
      [subtitle]="content?.costsPerYearWithDiscountLabel"
      [costWithDiscount]="offer?.expectedYearlyAmount"
      [costWithoutDiscount]="offer?.beforeDiscountExpectedYearlyAmount"
      [discountPrice]="offer?.discountPrice"
      [alignPriceHorizontal]="content?.showDiscountAmountInRowFormat"
    >
    </wl-commodity-price-breakdown-row>
    @if (!offer.isDynamicProduct) {
      <wl-commodity-price-breakdown-row
        [title]="montlyCostsLabel(offer)"
        [subtitle]="content?.costsPerMonthWithDiscountLabel"
        [costWithDiscount]="offer?.expectedMonthlyAmount"
        [costWithoutDiscount]="offer?.budgetBillAmount"
        [discountPrice]="offer?.discountPrice"
        [alignPriceHorizontal]="content?.showDiscountAmountInRowFormat"
      >
      </wl-commodity-price-breakdown-row>
    }
    @if (content?.showBudgetbillAmount) {
      <wl-commodity-price-breakdown-row
        [title]="budgetbillAmountLabel(offer)"
        [subtitle]="content?.withoutDiscountLabel"
        [costWithoutDiscount]="offer?.budgetBillAmount"
        [discountPrice]="offer?.discountPrice"
        [isBBA]="true"
        [tooltipDescription]="content?.withoutDiscountTooltip"
      >
      </wl-commodity-price-breakdown-row>
    }
  </div>
}
