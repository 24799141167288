@if (control && control.enabled) {
  <div class="form-section">
    @if (newDesign) {
      <rfa-errors [control]="control" [newDesign]="true"></rfa-errors>
    }
    <label class="checkbox">
      <input
        class="form-check-input"
        type="checkbox"
        [id]="control.id"
        [formControl]="control"
        [wlTrackValueChange]="valueChangeTrackConfig()"
      />
      <span>
        {{ label }}
      </span>
    </label>
    @if (!newDesign) {
      <rfa-errors [control]="control"></rfa-errors>
    }
  </div>
}
