import { ErrorHandler, inject, Injectable } from '@angular/core';
import { GenericModalSources } from '@legacy/common-ui-shared-interfaces';
import { AnalyticsActionTypes } from '@core/analytics';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Angulartics2 } from 'angulartics2';
import { map, mergeMap, tap } from 'rxjs/operators';

import {
  openModalFromFunnelSettingsAction,
  redirectToFunnelStartPageAction,
  setFunnelSettingsFromGraphqlErrorAction,
} from '../../funnel';
import { handleBacApiErrorAction } from './api-error.actions';
import { noFlowIdErrorAction } from '../flow-id';

@Injectable()
export class ApiErrorEffects {
  readonly #actions$ = inject(Actions);
  readonly #angulartics = inject(Angulartics2);
  readonly #errorHandler = inject(ErrorHandler);

  /**
   *  Open the Chatbot modal and pass the required data to it
   */
  public readonly onBacApiError$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(handleBacApiErrorAction),
      tap((action) => {
        // Fire tool error
        this.#angulartics.eventTrack.next({
          action: AnalyticsActionTypes.TOOL_ERROR,
          properties: {
            message: `There was an API error: ${action.message}`,
            stepName: action.stepName,
            type: 'System error',
          },
        });
      }),
      mergeMap((action) =>
        action.showModal
          ? [
              openModalFromFunnelSettingsAction({
                source: GenericModalSources.BAC_ERROR,
              }),
            ]
          : []
      )
    )
  );

  /**
   * Redirect back to the calculate step when a 'noFlowIdError' is raised.
   */
  public readonly onNoFlowIdError$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(noFlowIdErrorAction),
      map(() => redirectToFunnelStartPageAction())
    )
  );

  /**
   * Log errors to the console when retrieved the funnel settings
   * with GraphQL fails.
   */
  public readonly onSetFunnelSettingsFromGraphQlError$ = createEffect(
    () =>
      this.#actions$.pipe(
        ofType(setFunnelSettingsFromGraphqlErrorAction),
        tap(({ error }) => this.#errorHandler.handleError(error))
      ),
    { dispatch: false }
  );
}
