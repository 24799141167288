@if (explanationVisible) {
  <div class="col-12 col-xl-6 mt-2 p-0">
    <div class="me-xl-1">
      <button
        (click)="giveMoreInfo.emit(explanation)"
        type="button"
        class="btn btn-outline-primary w-100"
        *scText="explanation?.button"
        wlTrackLink
        wlCSCapture
        [trackLinkInfo]="{
          rendering: rendering,
          field: explanation?.button?.value,
          type: 'button',
          eventType: 'button-click',
        }"
      ></button>
    </div>
  </div>
}
