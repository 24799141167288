import { EmailQuotationReactionGuard } from '@contracting/email-quotation-reaction-store';
import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildren = () =>
  import('./lib/contracting-email-quotation-reaction-lazy.module').then(
    (mod) => mod.ContractingEmailQuotationReactionLazyModule
  );

export const emailQuotationReactionLazyImports: ComponentNameAndModule[] = [
  {
    path: 'EmailQuotationReactionHandler',
    loadChildren,
    canActivate: [EmailQuotationReactionGuard],
  },
];
