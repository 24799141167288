import { Component, Input } from '@angular/core';
import { TrackToolStep } from '@core/analytics';
import { ScEmailFormInput } from '@innogy/sitecore-forms/models';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-sc-email[control][fields][trackStep]',
  templateUrl: './sc-email.component.html',
})
export class ScEmailComponent {
  @Input() control!: FormState<string>;
  @Input() fields!: ScEmailFormInput;
  @Input() floatingLabel = false;
  @Input() trackStep!: TrackToolStep;

  get isEmailValidation(): boolean {
    return this.fields.IsValidation.value ?? false;
  }
}
