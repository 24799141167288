<wl-modal
  (closeModal)="closeModal()"
  [modalTitle]="modalTitle"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionName]="'AOM'"
  class="aom-modal"
>
  <ng-template wlModalBody>
    <div>
      <wl-aom-content
        [offer]="offer"
        [isVATIncluded]="(isVATIncluded$ | async) ?? undefined"
        [costPerUnitVM]="costPerUnitVM"
        [consumptionValuesVM]="consumptionValuesVM"
        [rendering]="rendering"
        [fields]="fields"
      ></wl-aom-content>
    </div>
  </ng-template>
  <ng-template wlModalFooter>
    @if (showFooter) {
      <div class="modal-footer">
        <wl-aom-footer
          [orderPageUrl]="orderPage?.href"
          [fields]="fields"
          class="w-100"
        ></wl-aom-footer>
      </div>
    }
  </ng-template>
</wl-modal>
