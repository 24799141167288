import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import { isSitecorePlaceholderImage } from '@core/jss-utils';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import type { ResponsiveSettings } from './background.model';
import { BackgroundSettings } from './background.model';

@Directive({
  selector: '[wlBackground]',
})
export class BackgroundDirective implements AfterViewInit, OnDestroy {
  /**
   * Make sure to extend the correct template in Sitecore and use `getBackgroundSettingsFromRendering()` to construct the settings required for the background
   */
  @Input('wlBackground') settings?: BackgroundSettings;

  readonly #mediaObserver = inject(MediaObserverService);
  readonly #renderer = inject(Renderer2);
  readonly #host = inject(ElementRef);

  readonly onDestroy$ = new Subject();

  deviceInfo$ = this.#mediaObserver.deviceInfo$;

  ngAfterViewInit() {
    if (!!this.settings?.backgroundClass) {
      this.#renderer.addClass(
        this.#host.nativeElement,
        this.settings?.backgroundClass || ''
      );
    }
    if (!!this.settings?.mobile?.src || !!this.settings?.desktop?.src) {
      this.deviceInfo$
        .pipe(
          distinctUntilChanged((curr, prev) => curr.isMobile === prev.isMobile),
          takeUntil(this.onDestroy$)
        )
        .subscribe((deviceInfo) => {
          let responsiveSettings = deviceInfo.isMobile
            ? this.settings?.mobile
            : this.settings?.desktop;

          if (
            !responsiveSettings?.src &&
            this.settings?.useDesktopBackgroundAsFallback
          ) {
            responsiveSettings = this.settings?.desktop;
          }

          this.setStyles(responsiveSettings);
        });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  setStyles(responsiveSettings?: ResponsiveSettings): void {
    if (responsiveSettings) {
      const { src, size, position, repeat } = responsiveSettings;

      const settings: [property: string, value?: string][] = [
        [
          'background-image',
          !src || isSitecorePlaceholderImage(src) ? undefined : `url(${src})`,
        ],
        ['background-size', size],
        ['background-position', position],
        ['background-repeat', repeat],
      ];
      settings.forEach(([property, value]) =>
        !!value
          ? this.#renderer.setStyle(this.#host.nativeElement, property, value)
          : this.#renderer.removeStyle(this.#host.nativeElement, property)
      );
    }
  }
}
