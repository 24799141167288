@if (!!label) {
  <div [class.form-floating]="labelFloating">
    @if (!labelFloating) {
      <label [for]="formsControl.id" class="form-label fw-bold">
        {{ label }}
      </label>
    }
    <ng-container *ngTemplateOutlet="inputField" />
    @if (labelFloating) {
      <label [for]="formsControl.id" class="form-label">
        {{ label }}
      </label>
    }
  </div>
} @else {
  <input
    type="text"
    inputmode="numeric"
    [id]="formsControl.id"
    [name]="formsControl.id"
    [placeholder]="placeholder"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [ngrxFormControlState]="formsControl"
    [ngrxEnableFocusTracking]="true"
    class="form-control"
    [class.has-value]="!!formsControl.value"
    [wlAutomationId]="formsControl.id"
    [wlTrackValueChange]="{
      rendering: rendering,
      label: fieldName ?? '',
      inputLabel: formsControl.value,
      sendValue: sendValue ?? false,
      trackFocus: true,
      stepName: stepName,
      step: step!,
    }"
  />
}

<ng-template #inputField>
  <input
    type="text"
    inputmode="numeric"
    [id]="formsControl.id"
    [name]="formsControl.id"
    [placeholder]="placeholder"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [ngrxFormControlState]="formsControl"
    [ngrxEnableFocusTracking]="true"
    class="form-control"
    [class.has-value]="!!formsControl.value"
    [wlAutomationId]="formsControl.id"
    [wlTrackValueChange]="{
      rendering: rendering,
      label: fieldName ?? '',
      inputLabel: formsControl.value,
      sendValue: sendValue ?? false,
      trackFocus: true,
      stepName: stepName,
      step: step!,
    }"
  />
</ng-template>
