<div class="card border rounded">
  <div class="card-body p-2">
    @if (offer) {
      <div class="row g-1 justify-content-between">
        <div class="col">
          <p class="h5 m-0 fw-bold">{{ offer.productTitle }}</p>
          @if (durationTitle) {
            <p class="mb-1">
              <span *scText="rendering?.fields?.DurationLabel"></span>
              {{ durationTitle }}
            </p>
          }
        </div>
        <div class="col-auto d-lg-none">
          @if (rendering?.fields?.showEditButton?.value) {
            <wl-offer-overview-change-offer
              [rendering]="rendering"
            ></wl-offer-overview-change-offer>
          }
        </div>
      </div>
      <div class="row g-1 mb-2">
        <div class="col-9">
          <p
            class="fw-bold"
            *scText="rendering?.fields?.SummaryCalculationBasedOn"
          ></p>
          @if (consumptionValuesVM) {
            <wl-order-overview-consumption
              [consumptionValues]="consumptionValuesVM"
            ></wl-order-overview-consumption>
          }
        </div>
        <div class="col-3 text-end">
          @if (rendering?.fields?.Sticker; as sticker) {
            <wl-order-overview-sticker
              [stickerImage]="sticker?.value"
              [offer]="offer"
            >
            </wl-order-overview-sticker>
          }
        </div>
      </div>
      @if (electricityOverview) {
        <wl-order-overview-cost
          [label]="rendering?.fields?.ElectricityLabel"
          [cost]="electricityOverview?.totalPrice?.expectedYearlyAmount"
          [boldLabel]="true"
        ></wl-order-overview-cost>
      }
      @if (gasOverview?.totalPrice?.expectedYearlyAmount) {
        <wl-order-overview-cost
          [label]="rendering?.fields?.GasLabel"
          [cost]="gasOverview?.totalPrice?.expectedYearlyAmount"
          [boldLabel]="true"
        ></wl-order-overview-cost>
      }
      <div class="mb-2"></div>
      <p class="mb-1" *scText="rendering?.fields?.ProposedBBALabel"></p>
      <wl-order-overview-budget-bill
        class="d-block mb-2 pb-2 border-bottom"
        [beforeDiscountYearlyLabel]="
          rendering?.fields?.BeforeDiscountExpectedYearlyLabel
        "
        [beforeDiscountYearlyAmount]="offer.beforeDiscountExpectedYearlyAmount"
        [cashbackAmount]="offer.discountPrice"
        [cashbackLabel]="rendering?.fields?.IncentiveLabel"
        [yearlyLabel]="yearlyCostLabel"
        [yearlyAmount]="offer.expectedYearlyAmount"
        [monthlyAmount]="offer.expectedMonthlyAmount"
        [monthlyAmountLabel]="rendering?.fields?.MonthlyAmountLabel"
        [monthlyAmountTooltip]="rendering?.fields?.MonthlyAmountTooltip?.value"
        [budgetBillAmount]="offer.budgetBillAmount"
        [budgetBillLabel]="rendering?.fields?.BudgetBillSuggestion"
        [budgetBillTooltip]="
          rendering?.fields?.BudgetBillSuggestionTooltip?.value
        "
      ></wl-order-overview-budget-bill>
      <div class="d-flex mb-3 mt-3 justify-content-center">
        <button
          class="p-0 btn btn-link text-primary"
          [wlTrackLink]="rendering?.fields?.AOMModalLinkText?.value"
          [trackLinkInfo]="{
            rendering: {
              componentName: 'product detail',
            },
            field: rendering?.fields?.AOMModalLinkText?.value,
            eventType: 'link-click',
          }"
          wlAutomationId="show-tarifs"
          (click)="showTarifs.emit(offer)"
          *scText="rendering?.fields?.AOMModalLinkText"
        ></button>
        @if (!!rendering?.fields?.AOMModalTooltip?.value) {
          <span class="d-flex align-items-center ps-1">
            <wl-tooltip
              [tooltip]="{
                description: rendering?.fields?.AOMModalTooltip?.value,
              }"
            ></wl-tooltip>
          </span>
        }
      </div>
    } @else {
      <wl-svg-skeleton-loader [mask]="skeletonLoaderMask">
      </wl-svg-skeleton-loader>
    }
  </div>
</div>
