import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiCommonRenderingsModule } from '@innogy/common-ui/common-renderings';
import { CommonUiPartialsModule } from '@legacy/common-ui-partials';
import { CommonUiSharedModule } from '@legacy/common-ui-shared';
import { CommonUiUtilityComponentsModule } from '@legacy/common-ui-utility-components';
import { CoreAnalyticsModule } from '@core/analytics';
import { CoreExperienceEditorComponentsModule } from '@core/experience-editor-components';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsContentsquareModule } from '@legacy/utils-contentsquare';
import { TranslatePipe, TranslateDirective } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

@NgModule({
  imports: [AutomationIdDirective, TranslatePipe, TranslateDirective],
  exports: [
    CommonModule,
    JssModule,
    UtilsModule,
    RouterModule,
    TranslatePipe,
    TranslateDirective,
    UtilsContentsquareModule,
    FontAwesomeModule,
    CommonUiCommonRenderingsModule,
    CommonUiUtilityComponentsModule,
    CommonUiPartialsModule,
    CommonUiSharedModule,
    CoreAnalyticsModule,
    CoreExperienceEditorComponentsModule,
    AutomationIdDirective,
  ],
})
export class DefaultComponentModule {}
