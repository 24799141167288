@if (viewModel$ | async; as vm) {
  @if (trackToolConfig) {
    <wl-composable-card [config]="cardConfig">
      <!-- FIXME: This is not the way to do this. -->
      <div class="card">
        <div class="card-header">
          @if (rendering.fields.Title && displayForm(vm)) {
            <h3
              class="h3 my-0 d-flex align-items-center"
              *scText="rendering.fields.Title"
            ></h3>
          }
          @if (rendering.fields.Subtitle && displayForm(vm)) {
            <p *scText="rendering.fields.Subtitle" class="my-0"></p>
          }
        </div>
        <div class="card-body">
          @if (displayForm(vm)) {
            <form novalidate [wlTrackStep]="trackToolConfig.forStep('form')">
              <wl-generic-form
                [trackStep]="trackToolConfig!.forStep('form')"
                [form]="rendering.fields.Form"
                [formState]="vm.form.formState"
              ></wl-generic-form>
              <button
                type="submit"
                class="btn mt-2 w-100 btn--icon-prefixed submit-button"
                [class.btn-primary]="context === 'default'"
                [class.btn-sales-primary]="context === 'sales'"
                [wlAutomationId]="
                  'generic-form.' + rendering.fields.Form.id + '.submit'
                "
                (click)="submit($event, vm.form.formState)"
                *scText="rendering.fields.SubmitButtonLabel"
                wlTrackImpression="button-impression"
                trackImpressionMode="button"
                [trackImpressionName]="rendering.fields.SubmitButtonLabel.value"
                [trackImpressionLinkurl]="''"
                [trackImpressionInfo]="{
                  componentName: rendering.componentName,
                }"
              ></button>
            </form>
          }
          @if (displaySubmissionSucceededText(vm)) {
            <p
              [wlAutomationId]="
                'generic-form.' + rendering.fields.Form.id + '.successText'
              "
              class="mt-2"
              *wlGenericRichText="rendering.fields.SubmissionSucceededText"
            ></p>
          }
          @if (vm.feedback?.submitError) {
            <wl-info-card
              class="d-block mt-2"
              [type]="'danger'"
              [title]="rendering.fields.SubmissionFailedTitle"
              [message]="rendering.fields.SubmissionFailedMessage"
              [border]="true"
              [shadow]="false"
              [automationId]="
                'generic-form.' + rendering.fields.Form.id + '.errorText'
              "
            ></wl-info-card>
          }
        </div>
      </div>
    </wl-composable-card>
  }
}
