import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SetValueAction } from 'ngrx-forms';
import { filter, mergeMap } from 'rxjs/operators';
import {
  GenericModalResults,
  GenericModalSources,
} from '@legacy/common-ui-shared-interfaces';

import { closeGenericModal } from '../generic-modal.actions';
import { closeResidentialModal } from './residential.actions';

@Injectable()
export class ResidentialModalEffects {
  constructor(private readonly actions$: Actions) {}

  public readonly primaryResidentialModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          action.source === GenericModalSources.RESIDENTIAL &&
          action.result === GenericModalResults.PRIMARY
      ),
      mergeMap((action) => [closeResidentialModal(action)])
    )
  );

  public readonly secondaryOrCloseResidentialModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeGenericModal),
      filter(
        (action) =>
          action.source === GenericModalSources.RESIDENTIAL &&
          (action.result === GenericModalResults.SECONDARY ||
            action.result === GenericModalResults.CLOSE)
      ),
      mergeMap((action) => [
        new SetValueAction(
          action.additionalPayload.residenceOrWorkplaceFormControlId,
          true
        ),
        closeResidentialModal(action),
      ])
    )
  );
}
