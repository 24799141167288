<div class="d-flex flex-column gap-4">
  @if (showFilters) {
    <wl-commodity-offer-filter
      class="d-block w-100"
      [class.mb-xl-4]="isEnergiedirectConsumerRetention"
      [offers]="offers"
      [selectedContractType]="selectedContractType"
      [availableContractTypes]="availableContractTypes"
      (changeContractType)="changeContractType($event)"
    ></wl-commodity-offer-filter>
  }

  <div
    class="d-flex offer-list justify-content-center"
    [class.offer-list--vertical]="computedVariant === 'accordion'"
    [class.offer-list--has-floating-label]="listContainsHighlights"
    [class.mt-xl-6]="
      isEnergiedirect &&
      alignment === 'horizontal' &&
      listContainsHighlights &&
      !showFilters
    "
    wlAutomationId="commodity-offer-list-offers"
  >
    @if (isEssentConsumerOpen) {
      @if (offers && offers.length > 0) {
        @for (offer of prioritizedOffers; track offer) {
          <essent-consumer-acquisition-commodity-offer
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [showMemberGetMember]="showMemberGetMember"
            [ensureHorizontalAlignment]="listContainsHighlights"
            [hideCommodityProductBreakdownTable]="
              config?.hideCommodityProductBreakdownTable
            "
            [useAlternateCommodityTariffView]="
              config?.useAlternateCommodityTariffView
            "
            (selectOffer)="selectOffer.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
            (viewTariffs)="viewTariffs.emit(offer)"
          ></essent-consumer-acquisition-commodity-offer>
        }
      } @else {
        @for (_ of [1, 2, 3]; track _) {
          <essent-consumer-acquisition-commodity-offer
            [showLoadingState]="true"
            [variant]="computedVariant"
            [active]="false"
          ></essent-consumer-acquisition-commodity-offer>
        }
      }
    }
    @if (isEssentSMEOpen) {
      @if (offers && offers.length > 0) {
        @for (offer of prioritizedOffers; track offer) {
          <essent-sme-acquisition-commodity-offer
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [showMemberGetMember]="showMemberGetMember"
            [ensureHorizontalAlignment]="listContainsHighlights"
            (selectOffer)="selectOffer.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
            (viewTariffs)="viewTariffs.emit(offer)"
          ></essent-sme-acquisition-commodity-offer>
        }
      } @else {
        @for (_ of [1, 2, 3]; track _) {
          <essent-sme-acquisition-commodity-offer
            [showLoadingState]="true"
            [variant]="computedVariant"
            [active]="false"
          ></essent-sme-acquisition-commodity-offer>
        }
      }
    }

    @if (isEssentConsumentRetention) {
      @if (!content?.useMinimalOfferCards) {
        @for (offer of prioritizedOffers; track offer) {
          <essent-consumer-retention-commodity-offer
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [ensureHorizontalAlignment]="listContainsHighlights"
            (selectOffer)="selectOffer.emit(offer)"
            (viewTariffs)="viewTariffs.emit(offer)"
          >
          </essent-consumer-retention-commodity-offer>
        }
      }

      @if (content?.useMinimalOfferCards === true) {
        @for (offer of prioritizedOffers; track offer) {
          <essent-consumer-retention-minimal-commodity-offer
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [active]="isActive(offer)"
            [ensureHorizontalAlignment]="
              listContainsHighlights || listContainsIncentives
            "
            (selectOffer)="selectOffer.emit(offer)"
            (viewTariffs)="viewTariffs.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
          >
          </essent-consumer-retention-minimal-commodity-offer>
        }
      }
    }

    @if (isEnergiedirectConsumerOpen) {
      @if (offers && offers.length > 0) {
        @for (offer of prioritizedOffers; track offer) {
          <ed-consumer-acquisition-commodity-offer
            [offer]="offer"
            [variant]="computedVariant"
            [content]="content"
            [hideCommodityProductBreakdownTable]="
              config?.hideCommodityProductBreakdownTable
            "
            [useAlternateCommodityTariffView]="
              config?.useAlternateCommodityTariffView
            "
            [active]="isActive(offer)"
            [showMemberGetMember]="showMemberGetMember"
            [ensureHorizontalAlignment]="listContainsHighlights"
            [showTariffs]="showTariffsForAllOffers"
            (selectOffer)="selectOffer.emit(offer)"
            (openMoreInfo)="openMoreInfo.emit(offer)"
            (viewTariffModal)="viewTariffs.emit(offer)"
            (openTariffDropdown)="_showTariffsForAllOffers = true"
            (closeTariffDropdown)="_showTariffsForAllOffers = false"
          ></ed-consumer-acquisition-commodity-offer>
        }
      } @else {
        @for (_ of [1, 2, 3]; track _) {
          <ed-consumer-acquisition-commodity-offer
            [showLoadingState]="true"
            [variant]="computedVariant"
            [active]="false"
          ></ed-consumer-acquisition-commodity-offer>
        }
      }
    }

    @if (isEnergiedirectConsumerRetention) {
      @if (offers && offers.length > 0) {
        @if (!content?.useMinimalOfferCards) {
          @for (offer of prioritizedOffers; track offer) {
            <ed-consumer-retention-commodity-offer
              [offer]="offer"
              [variant]="computedVariant"
              [content]="content"
              [active]="isActive(offer)"
              [showTariffs]="showTariffsForAllOffers"
              (selectOffer)="selectOffer.emit(offer)"
              (openMoreInfo)="openMoreInfo.emit(offer)"
              (viewTariffModal)="viewTariffs.emit(offer)"
              (openTariffDropdown)="_showTariffsForAllOffers = true"
              (closeTariffDropdown)="_showTariffsForAllOffers = false"
            ></ed-consumer-retention-commodity-offer>
          }
        }
        @if (content?.useMinimalOfferCards) {
          @for (offer of prioritizedOffers; track offer) {
            <ed-consumer-retention-minimal-commodity-offer
              [offer]="offer"
              [variant]="computedVariant"
              [content]="content"
              [active]="isActive(offer)"
              [ensureHorizontalAlignment]="
                listContainsHighlights || listContainsIncentives
              "
              (selectOffer)="selectOffer.emit(offer)"
              (viewTariffs)="viewTariffs.emit(offer)"
              (openMoreInfo)="openMoreInfo.emit(offer)"
            >
            </ed-consumer-retention-minimal-commodity-offer>
          }
        }
      } @else {
        @for (_ of [1, 2, 3]; track _) {
          <ed-consumer-retention-commodity-offer
            [showLoadingState]="true"
            [variant]="computedVariant"
            [active]="false"
          ></ed-consumer-retention-commodity-offer>
        }
      }
    }
  </div>
</div>
