import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { PlatformService } from '@core/platform';
import { JssPlatformService } from '@core/jss-platform';
import { enc, SHA256 } from 'crypto-js';

import type { ScriptInjectionConfig, ScriptStatus } from './dom.model';
import { ScriptInjectOn } from './dom.model';

@Injectable({ providedIn: 'root' })
export class DomService {
  private alreadyInjectedScripts: { [source: string]: ScriptStatus } = {};

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly ngZone: NgZone,
    private readonly platformService: PlatformService,
    private readonly jssPlatformService: JssPlatformService
  ) {}

  /**
   * Use this function to inject a script in the <head> (or <body>) of the document.
   * It will prevent a script from being injected multiple times
   * and will (by default) prevent injection in the Sitecore Experience Editor.
   */
  public injectScript(config: ScriptInjectionConfig = {}): void {
    const { runOutsideAngular = true } = config;

    this._validateConfig(config);

    const key = this._getKey(config);
    const shouldInject = this._shouldInject(config);

    const isAlreadyInjected = this.alreadyInjectedScripts[key]?.injected;
    const isAlreadyLoaded = this.alreadyInjectedScripts[key]?.loaded;

    if (isAlreadyLoaded) {
      config.onAlreadyLoaded?.();
    }

    if (shouldInject && !isAlreadyInjected) {
      this.alreadyInjectedScripts[key] = {
        injected: true,
      };

      const script = this._createScript(config);
      if (runOutsideAngular) {
        return this.ngZone.runOutsideAngular(() =>
          this._insertScript(config, script)
        );
      }
      return this._insertScript(config, script);
    }
  }

  private _shouldInject(config: ScriptInjectionConfig): boolean {
    const { CLIENT_AND_SERVER, CLIENT_ONLY, SERVER_ONLY } = ScriptInjectOn;
    const { injectInExperienceEditor, injectOnPlatform = CLIENT_AND_SERVER } =
      config;

    const inEditor = this.jssPlatformService.isEditorActive();
    const allowInjection = (injectInExperienceEditor && inEditor) || !inEditor;

    const onClient =
      CLIENT_AND_SERVER === injectOnPlatform ||
      CLIENT_ONLY === injectOnPlatform;
    const onServer =
      CLIENT_AND_SERVER === injectOnPlatform ||
      SERVER_ONLY === injectOnPlatform;
    const isClient = this.platformService.isClient();
    const isServer = this.platformService.isServer();
    const shouldInject = (onClient && isClient) || (onServer && isServer);

    return allowInjection && shouldInject;
  }

  private _validateConfig(config: ScriptInjectionConfig) {
    if (!config.src && !config.innerHTML) {
      throw new Error('"src" and "innerHTML" can not both be empty');
    }

    if (config.src && config.innerHTML) {
      throw new Error(
        'Only one of the following properties can be provided "src" and "innerHTML"'
      );
    }
  }

  /**
   * This gets a unique hash for the provided script config
   */
  private _getKey(config: ScriptInjectionConfig): string {
    return enc.Hex.stringify(SHA256(JSON.stringify(config)));
  }

  private _createScript(config: ScriptInjectionConfig): HTMLScriptElement {
    const key = this._getKey(config);

    const {
      src,
      async = false,
      type = 'text/javascript',
      innerHTML,
      onLoad,
      onError,
    } = config;

    const script = this.document.createElement('script');
    if (innerHTML) {
      script.innerHTML = innerHTML;
    }
    if (src) {
      script.src = src;
    }
    script.type = type;
    script.async = async;
    script.onload = () => {
      this.alreadyInjectedScripts[key].loaded = true;
      onLoad?.();
    };
    script.onerror = () => {
      this.alreadyInjectedScripts[key].error = true;
      onError?.();
    };
    return script;
  }

  private _insertScript(
    config: ScriptInjectionConfig,
    script: HTMLScriptElement
  ): void {
    const { target = 'head', injectBeforeOtherScripts = false } = config;
    const targetEl = this.document[target];

    if (injectBeforeOtherScripts && targetEl.firstElementChild) {
      targetEl.insertBefore(script, targetEl.firstElementChild);
      return;
    }

    targetEl.appendChild(script);
  }
}
