<div class="offers" [class.mt-4]="showCurrentContract && brand === 'ed'">
  <div [class.mt-lg-6]="!showCurrentContract">
    <wl-commodity-offer-header
      [alignment]="'vertical'"
      [headerTitle]="
        showCurrentContract
          ? content?.currentContractTitle
          : content?.componentTitle
      "
      [showBrandHeader]="!showCurrentContract"
    ></wl-commodity-offer-header>

    <!-- Current contract -->
    @if (showCurrentContract) {
      <div class="mb-4">
        @switch (brand) {
          @case ('essent') {
            <essent-consumer-retention-commodity-offer
              [offer]="currentContract"
              [variant]="'accordion'"
              [content]="content"
              (viewTariffs)="viewTariffs.emit(currentContract)"
              [selectableOffer]="false"
              wlTrackImpression="contentblock-impression"
              [trackImpressionInfo]="rendering"
              [trackImpressionName]="currentContractImpression"
            >
            </essent-consumer-retention-commodity-offer>
          }
          @case ('ed') {
            <ed-consumer-retention-commodity-offer
              [offer]="currentContract"
              [variant]="'accordion'"
              [content]="content"
              (viewTariffModal)="viewTariffs.emit(currentContract)"
              [selectableOffer]="false"
              wlTrackImpression="contentblock-impression"
              [trackImpressionInfo]="rendering"
              [trackImpressionName]="currentContractImpression"
            >
            </ed-consumer-retention-commodity-offer>
          }
        }
      </div>
    }

    <div class="mb-4">
      <wl-commodity-offer-calculation-based-on
        [variant]="'narrow'"
        [label]="content?.calculationBasedOnLabel"
        [address]="supplyAddress"
        [consumptions]="consumptionValues"
        [electricityLabel]="'ElectricityUsageUnit' | translate"
        [gasLabel]="'GasUsageUnit' | translate"
        [adjustCalculationLabel]="content?.adjustCalculationLabel"
        (adjustCalculation)="adjustCalculation.emit()"
      ></wl-commodity-offer-calculation-based-on>
    </div>
    @if (content?.offerFootNote || content?.componentFootNote) {
      <div class="d-none d-lg-block">
        <ng-container *ngTemplateOutlet="text"></ng-container>
      </div>
    }
    @if (content?.referenceIdLabel && flowId) {
      <wl-commodity-offer-reference-id
        [flowId]="flowId"
        [referenceIdLabel]="content?.referenceIdLabel"
      >
      </wl-commodity-offer-reference-id>
    }
    @if (content?.customerIdLabel && accountId) {
      <wl-commodity-offer-customer-information
        [customerId]="accountId"
        [customerIdLabel]="content?.customerIdLabel"
        class="col-12"
      ></wl-commodity-offer-customer-information>
    }
  </div>
  <div>
    @if (showCurrentContract) {
      <wl-commodity-offer-header
        [alignment]="'vertical'"
        [headerTitle]="content?.componentTitle"
        [showBrandHeader]="false"
      ></wl-commodity-offer-header>
    }

    <div class="mb-3 mb-lg-0 d-flex flex-column gap-3">
      <wl-commodity-offer-list
        [offers]="offers"
        [alignment]="'vertical'"
        [content]="content"
        [showMemberGetMember]="showMemberGetMember"
        (selectOffer)="selectOffer.emit($event)"
        (openMoreInfo)="openMoreInfo.emit($event)"
        (viewTariffs)="viewTariffs.emit($event)"
        wlAutomationId="vertical-commodity-offer-list"
      />

      @if (content?.offerSaver; as offerSaverContent) {
        @if (content?.offerSaverShown) {
          <wl-offer-saver-button [content]="offerSaverContent" />
        }
      }
    </div>

    <div class="d-lg-none">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </div>
</div>

<ng-template #text>
  @if (content?.offerFootNote) {
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.offerFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  }
  @if (content?.componentFootNote) {
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.componentFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  }
</ng-template>
