<div class="bg-light">
  <div class="d-flex justify-content-between align-items-center container">
    <div class="my-1">
      @if (openNavigation && openNavigation.value && openNavigation.label) {
        <a
          class="top-navigation--item open-link"
          wlTrackLink
          [trackLinkInfo]="{
            rendering: { componentName: { value: 'top-navigation' } },
            field: openNavigation.value,
            eventType: 'navigation-click',
          }"
          *wlGenericLink="openNavigation.value"
          wlCSCapture
        >
          {{ openNavigation.label }}
        </a>
      }
      @for (navItem of navItems; track navItem) {
        <a
          class="top-navigation--item"
          [class.active]="navItem?.active"
          *wlGenericLink="{ value: { href: navItem.path } }"
          wlCSCapture
        >
          {{ navItem.name }}
        </a>
      }
    </div>

    @if (serviceNavigationActive) {
      <div ngbDropdown placement="bottom-right">
        <button
          class="top-navigation--dropdown-button"
          wlAutomationId="serviceNavigation"
          id="serviceNavigation"
          ngbDropdownToggle
          title="Servicenavigation"
        >
          @if (!isLoggedIn) {
            <fa-icon icon="user"></fa-icon>
          }
          @if (isLoggedIn) {
            <fa-icon class="text-success" icon="user-check"></fa-icon>
          }
          <span wlCSCapture>{{ serviceNavigationTitle }}</span>
          <fa-icon
            class="top-navigation--menu-icon"
            icon="angle-down"
          ></fa-icon>
        </button>

        <div
          ngbDropdownMenu
          aria-labelledby="serviceNavigation"
          class="top-navigation--dropdown"
        >
          <wl-header-tools-container
            [loginUrl]="loginUrl"
            [loginUrlLabel]="loginUrlLabel"
          ></wl-header-tools-container>
        </div>
      </div>
    } @else {
      @if (
        serviceNavigationLink &&
        serviceNavigationLink.label &&
        serviceNavigationLink.value
      ) {
        <a
          wlAutomationId="serviceLink"
          [title]="serviceNavigationLink.label"
          class="p-0 d-flex align-items-center top-navigation--button-link"
          *wlGenericLink="serviceNavigationLink.value"
        >
          <fa-icon icon="user"></fa-icon>
          <span>{{ serviceNavigationLink?.label }}</span>
        </a>
      }
    }

    <ng-template #serviceLink>
      @if (
        serviceNavigationLink &&
        serviceNavigationLink.label &&
        serviceNavigationLink.value
      ) {
        <a
          wlAutomationId="serviceLink"
          [title]="serviceNavigationLink.label"
          class="p-0 d-flex align-items-center top-navigation--button-link"
          *wlGenericLink="serviceNavigationLink.value"
        >
          <fa-icon icon="user"></fa-icon>
          <span>{{ serviceNavigationLink?.label }}</span>
        </a>
      }
    </ng-template>
  </div>
</div>
