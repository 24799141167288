import { Component, Input } from '@angular/core';
import { SkeletonLoaderMask } from '@legacy/common-ui-shared';

@Component({
  selector: 'wl-horizontal-offer-loader',
  templateUrl: './horizontal-offer-loader.component.html',
  styleUrls: ['./horizontal-offer-loader.component.essent.scss'],
})
export class HorizontalOfferLoaderComponent {
  @Input() mask?: SkeletonLoaderMask;
}
