import {
  createNgrxFormReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import type {
  ChargePointType,
  ProductColor,
} from '@integration/api-client/emobility';
import type { MountType } from '@integration/emobility-api-models';
import type { Action } from '@ngrx/store';
import {
  createFormGroupState,
  updateGroup,
  validate,
  type FormGroupState,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

export interface ChargePointConfigurationFormState {
  chargePointType: `${ChargePointType}` | undefined;
  productColor: `${ProductColor}` | undefined;
  fixedCable: boolean | undefined;
  mountType: `${MountType}` | undefined;
}

export interface ChargePointConfigurationState {
  formState: FormGroupState<ChargePointConfigurationFormState>;
}

export const chargePointConfigurationFormId =
  'emobility-lead-charge-point-configuration';

export const chargePointColorControlId = `${chargePointConfigurationFormId}.productColor`;
export const chargePointTypeControlId = `${chargePointConfigurationFormId}.chargePointType`;
export const chargePointFixedCableControlId = `${chargePointConfigurationFormId}.fixedCable`;

const chargePointConfigurationInitialState =
  createFormGroupState<ChargePointConfigurationFormState>(
    chargePointConfigurationFormId,
    {
      chargePointType: undefined,
      productColor: undefined,
      fixedCable: undefined,
      mountType: undefined,
    }
  );

export const initialChargePointConfigurationState: ChargePointConfigurationState =
  {
    formState: chargePointConfigurationInitialState,
  };

export const validateChargePointConfigurationForm = (
  state: ChargePointConfigurationState
) => {
  return updateGroup(state.formState, {
    chargePointType: validate(required),
    productColor: validate(required),
    fixedCable: validate(required),
    mountType: validate(required),
  });
};

export const _reducer = createNgrxFormReducer(
  initialChargePointConfigurationState,
  chargePointConfigurationFormId,
  validateChargePointConfigurationForm
);

const _wrappedReducer = wrappedFormReducer(
  _reducer,
  validateChargePointConfigurationForm
);

export function chargePointConfigurationReducer(
  state: ChargePointConfigurationState | undefined,
  action: Action
) {
  return _wrappedReducer(state, action);
}
