import { Directive, HostListener, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[wlKeyboardNavigation]',
  standalone: true,
})
export class KeyboardNavigationDirective {
  activeTargetIndex = -1;
  discoveredTargets!: NodeList;

  readonly #el = inject(ElementRef);

  @HostListener('keydown', ['$event'])
  keydownEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.focusNext();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.focusPrev();
    }
  }

  refreshNavigationTargets() {
    const newTargets = this.#el.nativeElement.querySelectorAll(
      '[wlKeyboardNavigationTarget]'
    );

    // If no targets are present, just update the list of available nodes.
    if (!this.discoveredTargets) {
      this.discoveredTargets = newTargets;
      return;
    }

    // If targets are present, check if the list has changed.
    // If so, set the active target index to -1 (reset the focus indicator).
    if (
      newTargets.length !== this.discoveredTargets.length ||
      newTargets.item(1) !== this.discoveredTargets.item(1)
    ) {
      this.activeTargetIndex = -1;
    }

    // update the list of available nodes.
    this.discoveredTargets = newTargets;
  }

  focusPrev() {
    this.refreshNavigationTargets();
    if (this.activeTargetIndex <= 0) {
      this.activeTargetIndex = this.discoveredTargets.length - 1;
    } else {
      this.activeTargetIndex -= 1;
    }
    (
      this.discoveredTargets.item(this.activeTargetIndex) as HTMLElement
    )?.focus();
  }

  focusNext() {
    this.refreshNavigationTargets();
    if (this.activeTargetIndex === this.discoveredTargets.length - 1) {
      this.activeTargetIndex = 0;
    } else {
      this.activeTargetIndex += 1;
    }
    (
      this.discoveredTargets.item(this.activeTargetIndex) as HTMLElement
    )?.focus();
  }
}
