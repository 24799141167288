import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import {
  ACCOUNT_FEATURE_REDUCERS,
  accountReducers,
  accountSelectorKey,
} from './feature.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(accountSelectorKey, ACCOUNT_FEATURE_REDUCERS),
  ],
  providers: [{ provide: ACCOUNT_FEATURE_REDUCERS, useValue: accountReducers }],
})
export class AccountEntityModule {}
