<div class="d-block d-sm-inline-block">
  <button
    type="submit"
    wlCSCapture
    [disabled]="isDisabled || submitButtonLoading"
    class="btn btn-primary mt-2 w-100 btn--icon-prefixed submit-button"
    [ngClass]="{ loading: submitButtonLoading }"
    [wlAutomationId]="submitButtonAutomationId"
    (click)="submitProgessiveForm.emit()"
  >
    @if (!submitButtonLoading) {
      {{ submitButtonText }}
      @if (submitButtonIcon) {
        <fa-icon icon="{{ submitButtonIcon }}" class="ms-2"></fa-icon>
      }
    } @else {
      <fa-icon
        class="icon icon--spinner"
        animation="spin"
        icon="spinner-third"
      ></fa-icon>
    }
  </button>
</div>
@if (isEditingCurrentStep) {
  <div class="d-block d-sm-inline-block">
    <button
      type="button"
      wlCSCapture
      class="btn btn-link mt-2 w-100"
      (click)="cancelEditingFormStep.emit()"
    >
      {{ 'CancelChanges' | translate }}
    </button>
  </div>
}
