import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  KEYWORD_QUERYSTRING_NAME,
  SEARCH_RESULTS_URL,
} from '@innogy/search-services';
import { KeyboardNavigationTargetDirective } from '@innogy/utils-accessibility';

@Component({
  selector: 'wl-search-suggestion',
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, KeyboardNavigationTargetDirective],
  templateUrl: './search-suggestion.component.html',
  styleUrls: ['./search-suggestion.component.essent.scss'],
})
export class SearchSuggestionComponent {
  readonly searchResultsPage = SEARCH_RESULTS_URL;

  @Input({ required: true }) query = '';
  @Input({ required: false }) value = '';

  /**
   * Maps a query to a spec compliant query string for search.
   * @param query search query
   * @returns queryParams object containing the querystring param for the search service
   */
  params(query: string) {
    return { [KEYWORD_QUERYSTRING_NAME]: query };
  }
}
