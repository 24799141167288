import type { ToolTrackingConfig } from '@core/analytics';
import { createToolStepConfig, parseToolConfig } from '@core/analytics';

import { selectTariffBreakdownAnalyticsPayload } from './tariff-breakdown.selectors';

const tariffBreakdownSteps = [
  {
    step: 0,
    stepName: 'tarief berekening',
    id: 'calculation',
  },
] as const;

export type TariffBreakdownToolTrackingSteps =
  (typeof tariffBreakdownSteps)[number]['id'];

const tariffBreakdownToolTrackingConfig: ToolTrackingConfig<TariffBreakdownToolTrackingSteps> =
  {
    toolName: 'tarief berekening',
    steps: tariffBreakdownSteps,
    toolComplete: createToolStepConfig(
      selectTariffBreakdownAnalyticsPayload,
      (action) => ({
        step: 1,
        payload: {
          postcode: action.postalCode,
        },
      })
    ),
  };

export const parsedTariffBreakdownToolTrackingConfig = parseToolConfig(
  tariffBreakdownToolTrackingConfig
);
