import { Injectable } from '@angular/core';
import { LocationService } from '@core/jss-seo';
import { PlatformService } from '@core/platform';
import { postQuotationReaction } from '@essent/contract';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';

import {
  navigateToPageAction,
  sendQuotationReactionAction,
} from './email-quotation-reaction.actions';

@Injectable()
export class EmailQuotationReactionEffects {
  public readonly onSendQuotationReactionAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendQuotationReactionAction),
      filter(() => this.platformService.isClient()),
      map(({ token }) =>
        postQuotationReaction({
          payload: { token },
        })
      )
    )
  );
  public readonly redirectOnQuotationReaction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToPageAction),
        filter(({ page: { href } }) => !!href),
        tap(({ page: { href } }) =>
          this.locationService.navigateGeneralLink(href as string)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly locationService: LocationService,
    private readonly platformService: PlatformService
  ) {}
}
