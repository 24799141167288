import type { SkeletonLoaderMask } from '@legacy/common-ui-shared';

export const accordionHeaderMask: SkeletonLoaderMask = {
  mask: '<svg width="382" height="58" viewBox="0 0 382 58" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="172" height="30" rx="2" fill="#F0F1F2"/><rect x="277" y="6" width="105" height="44" rx="2" fill="#D5D5DB"/><rect y="34" width="172" height="24" rx="2" fill="#F0F1F2"/></svg>',
  naturalHeight: 58,
  naturalWidth: 382,
};

export const accordionBodyMask: SkeletonLoaderMask = {
  mask: '<svg width="398" fill="none" xmlns="http://www.w3.org/2000/svg" height="275" viewBox="0 0 398 275"><rect width="398" height="24" rx="2" fill="#F0F1F2" y="0"/><rect x="26" width="348" height="24" rx="2" fill="#F0F1F2" y="251"/><rect height="32" fill="#F0F1F2" width="398" y="32"/><rect width="398" height="32" rx="2" fill="#F0F1F2" y="66"/><rect width="398" height="32" rx="2" fill="#F0F1F2" y="100"/><rect width="398" height="49" rx="2" fill="#D5D5DB" y="186"/><rect width="234" height="22" rx="2" fill="#F0F1F2" y="148"/></svg>',
  naturalHeight: 364,
  naturalWidth: 398,
};

export const cardMask: SkeletonLoaderMask = {
  mask: '<svg width="311" height="348" viewBox="0 0 311 348" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="172" height="30" rx="2" fill="#F0F1F2"/><rect x="235" width="76" height="44" rx="2" fill="#D5D5DB"/><rect y="34" width="172" height="24" rx="2" fill="#F0F1F2"/><rect y="73" width="311" height="24" rx="2" fill="#F0F1F2"/><rect x="20" y="324" width="272" height="24" rx="2" fill="#F0F1F2"/><rect y="105" width="311" height="32" rx="2" fill="#F0F1F2"/><rect y="139" width="311" height="32" rx="2" fill="#F0F1F2"/><rect y="173" width="311" height="32" rx="2" fill="#F0F1F2"/><rect y="259" width="311" height="49" rx="2" fill="#D5D5DB"/><rect y="221" width="228" height="22" rx="2" fill="#F0F1F2"/></svg>',
  naturalHeight: 348,
  naturalWidth: 311,
};
