@if (control && control.enabled) {
  <div class="form-section">
    <rfa-label [control]="control"></rfa-label>
    @if (newDesign) {
      <rfa-errors [control]="control" [newDesign]="true"></rfa-errors>
    }
    <div
      class="radio-group"
      [wlTrackValueChange]="valueChangeTrackConfig()"
      [id]="control.id"
    >
      @for (entry of entries; track entry; let i = $index) {
        <label>
          <input
            type="radio"
            class="form-check"
            [id]="i + '-' + control.id"
            [name]="radioGroupName"
            [value]="entry.value"
            [formControl]="control"
          />
          <span>{{ entry.text }}</span>
        </label>
      }
    </div>
    @if (!newDesign) {
      <rfa-errors [control]="control"></rfa-errors>
    }
  </div>
}
