<wl-partial-card
  [divider]="linedTitle"
  wlTrackImpression="contentblock-impression"
  [trackImpressionName]="trackImpressionName"
  [wlAutomationId]="automationId"
  [trackImpressionInfo]="rendering"
  class="mb-3"
>
  @if (title?.value) {
    <ng-container slot="header">
      <div class="d-flex align-items-baseline h3">
        @if (icon) {
          <fa-icon [icon]="icon" class="me-1"></fa-icon>
        }
        <h3
          class="card-title"
          *scText="title"
          [wlAutomationId]="automationId + '-title'"
        ></h3>
      </div>
    </ng-container>
  }
  <ng-container slot="content">
    @if (message?.value) {
      <div class="mb-3">
        <wl-rich-text-wrapper
          [field]="
            interpolationOptions
              ? (message
                | interpolate
                  : {
                      mode: interpolationOptions.mode,
                      variables: interpolationOptions.variables,
                    })
              : message
          "
          [rendering]="rendering"
        >
          <p
            [wlAutomationId]="automationId + '-content'"
            class="mb-1"
            [ngClass]="{ 'text-body-secondary': sideNote }"
          ></p>
        </wl-rich-text-wrapper>
      </div>
    }

    <div
      class="d-block d-md-flex justify-content-between align-items-center flex-column flex-md-row"
      role="group"
    >
      @if (link?.value?.href) {
        <div class="flex-grow-1 flex-md-grow-0 order-0 order-md-1 mb-2 mb-md-0">
          <a
            class="w-100"
            [ngClass]="{
              'btn btn-outline-primary': buttonType === 'button',
              'card-link': buttonType === 'link',
            }"
            [wlAutomationId]="automationId + '-primary-button'"
            *wlGenericLink="link; extras: navigationExtras"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: rendering,
              field: link?.value?.text,
              type: 'text',
              linkurl: link?.value?.href,
              eventType: 'link-click',
            }"
          ></a>
        </div>
      }
      @if (linkSecondary?.value?.href && buttonType === 'button') {
        <div class="flex-grow-1 flex-md-grow-0 order-1 order-md-0">
          <a
            class="btn btn-outline-secondary w-100"
            [wlAutomationId]="automationId + '-secondary-button'"
            *wlGenericLink="linkSecondary; extras: navigationExtras"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: rendering,
              field: linkSecondary?.value?.text,
              type: 'text',
              linkurl: linkSecondary?.value?.href,
              eventType: 'link-click',
            }"
          ></a>
        </div>
      }
    </div>

    <ng-content></ng-content>
  </ng-container>
</wl-partial-card>
